import { Store } from '@/Core/Models/Stores';
import { Component, MapGetter, Vue } from 'types-vue'
import { UploadedFile, UploadedFileState } from '../../models';

@Component
export default class ResultSection extends Vue {
  
  @MapGetter(Store.Uploader) protected files: UploadedFile[];

  protected activeName: string = ''

  protected getTitleIcon(state): string {
    switch (state) {
      case UploadedFileState.done: return 'el-icon-circle-check'
      case UploadedFileState.error: return 'el-icon-circle-close'
      case UploadedFileState.invalid: return 'el-icon-warning-outline'
      case UploadedFileState.uploading: return 'el-icon-loading'
    }
  }

  protected onClickOtherFile(): void {
    this.$emit('clickOtherFile')
  }
}