import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Validate: `${CORE_SERVICE_URL_BASE}/files/validate`
}

class FilesApiSingleton extends AbstractApi {

  private static _instance: FilesApiSingleton
  private constructor() { super() }

  public static get instance(): FilesApiSingleton {
    if (!FilesApiSingleton._instance) FilesApiSingleton._instance = new FilesApiSingleton();
    return FilesApiSingleton._instance;
  }
  
  
  public getValidateResult(keyname: string): AxiosPromise {
    const method = Method.POST
    const url = Path.Validate
    const data = { keyname }

    return this.request({ method, url, data })
  }

  
}

export const FilesApi = FilesApiSingleton.instance