import _ from 'lodash'
import { Component, Prop, Vue } from 'types-vue'
import { FilesApi } from '@/Modules/Processes/connections/api/Files'

@Component
export default class ValidationResult extends Vue {

  @Prop({ required: true, type: String })
  protected keyname: string


  /**
   * STATE
   */

  protected drawerVisible: boolean = false
  protected loading: boolean = true
  protected validationErrors = []



  /**
   * GETTERS
   */
  protected get filename(): string {
    return _.last(this.keyname.split('/'))
  }



  /**
   * EVENTS
   */

  protected async onClickButton() {
    this.drawerVisible = true
    if (_.isEmpty(this.validationErrors))
      return await this.obtainResult()
  }

  protected onCloseDrawer() {
    this.drawerVisible = false
  }



  /**
   * METHODS
   */

  protected async obtainResult() {
    try {
      this.loading = true
      const response = await FilesApi.getValidateResult(this.keyname)
      this.validationErrors = response.data.body.error.filter((item: string) => !item.includes('OK'))
    } catch (error) {
      this.drawerVisible = false
      switch (error.response.data.code) {
        case '12':
          this.$notify({ title: 'Error al obtener los datos', message: 'No se ha encontrado la verificación de este fichero', type: 'error' })
          break;
        default:
          this.$notify({ type: 'error', title: 'Error', message: 'Error al obtener los datos de la validación'})
          break;
      }
    } finally {
      this.loading = false
    }
  }
}