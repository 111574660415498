import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { ProcessModel } from '@/Modules/Processes/models'
import { property } from 'lodash'
import { Component, mixins, Prop, Vue } from 'types-vue'

@Component
export default class ProcessTypeColumn extends mixins(FiltersMixin) {

  @Prop({ required: true }) protected data: ProcessModel
  @Prop({ default: false, type: Boolean }) protected withoutTooltip: boolean


  protected get code(): string {
    return this.data.processCode
  }

  protected get description(): string {
    return `${this.processCodeFilter(this.code)}: ${this.data.processDescription}`
  }
}