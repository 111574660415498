import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClientForm1 extends Vue {

  protected get formOptions(): FormConfig {
    return {
      label: 'Cliente',
      submitButton: 'Siguiente',
      items: [
        { type: 'select', name: 'titulartype', label: 'Tipo de persona titular', options: `${CORE_URL}/client-person-types`, placeholder: 'Selecciona tipo persona titular', valueQuery: 'code' },
        { type: 'select', name: 'nationality', label: 'Nacionalidad', options: `${CORE_URL}/location-nationalities`, placeholder: 'Selecciona nacionalidad', valueQuery: 'code' },
        { type: 'select', name: 'documenttype', label: 'Tipo de documento', options: `${CORE_URL}/client-document-types`, placeholder: 'Selecciona tipo de documento', valueQuery: 'code' },
        { type: 'text', name: 'documentnum', label: 'Numero de documento', placeholder: 'Introduce el numero de documento', maxCharacters: 20 },
        { type: 'select', name: 'language', label: 'Idioma', options: `${CORE_URL}/general-languages`, placeholder: 'Selecciona idioma', valueQuery: 'code' },
      ],
      rules: {
        documenttype: [{ required: true, trigger: 'blur', message: 'El tipo de documento es obligatorio' }],
        documentnum: [{ required: true, trigger: 'blur', message: 'El numero de documento es obligatorio' }],
        nationality: [{ required: true, trigger: 'blur', message: 'La nacionalidad es obligatoria' }],
      }
    }
  }

  protected change(process: any) {
    const titulartype = process.titulartype;
    localStorage.setItem("p38_titulartype_json", titulartype);
  }

  protected onSubmit(model) {
    // Form constants.
    const nationality = model.nationality;
    const documenttype = model.documenttype;

    // DOCUMENT TYPE LOGIC.
    if (nationality !== "" && documenttype !== "") {
      if (nationality === "ES") {
        if (documenttype !== "01" && documenttype !== "03") {
          this.$notify.error({ title: 'Error en el tipo de documentación', message: "Solamente puedes poner el 01(NIF) o el 03(PASAPORTE)" });
        } else {
          this.$emit('submit', model)
        }
      } else {
        if (documenttype === "01") {
          this.$notify.error({ title: 'Error en el tipo de documentación', message: "No puedes seleccionar el 01(NIF)" });
        } else {
          this.$emit('submit', model)
        }
      }
    }
  }
}