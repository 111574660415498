import { ElUploadInternalFileDetail } from "element-ui/types/upload";


export interface UploadedFileFileResult {
  validationResponseVoList: {
    keyname: string
    body: {
      result: boolean
      error: string[]
    }
  }[]
}

export enum UploadedFileState {
  uploading = 'uploading',
  done = 'done',
  error = 'error',
  invalid = 'invalid'
}

export interface UploadedFile {
  file: ElUploadInternalFileDetail
  error?: string
  result?: UploadedFileFileResult
  state: UploadedFileState
}