import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import List from './pages/List/List.vue'
import Create from './pages/Create/Create.vue'

export enum NoContractPages {
  Main = 'withoutContract',
  Create = 'createContract'
}

const routes: Array<RouteConfig> = [
  {
    path: '/no-contract',
    component: Page,
    children: [
      {
        path: '',
        name: NoContractPages.Main,
        meta: { menuActive: 'withoutContract' },
        component: List
      },
      {
        path: 'create',
        name: NoContractPages.Create,
        meta: { menuActive: 'withoutContract' },
        component: Create
      }
    ]
  }
]

export default routes