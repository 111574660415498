var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Cups-DetailsSection",
        { attrs: { title: "Cliente" } },
        [_c("Core-DataSection", { attrs: { elements: _vm.generalElements } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }