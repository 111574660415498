import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { FlowProcessesApi } from '../connections/api/FlowProcesses'
import { FlowProcessesStepsApi } from '../connections/api/FlowProcessSteps'
import { FlowProcessDistribution, ProcessDocument, ProcessModel, ProcessStepModel } from '../models'
import { Notification } from 'element-ui'
import { AxiosError } from 'axios'
import { ProcessDocumentsApi } from '../connections/api/ProcessDocuments'


@Module({ namespaced: true })
export default class extends VuexModule {
  
  private _process: ProcessModel = null
  private _processSteps: ProcessStepModel[] = null
  private _distribution: FlowProcessDistribution = null
  private _uploadDocumentsLoading: boolean = false
  private _documents: ProcessDocument[] = []


  

  @Getter() public process(): ProcessModel { return this._process }
  @Getter() public processSteps(): ProcessStepModel[] { return this._processSteps }
  @Getter() public distribution(): FlowProcessDistribution { return this._distribution }
  @Getter() public documents(): ProcessDocument[] { return this._documents }
  @Getter() public uploadDocumentsLoading(): boolean { return this._uploadDocumentsLoading }



  @Mutation() protected setNewProcess(process: ProcessModel) { this._process = process }
  @Mutation() protected setDistribution(value: FlowProcessDistribution) { this._distribution = value }
  @Mutation() protected setUploadDocumentsLoading(value: boolean) { this._uploadDocumentsLoading = value }


  @Mutation() protected setNewProcessSteps(processSteps: ProcessStepModel[]) {
    this._processSteps = _.sortBy(processSteps, ['createdAt'], ['asc']).reverse();
  }

  @Mutation() protected setDocuments(documents: string[]) {
    this._documents = documents.map(filename => ({ filename, extension: _.last(filename.split('.')) }))
  }



  @Action({ useContext: true })
  protected async initDetails(ctx: ActionContext<any, any>, processId: string): Promise<any> {
    const processResponse = await FlowProcessesApi.getProcessById(processId)
    const haveProcess = processResponse.data.length > 0
    const flowProcess = processResponse.data[0]

    if (!haveProcess) throw 'Process not found';

    try {
      const processStepsResponse = await FlowProcessesStepsApi.getStepsByProcessId(processId)
      ctx.commit('setNewProcess', flowProcess)
      ctx.commit('setNewProcessSteps', processStepsResponse.data)
    
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los detalles del proceso.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }
    
    
    

    if (flowProcess.processCode === '09') {
      const flowProcessDistributionResponse = await FlowProcessesApi.getFlowProcessDistribution(flowProcess.id)
      ctx.commit('setDistribution', flowProcessDistributionResponse.data[0])
    }
  }


  @Action({ useContext: true })
  protected async getDocuments(ctx: ActionContext<any, any>): Promise<any> {
    const process = ctx.getters.process as ProcessModel
    try {
      const response = await ProcessDocumentsApi.getFileList(process.id)
      ctx.commit('setDocuments', response.data.objects)
    } catch (error) {
      console.error(error)
    }
  }


  @Action({ useContext: true })
  protected async uploadFiles(ctx: ActionContext<any, any>, eventFiles): Promise<any> {
    try {
      const files: File[] = Object.values(eventFiles)
      const process = ctx.getters.process as ProcessModel
      
      ctx.commit('setUploadDocumentsLoading', true)
  
      for (const file of files) {
        const response = await ProcessDocumentsApi.getPresignedUploadUrl(process.id, file.name)
        await ProcessDocumentsApi.uploadDocument(response.data.url, file)
      }
      
      await ctx.dispatch('getDocuments')

      Notification.success('Fichero subido correctamente')

    } catch (error) {
      console.error(error)
      Notification.error('Error al subir los ficheros, inténtalo otra vez')
    } finally {
      ctx.commit('setUploadDocumentsLoading', false)
    }
  }

  @Action({ useContext: true })
  protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setNewProcess', null)
    ctx.commit('setNewProcessSteps', null)
    ctx.commit('setDocuments', [])
  }
}
