import { AtrContractsApi } from '@/Modules/AtrContracts/connections/api/AtrContracts'
import { AtrContract } from '@/Modules/AtrContracts/models'
import { BillsApi } from '@/Modules/Bills/connections/api/Bills'
import { Bill } from '@/Modules/Bills/models'
import { CupsApi } from '@/Modules/Cups/connections/api/Cups'
import { FlowProcessesApi } from '@/Modules/Processes/connections/api/FlowProcesses'
import { ProcessModel } from '@/Modules/Processes/models'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { Notification } from 'element-ui'
import { AxiosError } from 'axios'




@Module({ namespaced: true })
export default class Result extends VuexModule {
  
  private _cups: string = null
  private _loading: boolean = false
  private _process: ProcessModel[] = []
  private _totalProcess: number = null
  private _bills: Bill[] = []
  private _totalBills: number = null
  private _atrContracts: AtrContract[] = []
  private _totalAtrContracts: number = null
  private _cupsInfo: any[] = []
  private _totalCupsInfo: number = null


  


  @Getter() public cups(): string { return this._cups }
  @Getter() public loading(): boolean { return this._loading }
  @Getter() public process(): ProcessModel[] { return this._process }
  @Getter() public totalProcess(): number { return this._totalProcess }
  @Getter() public bills(): Bill[] { return this._bills }
  @Getter() public totalBills(): number { return this._totalBills }
  @Getter() public atrContracts(): AtrContract[] { return this._atrContracts }
  @Getter() public totalAtrContracts(): number { return this._totalAtrContracts }
  @Getter() public cupsInfo(): any[] { return this._cupsInfo }
  @Getter() public totalCupsInfo(): number { return this._totalCupsInfo }




  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setCups(value: string) { this._cups = value }
  @Mutation() protected setProcess(value: ProcessModel[]) { this._process = value }
  @Mutation() protected setTotalProcess(value: number) { this._totalProcess = value }
  @Mutation() protected setBills(value: Bill[]) { this._bills = value }
  @Mutation() protected setTotalBills(value: number) { this._totalBills = value }
  @Mutation() protected setAtrContracts(value: AtrContract[]) { this._atrContracts = value }
  @Mutation() protected setTotalAtrContracts(value: number) { this._totalAtrContracts = value }
  @Mutation() protected setCupsInfo(value: any[]) { this._cupsInfo = value }
  @Mutation() protected setTotalCupsInfo(value: number) { this._totalCupsInfo = value }



  @Action({ useContext: true })
  protected async search(ctx: ActionContext<any, any>, payload: { cups: string }) {
    const { cups } = payload

    try {
      ctx.commit('setLoading', true)
      const cupsInfo = await CupsApi.getCups(payload.cups)
      const processResponse = await FlowProcessesApi.getProcessesList({ pageNumber: 0, pageSize: 3, fieldOrder:'createdAt', orderASCorDESC: 'DESC', cupsList: cups })
      const billResponse = await BillsApi.getFilteredFlowBills({ pageNumber: 0, pageSize: 3, cupsList: cups , fieldOrder: 'billDate', orderASCorDESC: 'DESC' })
      const atrContractsResponse = await AtrContractsApi.getFiltered({ pageNumber: 0, pageSize: 3, fieldOrder: 'effectiveStartDate', orderASCorDESC: 'DESC', cupsList: cups })

      ctx.commit('setProcess', processResponse.data.flowProcessVos)
      ctx.commit('setTotalProcess', processResponse.data.totalElements)
      ctx.commit('setBills', billResponse.data.flowBillVos)
      ctx.commit('setTotalBills', billResponse.data.totalElements)
      ctx.commit('setAtrContracts', atrContractsResponse.data.atrContracts)
      ctx.commit('setTotalAtrContracts', atrContractsResponse.data.totalElements)
      ctx.commit('setCupsInfo', cupsInfo.data)
      ctx.commit('setTotalCupsInfo', cupsInfo.data.length)  
    
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al buscar el cups.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setProcess', [])
    ctx.commit('setTotalProcess', null)
    ctx.commit('setBills', [])
    ctx.commit('setTotalBills', null)
    ctx.commit('setAtrContracts', [])
    ctx.commit('setTotalAtrContracts', null)
    ctx.commit('setCupsInfo', [])
    ctx.commit('setTotalCupsInfo', null)
  }
}
