var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isUploaded
      ? _c("div", [
          _c("i", { staticClass: "el-icons-loading" }),
          _vm._v(" Subiendo ficheros ")
        ])
      : _vm.isError
      ? _c(
          "div",
          [
            _c(
              "el-alert",
              { attrs: { type: "error", "show-icon": "", closable: false } },
              [_vm._v(_vm._s(_vm.errorMessage))]
            )
          ],
          1
        )
      : _vm.isDone
      ? _c(
          "div",
          [
            _c(
              "el-alert",
              { attrs: { type: "success", "show-icon": "", closable: false } },
              [_vm._v("Fichero subido correctamente")]
            )
          ],
          1
        )
      : _vm.isInvalid
      ? _c(
          "div",
          [
            _c(
              "el-alert",
              { attrs: { type: "warning", "show-icon": "", closable: false } },
              [
                _vm._v(
                  "Revisa los errores de la validación y vuelve a subir el fichero"
                )
              ]
            ),
            _vm.isInvalid
              ? _c(
                  "div",
                  _vm._l(_vm.validationResultList, function(fileResult, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        !fileResult.body.result
                          ? _c("Uploader-ValidationErrorList", {
                              attrs: { validationErrors: fileResult.body.error }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }