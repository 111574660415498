var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "el-select",
        {
          attrs: { placeholder: "Select", size: "small" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        _vm._l(_vm.options, function(item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: item.label, value: index }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "el-icon-sort" }),
      _vm._v(" Ordenar por:")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }