import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { CurApi } from '../connections/api/Cur'
import { SystemSubjectsApi } from '../connections/api/SystemSubjects'
import { SystemSubject } from '../models'


@Module({ namespaced: true })
export default class SendCur extends VuexModule {
  
  private _systemSubjectsOrigin: SystemSubject[] = null
  private _systemSubjectsDestination: SystemSubject[] = null
  private _loading: boolean = false

  
  @Getter() public systemSubjectsOrigin(): SystemSubject[] { return this._systemSubjectsOrigin }
  @Getter() public systemSubjectsDestination(): SystemSubject[] { return this._systemSubjectsDestination }
  @Getter() public loading(): boolean { return this._loading }


  @Mutation() protected setSystemSubjectsOrigin(value: SystemSubject[]) { this._systemSubjectsOrigin = value }
  @Mutation() protected setSystemSubjectsDestination(value: SystemSubject[]) { this._systemSubjectsDestination = value }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }


  @Action({ useContext: true })
  protected async initForm(ctx: ActionContext<any, any>): Promise<any> {
    
    try {
      
      ctx.commit('setLoading', true)
      
      const responseSSDestination = await SystemSubjectsApi.get({ cur: true })
      const responseSSOrigin = await SystemSubjectsApi.get({ cur: false, disabled: true })
      
      ctx.commit('setSystemSubjectsOrigin', responseSSOrigin.data)
      ctx.commit('setSystemSubjectsDestination', responseSSDestination.data)
      
    } catch (err) {
      
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los datos del formulario.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async sendCur(ctx: ActionContext<any, any>, payload: { origin: string, destination: string, cups: string[], reqTransferDate: string }): Promise<any> {
    try {
      await CurApi.handover({
        destinyCUR: payload.destination,
        originMarketer: payload.origin,
        cups: payload.cups,
        reqTransferDate: payload.reqTransferDate
      })
      Notification.success({ title: 'Enviado', message: 'Se ha enviado el CUR correctamente'})
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al enviar el CUR.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }
  }
}
