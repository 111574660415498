var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.customClass },
    [
      _c(
        "vue-timeline-update",
        {
          attrs: {
            date: new Date(_vm.item.createdAt),
            dateString: _vm.dateFromNow,
            title: _vm.title,
            category: _vm.code,
            "is-last": _vm.isLast,
            color: _vm.timelineColor,
            icon: "",
            theme: "light"
          }
        },
        [
          _c("div", { staticClass: "card-content" }, [
            _c(
              "div",
              { staticClass: "left-content" },
              [
                _c("div", { staticClass: "item confirmation file-validator" }, [
                  _c("i", { class: _vm.validatedIcon }),
                  _c("span", [_vm._v(_vm._s(_vm.validatedLabel) + " ")])
                ]),
                !_vm.item.validated
                  ? _c("Processes-ValidationResult", {
                      attrs: { keyname: _vm.item.keyname }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "right-content" }, [
              _c(
                "div",
                { staticClass: "file-options" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-download" },
                      on: { click: _vm.downloadProcessStep }
                    },
                    [_vm._v("Descargar fichero ")]
                  ),
                  _vm.canEdit && _vm.isAdminPermission
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: { click: _vm.goToEdit }
                        },
                        [_vm._v("Editar")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.item.downloaded
                ? _c(
                    "div",
                    { staticClass: "item date" },
                    [
                      _c("el-alert", {
                        attrs: {
                          title: "Ya descargado",
                          type: "info",
                          closable: false,
                          description:
                            "El fichero ha sido previamente descargado",
                          "show-icon": ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "item date" }, [
                _vm._v(" Creado el " + _vm._s(_vm.date))
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }