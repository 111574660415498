var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var tablet = ref.tablet
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                !_vm.processes.length && !_vm.loading
                  ? _c("span", { staticClass: "no-process" }, [
                      _vm._v("No hay processos")
                    ])
                  : _c(
                      "el-table",
                      {
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "empty-text": _vm.emptyText,
                          data: _vm.processes
                        },
                        on: {
                          "cell-mouse-leave": _vm.onCellMouseLeave,
                          "cell-mouse-enter": _vm.onCellMouseEnter,
                          "selection-change": _vm.onSelectionChange
                        }
                      },
                      [
                        _vm.withSelect
                          ? _c("el-table-column", {
                              attrs: { type: "selection", width: "55" }
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: "ESTADO",
                            prop: "status",
                            width: "140"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Processes-StateColumn", {
                                      attrs: { state: scope.row.status }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "TIPO DE PROCESO",
                            prop: "masterProcess.code",
                            "min-width": "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Processes-ProcessTypeColumn", {
                                      attrs: { data: scope.row }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        !tablet
                          ? _c("el-table-column", {
                              attrs: {
                                label: "ÚLTIMO PASO",
                                prop: "lastStep.code",
                                "min-width": "150"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("Processes-LastStepColumn", {
                                          attrs: { data: scope.row }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e(),
                        _vm.showMarketerInfo
                          ? _c("el-table-column", {
                              attrs: {
                                label: "COM. SOLICITANTE",
                                prop: "marketerCode",
                                "min-width": "150"
                              }
                            })
                          : _vm._e(),
                        _vm.showMarketerInfo
                          ? _c("el-table-column", {
                              attrs: {
                                label: "COM. SALIENTE",
                                prop: "outgoingMarketer",
                                "min-width": "150"
                              }
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: "REFERENCIA",
                            prop: "numreference",
                            "min-width": "200"
                          }
                        }),
                        !tablet
                          ? _c("el-table-column", {
                              attrs: {
                                label: "CUPS",
                                prop: "cups",
                                "min-width": "200"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("Core-CupsColumn", {
                                          attrs: {
                                            cups: scope.row.cups,
                                            index: scope.$index
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e(),
                        !tablet
                          ? _c("el-table-column", {
                              attrs: {
                                label: "MOTIVO",
                                prop: "processReason",
                                "min-width": "150"
                              }
                            })
                          : _vm._e(),
                        !tablet
                          ? _c("el-table-column", {
                              attrs: {
                                label: "INICIADO",
                                prop: "createdAt",
                                width: "150"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("Core-TimeColumn", {
                                          attrs: { time: scope.row.createdAt }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e(),
                        !tablet && _vm.showDownloadHistory
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.messageTypeDownloadedColumnLabel,
                                prop: "hasAnyMessageBeenDownloaded",
                                width: "150"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "Processes-HasBeenDownloadedColumn",
                                          { attrs: { data: scope.row } }
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e(),
                        !tablet
                          ? _c("el-table-column", {
                              attrs: {
                                label: "",
                                width: "120",
                                align: "center",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickDetails(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v("Detalles "),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-top-right"
                                                })
                                              ]
                                            ),
                                            _c("span")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: { label: "", width: "50", fixed: "right" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "buttons-container" },
                                      [
                                        _c("Processes-ListItemMenu", {
                                          attrs: {
                                            process: scope.row,
                                            "row-index": scope.$index
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }