var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var mobile = ref.mobile
          return [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.concepts,
                      "empty-text": "No hay conceptos"
                    }
                  },
                  [
                    !mobile
                      ? _c("el-table-column", {
                          attrs: { prop: "code", label: "CÓDIGO", width: "100" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { prop: "description", label: "DESCRIPCIÓN" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "amount", label: "IMPORTE", width: "180" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterMoney")(scope.row.amount)
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }