import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import { ActionContext } from 'vuex'
import MasterApiConnection from '../connections/api/Master'
import { MasterConfig, Masters, MastersListRequest } from '../models/Masters'
import { Notification } from 'element-ui'

@Module({ namespaced: true })
export default class Master extends VuexModule<any, any> {
  
  
  private _masterConfig: MasterConfig = null
  private _editorElement: any = null
  private _loading: boolean = false
  private _list: any[] = []
  private _page: number = 0
  private _size: number = 25
  private _totalElements: number = 0

  
  @Getter() public masterName(): string { return _.startCase(this?._masterConfig?.code) }
  @Getter() public masterConfig(): MasterConfig { return this._masterConfig }
  @Getter() public editorElement(): string { return this._editorElement }
  @Getter() public loading(): boolean { return this._loading }
  @Getter() public list(): any[] { return this._list }
  @Getter() public pageNumber(): number { return this._page }
  @Getter() public pageSize(): number { return this._size }
  @Getter() public totalElements(): number { return this._totalElements }

  @Getter()
  public properties(): string[] {
    const allKeys = _.flattenDeep(this._list.map(item => Object.keys(item)))
    const setUnique = new Set(allKeys)
    let keys = [...setUnique]

    keys = keys.filter(key => {
      return key !== 'id'
    })

    return keys
  }

  @Getter()
  public propertiesForForm(): string[] {
    const allKeys = _.flattenDeep(this._list.map(item => Object.keys(item)))
    const setUnique = new Set(allKeys)
    let keys = [...setUnique]

    keys = keys.filter(key => {
      return key !== 'id' && key !== 'createDate' && key !== 'updateDate'
    })

    return keys
  }

  @Mutation() public setMasterConfig(masterConfig: MasterConfig) {
    this._masterConfig = masterConfig
  }
  @Mutation() protected setEditorElement(masterItem) {
    this._editorElement = masterItem
  }
  @Mutation() protected setList(list: any[]) {
    this._list = list
  }
  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }

  @Mutation()
  protected addNewMasterItem(masterItem) {
    this._list = [masterItem, ...this._list]
  }

  @Mutation()
  protected setMasterItem(masterItem) {
    this._list = [...this._list.map(item => (item.id === masterItem.id ? masterItem : item))]
  }

  @Mutation()
  protected removeMasterItemMut(masterItem) {
    this._list = [...this._list.filter(item => item.id !== masterItem.id)]
  }

  @Mutation()
  protected setTotalElements(totalElements: number) {
    this._totalElements = totalElements
  }

  @Mutation()
  protected setPageSize(pageSize: number) {
    this._size = pageSize
  }

  @Mutation()
  protected setCurrentPage(currentPage: number) {
    this._page = currentPage
  }

  @Action({ useContext: true })
  protected async initMaster(ctx: ActionContext<any, any>, masterCode: string): Promise<any> {
    const masterConfig = Masters.searchByCode(masterCode)

    ctx.commit('setMasterConfig', masterConfig)

    try {
      ctx.commit('setLoading', true)

      const result = await MasterApiConnection.getList(masterConfig.apiSlug)
      const totalElements = result.data.totalElements
      const mastersList = result.data.content

      ctx.commit('setTotalElements', totalElements)
      ctx.commit('setList', mastersList)
      ctx.commit('setLoading', false)
    } catch (error) {
      Notification({
        type: 'error',
        title: `Error ${error.response.status}`,
        message: `Error al cargar los datos de '${masterCode}'`
      })
    }
  }

  @Action({ useContext: true, commit: 'addNewMasterItem' })
  protected async createMasterItem(ctx: ActionContext<any, any>, masterItem: any): Promise<any> {
    const masterConfig = ctx.getters.masterConfig as MasterConfig
    const result = await MasterApiConnection.createItem(masterConfig.apiSlug, masterItem)

    return result.data
  }

  @Action({ useContext: true, commit: 'setMasterItem' })
  protected async updateMasterItem(ctx: ActionContext<any, any>, masterItem: any): Promise<any> {
    const masterConfig = ctx.getters.masterConfig as MasterConfig
    const result = await MasterApiConnection.updateItem(masterConfig.apiSlug, masterItem)

    return result.data
  }

  @Action({ useContext: true, commit: 'removeMasterItemMut' })
  protected async removeMasterItem(ctx: ActionContext<any, any>, masterItem: any): Promise<any> {
    const masterConfig = ctx.getters.masterConfig as MasterConfig
    await MasterApiConnection.removeItem(masterConfig.apiSlug, masterItem)

    return masterItem
  }

  @Action({ commit: 'setEditorElement' })
  protected startEditor(element) {
    return element
  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ commit: 'setCurrentPage' })
  protected updateCurrentPage(currentPage) {
    return currentPage
  }

  @Action({ useContext: true })
  protected async obtainMasters(ctx: ActionContext<any, any>): Promise<any> {
    const masterConfig = ctx.getters.masterConfig as MasterConfig

    try {
      ctx.commit('setLoading', true)

      const params: MastersListRequest = {
        size: ctx.getters.pageSize,
        page: ctx.getters.pageNumber - 1
      }

      const result = await MasterApiConnection.getList(masterConfig.apiSlug, params)
      const mastersList = result.data.content

      ctx.commit('setList', mastersList)
      ctx.commit('setLoading', false)
    } catch (error) {
      Notification({
        type: 'error',
        title: `Error ${error.response.status}`,
        message: `Error al cargar los datos.'`
      })
    }
  }
}
