import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Vue } from 'types-vue'
import { Address } from '../../models'

@Component
export default class AddressesList extends Vue {

  /**
   * STORE
   */

  @MapGetter(Store.Addresses) protected addressesList: Address[]
  @MapGetter(Store.Addresses) protected listLoading: boolean


  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return (this.listLoading)
      ? 'CARGANDO CALLEJEROS...'
      : 'NO HAY CALLEJEROS A MOSTRAR'
  }

}
