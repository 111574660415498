import FormValidateMixin from '@/Core/mixins/FormValidate'
import _ from 'lodash'
import { Component, mixins, Prop } from 'types-vue'
import { FormConfig, ItemFormConfig } from '../../models'

const validator = (value: FormConfig) => {
  return !_.isNil(value.items) || !_.isNil(value.label)
}

@Component
export default class Form extends mixins(FormValidateMixin) {
  @Prop({ required: true, validator: validator }) protected options: FormConfig

  protected formModel: any = {}

  protected getComponent(item: ItemFormConfig): string {
    switch (item.type) {
      case 'text':
        return 'Forms-Input'
      case 'textarea':
        return 'Forms-Input'
      case 'select':
        return 'Forms-Select'
      case 'month':
        return 'Forms-Date'
      case 'date':
        return 'Forms-Date'
      case 'daterange':
        return 'Forms-Date'
      case 'divider':
        return 'Forms-Divider'
      case 'switch':
        return 'Forms-SwitchItem'
      case 'tag':
        return 'Forms-Tag'
      case 'time':
        return 'Forms-Time'
    }
  }

  protected get rules(): any {
    return this.options?.rules || {}
  }
  protected get hideRequiredAsterisk(): boolean {
    return this.options?.hideRequiredAsterisk || true
  }
  protected get submitButtonLabel(): string {
    return this.options?.submitButton || 'Submit'
  }
  protected get loading(): boolean {
    return this.options?.loading || false
  }
  protected get hideSubmit(): boolean {
    return this.options?.withoutSubmit || false
  }

  protected get disabled(): boolean {
    return this.options?.disabled || false
  }

  protected get items(): ItemFormConfig[] {
    return this.options.items.map(item => ({
      size: this.options.size,
      disabled: false,
      visible: true,
      ...item
    }))
  }

  private setDefaultModel() {
    this.options.items.forEach(item => {
      switch (item.type) {
        case 'tag':
          this.formModel[item.name] = []
          break
        case 'switch':
          this.formModel[item.name] = false
          break
        default:
          this.formModel[item.name] = ''
          break
      }
    })
  }

  protected async onChangeItem(payload: { item: ItemFormConfig; value: any }) {
    const { item, value } = payload
    this.formModel = { ...this.formModel, [item.name]: value }
    this.$emit('change', this.formModel)
  }

  protected async onSubmit() {
    if (!(await this.validateForm())) return this.$notify.warning('Revisa el formulario.')

    this.$emit('submit', this.formModel)
  }

  protected created() {
    this.setDefaultModel()
  }

  public clean() {
    this.setDefaultModel()
  }
}
