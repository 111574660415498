import { Store } from '@/Core/Models/Stores'
import ChangePasswordFormModel from '@/Modules/Auth/models/ChangePasswordForm'
import ChangePasswordBody from '@/Modules/Auth/models/connections/api/ChangePasswordBody'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

@Component
export default class ChangePasswordCard extends Vue {


  /**
   * STORE
   */

  @MapAction(Store.ChangePassword) private changePassword: (data: ChangePasswordBody) => Promise<any>
  @MapGetter(Store.Signin) private username: string

  

  /**
   * STATE
   */

  protected loading: boolean = false



  /**
   * EVENTS
   */

  protected async onSubmit(form: ChangePasswordFormModel) {
    try {
      this.loading = true
      
      await this.changePassword({
        email: this.username,
        newPassword: form.newPassword,
        oldPassword: form.oldPassword
      })

      this.$notify({
        type: 'success',
        title: this.$t('user userConfiguration.successTitle').toString(),
        message: this.$t('user userConfiguration.successMessage').toString()
      })
    } catch (error) {

      this.$notify({
        type: 'error',
        title: 'Error',
        message: this.$t('user userConfiguration.errorMessage').toString()
      })
      
    } finally {
      this.loading = false
    }
  }
}