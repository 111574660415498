import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
import { FormConfig, SelectOption } from '@/Modules/Forms/models'
import { ReportsApi } from '@/Modules/Reports/connections/api/Reports'
import { B70ReportParams } from '@/Modules/Reports/models/api'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue } from 'types-vue'


const CORE_URL = process.env.VUE_APP_CORE_URL


@Component
export default class B70Report extends Vue {
  
  
  protected showing: boolean = false
  protected loading: boolean = false
  protected processTypeOptions: SelectOption[] = []
  protected billTypeOptions: SelectOption[] = []

  
  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean  


  protected get isMRG(): boolean {
    return this.systemSubject.code === '0000' || this.systemSubject.code === '0234'
  }


  protected get formOptions(): FormConfig {
    return {
      label: 'form',
      submitButton: 'Generar informe',
      loading: this.loading,
      items: [
        {
          name: 'extension',
          type: 'select',
          label: 'Selecciona el tipo de reporte',
          default: 'csv',
          options: [{ label: 'CSV', value: 'csv'}, { label: 'Excel', value: 'xlsx'}]
        },
        {
          type: 'select',
          name: 'processType',
          label: 'Tipo de proceso',
          options: this.processTypeOptions,
          placeholder: 'Selecciona el tipo de proceso'
        },
        {
          type: 'select',
          name: 'billType',
          label: 'Tipo de factura',
          options: this.billTypeOptions,
          placeholder: 'Selecciona el tipo de proceso',
          multiple: true
        },
        { 
          name: 'date',
          type: 'daterange',
          label: 'Rango de fechas',
          dateLimit: '1m',
        },
        {
          type: 'select',
          name: 'marketer',
          label: 'Comercializadora',
          default: (!this.isMRG) ? this.systemSubject.code : null,
          visible: this.isMRG,
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          valueQuery:'code',
          clearable: true
        },
      ],
      rules: {
        date:        [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
        extension:   [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
        processType: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
        billType:    [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],

      }
    }
  }

  public show() {
    this.showing = true
  }

  protected async onSubmit(form) {
    try {
      const { processType, billType, extension, date, marketer } = form
      const params: B70ReportParams = {
        process_type: processType.toString(),
        bill_type: billType,
        start_date: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(date[1]).format('YYYY-MM-DD HH:mm:ss'),
        marketer_code: _.isEmpty(marketer) ? null : marketer,
      }
      
      this.loading = true
      const response = await ReportsApi.getB70Report(extension, params)
      window.open(response.data,'_blank');
    
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al generar el reporte.'
      
      console.error(err)
      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
      this.showing = false
    }

  }

  protected async created() {
    const processTypes = await ReportsApi.getB70ReportProcessTypes()  
    const billTypes = await ReportsApi.getB70ReportBillTypes()

    const validTypes = processTypes.data.filter(item => item.code === '31' || item.code === '32' || item.code === '33')

    validTypes.forEach(option => {
      this.processTypeOptions.push({
        value: option.code,
        label: `${option.code} - ${option.description}`
      })
    });

    billTypes.data.forEach(option => {
      this.billTypeOptions.push({
        value: option.code,
        label: `${option.code} - ${option.description}`
      })
    });
  }
}