import { Store } from '@/Core/Models/Stores';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { Bill } from '../../models';

@Component
export default class BillPage extends Vue {

  @MapAction(Store.BillDetails) protected initDetails: (id: number) => Promise<void>
  @MapAction(Store.BillDetails) protected clean: () => void
  @MapGetter(Store.BillDetails) protected bill: Bill
  @MapGetter(Store.BillDetails) protected loading: boolean
  @MapGetter(Store.BillDetails) protected error: boolean


  @Watch('$route.params.id', { immediate: true })
  protected async onChangeParamId(id: string) {
    await this.initDetails(parseInt(id))
  }

  beforeDestroy() {
    this.clean()
  }
}