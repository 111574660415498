import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { isEmpty, isNil, omitBy } from 'lodash'

import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { HistoryBill } from '../models'
import { HistoryBillsApi } from '../connections/api/HistoryBills'
import { Notification } from 'element-ui'
import { QueryParams } from '../definitions/QueryParams'

interface HistoryBillsFilters {
  cups?: string[]
  processType?: string[]
}

@Module({ namespaced: true })
export default class HistoryBills extends VuexModule {
  private _historyBills: HistoryBill[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _isLoading: boolean = false
  private _cupsFilter: string = null
  private _fromDateFilter: string = null
  private _toDateFilter: string = null
  private _fromBillDateFilter: string = null
  private _toBillDateFilter: string = null
  private _billTypeFilter: string = null
  private _billNumberFilter: string = null
  private _pseudoBillNumberFilter: string = null
  private _marketerCodeFilter: string = null
  private _processTypeFilter: string = null

  @Getter() public historyBills(): HistoryBill[] {
    return this._historyBills
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public isLoading(): boolean {
    return this._isLoading
  }

  @Getter() public allProcessFilters(): HistoryBillsFilters {
    const filters = {
      cups: this._cupsFilter,
      fromDate: this._fromDateFilter,
      toDate: this._toDateFilter,
      fromBillDate: this._fromBillDateFilter,
      toBillDate: this._toBillDateFilter,
      billTypeCode: this._billTypeFilter,
      billNum: this._billNumberFilter,
      pseudoBillNum: this._pseudoBillNumberFilter,
      marketerCode: this._marketerCodeFilter,
      processCode: this._processTypeFilter
    }

    return omitBy(filters, isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!isNil(this._cupsFilter)) result++
    if (!isNil(this._fromDateFilter)) result++
    if (!isNil(this._toDateFilter)) result++
    if (!isNil(this._fromBillDateFilter)) result++
    if (!isNil(this._toBillDateFilter)) result++
    if (!isNil(this._billTypeFilter)) result++
    if (!isNil(this._billNumberFilter)) result++
    if (!isNil(this._pseudoBillNumberFilter)) result++
    if (!isNil(this._marketerCodeFilter)) result++
    if (!isNil(this._processTypeFilter)) result++

    return result
  }

  @Mutation()
  protected setHistoryBills(data: any) {
    this._historyBills = data.content
    this._totalElements = data.totalElements
  }

  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }
  @Mutation() protected setLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmptyValue = isEmpty(value)
    this._currentPage = isEmptyValue ? 1 : parseInt(value)
  }

  @Mutation() protected setCupsFilter(value: string) {
    const hasChanged = value !== this._cupsFilter

    this._cupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setFromDateFilter(value: string) {
    const hasChanged = value !== this._fromDateFilter

    this._fromDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setToDateFilter(value: string) {
    const hasChanged = value !== this._toDateFilter

    this._toDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setFromBillDateFilter(value: string) {
    const hasChanged = value !== this._fromBillDateFilter

    this._fromBillDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setToBillDateFilter(value: string) {
    const hasChanged = value !== this._toBillDateFilter

    this._toBillDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setBillTypeFilter(value: string) {
    const hasChanged = value !== this._billTypeFilter

    this._billTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setBillNumberFilter(value: string) {
    const hasChanged = value !== this._billNumberFilter

    this._billNumberFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setPseudoBillNumberFilter(value: string) {
    const hasChanged = value !== this._pseudoBillNumberFilter

    this._pseudoBillNumberFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMarketerCodeFilter(value: string) {
    const hasChanged = value !== this._marketerCodeFilter

    this._marketerCodeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessTypeFilter(value: string) {
    const hasChanged = value !== this._processTypeFilter

    this._processTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  // ACTIONS

  @Action({ useContext: true })
  protected async obtainHistoryBills(ctx: ActionContext<any, any>): Promise<void> {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const filters = ctx.getters.allProcessFilters

    const params = { ...filters, page, size }

    if (isNil(page) || isNil(size) || page < 0) return

    try {
      ctx.commit('setLoading', true)
      const response = await HistoryBillsApi.getHistoryBillList(params)
      ctx.commit('setHistoryBills', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.Page])
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setFromDateFilter', queryParams[QueryParams.FromDate])
    ctx.commit('setToDateFilter', queryParams[QueryParams.ToDate])
    ctx.commit('setFromBillDateFilter', queryParams[QueryParams.FromBillDate])
    ctx.commit('setToBillDateFilter', queryParams[QueryParams.ToBillDate])
    ctx.commit('setBillTypeFilter', queryParams[QueryParams.BillType])
    ctx.commit('setBillNumberFilter', queryParams[QueryParams.BillNumber])
    ctx.commit('setPseudoBillNumberFilter', queryParams[QueryParams.PseudoBillNumber])
    ctx.commit('setMarketerCodeFilter', queryParams[QueryParams.MarketerCode])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])

    ctx.dispatch('obtainHistoryBills')
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainHistoryBills')
  }
}
