import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { SessionVariables } from "@/Core/enum/SessionVariables";
import { AxiosPromise } from 'axios';
import { GetAllGroupsResponse, Group } from "../../models";


const AUTH_SERVICE_URL_BASE = process.env.VUE_APP_AUTH_URL
const Path = {
  List: `${AUTH_SERVICE_URL_BASE}/listGroups`,
  Create: `${AUTH_SERVICE_URL_BASE}/createGroup`
}

class GroupSingleton extends AbstractApi {
  private static _instance: GroupSingleton
  private constructor() { super() }

  public static get instance(): GroupSingleton {
    if (!GroupSingleton._instance) GroupSingleton._instance = new GroupSingleton();
    return GroupSingleton._instance;
  }
  
  

  public getAll(): AxiosPromise<GetAllGroupsResponse> {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    
    return this.request({
      method: Method.POST,
      url: Path.List,
      headers: { 'Authorization': `Bearer ${token}`}
    })
  }


  
  public create(data: Group): AxiosPromise {
    const token = sessionStorage.getItem(SessionVariables.Auth)

    return this.request({
      method: Method.POST,
      url: Path.Create,
      headers: { Authorization: `Bearer ${token}`},
      data
    })
  }
}

export const GroupApi = GroupSingleton.instance