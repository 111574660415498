var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      !!!Object.keys(_vm.alertsToRender).length
        ? _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.alertsLoading,
                  expression: "alertsLoading"
                }
              ],
              staticClass: "card"
            },
            [
              _c("div", { staticClass: "no-alerts" }, [
                _c("h4", [_vm._v("Sin alertas configuradas")]),
                _c("p", [_vm._v("Cuando configures alertas aparecerán aquí")])
              ])
            ]
          )
        : _vm._e(),
      _vm._l(_vm.alertsToRender, function(value, key) {
        return _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.alertsLoading,
                expression: "alertsLoading"
              }
            ],
            key: key,
            staticClass: "card"
          },
          [
            _c("div", { staticClass: "marketer-name" }, [_vm._v(_vm._s(key))]),
            _c(
              "div",
              { staticClass: "process-container" },
              [
                _vm._l(value, function(va, ke) {
                  return _c(
                    "div",
                    { key: ke },
                    [
                      _c("p", { staticClass: "process" }, [
                        _vm._v(_vm._s(_vm.mainOptions[ke]))
                      ]),
                      _vm._v(" "),
                      _vm._l(va, function(v, k) {
                        return _c("div", { key: k }, [
                          k !== "delayedByDays"
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(_vm.subOptions[k]) + ": " + _vm._s(v)
                                )
                              ])
                            : _vm._e(),
                          k === "delayedByDays"
                            ? _c("div", [
                                _vm._v(
                                  " Hay " +
                                    _vm._s(
                                      v.count === 1
                                        ? v.count + " proceso abierto"
                                        : v.count + " procesos abiertos"
                                    ) +
                                    " con más de " +
                                    _vm._s(
                                      v.numberOfDays === 1
                                        ? v.numberOfDays + " día"
                                        : v.numberOfDays + " días"
                                    ) +
                                    " desde que se inició el proceso."
                                )
                              ])
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                }),
                !!!Object.keys(value).length
                  ? _c("div", { staticClass: "no-alerts" }, [
                      _c("h4", [_vm._v("Sin alertas")]),
                      _c("p", [_vm._v("Cuando hayan alertas aparecerán aquí")])
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }