var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "completed" }, [
    _c("div", { staticClass: "success-icon" }, [
      _c("i", { staticClass: "el-icon-success" }),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          "Se ha generado correctamente el informe " +
            _vm._s(_vm.reportName) +
            ". Ves a la página de históricos para descargarlo."
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          "router-link",
          { staticClass: "el-link", attrs: { to: _vm.historicalLocation } },
          [_vm._v("Ir a la página de históricos")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }