import { RouteConfig } from 'vue-router'

import Page from '@/Core/views/Page/Page.vue'
import List from './pages/List/List.vue'
import { Roles } from '../UsersManager/models'


export enum HolidaysPages {
  List = 'HolidaysList',
}


const routes: Array<RouteConfig> = [
  {
    path: '/holidays',
    component: Page,
    children: [
      {
        path: '',
        name: HolidaysPages.List,
        meta: { menuActive: 'holidays', denyRolesAcess: [Roles.Marketer.code] },
        component: List
      }
    ]
  }
]

export default routes