var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: { "empty-text": _vm.emptyText, data: _vm.elements },
                    on: {
                      "cell-mouse-leave": _vm.onCellMouseLeave,
                      "cell-mouse-enter": _vm.onCellMouseEnter
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "",
                        prop: "validated",
                        align: "center",
                        width: "70"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "validated-cell" }, [
                                  scope.row.validated
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Validado",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-success icon"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "No validado",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-error error icon"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "ESTADO", prop: "state" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.state
                                  ? _c("NoContract-StateColumn", {
                                      attrs: { state: scope.row.state }
                                    })
                                  : _c("span", [_vm._v("-")])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "COMERCIALIZADORA",
                            prop: "marketerDescription"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.marketer) +
                                          " - " +
                                          _vm._s(scope.row.marketerDescription)
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "CUPS", width: "175" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDownload(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Descargar resultado"),
                                            _c("i", {
                                              staticClass: "el-icon-download"
                                            })
                                          ]
                                        ),
                                        _c("span")
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "", width: "50", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "buttons-container" },
                                  [
                                    _c("NoContract-ListItemMenu", {
                                      attrs: {
                                        noContract: scope.row,
                                        "row-index": scope.$index
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }