import { Component, Vue } from 'types-vue'
import {v4 as uuidv4} from 'uuid'

const TIME_SUCCESS_SHOWING = 3000
const TIME_ERROR_SHOWING = 10000

@Component
export default class NetworkNotification extends Vue {
  protected notifications: { id: string, message: string }[] = []


  onNotificationSuccess(id: string) {
    setTimeout(() => {
      this.notifications = this.notifications.filter(element => element.id !== id)
    }, TIME_SUCCESS_SHOWING)
  }

  onNotificationError(id: string) {
    setTimeout(() => {
      this.notifications = this.notifications.filter(element => element.id !== id)
    }, TIME_ERROR_SHOWING)
  }

  public async notify(message: string): Promise<any> {
    const id = uuidv4()
    this.notifications.push({ id, message })

    const ref = await new Promise((resolve) => {
      this.$nextTick(() => {
        resolve(this.$refs[id])
      })
    })
    
    return ref[0] as { success: (message: string) => void, error: (message: string) => void}
  }
}