export const p48required = {
  t01s001: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: true,
    extrainfo: true,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: true,
    fraudrecordnum: false
  },
  t01s002: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: true,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s003: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s004: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: true,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s005: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s006: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s007: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s008: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: true,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s009: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s010: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s011: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s012: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s013: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s014: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: true,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s015: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t04s018: {
    documentnum: false,
    businessName: true,
    telephonenumber: true,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: true,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t04s019: {
    documentnum: false,
    businessName: true,
    telephonenumber: true,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: true,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t05s021: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t05s022: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t05s023: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t06s024: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: true,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t06s025: {
    documentnum: false,
    businessName: false,
    telephonenumber: true,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: true,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t06s026: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: true,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t07s027: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t07s028: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t07s029: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: true,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t07s030: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: true,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t07s031: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: true,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t07s032: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t08s033: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: true,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s034: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: true,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s035: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s036: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: true,
    readingdate: true,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s037: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t01s038: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s040: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s045: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s046: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s048: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s049: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: true,
    dateto: true,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t09s050: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t09s051: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t09s052: {
    documentnum: true,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t09s053: {
    documentnum: false,
    businessName: false,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t09s054: {
    documentnum: false,
    businessName: true,
    telephonenumber: true,
    cups: true,
    incidentdate: true,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s057: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: true
  },

  t03s058: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s059: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s060: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s061: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s062: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s063: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s064: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s065: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s066: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: true,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s067: {
    documentnum: false,
    businessName: false,
    telephonenumber: true,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: true,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t02s068: {
    documentnum: true,
    businessName: true,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: true,
    invoicenumber: true,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t01s070: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: true,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: true,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s101: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s102: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s103: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s104: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s105: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s106: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s107: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s108: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s109: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t02s110: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s111: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s112: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s113: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s114: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: false,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: false,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },

  t03s071: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s72: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: false,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  },
  t03s074: {
    documentnum: false,
    businessName: false,
    telephonenumber: false,
    cups: true,
    incidentdate: false,
    extrainfo: false,
    contactname: false,
    invoicenumber: false,
    conceptfacturation: false,
    readingvalue: false,
    readingdate: false,
    datefrom: false,
    dateto: false,
    incidentlocation: false,
    comreferencenum: true,
    conceptcontract: false,
    iban: false,
    nnssexpedient: false,
    targetclaimcomreferencenum: true,
    claimedcompensation: false,
    wrongattentiontype: false,
    fraudrecordnum: false
  }
}
