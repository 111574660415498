import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class InitialForm_42 extends Vue {
  // Personalized boolean variables.
  protected isTitularTypeRequired: boolean = false
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa emisora',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa receptora',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'titulartype',
          label: 'Tipo de titular',
          options: `${CORE_URL}/client-person-types`,
          placeholder: 'Selecciona el tipo de titular',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'nationality',
          label: 'Nacionalidad',
          placeholder: 'Selecciona la nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo Documento de Identificación',
          placeholder: 'Selecciona el tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Nº Documento',
          placeholder: 'Escribe el número de documento',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'modeffectdate',
          label: 'Modelo fecha efecto',
          options: `${CORE_URL}/contract-effect-date-models?code=04`,
          placeholder: 'Selecciona modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'reqtransferdate',
          label: 'Fecha Efecto Solicitada',
          placeholder: 'Introduce fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'updatereason',
          label: 'Motivo Modificación',
          options: `${CORE_URL}/contract-modification-reason-in-marketer-change?code=01`,
          placeholder: 'Selecciona el motivo de la modificación',
          valueQuery: 'code'
        },
        { type: 'switch', name: 'surrogacy', label: 'Subrogación', valueQuery: 'code' }
      ],
      rules: {
        titulartype: [
          { required: this.isTitularTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        nationality: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        modeffectdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documenttype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documentnum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqtransferdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  protected change(process: any): void {
    const documenttype = process.documenttype
    const titulartype = process.titulartype
    localStorage.setItem('p42_titulartype_json', titulartype)

    // DOCUMENT TYPE LOGIC.
    if (documenttype !== '') {
      if (documenttype === '07' || documenttype === '08') {
        this.isTitularTypeRequired = true
      } else {
        this.isTitularTypeRequired = false
      }
    }
  }

  protected onSubmit(model) {
    // Form constants.
    const nationality = model.nationality
    const documenttype = model.documenttype

    // DOCUMENT TYPE LOGIC.
    if (nationality !== '' && documenttype !== '') {
      if (nationality === 'ES') {
        if (documenttype !== '01' && documenttype !== '03') {
          this.$notify.error({
            title: 'Error en el tipo de documentación',
            message: 'Solamente puedes poner el 01(NIF) o el 03(PASAPORTE)'
          })
        } else {
          this.$emit('submit', model)
        }
      } else {
        if (documenttype === '01') {
          this.$notify.error({
            title: 'Error en el tipo de documentación',
            message: 'No puedes seleccionar el 01(NIF)'
          })
        } else {
          this.$emit('submit', model)
        }
      }
    }
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
