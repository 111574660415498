import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class InstallationForm_42 extends Vue {
  protected isProductCodeRequired: boolean = false
  protected isProductQdRequired: boolean = false
  protected isProductQaRequired: boolean = false

  protected get formOptions(): FormConfig {
    return {
      label: 'Installation',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'disconnectedserviceaccepted',
          label: 'Indicador de contratar el suministro independientemente de su estado',
          options: [
            { label: 'Si', value: 'S' },
            { label: 'No', value: 'N' }
          ],
          placeholder: 'Selecciona la opción que deseas'
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Observaciones de la solicitud',
          placeholder: 'Introduce las observaciones de la solicitud',
          maxCharacters: 400
        },
        {
          type: 'select',
          name: 'reqtype',
          label: 'Tipo de solicitud',
          options: `${CORE_URL}/product-type-requests`,
          placeholder: 'Selecciona el tipo de solicitud',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'productcode',
          label: 'Código del producto',
          placeholder: 'Introduce el código del producto',
          maxCharacters: 26
        },
        {
          type: 'select',
          name: 'producttype',
          label: 'Tipo de producto',
          options: `${CORE_URL}/contract-product-types`,
          placeholder: 'Selecciona tipo de producto',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'producttolltype',
          label: 'Tipo de peaje del producto',
          options: `${CORE_URL}/contract-toll-types`,
          placeholder: 'Selecciona tipo de peaje del producto',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'productqd',
          label: 'Caudal diario',
          placeholder: 'Introduce el caudal diario'
        },
        {
          type: 'text',
          name: 'productqa',
          label: 'Caudal estimado anual qa',
          placeholder: 'Introduce el caudal estimado anual'
        }
      ],
      rules: {
        disconnectedserviceaccepted: [
          { required: true, trigger: 'blur', message: 'Es obligatorio' }
        ],
        reqtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        productcode: [
          { required: this.isProductCodeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        producttolltype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        producttype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        productqd: [
          { required: this.isProductQdRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        productqa: [
          { required: this.isProductQaRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected change(process: any) {
    const reqtype = process.reqtype

    if (reqtype !== '') {
      if (reqtype === '01' || reqtype === '02') {
        this.isProductCodeRequired = true
      } else {
        this.isProductCodeRequired = false
      }
      if (reqtype === '03') {
        this.isProductQdRequired = true
        this.isProductQaRequired = true
      } else {
        this.isProductQdRequired = false
        this.isProductQaRequired = false
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
