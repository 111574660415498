var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isButton
        ? _c(
            "el-button",
            {
              class: "cups-" + _vm.index,
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  return _vm.onCupsClick(_vm.cups)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.cups) + " ")]
          )
        : _c("span", { class: "cups-" + _vm.index }, [_vm._v(_vm._s(_vm.cups))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }