import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise, AxiosRequestConfig } from 'axios';



class FormSingleton extends AbstractApi {
  private static _instance: FormSingleton
  private constructor() { super() }

  public static get instance(): FormSingleton {
    if (!FormSingleton._instance) FormSingleton._instance = new FormSingleton();
    return FormSingleton._instance;
  }

  public getSelectContent(config: AxiosRequestConfig): AxiosPromise {
    return this.request(config)
  }

}

export const formApi = FormSingleton.instance
