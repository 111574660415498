import { ProcessTableMetric } from '@/Modules/Dashboard/models'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'
import { ProcessStates } from '@/Modules/Processes/enums/ProcessStates'
import { ProcessesPages } from '@/Modules/Processes/router'
import _ from 'lodash'
import { Component, Filter, Prop, Vue } from 'types-vue'

@Component
export default class ProcessesMetricsTableMobile extends Vue {
  
  @Prop({ required: true, type: Array }) protected metrics: ProcessTableMetric[]
  @Prop({ default: false }) protected loading: boolean

  protected goToProcess(metric: ProcessTableMetric, columnName: string) {
    const goToProcesses = (query) => this.$router.push({
      name: ProcessesPages.InProcess,
      query: { [QueryParams.ProcessType]: metric.processCode, ...query }
    })

    switch (columnName) {
      case "CARGADOS":        return goToProcesses({})
      case "ACEPTADOS":       return goToProcesses({ [QueryParams.Status]: [ProcessStates.Opened, ProcessStates.Completed, ProcessStates.CANCELED, ProcessStates.DESISTED, ProcessStates.SUSPENDED].join(',') })
      case "RECHAZADOS":      return goToProcesses({ [QueryParams.Status]: [ProcessStates.Rejected].join(',') })
      case "PENDIENTES":      return goToProcesses({ [QueryParams.Status]: [ProcessStates.Opened, ProcessStates.SUSPENDED].join(',') })
      case "CANCELADOS":      return goToProcesses({ [QueryParams.Status]: [ProcessStates.DESISTED, ProcessStates.CANCELED].join(',') })
      case "ACTIVADOS":       return goToProcesses({ [QueryParams.Status]: [ProcessStates.Completed].join(',') })
      default:                return goToProcesses({})
    }
  }

  @Filter() protected filterTimeInDays(value: number) {
    return (_.isNull(value)) ? '-' : _.ceil(value, 2)
  }
}