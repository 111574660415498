import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import _ from "lodash";
import moment from 'moment';
import { AverageTimeReportParams, B70ReportBillType, B70ReportParams, B70ReportProcessType, OcsumReportParams, PSReportParams, ReportProcessParams } from "../../models/api";

const PROCESSES_REPORT_BUCKET = process.env.VUE_APP_PROCESSES_REPORT_BUCKET
const REPORT_SERVICE_URL_BASE = process.env.VUE_APP_REPORT_URL
const CORE_URL = process.env.VUE_APP_CORE_URL

const Path = {
  temporal: `${REPORT_SERVICE_URL_BASE}/files/launch/temporal`,
  processTypes: `${CORE_URL}/process-process`,
  billTypes: `${CORE_URL}/bill-types`,
  systemSubjects: `${CORE_URL}/system-subjects`
}

class ReportsApiSingleton extends AbstractApi {
  private static _instance: ReportsApiSingleton
  private constructor() { super() }

  public static get instance(): ReportsApiSingleton {
    if (!ReportsApiSingleton._instance) ReportsApiSingleton._instance = new ReportsApiSingleton();
    return ReportsApiSingleton._instance;
  }


  public getProcessesReport(extension: 'pdf' | 'xlsx', params: ReportProcessParams ): AxiosPromise {
    return this.request({
      method: 'post',
      url: Path.temporal,
      data: {
        bucket: PROCESSES_REPORT_BUCKET,
        fileName: "sctd_process_list.jrxml",
        keyName: "sctd_process_list.jrxml",
        temporalUrl: true,
        extension,
        params: _.omitBy(params, _.isNil)
      }
    })
  }


  public getOcsumReport(extension: 'pdf' | 'xlsx', params: OcsumReportParams ): AxiosPromise {
    const toFileName = `CUPS en CUR sin TUR [${params?.marketer_code || '0234'}][${params.year}.${params.month}]`
    return this.request({
      method: 'post',
      url: Path.temporal,
      data: {
        toFileName,
        bucket: PROCESSES_REPORT_BUCKET,
        fileName: "sctd_ocsum.jrxml",
        keyName: "sctd_ocsum.jrxml",
        extension,
        params: _.omitBy(params, _.isNil)
      }
    })
  }
  
  public getPSReport(extension: 'pdf' | 'xlsx', params: PSReportParams ): AxiosPromise {
    return this.request({
      method: 'post',
      url: Path.temporal,
      data: {
        bucket: PROCESSES_REPORT_BUCKET,
        fileName: "sctd_ps.jrxml",
        keyName: "sctd_ps.jrxml",
        extension,
        params: _.omitBy(params, _.isNil)
      }
    })
  }
  
  public getB70Report(extension: 'pdf' | 'xlsx', params: B70ReportParams ): AxiosPromise {
    return this.request({
      method: 'post',
      url: Path.temporal,
      data: {
        bucket: PROCESSES_REPORT_BUCKET,
        fileName: "sctd_b70.jrxml",
        keyName: "sctd_b70.jrxml",
        extension,
        params: _.omitBy(params, _.isNil)
      }
    })
  }

  public getAverageTimeReport(extension: 'pdf' | 'xlsx', params: AverageTimeReportParams, isDischarge: boolean = false ): AxiosPromise {
    const reportFile = isDischarge ? "sctd_avg_times_ASAP.jrxml" : "sctd_avg_times.jrxml"
    const { year, month } = params
    const marketerCode = params.marketer_code || "0234"
    const quarter = moment().set('month', parseInt(month)-1).quarter()
    const toFileName = `TMD_${marketerCode}_${year}T${quarter}_01`
    
    return this.request({
      method: 'post',
      url: Path.temporal,
      data: {
        toFileName,
        bucket: PROCESSES_REPORT_BUCKET,
        fileName: reportFile,
        keyName: reportFile,
        extension,
        params: _.omitBy(params, _.isNil)
      }
    })
  }

  public getB70ReportProcessTypes(params = {}): AxiosPromise<B70ReportProcessType[]> {
    const method = Method.GET
    const url = Path.processTypes

    return this.request({ method, url, params })
  }

  public getB70ReportBillTypes(params = {}): AxiosPromise<B70ReportBillType[]> {
    const method = Method.GET
    const url = Path.billTypes

    return this.request({ method, url, params })
  }

  public getSystemSubject(code: string): AxiosPromise<any[]> {
    const method = Method.GET
    const url = Path.systemSubjects

    return this.request({ method, url })
  }
}

export const ReportsApi = ReportsApiSingleton.instance
