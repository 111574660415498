import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue, Prop, Watch } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class AddressForm1 extends Vue {
  @Prop()
  formData: any

  // Personalized boolean variables.
  protected isProvincePsRequired: boolean = false
  protected isCityPsRequired: boolean = false
  protected isZipCodeRequired: boolean = false
  protected isStreetPsRequired: boolean = false
  protected isStreetTypePsRequired: boolean = false
  protected isStreetNumerPsRequired: boolean = false

  protected get formOptions(): FormConfig {
    return {
      label: 'Dirección del punto de suminstro',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'provincePS',
          label: 'Provincia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la provincia',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'cityPS',
          label: 'Municipio',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el municipio',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription'
        },
        {
          type: 'text',
          name: 'zipcodePS',
          label: 'Código postal',
          placeholder: 'Introduce el código postal',
          maxCharacters: 5
        },
        {
          type: 'select',
          name: 'streettypePS',
          label: 'Tipo de vía',
          options: `${CORE_URL}/location-street-types`,
          placeholder: 'Selecciona el tipo de vía',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'streetPS',
          label: 'Nombre de calle',
          placeholder: 'Introduce la calle',
          maxCharacters: 60
        },
        {
          type: 'text',
          name: 'streetnumberPS',
          label: 'Número de finca',
          placeholder: 'Introduce el número de finca',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'portalPS',
          label: 'Portal',
          placeholder: 'Introduce el portal',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'staircasePS',
          label: 'Escalera',
          placeholder: 'Introduce la escalera',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'floorPS',
          label: 'Piso',
          placeholder: 'Introduce el piso',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'doorPS',
          label: 'Puerta',
          placeholder: 'Introduce la puerta',
          maxCharacters: 5
        }
      ],
      rules: {
        provincePS: [
          { required: this.isProvincePsRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        cityPS: [{ required: this.isCityPsRequired, trigger: 'blur', message: 'Es obligatorio' }],
        zipcodePS: [
          { required: this.isZipCodeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        streetPS: [
          { required: this.isStreetPsRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        streettypePS: [
          { required: this.isStreetTypePsRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        streetnumberPS: [
          { required: this.isStreetNumerPsRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  @Watch('formData')
  protected onChangeFormData() {
    if (this.formData?.updatereason === '01') {
      this.isProvincePsRequired = true
      this.isCityPsRequired = true
      this.isZipCodeRequired = true
      this.isStreetPsRequired = true
      this.isStreetTypePsRequired = true
      this.isStreetNumerPsRequired = true
    } else {
      this.isProvincePsRequired = false
      this.isCityPsRequired = false
      this.isZipCodeRequired = false
      this.isStreetPsRequired = false
      this.isStreetTypePsRequired = false
      this.isStreetNumerPsRequired = false
    }
  }
}
