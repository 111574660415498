import _ from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'types-vue'
import { DateFormItemConfig } from '../../models'

const validator = (prop: any) => true

@Component
export default class Date extends Vue {
  @Prop({ required: true, validator: validator }) protected config: DateFormItemConfig

  protected model: any = null
  protected startDate: Date = null

  protected get type(): string {
    return this.config.type
  }
  protected get size(): string {
    return this.config.size
  }
  protected get format(): string {
    return this.config.format
  }
  protected get defaultValue(): string {
    return this.config?.default
  }
  protected get disabled(): boolean {
    return this.config?.disabled
  }

  @Watch('model', { deep: true }) protected onChangeModel() {
    const value = !!this.format ? moment(this.model).format(this.format) : this.model
    this.$emit('change', { item: this.config, value })
  }

  private setDefaultValue() {
    this.model = this.defaultValue
  }

  created() {
    if (!!this.defaultValue) this.setDefaultValue()
  }
}