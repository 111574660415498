import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Component, mixins, Prop } from 'types-vue'

@Component
export default class SigninForm extends mixins(FormValidateMixin) {

  @Prop({ type: Boolean, default: false })
  protected loading: boolean

  /**
   * STATE
   */

  protected form = {
    username: '',
    password: '',
    rememberme: ''
  }

  protected rules = {
    username: [
      { required: true, message: 'Please input email', trigger: 'blur' },
      { type: 'email', message: 'Please input correct email address', trigger: 'blur' }
    ],
    password: [
      { required: true, message: 'Please input password', trigger: 'blur' }
    ]
  }



  /**
   * EVENTS
   */
  
  protected onClickForgotPassword() {
    this.$emit('forgotPassword', this.form)
  }

  protected async onClickLogin() {
    if (!await this.validateForm()) return

    this.$emit('submit', this.form)
  }

  protected onClickSignup() {
    this.$emit('signup')
  }
}