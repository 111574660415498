var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(value, key) {
      return _c("div", { key: key }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            key === "delayedByDays"
              ? _c(
                  "span",
                  { staticClass: "text-input-container" },
                  [
                    _vm._v(" Proceso pendiente durante "),
                    _c("el-input", {
                      staticClass: "text-input",
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form[_vm.group][key],
                        callback: function($$v) {
                          _vm.$set(_vm.form[_vm.group], key, $$v)
                        },
                        expression: "form[group][key]"
                      }
                    }),
                    _vm._v(" días desde el inicio ")
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "option-name" }, [
              _vm._v(_vm._s(_vm.mainOptions[key]))
            ]),
            key !== "delayedByDays"
              ? _c("el-switch", {
                  model: {
                    value: _vm.form[_vm.group][key],
                    callback: function($$v) {
                      _vm.$set(_vm.form[_vm.group], key, $$v)
                    },
                    expression: "form[group][key]"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }