import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { AddressesRequest, AddressesResponse } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  AddressesList: `${CORE_SERVICE_URL_BASE}/addresses`,
  AddressesListPaged: `${CORE_SERVICE_URL_BASE}/addresses/paged`,
}

class AddressesApi extends AbstractApi {

  public getAddresses(params: AddressesRequest  = { page: 1 }): AxiosPromise<AddressesResponse> {
    const method = Method.GET
    const url = Path.AddressesListPaged
    return this.request({ method, url, params })
  }

}

export default new AddressesApi()