var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fileList
        ? _c(
            "el-table",
            {
              staticClass: "dir-content",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.fileList, "empty-text": "No hay documentos" }
            },
            [
              _c("el-table-column", {
                attrs: { width: "40" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "cell" },
                            [
                              _c("Processes-ExtensionIcon", {
                                staticClass: "icon",
                                attrs: { extension: scope.row.extension }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  282947153
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: _vm.labelName },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "cell" }, [
                            _c("span", { staticClass: "file-name" }, [
                              _vm._v(_vm._s(scope.row.filename))
                            ])
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1105983457
                )
              }),
              _c("el-table-column", {
                attrs: { width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "button-container" },
                            [
                              _c("Processes-DownloadButton", {
                                attrs: { document: scope.row }
                              }),
                              _c("Processes-DeleteButton", {
                                attrs: { document: scope.row }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3852054098
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }