var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "origin",
                label: "COMERCIALIZADORA INHABILITADA",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Selecciona comercializadora",
                    size: "medium",
                    disabled: _vm.loading
                  },
                  model: {
                    value: _vm.form.origin,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "origin", $$v)
                    },
                    expression: "form.origin"
                  }
                },
                _vm._l(_vm.systemSubjectsOrigin, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: "(" + item.code + ") " + item.description,
                      value: item.code
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "destination",
                label: "COMERCIALIZADORA DE ÚLTIMO RECURSO",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Selecciona comercializadora CUR",
                    size: "medium",
                    disabled: _vm.loading
                  },
                  model: {
                    value: _vm.form.destination,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "destination", $$v)
                    },
                    expression: "form.destination"
                  }
                },
                _vm._l(_vm.systemSubjectsDestination, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: "(" + item.code + ") " + item.description,
                      value: item.code
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "reqTransferDate",
                label: "FECHA DE TRANSFERENCIA",
                "label-position": "top"
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "Selecciona fecha de transferencia"
                },
                model: {
                  value: _vm.form.reqTransferDate,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "reqTransferDate", $$v)
                  },
                  expression: "form.reqTransferDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "cups", label: "CUPS", "label-position": "top" } },
            [
              _c("el-input", {
                on: { blur: _vm.onInputConfirm },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onInputConfirm.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.cup,
                  callback: function($$v) {
                    _vm.cup = $$v
                  },
                  expression: "cup"
                }
              }),
              _vm._l(_vm.form.cups, function(cup) {
                return _c(
                  "el-tag",
                  {
                    key: cup,
                    attrs: {
                      type: "info",
                      closable: "",
                      "disable-transitions": false
                    },
                    on: {
                      close: function($event) {
                        return _vm.onClose(cup)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(cup) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          size: "medium"
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("Enviar CUR")]
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      ),
      _c("CUR-CupsModal", {
        ref: "cupsModal",
        on: { onSelectCups: _vm.onSelectCups }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }