var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Informe CUPS en CUR sin TUR",
        visible: _vm.showing,
        width: "45rem",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("Forms-Form", {
            attrs: { options: _vm.formOptions },
            on: { submit: _vm.onSubmit }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }