import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClaimerForm extends Vue {
  claimerType = ''
  lastClaimerType = ''

  // Personalized boolean variables.
  protected isFirstNameRequired: boolean = true
  protected isLastNameRequired: boolean = true
  protected isSecondNameRequired: boolean = true
  protected isClaimerDocumentTypeRequired: boolean = true
  protected isClaimerDocumentNumRequired: boolean = true
  protected isClaimerNameRequired: boolean = true
  protected isClaimerTelephoneRequired: boolean = true
  protected isClaimerPrefixRequired: boolean = true

  protected get formOptions(): FormConfig {
    return {
      label: 'Reclamante',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'claimertype',
          label: 'Tipo de reclamante',
          options: `${CORE_URL}/applicant-types`,
          placeholder: 'Selecciona el tipo de reclamante',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'claimerdocumenttype',
          label: 'Tipo de documente del reclamante',
          options: `${CORE_URL}/client-document-types`,
          placeholder: 'Selecciona el tipo de documento del reclamante',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'claimerdocumentnum',
          label: 'Núm. de documento del reclamante',
          placeholder: 'Introduce el numero de documento del reclamante',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'claimerfirstname',
          label: 'Nombre del reclamante',
          placeholder: 'Introduce el nombre del reclamante',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'claimerlastname',
          label: 'Apellido 1 o razón social del reclamante',
          placeholder: 'Introduce el apellido 1 del reclamante',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'claimersecondname',
          label: 'Apellido 2 del reclamante',
          placeholder: 'Introduce el apellido 2 del reclamante',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'claimerbusinessname',
          label: 'Razón social del reclamante',
          placeholder: 'Introduce la razón social del reclamante',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'claimerprefixtel1',
          label: 'Prefijo del teléfono del reclamante',
          placeholder: 'Introduce el prefijo del teléfono del reclamante',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'claimertelephone1',
          label: 'Teléfono del reclamante',
          placeholder: 'Introduce el teléfono del reclamante',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'claimeremail',
          label: 'Email del reclamante',
          placeholder: 'Introduce el correo electrónico del reclamante',
          maxCharacters: 100
        }
      ],
      rules: {
        claimertype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        claimerdocumenttype: [
          {
            required: this.isClaimerDocumentNumRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        claimerdocumentnum: [
          {
            required: this.isClaimerDocumentNumRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        claimerfirstname: [
          {
            required: this.isFirstNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        claimerlastname: [
          {
            required: this.isLastNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        claimersecondname: [
          {
            required: this.isSecondNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        claimertelephone1: [
          {
            required: this.isClaimerTelephoneRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        claimerprefixtel1: [
          {
            required: this.isClaimerPrefixRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ]
      }
    }
  }

  protected change(process: any): void {
    const { claimertype, claimerbusinessname } = process
    if (claimertype === '06') {
      this.isFirstNameRequired = false
      this.isLastNameRequired = false
      this.isSecondNameRequired = false
      this.isClaimerDocumentTypeRequired = false
      this.isClaimerDocumentNumRequired = false
      this.isClaimerNameRequired = false
      this.isClaimerTelephoneRequired = false
      this.isClaimerPrefixRequired = false
    }

    if (!!this.lastClaimerType  && claimertype !== '06') {
      this.isFirstNameRequired = true
      this.isLastNameRequired = true
      this.isSecondNameRequired = true
      this.isClaimerDocumentTypeRequired = true
      this.isClaimerDocumentNumRequired = true
      this.isClaimerNameRequired = true
      this.isClaimerTelephoneRequired = true
      this.isClaimerPrefixRequired = true
    }

    if (claimertype !== '06' && claimerbusinessname) {
      this.isFirstNameRequired = false
      this.isLastNameRequired = false
      this.isSecondNameRequired = false
    }

    if (claimertype !== '06' && !claimerbusinessname) {
      this.isFirstNameRequired = true
      this.isLastNameRequired = true
      this.isSecondNameRequired = true
    }

    this.lastClaimerType = claimertype
  }

  protected onSubmit(model) {
    const claimeremail = model.claimeremail

    if (claimeremail !== '') {
      const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

      if (regexp.test(claimeremail)) {
        this.$emit('submit', model)
      } else {
        this.$notify.error({
          title: `Error en el correo electrónico`,
          message: 'Por favor pon un email correcto'
        })
      }
    } else {
      this.$emit('submit', model)
    }
  }
}
