import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, mixins, Prop, Vue } from 'types-vue'
import { ProcessModel } from '@/Modules/Processes/models'
import { ProcessesPages } from '@/Modules/Processes/router'

enum Commands {
  seeDetails = 'seeDetails',
  delete = 'delete'
}

@Component
export default class ListItemMenu extends mixins(PermissionMixin) {
  
  @Prop({ required: true })
  protected process: ProcessModel

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.ProcessesInprocess)
  protected removeProcess: (process: ProcessModel) => Promise<any>


  get haveRemovePermissions(): boolean {
    return this.isSystemRol || this.isDistributorRol && this.isAdminPermission
  }
  

  get commandDelete(): string {
    return Commands.delete
  }

  get commandSeeDetails(): string {
    return Commands.seeDetails
  }


  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
      case Commands.seeDetails:
        this.goToDetails()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar proceso "${this.process.numreference}"`
    const message = `¿Estas seguro de eliminar el proceso ${this.process.processCode} con referencia "${this.process.numreference}"?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'Proceso eliminado'
    const message = 'Se ha eliminado el proceso correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar proceso'
    const message = `Ha ocurrido un error al intentar eliminar el proceso con referencia ${this.process.numreference}`
    
    this.$notify({ title, message, type: 'error' })
  }

  protected goToDetails() {
    this.$router.push({ name: ProcessesPages.Details, params: { id: this.process.id.toString() }})
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeProcess(this.process)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }
  
}