import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'

@Component
export default class ForgotPassword extends Vue {
  
  protected loading: boolean = false

  /**
   * STORE
   */
  @MapAction(Store.ForgotPassword)
  protected forgotPassword: (email: string) => Promise<any>
  
  /**
   * EVENTS
   */

  protected async onSubmit(forgotPasswordForm: { username: string }) {
    try {
      this.loading = true
      await this.forgotPassword(forgotPasswordForm.username)
    } catch (error) {
      this.$notify({
        type: 'error',
        title: 'Error',
        message: this.$t('auth forgotPassword.requestError').toString()
      })
    } finally {
      this.loading = false
    }
  }

  protected onBack() {
    this.$router.back()
  }
}