import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { Component, Prop, mixins, MapGetter } from 'types-vue'
import { ProcessModel } from '@/Modules/Processes/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Store } from '@/Core/Models/Stores'

@Component
export default class ProcessDetailsHeader extends mixins(FiltersMixin, PermissionMixin) {

  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean
  
  @Prop({ required: true })
  protected process: ProcessModel


  protected get processCode(): string { return this.process.processCode }
  protected get description(): string { return this.process.processDescription }
  protected get numreference(): string { return this.process.numreference }
  protected get cups(): string { return this.process.cups }
  protected get resultReason(): string { return this.process.resultreason || this.process.resultreasonBMessage }
  
  protected get showMarketerInfo(): boolean {
    return ((this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol)
  }
}