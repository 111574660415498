import { Store } from '@/Core/Models/Stores';
import { Component, MapGetter, mixins } from 'types-vue'
import ObtainVersionListMixin from '../../mixins/ObtainVersionList';
import { Version } from '../../models';
import { VersionPages } from '../../router';

@Component
export default class VersionButton extends mixins(ObtainVersionListMixin) {

  @MapGetter(Store.Versions) protected latest: Version


  protected get version(): string { return this.latest.versionNumber }
  protected get tooltipContent(): string { return this.latest.shortSummary }

  protected onClickButton() {
    this.$router.push(VersionPages.Changelog)
  }
}