import { SessionVariables } from '@/Core/enum/SessionVariables'
import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import SigninFormUser from '../../models/SigninFormUser'
import { AuthPages } from '../../router'

@Component
export default class Signin extends Vue {
  
  /**
   * STATE
   */

  protected loading: boolean = false



  /**
   * STORE
   */

  @MapAction(Store.Signin)
  protected signin: (formUser: SigninFormUser) => Promise<any>



  /**
   * EVENTS
   */

  protected async onSubmit(formUser: SigninFormUser) {
    try {
      this.loading = true
      await this.signin(formUser)
    } catch (error) {
      const errorMessage = this.$t('auth signin.errorMessage') as string
      this.$notify({ type: 'error', title: 'Error', message: errorMessage })
    } finally {
      this.loading = false
    }
  }

  protected onForgotPassword(formUser: SigninFormUser) {
    this.$router.push({ name: AuthPages.ForgotPassword, query: { username: formUser.username } })
  }


  /**
   * VUE LIFE CYCLE
   */

  created() {
   sessionStorage.removeItem(SessionVariables.Auth)
  }
}