var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.loading } }, [
        _c("h2", [_vm._v("Conector AWS S3")])
      ]),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.isAdminPermission
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.onClickCreateButton }
                  },
                  [_vm._v("Crear nuevo conector")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-card",
            { staticClass: "is-table" },
            [_c("S3Connector-BucketsTable", { on: { edit: _vm.onEdit } })],
            1
          )
        ],
        1
      ),
      _c("S3Connector-CreateDialog", { ref: "createDialog" }),
      _c("S3Connector-EditorDialog", { ref: "editorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }