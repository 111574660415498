var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "group", on: { click: _vm.onClickGroup } }, [
    _c("div", { staticClass: "code" }, [_vm._v(_vm._s(_vm.group.name))]),
    _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.group.description))]),
    _c("div", [
      _vm.itemLoading
        ? _c("i", { staticClass: "el-icon-loading" })
        : _c("i", { staticClass: "el-icon-arrow-right" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }