import { Component, MapAction, MapGetter, Vue } from 'types-vue';
import { decode } from "jsonwebtoken"
import _ from 'lodash'
import { SessionVariables } from '../../../Core/enum/SessionVariables';
import AbstractApi from '@/Core/api/AbstractApi';
import { Store } from '@/Core/Models/Stores';

@Component
export default class ObtainTokenEmailMixin extends Vue {

  @MapGetter(Store.Signin) private username: string
  @MapAction(Store.Signin) private newUsername: (username: string) => void

  private saveNewUsername() {
    if (!_.isEmpty(this.username)) return
    
    const token = sessionStorage.getItem(SessionVariables.Auth)
    const decodedToken = decode(token) as any
    const email = decodedToken.email

    AbstractApi.setAuthorization(token)
    this.newUsername(email)
  }


  
  created() {
    this.saveNewUsername()
  }
}