var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("user userConfiguration.changePassword")))
          ])
        ]
      ),
      _c("Auth-ChangePasswordForm", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.onSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }