var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Nombre", prop: "GroupName" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Descripcion", prop: "Description" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                on: { input: _vm.onInput },
                model: {
                  value: _vm.form.Description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "Description", $$v)
                  },
                  expression: "form.Description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          size: "small",
                          disabled: _vm.disableSubmitButton
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.submitButtonText))]
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }