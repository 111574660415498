import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { MarketersApi } from '../connection/api/Marketers'
import { Marketer } from '../models'

@Module({ namespaced: true })
export default class Marketers extends VuexModule {


  protected _marketersList: Marketer[] = null
  protected _loading: boolean = true



  @Getter() protected marketersList(): Marketer[] { return this._marketersList }
  @Getter() protected loading(): boolean { return this._loading }

  
  
  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setMarketersList(list: Marketer[]) {
    this._marketersList = list
    this._loading = false
  }



  @Action({ commit: 'setMarketersList', useContext: true })
  protected async obtainMarketers(ctx: ActionContext<any, any>): Promise<Marketer[]> {
    ctx.commit('setLoading', true)
    const response = await MarketersApi.getAll()
    return response.data
  }

  @Action({ useContext: true })
  protected async removeMarketer(ctx: ActionContext<any, any>, marketer: Marketer): Promise <void> {
    await MarketersApi.delete(marketer.id)
    await ctx.dispatch('obtainMarketers')
  }

  @Action({ useContext: true })
  protected async createMarketer(ctx: ActionContext<any, any>, marketer: Marketer): Promise <void> {
    await MarketersApi.create(marketer)
    await ctx.dispatch('obtainMarketers')
  }

  @Action({ useContext: true })
  protected async editMarketer(ctx: ActionContext<any, any>, marketer: Marketer): Promise <void> {
    await MarketersApi.update(marketer)
    await ctx.dispatch('obtainMarketers')
  }

}
