import VueI18n from 'vue-i18n';
import messages from '../literals'
import Vue from 'vue'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'es',
  messages
})
