var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showing,
          expression: "showing"
        }
      ],
      staticClass: "test-message"
    },
    [_c("div", [_vm._v("Estás en un entorno de pruebas")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }