var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-content" }, [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h2", [_vm._v("Consulta de PS con contratos")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "", size: "small" },
              on: { click: _vm.onClickCreateButton }
            },
            [_vm._v("Volver")]
          )
        ],
        1
      ),
      _c("div", [_c("LOPD-QuerySQForm")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }