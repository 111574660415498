import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { HistoryPSApi } from '../connections/api/HistoryPS'
import { AxiosError } from 'axios'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'

@Module({ namespaced: true })
export default class HistoryPSList extends VuexModule {
  private _items: any[] = []
  private _loading: boolean = true
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null

  @Getter() public items(): any[] {
    return this._items
  }
  @Getter() public loading(): boolean {
    return this._loading
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public orderProp(): string {
    return this._orderProp
  }
  @Getter() public orderDirection(): string {
    return this._orderDirection
  }

  @Mutation() protected setItems(value: any[]) {
    this._items = [...value]
  }
  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }
  @Mutation() protected setOrderProp(value: string) {
    this._orderProp = value
  }
  @Mutation() protected setOrderDirection(value: string) {
    this._orderDirection = value
  }
  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = isEmpty ? 1 : parseInt(value)
  }

  @Mutation()
  protected setResponse(response: any) {
    this._items = response.data.content
    this._totalElements = response.data.totalElements
    this._loading = false
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])

    ctx.dispatch('fetchHistoryPSList')
  }

  @Action({ useContext: true })
  protected async fetchHistoryPSList(ctx: ActionContext<any, any>) {
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize

    ctx.commit('setLoading', true)

    if (_.isNil(pageNumber) || _.isNil(pageSize) || pageSize < 1) return

    try {
      const response = await HistoryPSApi.getHistoryPSList({
        pageNumber,
        pageSize
      })
      ctx.commit('setResponse', response)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los datos'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ useContext: true })
  protected async downloadHistoryPSItem(ctx: ActionContext<any, any>, id: number) {
    try {
      ctx.commit('setLoading', true)
      const response = await HistoryPSApi.downloadHistoryPSItem(id)
      const fileDownloadUrl = response.data
      window.open(fileDownloadUrl, '_blank')
    } catch (error) {
      Notification.error({ title: 'Error', message: 'No se pudo descargar el fichero' })
      throw error
    } finally {
      ctx.commit('setLoading', false)
    }
  }
}
