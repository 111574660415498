var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Festivos")]),
      _c("p", [_vm._v("Gestiona los días festivos")]),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c("section", { staticClass: "holidays-table" }, [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("Core-Title", { attrs: { loading: _vm.loading } }, [
                _c("h3", [_vm._v("Lista de días festivos")])
              ]),
              _c(
                "div",
                { staticClass: "menu" },
                [
                  _c("Holidays-DatePicker"),
                  _vm.isAdminPermission
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onClickCreate }
                        },
                        [_vm._v("Crear nuevo festivo")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content" },
            [_c("Holidays-HolidaysList", { on: { edit: _vm.onClickEdit } })],
            1
          )
        ])
      ]),
      _c("Holidays-CreateHolidayDialog", { ref: "createDialog" }),
      _c("Holidays-UpdateHolidayDialog", { ref: "updateDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }