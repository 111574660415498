import { Store } from '@/Core/Models/Stores';
import { ProcessDocument } from '@/Modules/Processes/models';
import _ from 'lodash';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import DropUploadFile from '@/Modules/Processes/components/details/DocumentsTab/DropUploadFile/DropUploadFile'

@Component
export default class DocumentsTab extends Vue {

  @MapAction(Store.Details) protected getDocuments: () => Promise<void>
  @MapGetter(Store.Details) protected documents: ProcessDocument[]


  @Watch('$route', { deep: true, immediate: true }) protected async onChangeRoute() {
    await this.getDocuments()
  }


  protected onDragOver(e) {
    const dropUploadFile = this.$refs.DropUploadFile as DropUploadFile
    dropUploadFile.show()
  }
}