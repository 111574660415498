export enum FiltersName {
  ProcessType = 'process_type',
  Reference = 'numreference',
  Cups = 'cups',
  Status = 'status',
  FromDate = 'fromDate',
  ToDate = 'toDate',
  LastMessageType ='lastMessageType',
  MessageTypeWithinInterval = 'messageTypeWithinInterval',
  MessageTypeWithinIntervalDownloaded = 'downloaded',
  FromMessageDate = 'fromMessageDate',
  ToMessageDate = 'toMessageDate',
  ProcessReason = 'processReason',
  IngoingMarketer = 'marketerCode'
}