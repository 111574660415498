var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" }, on: { command: _vm.onCommand } },
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link", attrs: { id: "user-menu-btn" } },
            [
              _c(
                "el-avatar",
                { attrs: { size: "medium", src: _vm.avatarSrc } },
                [_c("el-avatar", { attrs: { icon: "el-icon-user-solid" } })],
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.menuItems, function(item, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  attrs: {
                    id: item.command,
                    icon: item.icon,
                    command: item.command,
                    disabled: !item.enabled
                  }
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }