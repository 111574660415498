import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

@Component
export default class Send extends Vue {


  @MapGetter(Store.SendCur) protected loading: boolean
  @MapAction(Store.SendCur) protected initForm: () => Promise<void>


  async created() {
    await this.initForm()
  }
}