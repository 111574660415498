var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showing
        ? _c(
            "el-select",
            {
              staticClass: "select-group",
              attrs: {
                placeholder: "Selecciona comercializadora",
                loading: _vm.loading,
                disabled: _vm.changeLoading
              },
              on: { change: _vm.onChange },
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            },
            _vm._l(_vm.userGroupList, function(item) {
              return _c(
                "el-option",
                {
                  key: item.name,
                  attrs: {
                    label: item.name + " - " + item.description,
                    value: item.name
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(item.name) + " - " + _vm._s(item.description))
                  ])
                ]
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }