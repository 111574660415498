import Page from '@/Core/views/Page/Page.vue'
import { RouteConfig } from 'vue-router'
import { Roles } from '../UsersManager/models'
import MasterList from './pages/MasterList/MasterList.vue'
import MasterPage from './pages/MasterPage/MasterPage.vue'

export enum MasterPages {
  Masters = 'masters',
  Master = 'master'
}

const routes: Array<RouteConfig> = [
  {
    path: '/configuration/masters',
    component: Page,
    children: [
      {
        path: '',
        name: MasterPages.Masters,
        meta: { menuActive: 'masters', denyRolesAcess: [Roles.Marketer.code] },
        component: MasterList
      },
      {
        path: ':master',
        name: MasterPages.Master,
        meta: { menuActive: 'masters', denyRolesAcess: [Roles.Marketer.code] },
        component: MasterPage
      }
    ]
  }
]

export default routes