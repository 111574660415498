import { cleanStringNumber } from '@/Core/Utils'
import { CupsMasterApi } from '@/Modules/Cups/connections/api/Masters'
import { Cups } from '@/Modules/Cups/models'
import _ from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'types-vue'

@Component
export default class ClientSection extends Vue {

  @Prop({ required: true }) cups: Cups


  protected province: string = ''
  protected town: string = ''
  protected streetType: string = ''
  protected documentType: string = ''
  protected personType: string = ''


  protected get generalElements(): any[] {
    const client = this.cups.clientData
    const numFinca = cleanStringNumber(client.numero)
    const portal = cleanStringNumber(client.portal)
    const escalera = cleanStringNumber(client.escalera)
    const piso = cleanStringNumber(client.piso)
    const puerta = cleanStringNumber(client.puerta)
    let fullStreet = `${this.streetType.toLowerCase()} ${client.calle.toLowerCase() } ${numFinca}`

    if (!_.isEmpty(portal))   fullStreet = `${fullStreet}, portal ${portal}`
    if (!_.isEmpty(escalera)) fullStreet = `${fullStreet}, escalera ${escalera}`
    if (!_.isEmpty(piso))     fullStreet = `${fullStreet}, piso ${piso}`
    if (!_.isEmpty(puerta))   fullStreet = `${fullStreet}, puerta ${puerta}`



    return [
      { name: 'Tipo', value: client.tipoDocumento || '-' },
      { name: 'Nombre', value: client.nombre || '-' },
      { name: 'Apellidos', value: client.apellidos || '-' },
      
      { name: 'Tipo Documento', value: this.documentType || '-' },
      { name: 'Num. Documento', value: client.numeroDocumento || '-' },
      { name: 'Nacionalidad', value: client.nacionalidad || '-' },
      
      { name: 'Idioma', value: client.idioma || '-' },
      { name: 'Email', value: client.email || '-' },
      { name: 'FAX', value: client.fax || '-' },
      
      { name: 'Teléfono fijo', value: client.telefonoFijo || '-' },
      { name: 'Teléfono 1', value: client.telefono || '-' },
      { name: 'Teléfono 2', value: client.telefono2 || '-' },

      { name: 'Provincia', value: this.province || '-' },
      { name: 'Municipio', value: this.town || '-' },
      { name: 'Código postal', value: client.cp || '-' },

      { name: 'Dirección', value: fullStreet  || '-' },
      { name: '', value: '' },
      { name: '', value: '' },
    ]
  }

  private async getProvince(code: string) {
    try {
      const response = await CupsMasterApi.getProvince(code)
      this.province = (response.data.length > 0) ? response.data[0].description : '-'
    } catch (error) {
      this.province = '-'
    }
  }

  private async getTown(code: string) {
    try {
      const response = await CupsMasterApi.getTown(code)
      this.town = (response.data.length) ? response.data[0].municipalityIneDescription : '-'
    } catch (error) {
      this.town = '-'
    }
  }

  private async getStreetType(code: string) {
    try {
      const response = await CupsMasterApi.getTypeStreet(code)
      this.streetType = (response.data.length) ? response.data[0].description : ''
    } catch (error) {
      this.streetType = ''
    }
  }

  private async getDocumentType(code: string) {
    try {
      const response = await CupsMasterApi.getDocumentType(code)
      this.documentType = response.data[0].description
    } catch (error) {
      this.documentType = ''
    }
  }

  private async getPersonType(code: string) {
    try {
      const response = await CupsMasterApi.getPersonType(code)
      this.personType = response.data[0].description
    } catch (error) {
      this.personType = ''
    }
  }


  @Watch('cups', { deep: true, immediate: true }) protected async onChangeCups() {
    const haveProvince = !_.isEmpty(this.cups?.clientData?.provincia)
    const haveTown = !_.isEmpty(this.cups?.clientData?.provincia)
    const haveStreetType = !_.isEmpty(this.cups?.clientData?.tipoVia)
    const haveDocumentType = !_.isEmpty(this.cups?.clientData?.tipoDocumento)
    const havePersonType = !_.isEmpty(this.cups?.clientData?.tipoPersona)
    
    if (haveProvince) this.getProvince(this.cups?.clientData?.provincia)
    if (haveTown) this.getTown(this.cups?.clientData?.municipio)
    if (haveStreetType) this.getStreetType(this.cups?.clientData?.tipoVia)
    if (haveDocumentType) this.getDocumentType(this.cups?.clientData?.tipoDocumento)
    if (havePersonType) this.getPersonType(this.cups?.clientData?.tipoPersona)
  }
}