import { Component, MapAction, MapGetter, Vue } from 'types-vue'

import { FormConfig } from '@/Modules/Forms/models'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class QuerySQForm extends Vue {
  @MapGetter(Store.FormCreateCupsWithContract) protected loading: boolean
  @MapAction(Store.FormCreateCupsWithContract) protected create: (payload: {
    form: any
    fileType: string
  }) => Promise<any>

  protected showResult: boolean = false
  protected provinceCode: string = '28'
  protected fileType: string = ''

  protected get settingFormConfig(): FormConfig {
    return {
      label: 'settingform',
      withoutSubmit: true,
      items: [
        {
          type: 'select',
          name: 'type',
          label: 'Formato',
          options: [
            { value: 'xml', label: 'XML' },
            { value: 'txt', label: 'TXT' },
            { value: 'csv', label: 'CSV' }
          ],
          default: 'csv'
        }
      ]
    }
  }

  protected get formConfig(): FormConfig {
    return {
      label: 'qsform',
      submitButton: 'Consultar',
      items: [
        { type: 'tag', name: 'cups', label: 'CUPS' },
        {
          type: 'select',
          name: 'distribuidor',
          label: 'Distribuidora',
          options: `${CORE_URL}/system-subjects?categories=DIS,GRE`,
          valueQuery: 'code',
          default: '0234'
        },
        {
          type: 'select',
          name: 'tarifapeaje',
          label: 'Peaje',
          options: `${CORE_URL}/contract-toll-types`,
          valueQuery: 'code'
        },
        { type: 'divider', name: '' },
        {
          type: 'select',
          name: 'codprovincia',
          label: 'Provincia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Provincia',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'codmunicipio',
          label: 'Municipio',
          options: `${CORE_URL}/location-town?provinceId=${this.provinceCode}`,
          disabled: _.isNil(this.provinceCode),
          placeholder: 'Municipio',
          valueQuery: 'code'
        },
        { type: 'text', name: 'codpostal', label: 'Código postal' },
        {
          type: 'select',
          name: 'tipovia',
          label: 'Tipo vía',
          options: `${CORE_URL}/location-street-types`,
          valueQuery: 'code'
        },
        { type: 'text', name: 'via', label: 'Vía' },
        { type: 'text', name: 'numfinca', label: 'Numero' },
        { type: 'text', name: 'portal', label: 'Portal' },
        { type: 'text', name: 'escalera', label: 'Escalera' },
        { type: 'text', name: 'piso', label: 'Piso' },
        { type: 'text', name: 'puerta', label: 'Puerta' },
        { type: 'divider', name: '' },
        {
          type: 'select',
          name: 'impagos',
          label: 'Impagos',
          options: [
            { label: 'Si', value: 'S' },
            { label: 'No', value: 'N' }
          ]
        },
        {
          type: 'select',
          name: 'prohibpublicdatcli',
          label: 'prohibpublicdatcli',
          options: [
            { label: 'Si', value: 'S' },
            { label: 'No', value: 'N' }
          ]
        },
        {
          type: 'select',
          name: 'derechotur',
          label: 'TUR',
          options: [
            { label: 'Si', value: 'S' },
            { label: 'No', value: 'N' }
          ]
        }
      ],
      rules: {
        // codmunicipio: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
      }
    }
  }

  protected onChangeForm(form: any) {
    const provinceCode = form?.codprovincia
    const hasChangeProvinceCodeChanged = !!provinceCode && provinceCode !== this.provinceCode
    if (hasChangeProvinceCodeChanged) this.provinceCode = provinceCode
  }

  protected onChangeSettingForm(form: { type: string; numpage: string }) {
    this.fileType = form.type
  }

  protected async onSubmitForm(form: any) {
    try {
      const cleanForm = _.omitBy(form, _.isEmpty)

      if (cleanForm.codmunicipio) {
        cleanForm.codmunicipio = cleanForm.codmunicipio.slice(0, -1)
      }

      await this.create({ form: cleanForm, fileType: this.fileType })
      this.showResult = true
    } catch (error) {}
  }

  protected onReturn() {
    this.showResult = false
  }
}
