import { ProcessDocument } from '@/Modules/Processes/models'
import _ from 'lodash'
import { Component, Prop, Vue } from 'types-vue'


@Component
export default class TableFiles extends Vue {

  @Prop({ required: true, type: Array }) protected fileList: ProcessDocument[]


  protected loading: boolean = false

  protected get labelName(): string {
    return this.fileList?.length ? 'NOMBRE': ''
  }

  protected getFileIcon(extension: string) {
    return `fiv-icon-${extension.substring(1)}`
  }
}