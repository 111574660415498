var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        dragenter: function($event) {
          $event.preventDefault()
          return _vm.onDragOver.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "header" }, [_c("Processes-UploadButton")], 1),
      _c("Processes-TableFiles", { attrs: { fileList: _vm.documents } }),
      _c("Processes-DropUploadFile", { ref: "DropUploadFile" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }