import { QueryParams } from '@/Modules/Processes/definitions/QueryParams';
import { ProcessesPages } from '@/Modules/Processes/router';
import { ChartData, ChartOptions } from 'chart.js';
import { Component, mixins, Prop, Vue, Watch } from 'types-vue'
import { Pie } from 'vue-chartjs';
import { PieChart } from '@/Modules/Dashboard/enums/PieChart';

@Component({ extends: Pie })
export default class ProcessesDoughnut extends mixins(Pie) {

  @Prop({ required: true, type: Number }) protected open: number
  @Prop({ required: true, type: Number }) protected closed: number
  @Prop({ required: true, type: Number }) protected nonValidated: number


  protected get chartData(): ChartData {
    return {
      labels: ['Abiertos', 'Cerrados', 'Rechazados'],
      datasets: [
        { label: 'Abiertos', data: [this.open, this.closed, this.nonValidated], backgroundColor: ['#7A65E6', '#30D09A', '#EF3F55'] },
      ]
    }
  }

  protected get chartOptions(): ChartOptions {
    return {
      onClick: this.onChartSectionClick,
      onHover: this.setPointer,
      responsive: true,
      legend: {
        display: false,
      },
      plugins: {
        title: {
          display: false,
        }
      }
    }
  }

  protected onChartSectionClick(e, i) {
    const selectedSection = i[0]
    if (selectedSection) {
      switch (selectedSection._index) {
        case PieChart.Opened:
          this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'OPENED' } })
          break
        case PieChart.Closed:
          this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'COMPLETED' } })
          break
        case PieChart.NotValidated:
          this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'REJECTED' } })
        }
    }
  }

  protected setPointer(e) {
    e.target.style.cursor = 'pointer'
  }

  mounted() {
    this.renderChart(this.chartData, this.chartOptions)
  }


  @Watch('chartData', { deep: true })
  protected onChangeChartData() {
    this.renderChart(this.chartData, this.chartOptions)
  }
}