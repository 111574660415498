var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification" }, [
    _vm.isError
      ? _c("i", { staticClass: "el-icon-warning-outline" })
      : _vm.isSuccess
      ? _c("i", { staticClass: "el-icon-check" })
      : _c("i", { staticClass: "el-icon-loading" }),
    _c("span", [_vm._v(_vm._s(_vm.message))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }