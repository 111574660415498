var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header", on: { click: _vm.onClickHeader } },
        [
          _c("Core-Title", [
            _c("div", { staticClass: "title" }, [
              _c("i", { class: _vm.icon }),
              _c("h3", [_vm._v(_vm._s(_vm.title))])
            ])
          ])
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-bottom", appear: "" } }, [
        _vm.showing
          ? _c("div", { staticClass: "content" }, [_vm._t("default")], 2)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }