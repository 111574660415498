import moment from 'moment'
import { Component, Filter, Prop, Vue } from 'types-vue'
import _ from 'lodash'
import { DatesMixin } from '@/Core/mixins/Dates'

@Component
export default class TimeColumn extends DatesMixin {

  @Prop({ required: true }) protected time: number
  @Prop({ type: String, default: 'DD/MM/YYYY' }) protected format: string


  protected get timeToNow(): string {
    if (_.isEmpty(this.time)) return '-'
    return this.getLocalDate(this.time).fromNow()
  }

  

  protected get dateString(): string {
    if (_.isEmpty(this.time)) return ''
    return this.getLocalDate(this.time).format(this.format)
  }
}