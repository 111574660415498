import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import P02 from './pages/P02/P02.vue'
import P03 from './pages/P03/P03.vue'
import P04 from './pages/P04/P04.vue'
import P05 from './pages/P05/P05.vue'
import P38 from './pages/P38/P38.vue'
import P41 from './pages/P41/P41.vue'
import P42 from './pages/P42/P42.vue'
import P43 from './pages/P43/P43.vue'
import P44 from './pages/P44/P44.vue'
import P48 from './pages/P48/P48.vue'
import P49 from './pages/P49/P49.vue'
import List from './pages/List/List.vue'

export enum ProcessesFormsPages {
  List = 'process-form-list',
  P02 = 'process-02-form',
  P03 = 'process-03-form',
  P04 = 'process-04-form',
  P05 = 'process-05-form',
  P38 = 'process-38-form',
  P41 = 'process-41-form',
  P42 = 'process-42-form',
  P43 = 'process-43-form',
  P44 = 'process-44-form',
  P48 = 'process-48-form',
  P49 = 'process-49-form'
}

const routes: Array<RouteConfig> = [
  {
    path: '/create-process',
    component: Page,
    children: [
      {
        path: '',
        name: ProcessesFormsPages.List,
        meta: { menuActive: 'createProcess' },
        component: List
      },
      {
        path: '02',
        name: ProcessesFormsPages.P02,
        meta: { menuActive: 'createProcess' },
        component: P02,
        props: true
      },
      {
        path: '03',
        name: ProcessesFormsPages.P03,
        meta: { menuActive: 'createProcess' },
        component: P03,
        props: true
      },
      {
        path: '04',
        name: ProcessesFormsPages.P04,
        meta: { menuActive: 'createProcess' },
        component: P04,
        props: true
      },
      {
        path: '05',
        name: ProcessesFormsPages.P05,
        meta: { menuActive: 'createProcess' },
        component: P05,
        props: true
      },
      {
        path: '38',
        name: ProcessesFormsPages.P38,
        meta: { menuActive: 'createProcess' },
        component: P38,
        props: true
      },
      {
        path: '41',
        name: ProcessesFormsPages.P41,
        meta: { menuActive: 'createProcess' },
        component: P41,
        props: true
      },
      {
        path: '42',
        name: ProcessesFormsPages.P42,
        meta: { menuActive: 'createProcess' },
        component: P42,
        props: true
      },
      {
        path: '43',
        name: ProcessesFormsPages.P43,
        meta: { menuActive: 'createProcess' },
        component: P43,
        props: true
      },
      {
        path: '44',
        name: ProcessesFormsPages.P44,
        meta: { menuActive: 'createProcess' },
        component: P44,
        props: true
      },
      {
        path: '48',
        name: ProcessesFormsPages.P48,
        meta: { menuActive: 'createProcess' },
        component: P48,
        props: true
      },
      {
        path: '49',
        name: ProcessesFormsPages.P49,
        meta: { menuActive: 'createProcess' },
        component: P49,
        props: true
      }
    ]
  }
]

export default routes
