import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import BillingErrorApi from '../connections/api/BillingErrors'
import { BillingError } from '../models'
import { BillingErrorResponse } from '../models/api'

@Module({ namespaced: true })
export default class BillingErrors extends VuexModule {
  
  private _billingErrorList: BillingError[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _listLoading: boolean = true

  @Getter() public billingErrorsList(): BillingError[] { return this._billingErrorList}
  @Getter() public pageSize(): number { return this._pageSize } 
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }

  @Mutation()
  protected setBillingErrors(data: BillingErrorResponse) {
    this._billingErrorList = data.content
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setCurrentPage(currentPage: number) {
    this._currentPage = currentPage
  }

  @Mutation()
  protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  @Mutation()
  protected setListLoading(value: boolean) {
    this._listLoading = value
  }

  @Action({ commit: 'setCurrentPage' }) protected updateCurrentPage(currentPage) { return currentPage }


  @Action({ commit: 'setBillingErrors', useContext: true })
  protected async obtainBillingErrors(ctx: ActionContext<any, any>): Promise<BillingErrorResponse> {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const sort = 'createdAt,DESC'

    if (_.isNil(page) || _.isNil(size)) return
    
    ctx.commit('setListLoading', true)
    const response = await BillingErrorApi.getBillingErrors({ page, size, sort })

    return response.data
  }


  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }
  

  @Action({ useContext: true })
  protected async removeBillingError(ctx: ActionContext<any, any>, file: BillingError): Promise<any> {
    const response = await BillingErrorApi.removeById(file.id)

    if (response.status === 200)
      ctx.dispatch('obtainBillingErrors')
  }
}
