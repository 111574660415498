import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class FormResult extends Vue {
  
  
  @MapGetter(Store.FormCreateCupsWithContract) protected errors: any[]
  @MapGetter(Store.FormCreateCupsWithContract) protected result: any
  @MapGetter(Store.FormCreateCupsWithContract) protected url: any


  protected downloadloading: boolean = false


  onClickReturn() {
    this.$emit('return')
  }

  onClickDownload() {

    try {
      this.downloadloading = true
      
      
    } catch (error) {
      
    } finally {
      this.downloadloading = false
    }


  }
}