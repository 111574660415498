import { Store } from '@/Core/Models/Stores'
import UserGroup from '@/Modules/Auth/models'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class GroupsListItem extends Vue {

  @Prop({ type: Object }) protected group: UserGroup
  
  @MapAction(Store.UserGroups) protected changeGroup: (payload: { group: UserGroup }) => Promise<any>
  @MapGetter(Store.UserGroups) protected changeLoading: boolean

  protected itemLoading: boolean = false
 
 
   async onClickGroup() {
     if (this.changeLoading) return

     try {
       this.itemLoading = true
       await this.changeGroup({ group: this.group })
     } catch (error) {
       
     } finally {
       this.itemLoading = false
     }
   }
}