var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "configuration-page" } },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("user userConfiguration.title")))]),
      _c("User-ChangePasswordCard"),
      _c("User-ApiVersionCard")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }