import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ObservationsForm_42 extends Vue {
  protected get formOptions(): FormConfig {
    return {
      label: 'Observations',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Comentario de la solicitud',
          placeholder: 'Introduce los comentarios de la solicitud',
          maxCharacters: 400
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del documento',
          placeholder: 'Selecciona fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de documento',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona tipo de documento'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de descarga del documento',
          placeholder: 'Introduce la URL de descarga del documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Información adicional',
          placeholder: 'Introduce la información adicional',
          maxCharacters: 255
        }
      ]
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
