import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { QueryParams } from '../../definitions/QueryParams'
import { ProcessModel } from '../../models'
import moment from 'moment'

@Component
export default class InProgress extends Vue {
  /**
   * STORE
   */

  @MapAction(Store.ProcessesInprocess) private updateInitialRender: (value: boolean) => void
  @MapAction(Store.ProcessesInprocess) private processUrlQueryParams: (
    queryParams: any
  ) => Promise<any>
  @MapAction(Store.ProcessesInprocess) private newPageSize: (pageSize: number) => number
  @MapAction(Store.ProcessesInprocess) protected newOrder: (orderParams: {
    prop: string
    order: string
  }) => void
  @MapAction(Store.ProcessesInprocess) protected newProceesTypeFilterValue: (newValues) => any
  @MapAction(Store.ProcessesInprocess) protected newReferenceFilter: (newValues) => any
  @MapAction(Store.ProcessesInprocess) protected newCupsFilter: (newValues) => any

  @MapGetter(Store.SelectedProcesses) protected resendLoading: boolean
  @MapGetter(Store.ProcessesInprocess) protected listLoading: boolean
  @MapGetter(Store.ProcessesInprocess) protected pageSize: number
  @MapGetter(Store.ProcessesInprocess) protected totalElements: number
  @MapGetter(Store.ProcessesInprocess) protected processesList: any[]
  @MapGetter(Store.SelectedProcesses) protected selectedProcesses: ProcessModel[]
  @MapGetter(Store.ProcessesInprocess) protected totalActiveFilter: number
  @MapGetter(Store.ProcessesInprocess) protected initialRender: boolean

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }

  /**
   * WATCHERS
   */

  @Watch('$route', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    const orderPropIsNull = _.isNil(this.$route.query[QueryParams.OrderProp])
    const orderDirectionIsNull = _.isNil(this.$route.query[QueryParams.OrderDirection])

    // if (this.initialRender) {
    //   this.$router.push({
    //     ...this.$route,
    //     query: {
    //       ...this.$route.query,
    //       [QueryParams.OrderProp]: 'createdAt',
    //       [QueryParams.OrderDirection]: 'DESC',
    //       [QueryParams.FromDate]: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    //       [QueryParams.ToDate]: moment().format('YYYY-MM-DD')
    //     }
    //   })
    // }

    if (orderPropIsNull) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, [QueryParams.OrderProp]: 'createdAt' }
      })
      return
    }

    if (orderDirectionIsNull) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, [QueryParams.OrderDirection]: 'DESC' }
      })
      return
    }

    await this.processUrlQueryParams(this.$route.query)
  }

  mounted() {
    this.updateInitialRender(true)
  }
}
