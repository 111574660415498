import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, MapGetter, mixins, Watch } from 'types-vue'

@Component
export default class MasterPage extends mixins(FiltersMixin, PermissionMixin) {
  protected dialogCreateVisible: boolean = false
  protected dialogEditVisible: boolean = false

  @MapGetter(Store.Master) protected pageSize: number
  @MapGetter(Store.Master) protected totalElements: number
  @MapGetter(Store.Master) protected list: any[]
  @MapGetter(Store.Master) protected loading: boolean
  @MapAction(Store.Master) protected initMaster: (masterCode: string) => void
  @MapAction(Store.Master) protected startEditor: (element: any) => void
  @MapAction(Store.Master) private newPageSize: (pageSize: number) => number
  @MapAction(Store.Master) private updateCurrentPage: (pageNumber) => void
  @MapAction(Store.Master) private obtainMasters: () => Promise<any>

  protected get masterCode() {
    return this.$route.params.master
  }

  /**
   * METHODS
   */
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' } })
    }
  }

  protected async processCurrentPage() {
    const pageNumber = parseInt(this.$route.query.page as string) || 1
    this.updateCurrentPage(pageNumber)
    await this.obtainMasters()

  }

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    this.processCurrentPage()
  }

  protected onClickShowCreateForm() {
    this.dialogCreateVisible = true
  }

  protected onClickEditButton(item) {
    this.startEditor(item)
    this.dialogEditVisible = true
  }

  async created() {
    await this.initMaster(this.masterCode)
  }

  /**
   * WATCHERS
   */

  @Watch('$route.query.page')
  protected async onChangeQueryPage() {
    await this.processCurrentPage()
  }
}
