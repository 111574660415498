import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n'
import moment from 'moment'
import './plugins/elementUi'
import './plugins/vueCookies'
import './plugins/vueTimeline'
import './plugins/MatchMedia'
import './plugins/Customs/ProcessNotification'
import { initGtag } from './plugins/Gtag';
import ElInputTag from 'el-input-tag'


initGtag(router)
moment.locale('es')
Vue.config.productionTip = false;

Vue.use(ElInputTag)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

import '@/Core/components'

