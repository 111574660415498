var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.contractsList } },
    [
      _c("el-table-column", {
        attrs: { label: "CUPS", prop: "cups", "min-width": "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("Core-CupsColumn", {
                  attrs: { cups: scope.row.cups, index: scope.$index }
                })
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "TIPO DE MENSAJE", prop: "messageTypeCode" }
      }),
      _c("el-table-column", {
        attrs: { label: "FECHA DE INICIO", prop: "createdAt" }
      }),
      _c("el-table-column", {
        attrs: { label: "TIPO DE PROCESO", prop: "processCode" }
      }),
      _c("el-table-column", {
        attrs: { label: "NÚMERO DE REFERENCIA", prop: "numreference" }
      }),
      _c("el-table-column", {
        attrs: { label: "COMERCIALIZADORA", prop: "marketerCode" }
      }),
      _c("el-table-column", {
        attrs: { label: "MOTIVO", prop: "processReason" }
      }),
      _c("el-table-column", {
        attrs: { label: "DESCRIPCIÓN", prop: "processReasonDescription" }
      }),
      _c("el-table-column", {
        attrs: { width: "175", fixed: "right" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.onClickDownload(scope.row.id)
                          }
                        }
                      },
                      [
                        _vm._v("Descargar"),
                        _c("i", { staticClass: "el-icon-download" })
                      ]
                    ),
                    _c("span")
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }