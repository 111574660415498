import _ from 'lodash'
import { Component, Vue, Watch } from 'types-vue'
import { SearchType } from '../../definitions/SearchType'
import { SearcherPages } from '../../router'

const TIME_OUT_SECONDS = 1500

@Component
export default class SearchBar extends Vue {

  protected selectModel: string = SearchType.All
  protected inputModel: string = ''
  protected timeout: number = null

  protected get searchTypes() {
    return [
      { key: SearchType.All, label: 'Todo' },
      { key: SearchType.Processes, label: 'Procesos' },
      { key: SearchType.Bills, label: 'Facturas' },
      { key: SearchType.AtrContracts, label: 'Contratos ATR'}
    ]
  }

  protected applySearch() {
    this.cleanTimeout()
    
    if (_.isEmpty(this.inputModel)) return

    this.$router.push({ name: SearcherPages.Result, params: { type: this.selectModel, cups: this.inputModel }})
  }

  protected onChangeType() {
    this.applySearch()
  }

  @Watch('$route.params', { immediate: true, deep: true })
  protected onChangeRouteParams() {
    this.inputModel = this.$route.params.cups || ''
    this.selectModel = this.$route.params.type || SearchType.All
  }

  protected onInput() {
    this.cleanTimeout()
    this.createTimeout()
  }

  private cleanTimeout() {
    if (!!this.timeout) clearInterval(this.timeout)
  }

  private createTimeout() {
    this.timeout = setTimeout(this.applySearch, TIME_OUT_SECONDS)
  }

}