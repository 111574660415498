import { Component, Vue } from 'types-vue';
import c from 'template-colors-web';

@Component
export default class ShowVersionMixin extends Vue {
  protected version = process.env.VUE_APP_VERSION as string
  static showed: boolean = false
  
  protected showVersion() {
    c.define('titleStyle', ['rgb(16,172,11)','bold', 'style("font-size: 13px")']);
    c.define('versionStyle', ['grey', 'style("font-size: 11px")'])
    const text = c`${'IOGAS - Madrileña red de gas'}.titleStyle ${`v${this.version}`}.versionStyle`
    
    console.log(text);
  }
  
  created() {
    if (!ShowVersionMixin.showed) this.showVersion()
    ShowVersionMixin.showed = true
  }
}