import moment from 'moment'
import { Component, Prop, Vue } from 'types-vue'
import { FlowProcessDistribution } from '@/Modules/Processes/models'

@Component
export default class DistributionDetails extends Vue {

  @Prop({ required: true, type: Object }) protected distribution: FlowProcessDistribution

  protected get date() { return moment(this.distribution.fecreparto).format('LL') }
}