import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class SupplyPointForm2 extends Vue {

  protected get formOptions(): FormConfig {
    return {
      label: 'Supply point form',
      submitButton: 'Siguiente',
      items: [
        { type: 'select', name: 'streettype', label: 'Tipo de via', options: `${CORE_URL}/location-street-types`, placeholder: 'Selecciona tipo de via', valueQuery: 'code' },
        { type: 'text', name: 'street', label: 'Via', placeholder: 'Introduce el nombre de la via', maxCharacters: 60 },
        { type: 'text', name: 'streetnumber', label: 'Num. finca', placeholder: 'Introduce el numero de la finca', maxCharacters: 4 },
        { type: 'text', name: 'portal', label: 'Portal', placeholder: 'Introduce el portal', maxCharacters: 5 },
        { type: 'text', name: 'staircase', label: 'Escalera', placeholder: 'Introduce la escalera', maxCharacters: 5 },
        { type: 'text', name: 'floor', label: 'Piso', placeholder: 'Introduce la escalera', maxCharacters: 5 },
        { type: 'text', name: 'door', label: 'Puerta', placeholder: 'Introduce la puerta', maxCharacters: 5 },
      ],
      rules: {}
    }
  }

  protected change(process: any) {
    if (process.streettype !== "" && process.street !== "" && process.streetnumber !== "" && process.portal !== "" && process.staircase !== ""
      && process.floor !== "" && process.door !== "") {
      const jsonP38_SupplyPointForm2 = {
        streettype: process.streettype,
        street: process.street,
        streetnumber: process.streetnumber,
        portal: process.portal,
        staircase: process.staircase,
        floor: process.floor,
        door: process.door,
      };
      localStorage.setItem('p38_supplypointform2_json', JSON.stringify(jsonP38_SupplyPointForm2));
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}