import { render, staticRenderFns } from "./DropUploadFile.vue?vue&type=template&id=1f97e800&scoped=true&"
import script from "./DropUploadFile.ts?vue&type=script&lang=ts&"
export * from "./DropUploadFile.ts?vue&type=script&lang=ts&"
import style0 from "./DropUploadFile.vue?vue&type=style&index=0&id=1f97e800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f97e800",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f97e800')) {
      api.createRecord('1f97e800', component.options)
    } else {
      api.reload('1f97e800', component.options)
    }
    module.hot.accept("./DropUploadFile.vue?vue&type=template&id=1f97e800&scoped=true&", function () {
      api.rerender('1f97e800', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Processes/components/details/DocumentsTab/DropUploadFile/DropUploadFile.vue"
export default component.exports