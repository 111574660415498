var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "process-list" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { "empty-text": _vm.emptyText, data: _vm.addressesList }
        },
        [
          _c("el-table-column", {
            attrs: { label: "PROVINCIA", prop: "desprovincia" }
          }),
          _c("el-table-column", {
            attrs: { label: "MUNICIPIO", prop: "desmunicipio" }
          }),
          _c("el-table-column", {
            attrs: { label: "CP", prop: "codigopostal" }
          }),
          _c("el-table-column", { attrs: { label: "VÍA", prop: "codigovia" } }),
          _c("el-table-column", {
            attrs: { label: "NOMBRE", prop: "nombrevia" }
          }),
          _c("el-table-column", { attrs: { label: "FINCA", prop: "finca" } }),
          _c("el-table-column", { attrs: { label: "PORTAL", prop: "portal" } }),
          _c("el-table-column", {
            attrs: { label: "ESCALERA", prop: "escalera" }
          }),
          _c("el-table-column", { attrs: { label: "PISO", prop: "piso" } }),
          _c("el-table-column", { attrs: { label: "PUERTA", prop: "puerta" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }