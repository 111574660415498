var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "before-close": _vm.onBeforeClose,
        width: "45rem",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _vm.isSystemSubject && _vm.visible
        ? _c("Masters-SystemSubjectForm", {
            attrs: {
              loading: _vm.formLoading,
              "default-values": _vm.editorElement,
              "submit-label": "Guardar",
              "cancel-label": "Cancelar"
            },
            on: { cancel: _vm.onCancel, submit: _vm.onSubmit }
          })
        : _vm.visible
        ? _c("Masters-DefaultForm", {
            attrs: {
              loading: _vm.formLoading,
              "default-values": _vm.editorElement,
              "submit-label": "Guardar",
              "cancel-label": "Cancelar",
              "remove-label": "Eliminar"
            },
            on: {
              cancel: _vm.onCancel,
              submit: _vm.onSubmit,
              remove: _vm.onRemove
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }