var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: { "empty-text": _vm.emptyText, data: _vm.bills },
                    on: {
                      "cell-mouse-leave": _vm.onCellMouseLeave,
                      "cell-mouse-enter": _vm.onCellMouseEnter,
                      "selection-change": _vm.onSelectionChange
                    }
                  },
                  [
                    _vm.withSelect
                      ? _c("el-table-column", {
                          attrs: { type: "selection", width: "55" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        label: "",
                        prop: "validated",
                        align: "center",
                        width: "40"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "validated-cell" }, [
                                  scope.row.validated
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Validado",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-success icon"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "No validado",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-error error icon"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "NUM. FACTURA / PSEUDO",
                        prop: "billNum",
                        "min-width": "160"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "bill-num-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "bill-num-cell-content" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bill-num-cell-content-text"
                                        },
                                        [
                                          _c("span", [
                                            _c("b", [_vm._v("NUM:")]),
                                            _vm._v(
                                              " " + _vm._s(scope.row.billNum)
                                            )
                                          ]),
                                          _c("span", [
                                            _c("b", [_vm._v("PDO:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.pseudoBillNum)
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "CUPS",
                            prop: "cups",
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-CupsColumn", {
                                      attrs: {
                                        cups: scope.row.cups,
                                        index: scope.$index
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "TIPO FACTURA", prop: "billTypeCode" }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "COD. PROCESO", prop: "processCode" }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "FECHA FACTURACIÓN",
                            prop: "billDate",
                            "min-width": "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-TimeColumn", {
                                      attrs: { time: scope.row.billDate }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "FECHA PROCESO",
                            prop: "createdAt",
                            "min-width": "100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-TimeColumn", {
                                      attrs: { time: scope.row.createdAt }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        label: "IMPORTE",
                        prop: "amount",
                        "min-width": "100"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("currency")(scope.row.amount))
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "DESCARGADA",
                            prop: "downloaded",
                            width: "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Bills-HasBeenDownloadedColumn", {
                                      attrs: {
                                        hasBeenDownloaded: scope.row.downloaded
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "DETALLES", width: "100" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDetails(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Ver detalles "),
                                            _c("i", {
                                              staticClass: "el-icon-top-right"
                                            })
                                          ]
                                        ),
                                        _c("span")
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "", width: "50", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "buttons-container" },
                                  [
                                    _c("Bills-ListItemMenu", {
                                      attrs: {
                                        bill: scope.row,
                                        "row-index": scope.$index
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }