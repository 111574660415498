var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      ref: "table",
      staticStyle: { width: "100%" },
      attrs: { "empty-text": "No hay periodos", data: _vm.periods }
    },
    [
      _c("el-table-column", { attrs: { label: "TARIFA", prop: "tarifa" } }),
      _c("el-table-column", {
        attrs: { label: "TIPO DE LECTURA", prop: "tipoLectura" }
      }),
      _c("el-table-column", {
        attrs: { label: "LECTURA", prop: "consumo" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [_vm._v(_vm._s(parseInt(scope.row.consumo)) + " ")])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "FECHA INCIO", prop: "fechaInicio" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("dateFilter")(scope.row.fechaInicio)) + " "
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "FECHA FIN", prop: "fechaFin" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("dateFilter")(scope.row.fechaFin)) + " ")
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }