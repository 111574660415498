var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      attrs: { type: "text", loading: _vm.loading, icon: "el-icon-delete" },
      on: { click: _vm.onClickButton }
    },
    [_vm._v("Eliminar")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }