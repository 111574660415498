import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class InstallationForm extends Vue {

  // Personalized boolean variables.
  protected isProductTypeSelected: boolean = false;
  protected isProductTypeRequired: boolean = false;
  protected isApTransIdSelected: boolean = false;
  protected isApTransNumberRequired: boolean = false;
  protected isSwitchSelected: boolean = false;
  protected isTollTypeSelected: boolean = false;

  protected get formOptions(): FormConfig {
    return {
      label: 'Installation',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'aptransind',
          label: 'Indicador transformación aparatos',
          options: [{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }],
          placeholder: 'Selecciona indicador transformación aparatos'
        },
        {
          type: 'text',
          name: 'aptransnumber',
          label: 'Numero aparatos transformar',
          placeholder: 'Introduce el numero aparatos transformar'
        },
        {
          type: 'select',
          name: 'gasusetype',
          label: 'Uso gas',
          options: `${CORE_URL}/contract-use-gas-type`,
          placeholder: 'Selecciona uso gas',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'counterproperty',
          label: 'Propiedad contador',
          options: `${CORE_URL}/contract-meter-corrector-properties`,
          placeholder: 'Selecciona propiedad contador',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'designpower',
          label: 'Potencia diseño',
          placeholder: 'Introduce la potancia diseño',
        },
        {
          type: 'select',
          name: 'tolltype',
          label: 'Peaje',
          options: `${CORE_URL}/contract-toll-types`,
          placeholder: 'Selecciona peaje',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'reqestimatedqa',
          label: 'Consumo anual',
          placeholder: 'Introduce el consumo anual',
          disabled: !this.isTollTypeSelected
        },
        {
          type: 'text',
          name: 'reqqd',
          label: 'Caudal diario',
          placeholder: 'Introduce el caudal diario',
        },
        {
          type: 'text',
          name: 'reqqh',
          label: 'Caudal horario',
          placeholder: 'Introduce el caudal horario',
        },
        {
          type: 'date',
          name: 'iricertificatedate',
          label: 'Fecha certificado IRI',
          placeholder: 'Introduce la fecha certificado IRI',
        },
        {
          type: 'text',
          name: 'reig',
          label: 'Código instalador REIG',
          placeholder: 'Introduce el código instalador REIG',
        },
        {
          type: 'select',
          name: 'terminstexist',
          label: 'Existe instalación térmica',
          options: [{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }],
          placeholder: 'Selecciona si existe instalación térmica'
        },
        {
          type: 'text',
          name: 'reqoutgoingpressure',
          label: 'Presión de salida',
          placeholder: 'Introduce la presión de salida',
        },
        {
          type: 'select',
          name: 'telemetering',
          options: [{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }],
          label: 'Indicador de telemedida',
          placeholder: 'Selecciona indicador de telemedida',
        },
        {
          type: 'select',
          name: 'factmethod',
          label: 'Método de facturación',
          options: `${CORE_URL}/billing-method`,
          placeholder: 'Selecciona método de facturación',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'gasstationtype',
          label: 'Identificador de gasineras',
          options: `${CORE_URL}/gas-station-types`,
          placeholder: 'Selecciona método de facturación',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'wantAProductType',
          label: '¿Quieres seleccionar un tipo de producto?',
          options: [{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }],
          placeholder: 'Selecciona una de las dos opciones'
        },
        {
          type: 'select',
          name: 'producttype',
          label: 'Tipo de producto',
          options: `${CORE_URL}/contract-product-types`,
          placeholder: 'Selecciona tipo de producto',
          valueQuery: 'code',
          visible: this.isSwitchSelected
        }, {
          type: 'select',
          name: 'producttolltype',
          label: 'Tipo de peaje del producto',
          options: `${CORE_URL}/contract-toll-types`,
          placeholder: 'Selecciona tipo de peaje del producto',
          valueQuery: 'code',
          disabled: this.isProductTypeSelected,
          visible: this.isSwitchSelected
        },
        {
          type: 'text',
          name: 'productqd',
          label: 'Caudal diario del producto',
          placeholder: 'Introduce el caudal diario del producto',
          disabled: this.isProductTypeSelected,
          visible: this.isSwitchSelected
        },
        {
          type: 'text',
          name: 'productqa',
          label: 'Caudal anual del producto',
          placeholder: 'Introduce el caudal anual del producto',
          disabled: this.isProductTypeSelected,
          visible: this.isSwitchSelected
        }
      ],
      rules: {
        aptransind: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        aptransnumber: [{ required: this.isApTransNumberRequired, trigger: 'blur', message: 'Es obligatorio' }],
        gasusetype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        counterproperty: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        telemetering: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        gasstationtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        wantAProductType: [{ required: !this.isTollTypeSelected, trigger: 'blur', message: 'Es obligatorio' }],
        producttype: [{ required: this.isProductTypeRequired, trigger: 'blur', message: 'Es obligatorio' }],
        producttolltype: [{ required: this.isProductTypeRequired, trigger: 'blur', message: 'Es obligatorio' }],
        productqd: [{ required: this.isProductTypeRequired, trigger: 'blur', message: 'Es obligatorio' }],
        productqa: [{ required: this.isProductTypeRequired, trigger: 'blur', message: 'Es obligatorio' }],
      }
    }
  }

  protected async change(process: any) {
    // Form constants.
    const aptransind = process.aptransind;
    const producttype = process.producttype;
    const wantAProductType = process.wantAProductType;
    const tolltype = process.tolltype;

    // AP TRANS ID LOGIC.
    if (aptransind !== "") {
      if (aptransind === "S") {
        this.isApTransNumberRequired = true;
      } else {
        this.isApTransNumberRequired = false;
      }
    }

    // SWITCH SELECTED LOGIC.
    if (wantAProductType === "S") {
      this.isSwitchSelected = true;
    } else {
      this.isSwitchSelected = false;
    }

    // PRODUCT TYPE LOGIC.
    if (producttype !== "") {
      if (producttype === "01") {
        this.isProductTypeRequired = false;
        this.$notify.error({ title: 'Error en el tipo de producto', message: "No se puede seleccionar el 01(Indefinido)" });
      } else {
        this.isProductTypeRequired = true;
      }
    }

    // TOLL TYPE LOGIC.
    if (tolltype !== "") {
      this.isTollTypeSelected = true;
      this.isProductTypeRequired = false;
    } else {
      if (wantAProductType === "N") {
        this.$notify.error({ title: 'Error en el tipo de producto', message: "Tienes que seleccionar el si porque hay campos obligatorios" });
      }
      this.isTollTypeSelected = false;
      this.isProductTypeRequired = true;
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}