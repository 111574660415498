var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "newPassword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "show-password": "",
                  placeholder: _vm.$t("auth changePassword.newPassword"),
                  disabled: _vm.loading
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmit.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.newPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "newPassword", $$v)
                  },
                  expression: "form.newPassword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "repeatNewPassword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "show-password": "",
                  placeholder: _vm.$t("auth changePassword.repeatPassword"),
                  disabled: _vm.loading
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmit.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.repeatNewPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "repeatNewPassword", $$v)
                  },
                  expression: "form.repeatNewPassword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("Auth-PasswordChecker", {
                ref: "passwordChecker",
                attrs: { password: _vm.form.newPassword }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "signinBtn",
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary"
                  },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v(_vm._s(_vm.$t("auth changePassword.submitButton")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }