import { Store } from '@/Core/Models/Stores';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'

@Component
export default class Main extends Vue {

  @MapAction(Store.Addresses) protected obtainAddresses: () => Promise<void>
  @MapAction(Store.Addresses) private processUrlQueryParams: (queryParams: any) => Promise<any>
  @MapAction(Store.Addresses) private updateCurrentPage: (pageNumber) => void
  @MapAction(Store.Addresses) private newPageSize: (pageSize: number) => number

  @MapGetter(Store.Addresses) protected pageSize: number
  @MapGetter(Store.Addresses) protected totalElements: number
  @MapGetter(Store.Addresses) protected listLoading: boolean


  /**
   * METHODS
   */
    
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' }})
    }
  }

  protected async processCurrentPage() {
    const pageNumber = parseInt(this.$route.query.page as string) || 1
    this.updateCurrentPage(pageNumber)
    await this.obtainAddresses()

  }

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    this.processCurrentPage()
  }

  /**
   * VUE EVENTS
   */
  
  protected async created() {
    this.processCurrentPage()
  }


  /**
   * WATCHERS
   */

  @Watch('$route.query', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    await this.processUrlQueryParams(this.$route.query)
  }
  
}