import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { CupsApi } from '../connections/api/Cups'
import { mapCups } from '../mappers/cupsInfo'
import { Cups, ReadingReason } from '../models'


@Module({ namespaced: true })
export default class CupsDetails extends VuexModule {

  private _cups: Cups = null
  private _loading: boolean = false
  private _error: string = null


  @Getter() public cups(): Cups { return this._cups }
  @Getter() public loading(): boolean { return this._loading }
  @Getter() public error(): string { return this._error }


  @Mutation() protected setCups(value: any, readingReasons: ReadingReason) { this._cups = value }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setError(value: string) { this._error = value }


  @Action({ useContext: true })
  protected async initDetails(ctx: ActionContext<any, any>, id: string): Promise<any> {

    try {
      ctx.commit('setLoading', true)
      const response = await CupsApi.getCups(id)
      const cupsList = await mapCups(response.data)
      ctx.commit('setCups', cupsList[0])
      
    } catch (err) {
      const axiosError = err as AxiosError
      console.error('error', err)
      if (axiosError.response.data.code === '16') {
        ctx.commit('setError', axiosError.response.data.message)
      } else {
        Notification.error({ title: `Error ${axiosError.response.status}`, message: 'Error al obtener los datos' })
      }
    } finally {
      ctx.commit('setLoading', false)
    }
  }


  @Action({ useContext: true })
  protected async downloadConsumitionCSV(ctx: ActionContext<any, any>): Promise<any> {
    const cupsData: Cups = ctx.getters.cups
    const { data: url } = await CupsApi.getConsumptionDownloadUrl(cupsData.generalData.cups)
    window.open(url, '_blank')
  }


  @Action({ useContext: true })
  protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setCups', null)
    ctx.commit('setError', null)
  }
}
