var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "show-password": "",
                  placeholder: "Password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "repeatPassword" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "show-password": "",
                  placeholder: "Repeat password"
                },
                model: {
                  value: _vm.form.repeatPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "repeatPassword", $$v)
                  },
                  expression: "form.repeatPassword"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("Auth-PasswordChecker", {
                ref: "passwordChecker",
                attrs: { password: _vm.form.password }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "signinBtn",
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary"
                  },
                  on: { click: _vm.onClickButton }
                },
                [_vm._v("Change password")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }