import { Store } from '@/Core/Models/Stores'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'
import { Component, MapAction, Vue } from 'types-vue'
import { UploadedFileFileResult } from '@/Modules/Uploader/models';
import _ from 'lodash';
import { SessionVariables } from '@/Core/enum/SessionVariables';
import { getApiUrlWithCookieVersion } from '@/Core/Utils';

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL

@Component
export default class UploadSection extends Vue {


  @MapAction(Store.Uploader) protected newFile: (file: ElUploadInternalFileDetail) => void
  @MapAction(Store.Uploader) protected finishUploadFile: (payload: { result?: UploadedFileFileResult,  error?: string, file: ElUploadInternalFileDetail }) => void


  
  protected get uploaderUrl(): string { return getApiUrlWithCookieVersion(`${CORE_SERVICE_URL_BASE}/files/upload`) }

  
  protected get headers() {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    return {
      Authorization: `Bearer ${token}`
    }
  }

  /**
   * EVENTS 
   */

  protected onSuccess(response, file: ElUploadInternalFileDetail) {
    const result = response
    this.finishUploadFile({ result, file })
  }

  protected onError(err, file){
    const errorResponse = JSON.parse(err.message)
    const error = errorResponse.message
    this.finishUploadFile({ error, file })
  }

  protected onBeforeUpload(newFile) {
    this.newFile(newFile)
  }


  protected created() {
  }
}