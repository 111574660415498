import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Vue } from 'types-vue'
import { ProcessModel, ProcessStepModel } from '@/Modules/Processes/models'

@Component
export default class TimeLine extends Vue {

  @MapGetter(Store.Details) protected processSteps: ProcessStepModel[]
  @MapGetter(Store.Details) protected process: ProcessModel

  protected checkLast(index: number): boolean  {
    return this.processSteps.length === index +1
  }
}