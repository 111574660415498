import { Component, Vue } from 'types-vue'

@Component
export default class TestMessage extends Vue {

  protected showMessageTimer: number = 5000
  protected hideMessageTimer: number = 3000

  protected showing: boolean = false



  protected createTimer() {
    setInterval(()=>{
      this.showing = true
      setTimeout(() => this.showing = false, this.hideMessageTimer)
    }, this.showMessageTimer)
  }

  created() {
    this.createTimer()
  }
}