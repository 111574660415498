import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, mixins, Vue } from 'types-vue'
import { UnprocessedFile } from '../../models'
import UnprocessedFilesApi from '../../connections/api/UnprocessedMarketerFiles'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'

@Component
export default class UnprocessedFilesList extends mixins(PermissionMixin) {
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean
  /**
   * STATE
   */

  protected showingMenuItem: UnprocessedFile = null
  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  /**
   * STORE
   */

  @MapGetter(Store.UnprocessedMarketerFiles) protected unprocessedList: UnprocessedFile[]
  @MapGetter(Store.UnprocessedMarketerFiles) protected listLoading: boolean

  /**
   * EVENTS
   */

  protected onCellMouseEnter(item: UnprocessedFile): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: UnprocessedFile): void {
    if (this.showingMenuItem.keyname === item.keyname) this.showingMenuItem = null
  }

  protected async onClickDownload(item: UnprocessedFile): Promise<void> {
    const result = await UnprocessedFilesApi.getUnprocessedFileUrl(item)

    const fileDownloadUrl = result.data.url

    window.location.href = fileDownloadUrl
  }

  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return this.listLoading
      ? 'CARGANDO ARCHIVOS NO PROCESADOS POR ERROR...'
      : 'NO HAY ARCHIVOS NO PROCESADOS POR ERROR'
  }
}
