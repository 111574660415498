var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.localLoading,
          expression: "localLoading"
        }
      ],
      staticClass: "group-list"
    },
    _vm._l(_vm.userGroupList, function(item) {
      return _c("Auth-GroupsListItem", {
        key: item.name,
        attrs: { group: item }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }