import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import _ from 'lodash'
import { BillListRequest } from '../../models'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  HistoryBills: `${CORE_SERVICE_URL_BASE}/hist-flow-bill/paged`,
  Download: `${CORE_SERVICE_URL_BASE}/hist-flow-bill/download`
}

class HistoryBillsApiSingleton extends AbstractApi {
  private static _instance: HistoryBillsApiSingleton
  private constructor() {
    super()
  }

  public static get instance(): HistoryBillsApiSingleton {
    if (!HistoryBillsApiSingleton._instance)
      HistoryBillsApiSingleton._instance = new HistoryBillsApiSingleton()
    return HistoryBillsApiSingleton._instance
  }

  public getHistoryBillList(params: BillListRequest): AxiosPromise {
    const method = Method.GET
    const url = Path.HistoryBills

    return this.request({ method, url, params })
  }

  public async downloadHistoryBill(id: number): Promise<string> {
    const method = Method.GET
    const url = Path.Download
    const params = {
      id
    }

    const { data } = await this.request({ method, url, params })

    return data
  }
}

export const HistoryBillsApi = HistoryBillsApiSingleton.instance
