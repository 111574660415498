import { FiltersName } from '@/Modules/UnprocessedMarketerFiles/enums/Filters'
import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapGetter, mixins, Vue } from 'types-vue'
import _ from 'lodash'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Store } from '@/Core/Models/Stores'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class FiltersContainer extends mixins(PermissionMixin) {
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  protected messageTypeWithinIntervalEmpty: boolean = true

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'date',
          name: FiltersName.StartDate,
          label: 'Fecha inicio proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.StartDate]
        },
        {
          type: 'date',
          name: FiltersName.EndDate,
          label: 'Fecha fin proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.EndDate]
        },
        {
          type: 'text',
          name: FiltersName.Keyname,
          label: 'Nombre archivo',
          default: this.$route.query[FiltersName.Keyname]
        },
        {
          type: 'select',
          name: FiltersName.OriginSubject,
          label: 'Comercializadora',
          default: this.$route.query[FiltersName.OriginSubject] as string,
          clearable: true,
          valueQuery: 'code',
          options: `${CORE_URL}/system-subjects`,
          visible: this.showMarketerInfo
        }
      ]
    }
  }

  protected onChange(form: any) {
    const messageTypeWithinInterval = form.messageTypeWithinInterval
    this.messageTypeWithinIntervalEmpty = _.isEmpty(messageTypeWithinInterval)
  }
}
