var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var mobile = ref.mobile
          return [
            _c(
              "div",
              [
                _c("Core-Title", [_c("h2", [_vm._v("Iniciar proceso A1-42")])]),
                _c("p", { staticClass: "description" }, [
                  _vm._v(
                    "Solicitud de Cambio de Comercializador con Modificación en Contraro Acceso"
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    !mobile
                      ? _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.currentStep,
                              "align-center": "",
                              direction: "vertical"
                            }
                          },
                          [
                            _c("el-step", {
                              attrs: {
                                title: "Solicitud",
                                icon: "el-icon-tickets"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Nuevo cliente",
                                icon: "el-icon-user"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Nuevo cliente 2",
                                icon: "el-icon-user"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Dirección del punto de suministro",
                                icon: "el-icon-map-location"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Dirección fiscal",
                                icon: "el-icon-map-location"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Instalación",
                                icon: "el-icon-setting"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "Observaciones",
                                icon: "el-icon-view"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "steps-content" },
                      [
                        _c(
                          "el-card",
                          [
                            _c("ProcessesForms-InitialForm_42", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentStep === 0,
                                  expression: "currentStep === 0"
                                }
                              ],
                              on: { submit: _vm.onSubmitForm }
                            }),
                            _c(
                              "ProcessesForms-NewClientForm1",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 1,
                                    expression: "currentStep === 1"
                                  }
                                ],
                                attrs: { formData: _vm.formData },
                                on: { submit: _vm.onSubmitForm }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ProcessesForms-NewClientForm2",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 2,
                                    expression: "currentStep === 2"
                                  }
                                ],
                                attrs: { formData: _vm.formData },
                                on: { submit: _vm.onSubmitForm }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ProcessesForms-AddressForm1",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 3,
                                    expression: "currentStep === 3"
                                  }
                                ],
                                attrs: { formData: _vm.formData },
                                on: { submit: _vm.onSubmitForm }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ProcessesForms-AddressForm2",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 4,
                                    expression: "currentStep === 4"
                                  }
                                ],
                                attrs: { formData: _vm.formData },
                                on: { submit: _vm.onSubmitForm }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ProcessesForms-InstallationForm_42",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 5,
                                    expression: "currentStep === 5"
                                  }
                                ],
                                on: { submit: _vm.onSubmitForm }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "ProcessesForms-ObservationsForm_42",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentStep === 6,
                                    expression: "currentStep === 6"
                                  }
                                ],
                                on: { submit: _vm.onFinalSubmit }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "return-button",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        _vm.currentStep--
                                      }
                                    }
                                  },
                                  [_vm._v("Atrás")]
                                )
                              ],
                              1
                            ),
                            _vm.currentStep === 7
                              ? _c("ProcessesForms-CreationResult", {
                                  attrs: {
                                    validationErrors: _vm.errors,
                                    validationResult: !_vm.errors.length,
                                    loading: _vm.loading
                                  },
                                  on: {
                                    onReturn: function($event) {
                                      _vm.currentStep--
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }