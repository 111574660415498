import FormValidateMixin from '@/Core/mixins/FormValidate'
import moment from 'moment'
import { Component, mixins, Prop, Vue, Watch } from 'types-vue'
import { Holiday } from '../../models'
import _ from 'lodash'


@Component
export default class Form extends mixins(FormValidateMixin) {

  @Prop({ type: String, default: 'Guardar' }) protected submitButtonText: string
  @Prop({ type: Boolean, default: false }) protected disabledDate: boolean
  @Prop({ type: Boolean, default: false }) protected loading: boolean
  @Prop() protected value: Holiday

  protected disableSubmitButton: boolean = true
  protected form = { date: null, description: '' }
  protected rules = {
    date: [{ type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }],
    description: [{ required: true, trigger: 'blur', message: 'La descripcion es obligatoria' }]
  }

  @Watch('value', { immediate: true })
  protected onChangeDefault() {
    if (_.isNil(this.value)) return
    
    this.form = {
      date: moment(this.value.date).toDate(),
      description: this.value.description
    }
  }


  protected async onSubmit() {
    if (!await this.validateForm()) return
    this.$emit('input', this.form)
    this.$emit('submit', this.form)
  }

  protected onInput() {
    this.disableSubmitButton = false
  }

  protected destroyed() {
    this.form = { date: null, description: '' }
  }
}