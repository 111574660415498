var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c("LOPD-FormResult", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showResult,
            expression: "showResult"
          }
        ],
        on: { return: _vm.onReturn }
      }),
      _c("Forms-Form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showResult,
            expression: "!showResult"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: { options: _vm.settingFormConfig },
        on: { change: _vm.onChangeSettingForm }
      }),
      _c("Forms-Form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showResult,
            expression: "!showResult"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: { options: _vm.formConfig },
        on: { submit: _vm.onSubmitForm, change: _vm.onChangeForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }