var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Crear usuario",
        visible: _vm.showing,
        width: "45rem",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _vm.showing
        ? _c("UsersManager-UserForm", {
            attrs: { loading: _vm.loading, submitButtonText: "Crear usuario" },
            on: { submit: _vm.onSubmitCreate }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }