import { ProcessStates } from '../../enums/ProcessStates'

export default {
  processStates: {
    [ProcessStates.Completed]: 'Completado',
    [ProcessStates.Opened]: 'Abierto',
    [ProcessStates.Rejected]: 'Rechazado',
    [ProcessStates.CANCELED]: 'Cancelado',
    [ProcessStates.DESISTED]: 'Desistido',
    [ProcessStates.SUSPENDED]: 'Suspendido',
  }
}