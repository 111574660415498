import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { SessionVariables } from '@/Core/enum/SessionVariables'
import { AxiosPromise } from 'axios'
import _ from 'lodash'
import {
  createUserRequest,
  GetAllGroupsResponse,
  GetAllUsersRequest,
  GetAllUsersResponse,
  Group,
  signupRequest,
  User
} from '../../models'

const AUTH_SERVICE_URL_BASE = process.env.VUE_APP_AUTH_URL
const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL

const Path = {
  List: `${AUTH_SERVICE_URL_BASE}/listUsers`,
  Signup: `${AUTH_SERVICE_URL_BASE}/signUp`,
  Update: `${AUTH_SERVICE_URL_BASE}/updateUser`,
  Delete: `${AUTH_SERVICE_URL_BASE}/deleteUser`,
  DownloadList: `${CORE_SERVICE_URL_BASE}/users/export/csv`,
  RestorePassword: `${AUTH_SERVICE_URL_BASE}/forgotPassword`
}

class UsersSingleton extends AbstractApi {
  private static _instance: UsersSingleton
  private constructor() {
    super()
  }

  public static get instance(): UsersSingleton {
    if (!UsersSingleton._instance) UsersSingleton._instance = new UsersSingleton()
    return UsersSingleton._instance
  }

  public getAll(data: GetAllUsersRequest = {}): AxiosPromise<GetAllUsersResponse> {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    return this.request({
      method: Method.POST,
      url: Path.List,
      headers: { Authorization: `Bearer ${token}` },
      data: _.omitBy(data, _.isEmpty)
    })
  }

  public signup(data: signupRequest): AxiosPromise {
    const token = sessionStorage.getItem(SessionVariables.Auth)

    return this.request({
      method: Method.POST,
      url: Path.Signup,
      headers: { Authorization: `Bearer ${token}` },
      data
    })
  }

  public updateUser(data: createUserRequest): AxiosPromise {
    return this.request({
      method: Method.POST,
      url: Path.Update,
      data
    })
  }

  public deleteUser(email: string): AxiosPromise {
    return this.request({
      method: Method.POST,
      url: Path.Delete,
      data: { email }
    })
  }

  public downloadUserList(): AxiosPromise {
    return this.request({
      method: Method.GET,
      url: Path.DownloadList
    })
  }

  public restoreUserPassword(email: string): AxiosPromise {
    return this.request({
      method: Method.POST,
      url: Path.RestorePassword,
      data: { email }
    })
  }
}

export const UsersApi = UsersSingleton.instance
