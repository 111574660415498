import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Store } from '@/Core/Models/Stores'
import { Group, Permission, Rol, Roles, UserFormModel, Permissions } from '@/Modules/UsersManager/models'
import _ from 'lodash'
import { Component, MapGetter, mixins, Prop, Watch } from 'types-vue'
@Component
export default class UserForm extends mixins(FormValidateMixin) {
  

  @Prop({ type: String, default: 'Guardar' }) protected submitButtonText: string
  @Prop({ type: Boolean, default: false }) protected loading: boolean
  @Prop() protected value: any

  @MapGetter(Store.UserManagerGroups) protected groupList: Group[]


  protected disableSubmitButton: boolean = true
  protected form: UserFormModel = {
    // marketer: 'MRG',
    email: '',
    familyName: '',
    givenName: '',
    groupName: [],
    userPermissions: '',
    userRol: '',
    enabled: true
  }
  protected rules = {
    email: [{ required: true, message: 'El correo electrónico es obligatorio', trigger: 'blur' }],
    familyName: [{ required: true, trigger: 'blur', message: 'Los apellidos son obligatorios' }],
    givenName: [{ required: true, trigger: 'blur', message: 'El nombre es obligatorio' }],
    groupName: [{ required: true, trigger: 'blur', message: 'El grupo es obligatorio' }],
  }

  protected options: Group[] = []

  protected get isSystemRole(): boolean { return this.form.userRol === Roles.System.code }

  protected get roles(): Rol[] { return Roles.list }
  protected get permissions(): Permission[] { return Permissions.list }
  protected get isEditor(): boolean { return !!this.value }

  @Watch('value', { immediate: true })
  protected onChangeDefault() {
    if (_.isNil(this.value)) return
    this.form = {
      email: this?.value?.email,
      enabled: this?.value?.enabled,
      marketer: this?.value?.marketer,
      givenName: this?.value?.givenName,
      familyName: this?.value?.familyName,
      userRol: this?.value?.clientMetadata?.userRol,
      groupName: this?.value?.clientMetadata?.userGroups,
      userPermissions: this?.value?.clientMetadata?.userPermissions,
    }
  }

  @Watch('form.userRol')
  onChangeRol(rol: string) {
    if (rol === Roles.System.code) {
      this.form.groupName = ['0000']
      this.form.userPermissions = Permissions.Admin.code
    } else {
      if (this.form.groupName[0] === Roles.System.code) this.form.groupName = ['']
    }
  }

  @Watch('form', { deep: true })
  onChangeForm() {
    this.disableSubmitButton = false
  }


  protected async onSubmit() {
    if (!await this.validateForm()) return

    this.$emit('input', this.form)
    this.$emit('submit', this.form)
  }

  protected onInput() {
    this.disableSubmitButton = false
  }

  protected onFocus() {
    this.updateFilter('')
  }

  protected updateFilter(filter: string) {
    filter = filter.toLowerCase()

    this.options = this.groupList.filter(group => {
      if (group.description) {
        return group.name.toLowerCase().includes(filter) || 
               group.description.toLowerCase().includes(filter)
      } return group.name.toLowerCase().includes(filter)
    })
  }

  protected destroyed() {
    this.form = {
      email: '',
      familyName: '',
      givenName: '',
      groupName: [],
      userPermissions: '',
      userRol: '',
      // marketer: 'MRG',
      enabled: true
    }
  }
}