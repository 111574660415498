import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { Province, Town } from "../../models";
import { CoordinatesResponse } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  GetCoordinates: `https://nominatim.openstreetmap.org/search`, //?format=json&q=PASTOS%20DE%20INVIERNO%209%2028600
}

class MapsSingleton extends AbstractApi {
  private static _instance: MapsSingleton
  private constructor() { super() }

  public static get instance(): MapsSingleton {
    if (!MapsSingleton._instance) MapsSingleton._instance = new MapsSingleton();
    return MapsSingleton._instance;
  }

  public getCoordinates(postalcode: string, street: string, number: number): AxiosPromise<CoordinatesResponse[]> {
    const method = Method.GET
    const url = Path.GetCoordinates
    const params =  { format: 'json', postalcode, street: `${number} ${street}` }

    return this.request({ method, url, params })
  }

}

export const MapsApi = MapsSingleton.instance