import { Component, Vue, mixins } from 'types-vue'

import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { ReportsPages } from '../../router'

@Component
export default class Downloadable extends mixins(PermissionMixin) {

  protected downloadableReports = [
    { label: 'Informe CUPS en CUR sin TUR', component: 'Reports-OcsumReport', ref: 'ocsum' },
    { label: 'Informe OCSUM PS', component: 'Reports-OcsumPSReport', ref: 'ocsum-ps' },
    { label: 'Informe B70', component: 'Reports-B70Report', ref: 'b70' },
    { label: 'Informe Tiempo Medio', component: 'Reports-AverageTimeReport', ref: 'average-time' },
    { label: 'Informe Tiempo Medio Altas', component: 'Reports-DischargeAverageTimeReport', ref: 'disharge-average-time' },
  ]

  protected sipsReports = [
    { label: 'Descargar todos los informes', component: 'Reports-GlobalReport', ref: 'global' },
    { label: 'Informe LOPD', component: 'Reports-LopdReport', ref: 'lopd' },
    { label: 'Informe PS', component: 'Reports-PSReport', ref: 'ps' },
    { label: 'Informe CS', component: 'Reports-CsReport', ref: 'cs' },
    { label: 'Informe de consumo', component: 'Reports-ConsumptionReport', ref: 'consumption' },
    { label: 'Subir ficheros a la CNMC', component: 'Reports-UploadToCNMC', ref: 'cnmc' }
  ]

  protected get isDistributorOrSystemRole() {
    return this.isDistributorRol || this.isSystemRol
  }


  protected onClickElement(item) {
    const dialog = this.$refs[item.ref][0] as any
    dialog.show()
  }
}