import { Component, mixins, Prop, Vue } from 'types-vue'
import AnimatedNumber from "animated-number-vue";
import ColorsMixin from '@/Modules/Dashboard/mixins/Colors';

@Component({
  components: { AnimatedNumber }
})
export default class SimpleWitget extends mixins(ColorsMixin) {

  @Prop({ default: '#a7e4fd' }) protected color: string
  @Prop({ required: true, type: Number }) protected value: number
  @Prop({ required: true, type: String }) protected icon: string
  @Prop({ required: true, type: String }) protected label: string


  protected animationDuration: number = 500

  
  protected get darkColor(): string {
    return this.darken(this.color, 20)
  }

  get cardStyle(): object {
    const color = `${this.darkColor}`
    const border = `1px solid ${this.color}`

    return { color }
  }

  get iconStyle(): object {
    return {
      color: this.color
    }
  }



  
  protected onClick() {
    this.$emit('click')
  }

}