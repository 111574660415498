import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { ReportModel } from "../../models";
import { ReportListRequest } from "../../models/api";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  ReportHistories: `${CORE_SERVICE_URL_BASE}/report-histories`,
}

class ReportHistories extends AbstractApi {
  
  public getHistoricalReportsList(params: ReportListRequest = {} ): AxiosPromise {
    const method = Method.GET
    const url = Path.ReportHistories

    return this.request({ method, url, params })
  }
  

  public removeById(id: string | number): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.ReportHistories}/${id}`

    return this.request({ method, url })
  }

}

export default new ReportHistories()