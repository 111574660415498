import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import _ from 'lodash'

@Component
export default class FiltersSections extends Vue {
  @Prop() protected options: FormConfig
  @Prop() protected setCookies: (query: any) => void
  @Prop() protected onClickRestore: () => void

  protected filtersValues: any = {}
  protected loading: boolean = false
  protected cleaning: boolean = false
  protected enabledSubmitButton: boolean = false
  protected restore: boolean = false

  private addFiltersToRoute() {
    const rawQuery = _.assignIn({ ...this.$route.query }, this.filtersValues)
    const query = _.omitBy(rawQuery, _.isEmpty)
    const isNotEqual = !_.isEqual(query, this.$route.query)
    if (isNotEqual) this.$router.push({ ...this.$route, query })

    if (!!this.setCookies && !this.restore) {
      this.setCookies(query)
    }

    this.restore = false
  }

  protected onChange(form) {
    this.$emit('change', form)
    this.enabledSubmitButton = true
    this.filtersValues = _.mapValues(_.cloneDeep(form), (value) => {
      if (_.isArray(value)) return value.join(',')
      return value
    })
  }

  protected onClickSubmit() {
    this.addFiltersToRoute()
  }

  protected onClickClean() {
    this.restore = true
    this.filtersValues = _.mapValues(this.filtersValues, () => '')
    this.addFiltersToRoute()
    this.cleaning = true
    setTimeout(() => (this.cleaning = false), 1)
  }
}
