import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  searchCups: `${CORE_SERVICE_URL_BASE}/cups`,
  cupsContract: `${CORE_SERVICE_URL_BASE}/cups/contract`,
  consumitionDownloadUrl: `${CORE_SERVICE_URL_BASE}/cups/consumption/download`,
}

class CupsApiSingleton extends AbstractApi {
  private static _instance: CupsApiSingleton
  private constructor() { super() }

  public static get instance(): CupsApiSingleton {
    if (!CupsApiSingleton._instance) CupsApiSingleton._instance = new CupsApiSingleton();
    return CupsApiSingleton._instance;
  }

  public getCups(cupsList: string): AxiosPromise<any> {
    const method = Method.GET
    const url = Path.searchCups
    const params =  { cups: cupsList }

    return this.request({ method, url, params })
  }

  public getConsumptionDownloadUrl(cups: string): AxiosPromise<string> {  
    const method = Method.GET
    const url = Path.consumitionDownloadUrl
    const params =  { cups: cups }

    return this.request({ method, url, params })
  }

}

export const CupsApi = CupsApiSingleton.instance