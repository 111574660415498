import { Component, Vue } from 'types-vue';
import { ElForm } from 'element-ui/types/form';


@Component
export default class FormValidateMixin extends Vue {

  protected async validateForm(): Promise<boolean> {
    const form = this.$refs.form as ElForm

    if (!form)  throw 'Form ref not found'

    try {
      return await form.validate()
    } catch (err) { return false }
  }

}