import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import { S3Connector } from '../../models'
import Form from '../Form/Form'

@Component
export default class EditorDialog extends Vue {
  protected showing: boolean = false
  protected loading: boolean = false
  protected s3Connector: S3Connector = null


  @MapAction(Store.S3Connectors) private editS3Connector:(s3Connector: S3Connector) => Promise <void>


  protected async onSubmit(s3Connector: S3Connector) {
    const form = this.$refs.form as Form

    try {
      this.loading = true
      await this.editS3Connector(s3Connector)
      this.showing = false
      form.clean()
      this.$notify({ type: 'success', title: 'Se ha modificado correctamente', message: `Se ha modificado el conector correctamente` })
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: `Ha ocurrido un error al intentar modificar el conector`})
    } finally {
      this.loading = false
    }
  }

  public show(s3Connector: S3Connector) {
    this.showing = true
    this.s3Connector = s3Connector
  }

}