import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue, Prop, Watch } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class NewClientForm2 extends Vue {
  @Prop()
  formData: any

  // Personalized boolean variables.
  protected isNewTitularTypeDisabled: boolean = false
  protected isNewTelephone1Required: boolean = false
  protected isTypeFiscalAddressRequired: boolean = false

  protected get formOptions(): FormConfig {
    return {
      label: 'Nuevo cliente 2',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'newtitulartype',
          label: 'Tipo de persona',
          options: `${CORE_URL}/client-person-types`,
          placeholder: 'Selecciona tipo de persona',
          valueQuery: 'code',
          disabled: this.isNewTitularTypeDisabled
        },
        {
          type: 'text',
          name: 'newtelephone1',
          label: 'Teléfono 1',
          placeholder: 'Introduce el teléfono 1',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'newtelephone2',
          label: 'Teléfono 2',
          placeholder: 'Introduce el teléfono 2',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'newtelephone3',
          label: 'Teléfono 3',
          placeholder: 'Introduce el teléfono 3',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'newemail',
          label: 'Correo electrónico',
          placeholder: 'Introduce el correo electrónico',
          maxCharacters: 60
        },
        {
          type: 'select',
          name: 'newlanguage',
          label: 'Idioma',
          options: `${CORE_URL}/general-languages`,
          placeholder: 'Selecciona el idioma',
          valueQuery: 'code'
        },
        {
          type: 'switch',
          name: 'newregularaddress',
          label: 'Vivienda habitaul',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'typefiscaladdress',
          label: 'Tipo de dirección fiscal',
          options: `${CORE_URL}/fiscal-addresses`,
          placeholder: 'Selecciona el tipo de dirección fiscal',
          valueQuery: 'code'
        }
      ],
      rules: {
        newtelephone1: [
          { required: this.isNewTelephone1Required, trigger: 'blur', message: 'Es obligatorio' }
        ],
        typefiscaladdress: [
          { required: this.isTypeFiscalAddressRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected onSubmit(model) {
    localStorage.setItem('p42_typefiscaladdress_json', model.typefiscaladdress)
    const newemail = model.newemail

    if (newemail !== '') {
      const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

      if (regexp.test(newemail)) {
        this.$emit('submit', model)
      } else {
        this.$notify.error({
          title: `Error en el correo electrónico`,
          message: 'Por favor pon un email correcto'
        })
      }
    } else {
      this.$emit('submit', model)
    }
  }

  @Watch('formData')
  protected onChangeFormData() {
    const newdocumenttype = localStorage.getItem('p42_newdocumenttype_json')

    if (newdocumenttype === '07' || newdocumenttype === '08') {
      this.isNewTitularTypeDisabled = false
    } else {
      this.isNewTitularTypeDisabled = true
    }

    if (this.formData?.updatereason === '01') {
      this.isNewTelephone1Required = true
      this.isTypeFiscalAddressRequired = true
    } else {
      this.isNewTelephone1Required = false
      this.isTypeFiscalAddressRequired = false
    }
  }
}
