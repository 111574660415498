import { render, staticRenderFns } from "./LopdReport.vue?vue&type=template&id=571272f7&scoped=true&"
import script from "./LopdReport.ts?vue&type=script&lang=ts&"
export * from "./LopdReport.ts?vue&type=script&lang=ts&"
import style0 from "./LopdReport.vue?vue&type=style&index=0&id=571272f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571272f7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('571272f7')) {
      api.createRecord('571272f7', component.options)
    } else {
      api.reload('571272f7', component.options)
    }
    module.hot.accept("./LopdReport.vue?vue&type=template&id=571272f7&scoped=true&", function () {
      api.rerender('571272f7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Reports/components/reports/LopdReport/LopdReport.vue"
export default component.exports