import { Component, Prop, Vue } from 'types-vue'

@Component
export default class DetailsSection extends Vue {
  @Prop({ required: true, type: String }) protected title: string

  protected showing: boolean = true


  protected onClickHeader() {
    this.showing = !this.showing
  }


  protected get icon(): string {
    return (this.showing) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
  }

}