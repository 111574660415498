import FiltersMixin from '@/Core/mixins/FiltersMixin'
import Master from '@/Modules/Masters/connections/api/Master'
import { FiltersName } from '@/Modules/Addresses/enums/Filters'
import _ from 'lodash'
import { Component, mixins, Prop, Watch } from 'types-vue'

const MASTER_SLUG = 'location-town'

@Component
export default class MunicipalityFilter extends mixins(FiltersMixin) {
  
  /**
   * STATE
   */

  protected loading: boolean = true
  protected list: any[] = []
  protected selected: number = null
  protected options: any[] = []
  protected disabled: boolean = true



  /**
   * METHODS
   */

  protected addQueryParam() {
    const codes = this.selected.toString()
    const name = this.$route.name
    const query = (codes.length) 
      ? {...this.$route.query, [FiltersName.Municipio]: codes }
      : _.omit(this.$route.query, [FiltersName.Municipio]);
    
    if (!_.isEqual(query, this.$route.query))
      this.$router.push({ name, query })
  }

  protected setSelectedElementsByQueryParams() {
    const codes = +(this.$route.query[FiltersName.Municipio] as string)

    this.selected = codes
  }

  private async obtainList(provinceId: string) {
    try {
      this.loading = true
      const result = await Master.getAllList(MASTER_SLUG, { provinceId })
      this.list = result.data
    } catch (error) {
      
    } finally {
      this.loading = false
    }
  }

  protected updateFilter(filter: string) {
    filter = filter.toLowerCase()
    this.options = this.list.filter(item => {
      return item.code.toLowerCase().includes(filter) 
          || item.description.toLowerCase().includes(filter)
    })
  }


  protected onFocus() {
    this.updateFilter('')
  }


  /**
   * WHATCHERS
   */
  @Watch(`$route.query`, { immediate: true, deep: true })
  protected async onchangeQueryParam() {
    const haveQueryParam = !!this.$route.query[FiltersName.Municipio]
    const haveQueryParamProvincia = !!this.$route.query[FiltersName.Provincia]

    if (haveQueryParam) this.setSelectedElementsByQueryParams()
    if (haveQueryParamProvincia) {
      await this.obtainList(this.$route.query[FiltersName.Provincia] as string)
    } else {
      this.selected = null
    }

    this.disabled = !haveQueryParamProvincia
  }


  @Watch('selected')
  protected onChangeSelected() {
    this.addQueryParam()
  }

  @Watch('list')
  protected onChangeList() {
    this.updateFilter('')
  }
}