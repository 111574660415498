var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "validation-error-list" }, [
    _c("h3", [_vm._v("Errores:")]),
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_vm.validationErrors, function(item, index) {
        return _c("div", { key: index, staticClass: "element" }, [
          _c("span", [_vm._v(_vm._s(item))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }