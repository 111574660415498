var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "primary",
            round: "",
            size: "small",
            loading: _vm.uploadDocumentsLoading
          },
          on: { click: _vm.onClickUploadButton }
        },
        [_vm._v("Subir documento")]
      ),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", name: "name", multiple: "" },
        on: { change: _vm.onUploadFiles }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }