var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-result" }, [
    _c(
      "div",
      { staticClass: "search-section" },
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "is-table"
          },
          [
            _c("Cups-CupsList", {
              attrs: { cups: _vm.cupsInfo, loading: _vm.loading }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "search-section" },
      [
        _c("h3", [
          _vm._v("Procesos "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalProcess,
                  expression: "totalProcess"
                }
              ]
            },
            [_vm._v("(" + _vm._s(_vm.totalProcess) + ")")]
          )
        ]),
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "is-table"
          },
          [
            _c("Processes-ProcessesList", {
              attrs: { processes: _vm.process, loading: _vm.loading }
            })
          ],
          1
        ),
        _vm.totalProcess > _vm.limitShowButton
          ? _c(
              "div",
              { staticClass: "show-more-btn-container" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "show-more-btn",
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.goToProcess }
                  },
                  [_vm._v("Ver todos los procesos")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "search-section" },
      [
        _c("h3", [
          _vm._v("Facturas "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalBills,
                  expression: "totalBills"
                }
              ]
            },
            [_vm._v("(" + _vm._s(_vm.totalBills) + ")")]
          )
        ]),
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "is-table"
          },
          [
            _c("Bills-BillsList", {
              attrs: { bills: _vm.bills, loading: _vm.loading }
            })
          ],
          1
        ),
        _vm.totalBills > _vm.limitShowButton
          ? _c(
              "div",
              { staticClass: "show-more-btn-container" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "show-more-btn",
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.goToBills }
                  },
                  [_vm._v("Ver todas las facturas")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "search-section" },
      [
        _c("h3", [
          _vm._v("Contratos ATR "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.totalAtrContracts,
                  expression: "totalAtrContracts"
                }
              ]
            },
            [_vm._v("(" + _vm._s(_vm.totalAtrContracts) + ")")]
          )
        ]),
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "is-table"
          },
          [
            _c("AtrContracts-ContractsTable", {
              attrs: { atrContracts: _vm.atrContracts, loading: _vm.loading }
            })
          ],
          1
        ),
        _vm.totalAtrContracts > _vm.limitShowButton
          ? _c(
              "div",
              { staticClass: "show-more-btn-container" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "show-more-btn",
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.goToAtrContracts }
                  },
                  [_vm._v("Ver todos los contratos ATR")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }