var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section" },
    _vm._l(_vm.elements, function(item, i) {
      return _c("div", { key: i, staticClass: "element" }, [
        _c("div", { staticClass: "content" }, [
          _c("span", [_vm._v(_vm._s(item.name))]),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.value))])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }