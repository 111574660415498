import ProcessMetrics from '@/Modules/Dashboard/connections/api/ProcessMetrics'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'
import { ProcessesPages } from '@/Modules/Processes/router'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'types-vue'

@Component
export default class ProcessOpened extends Vue {
  protected popoverVisible: boolean = false
  protected visibleDays: number = 7
  protected daysModelInput: string = '7'
  protected counter: number = null
  protected loading: boolean = true

  protected async obtainProcessCount() {
    const daysModelInputNumber = parseInt(this.daysModelInput)
    const startDate = '2019-01-01'
    const endDate = moment().subtract(daysModelInputNumber, 'days').format('YYYY-MM-DD')

    try {
      this.loading = true
      const response = await ProcessMetrics.getMetrics(startDate, endDate)
      this.counter = response.data.openedProcessesCount
      this.visibleDays = daysModelInputNumber
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los datos.'
      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }

  }

  protected get isValidatedInput(): boolean {
    var reg = new RegExp('^[0-9]+$');
    return reg.test(this.daysModelInput)
  }

  protected onInput(value) {
    const stringValue = value.toString()
    const reg = new RegExp('^[0-9]+$');
    const number = _.filter(stringValue, (n: string) => reg.test(n)).join('')

    this.daysModelInput = number
  }

  protected onClickConfirm() {
    this.obtainProcessCount()
    this.popoverVisible = false
  }

  protected onClickLabel() {
    // ?orderDirection=DESC&orderProp=createdAt&fromDate=2021-09-22&toDate=2021-09-25&status=REJECTED
    const fromDate = '2019-01-01'
    const toDate = moment().subtract(this.visibleDays, 'd').format('YYYY-MM-DD')

    this.$router.push({
      name: ProcessesPages.InProcess,
      query: {
        [QueryParams.Status]: 'OPENED',
        [QueryParams.FromDate]: fromDate,
        [QueryParams.ToDate]: toDate
      }
    })
  }

  protected created() {
    this.obtainProcessCount()
  }
}