import { MapsApi } from '@/Modules/Cups/connections/api/Maps'
import { CupsMasterApi } from '@/Modules/Cups/connections/api/Masters'
import { Cups } from '@/Modules/Cups/models'
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'types-vue'
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";



@Component({
  components: {
    'l-map': LMap, 
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-icon': LIcon
  }
})
export default class LocationTab extends Vue {
  
  @Prop({ required: true }) cups: Cups

  protected province: string = '-'
  protected town: string = '-'
  protected streetType: string = ''
  protected coordinates: { lat: string, lon: string } = null

  protected get mapCoordinates(): string[] { return [this.coordinates?.lat, this.coordinates?.lon] }
  
  protected get elements(): any[] {
    const numFinca = this.cleanStringNumber(this.cups?.supplyAddress?.numero)
    const portal = this.cleanStringNumber(this.cups?.supplyAddress?.portal)
    const escalera = this.cleanStringNumber(this.cups?.supplyAddress?.escalera)
    const piso = this.cleanStringNumber(this.cups?.supplyAddress?.piso)
    const puerta = this.cleanStringNumber(this.cups?.supplyAddress?.puerta)
    let fullStreet = `${this.streetType.toLowerCase()} ${this.cups?.supplyAddress?.calle.toLowerCase() } ${numFinca}`

    if (!_.isEmpty(portal))   fullStreet = `${fullStreet}, portal ${portal}`
    if (!_.isEmpty(escalera)) fullStreet = `${fullStreet}, escalera ${escalera}`
    if (!_.isEmpty(piso))     fullStreet = `${fullStreet}, piso ${piso}`
    if (!_.isEmpty(puerta))   fullStreet = `${fullStreet}, puerta ${puerta}`

    return [
      { name: 'Provincia', value: this.province },
      { name: 'Municipio', value: this.town },
      { name: 'Código postal', value: this.cups?.supplyAddress?.cp || '-' },
      { name: 'Dirección', value: fullStreet },
      { name: '', value: '' },
      { name: '', value: '' },
    ]
  }

  private cleanStringNumber(number: string): string {
    const num = parseInt(number)
    
    if (_.isNaN(num)) return number
    return num.toString()
  }

  private async getProvince(code: string) {
    try {
      const response = await CupsMasterApi.getProvince(code)
      this.province = (response.data.length > 0) ? response.data[0].description : '-'
    
    } catch (error) {
      this.province = '-'
    }
  }

  private async getTown(code: string) {
    try {
      const response = await CupsMasterApi.getTown(code)
      this.town = (response.data.length) ? response.data[0].municipalityIneDescription : '-'
    
    } catch (error) {
      this.town = '-'
    }
  }

  private async getCoordinates() {
    try {
      const via = `${this.streetType} ${this.cups?.supplyAddress?.calle}`
      const response = await MapsApi.getCoordinates(this.cups?.supplyAddress?.cp, via, parseInt(this.cups?.supplyAddress?.numero))  
      this.coordinates = (response.data.length) ? { lat: response.data[0].lat, lon: response.data[0].lon } : null
    } catch (error) {
      this.coordinates = null
    }
  }

  private async getStreetType(code: string) {
    try {
      const response = await CupsMasterApi.getTypeStreet(code)
      this.streetType = (response.data.length) ? response.data[0].description : ''
    } catch (error) {
      this.streetType = ''
    }
  }

  @Watch('cups', { deep: true, immediate: true }) protected async onChangeCups() {
    const haveInfoForMap = !_.isEmpty(this.cups?.supplyAddress?.calle) && !_.isEmpty(this.cups?.supplyAddress?.numero) && !_.isEmpty(this.cups?.supplyAddress?.cp)
    const haveProvince = !_.isEmpty(this.cups?.supplyAddress?.provincia)
    const haveTown = !_.isEmpty(this.cups?.supplyAddress?.provincia)
    const haveStreetType = !_.isEmpty(this.cups?.supplyAddress?.tipoVia)
    
    if (haveProvince) this.getProvince(this.cups?.supplyAddress?.provincia)
    if (haveTown) this.getTown(this.cups?.supplyAddress?.municipio)
    if (haveStreetType) await this.getStreetType(this.cups?.supplyAddress?.tipoVia)
    if (haveInfoForMap) this.getCoordinates()
  }
}