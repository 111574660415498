var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.groupList,
                      "row-class-name": "cursor-pointer"
                    },
                    on: {
                      "row-click": _vm.onRowClick,
                      "cell-mouse-leave": _vm.onCellMouseLeave,
                      "cell-mouse-enter": _vm.onCellMouseEnter
                    }
                  },
                  [
                    desktop
                      ? _c("el-table-column", {
                          attrs: { prop: "name", label: "NOMBRE" }
                        })
                      : _c("el-table-column", {
                          attrs: { prop: "name", label: "NOMBRE", width: "100" }
                        }),
                    _c("el-table-column", {
                      attrs: { prop: "description", label: "DESCRIPCION" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }