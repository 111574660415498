var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      { staticClass: "up" },
      [
        _c("Addresses-CodViaFilter"),
        _c("Core-SimpleFilter", {
          attrs: {
            placeholder: "Nombre de la vía",
            name: _vm.nombreViaFilterName
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "down" },
      [_c("Addresses-ProvinceFilter"), _c("Addresses-MunicipalityFilter")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }