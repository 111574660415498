import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Yearly: `${CORE_SERVICE_URL_BASE}/dashboard/billing/yearly`,
  Monthly: `${CORE_SERVICE_URL_BASE}/dashboard/billing/monthly`,
}

class MarketerBilling extends AbstractApi {

  getYearlyBilling(): AxiosPromise {
    const url = Path.Yearly
    const method = Method.GET
    
    return this.request({ method, url })
  }

  getMonthlyBilling(): AxiosPromise {
    const url = Path.Monthly
    const method = Method.GET
    
    return this.request({ method, url })
  }

}

export default new MarketerBilling()
