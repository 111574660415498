import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapGetter, mixins, Vue } from 'types-vue'
import { ReportModel } from '../../../models'


@Component
export default class ReportsList extends mixins(PermissionMixin) {


  // /**
  //  * STATE
  //  */
   protected defaultOrder = { prop: null, order: null }
   protected showingMenuItem: ReportModel = null


  // /**
  //  * STORE
  //  */

  @MapGetter(Store.ReportsHistorical)
   protected historicalReportsList: any[]

  @MapGetter(Store.ReportsHistorical)
   protected listLoading: boolean


  // /**
  //  * EVENTS
  //  */

  protected onCellMouseEnter(item: ReportModel): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: ReportModel): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected onClickDetails(item: ReportModel): void {
    this.$router.push(item.url);
  }

  // /**
  //  * GETTERS
  //  */
  protected get emptyText(): string {
    return (this.listLoading)
      ? 'CARGANDO INFORMES HISTÓRICOS...'
      : 'NO HAY INFORMES HISTÓRICOS'
  }

  // /**
  //  * METHODS
  //  */

  protected checkShowOptions(process: ReportModel): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.id === process.id
  }

}