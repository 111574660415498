var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.isLoading } }, [
        _c("h2", [_vm._v("Histórico ATCOM")])
      ]),
      _c(
        "Core-FiltersDrawer",
        {
          attrs: {
            totalActiveFilter: _vm.totalActiveFilter,
            buttonLabel: "Filtros de procesos"
          }
        },
        [_c("History-ProcessFilters", { attrs: { page: "atcom" } })],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "is-table"
        },
        [
          _c("History-AtcomTable", { attrs: { atcomList: _vm.historyAtcom } }),
          _c("Core-Pagination", {
            attrs: { pageSize: _vm.pageSize, totalElements: _vm.totalElements }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("History-PageSizeSelect", {
              on: { change: _vm.onChangePageSize }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }