var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title-content" }, [
        _c("h2", [_vm._v("Administrador de usuarios y grupos")]),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "el-icon-loading"
        })
      ]),
      _c("p", [_vm._v("Gestiona todos los usuarios de la plataforma.")]),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-tabs",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.onTabClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Usuarios", name: "users" } },
                [_c("UsersManager-UsersTab")],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Grupos", name: "groups" } },
                [_c("UsersManager-GroupsTab")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.activeName === "users"
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "el-dropdown-link",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-download",
                    plain: "",
                    loading: _vm.loading
                  },
                  on: { click: _vm.handleDownloadUserList }
                },
                [_vm._v("Exportar")]
              ),
              _c("UsersManager-PageSizeSelect", {
                on: { change: _vm.onChangePageSize }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }