import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

@Component
export default class DropUploadFile extends Vue {
  
  protected visible: boolean = false
  
  @MapAction(Store.Details) protected uploadFiles: (eventFiles) => Promise<void>
  @MapGetter(Store.Details) protected uploadDocumentsLoading: boolean

  protected onDragLeave(e) {
    this.visible = false
  }

  protected onDrop(event: DragEvent) {
    this.visible = false
    this.uploadFiles(event.dataTransfer.files)
  }

  public show() {
    this.visible = true
  }
}