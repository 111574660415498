var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Resultados subida de ficheros")]),
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.files, function(file) {
          return _c(
            "el-collapse-item",
            {
              key: file.file.name,
              attrs: { title: file.file.name, name: file.file.name }
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "title", class: file.state }, [
                  _c("i", { class: _vm.getTitleIcon(file.state) }),
                  _c("span", [_vm._v(_vm._s(file.file.name))])
                ])
              ]),
              _c(
                "div",
                [_c("Uploader-FileResult", { attrs: { fileData: file } })],
                1
              )
            ],
            2
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onClickOtherFile } },
            [_vm._v("Subir otro fichero")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }