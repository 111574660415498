import { Component, Vue } from 'types-vue'
import { FormConfig } from '@/Modules/Forms/models'
import { SessionVariables } from '@/Core/enum/SessionVariables'
import { getApiUrlWithCookieVersion } from '@/Core/Utils'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL

@Component
export default class UploadToCNMC extends Vue {

  // STATE

  protected showing: boolean = false
  protected step: number = 1
  protected reportType: string = ''
  protected reportName: string = ''



  // GETTERS

  protected get uploaderUrl(): string { return getApiUrlWithCookieVersion(`${CORE_SERVICE_URL_BASE}/cnmc/send-file`) }
  protected get headers() {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    return {
      Authorization: `Bearer ${token}`
    }
  }

  protected get formOptions(): FormConfig {
    return {
      label: 'form',
      submitButton: 'Siguiente',
      items: [
        { type: 'text', name: 'reportType', label: 'Tipo de reporte' },
        { type: 'text', name: 'reportName', label: 'Nombre de reporte' },
      ],
      rules: {
        reportType: [{ required: true, trigger: 'blur', message: 'Tipo de reporte es obligatorio' }],
        reportName: [{ required: true, trigger: 'blur', message: 'Nombre de reporte es obligatorio' }],
      }
    }
  }

  // METHODS

  public show() {
    this.showing = true
  }


  // EVENTS

  protected onSuccess() {
    this.showing = false
    this.$notify({ type: 'success', title: 'Archivo cargado', message: 'El fichero se ha cargado correctamente.' })
  }

  protected onError(err) {

    this.showing = false
    const { message } = JSON.parse(err.message)
    this.$notify({ type: 'error', title: 'Error', message })
  }

  protected onSubmitForm({ reportName, reportType }) {
    this.reportName = reportName
    this.reportType = reportType
    this.step = 2
  }

}