var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: { visible: _vm.visible, direction: "rtl", size: "30rem" },
      on: { close: _vm.onClose }
    },
    [
      _vm.showResults
        ? _c(
            "div",
            { staticClass: "drawer-container" },
            [
              _c("Uploader-ResultSection", {
                on: { clickOtherFile: _vm.onClickOtherFile }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "drawer-container" },
            [
              _c("h2", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "uploader uploader.title",
                    expression: "'uploader uploader.title'"
                  }
                ]
              }),
              _c("Uploader-UploadSection")
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }