import _ from 'lodash'
import { Component, Vue, Watch } from 'types-vue'


@Component
export default class UserFilters extends Vue {

  protected emailModel: string = ''
  protected timeoutNumber: number = null

  // METHODS
  protected sendEmailToQueryParam() {
    this.$router.push({
      query: {
        email: this.emailModel
      }
    })
  }

  // EVENTS
  protected onChangeInput() {
    if (this.timeoutNumber) clearTimeout(this.timeoutNumber)
    this.timeoutNumber = setTimeout(this.sendEmailToQueryParam, 600)
  }

  @Watch('$route.query.email', { immediate: true })
  protected onChangeQuery() {
    this.emailModel = this.$route.query.email as string
  }

}