import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import InProgress from './pages/InProgress/InProgress.vue'
import Details from './pages/Details/Details.vue'

export enum ProcessesPages {
  InProcess = 'processesInProcess',
  Historical = 'historical',
  Details = 'details'
}

const routes: Array<RouteConfig> = [
  {
    path: '/processes',
    component: Page,
    children: [
      {
        path: '',
        name: ProcessesPages.InProcess,
        meta: { menuActive: 'inProgress' },
        component: InProgress
      },
      {
        path: 'historical',
        name: ProcessesPages.Historical,
        meta: { menuActive: 'historical' }
      },
      {
        path: 'details/:id',
        name: ProcessesPages.Details,
        component: Details
      }
    ]
  }
]

export default routes