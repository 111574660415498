import { Store } from '@/Core/Models/Stores'
import { ProcessModel } from '@/Modules/Processes/models'
import { Component, MapGetter, Prop, Vue } from 'types-vue'

@Component
export default class HasBeenDownloadedColumn extends Vue {
  @Prop({ required: true })
  protected data: ProcessModel


  @MapGetter(Store.ProcessesInprocess) protected messageTypeWithinInterval: string
  
  protected get hasBeenDownloaded() {
    return this.data.messagesDwonloadedList.includes(this.messageTypeWithinInterval)
  }
}