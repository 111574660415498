import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'

import { ActionContext } from 'vuex'
import { FlowCupsWithContractApi } from '../connections/api/FlowCupsWithContract'
import { Notification } from 'element-ui'
import _ from 'lodash'
import router from '@/Core/router'

@Module({ namespaced: true })
export default class FormCreateCupsWithContract extends VuexModule {
  protected _loading: boolean = false
  protected _result: any = null
  protected _errors: any[] = []
  protected _url: string = null
  protected _totalPages: string = null

  @Getter() protected loading(): boolean {
    return this._loading
  }
  @Getter() protected errors(): any[] {
    return this._errors
  }
  @Getter() protected result(): any {
    return this._result
  }
  @Getter() protected url(): any {
    return this._url
  }
  @Getter() protected totalPages(): any {
    return this._totalPages
  }

  @Mutation() protected setLoading(value: boolean): void {
    this._loading = value
  }

  @Mutation() protected setResponse(response: any): void {
    this._errors = response.validationResponse.body.error
    this._result = response.result
    this._url = response.url
    this._totalPages = response.totalPages
  }

  @Action({ useContext: true })
  protected async create(ctx: ActionContext<any, any>, payload: { form: any; fileType: string }) {
    try {
      ctx.commit('setLoading', true)
      const result = await FlowCupsWithContractApi.create(payload.form, payload.fileType)
      ctx.commit('setResponse', result.data)
    } catch (error) {
      Notification.error({ title: 'Error', message: 'No se pudieron consultar los contratos' })
      throw error
    } finally {
      ctx.commit('setLoading', false)
    }
  }
}
