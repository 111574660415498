var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-NetworkNotification", { ref: "notify" }),
      _c(
        "el-dropdown",
        { on: { command: _vm.onCommandDropdown } },
        [
          _c("el-button", { attrs: { plain: "", loading: _vm.loading } }, [
            _vm._v(
              " Ficheros seleccionados (" +
                _vm._s(_vm.selectedProcesses.length) +
                ") "
            ),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "download" } }, [
                _vm._v("Descargar ficheros seleccionados")
              ]),
              _c("el-dropdown-item", { attrs: { command: "downloadAll" } }, [
                _vm._v("Descargar todos los ficheros")
              ]),
              _c("el-dropdown-item", { attrs: { command: "resendB1" } }, [
                _vm._v("Reenviar B1")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Descargar ficheros",
            visible: _vm.showFlagDownloadFilesDialog,
            width: "30rem"
          },
          on: {
            "update:visible": function($event) {
              _vm.showFlagDownloadFilesDialog = $event
            },
            close: _vm.onCloseDownloadFilesDialog
          }
        },
        [
          _c(
            "div",
            { staticClass: "download-files-content" },
            [
              _c("Forms-Form", {
                attrs: { options: _vm.downloadFormOptions },
                on: { submit: _vm.onSubmitDownloadForm }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }