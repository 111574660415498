import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue, Prop, Watch } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL
@Component
export default class NewClientForm1 extends Vue {
  @Prop()
  formData: any

  // Personalized boolean variables.
  protected isFirstNameRequired: boolean = false
  protected isNewNationalityRequired: boolean = false
  protected isNewDocumentTypeRequired: boolean = false
  protected isNewDocumentNumRequired: boolean = false
  protected isNewFamilyName1Required: boolean = false

  protected get formOptions(): FormConfig {
    return {
      label: 'Nuevo cliente',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'newnationality',
          label: 'Nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          placeholder: 'Selecciona la nueva nacionalidad',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'newdocumenttype',
          label: 'Tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          placeholder: 'Selecciona el nuevo tipo de documentación',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'newdocumentnum',
          label: 'Número de documento',
          placeholder: 'Introduce el nuevo número de documento',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'newfirstname',
          label: 'Nombre de cliente',
          placeholder: 'Introduce el nuevo nombre del cliente',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'newfamilyname1',
          label: 'Apellido 1 / Razón social',
          placeholder: 'Introduce el nuevo apellido 1 o razón social',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'newfamilyname2',
          label: 'Apellido 2',
          placeholder: 'Introduce el nuevo apellido 2',
          maxCharacters: 30
        }
      ],
      rules: {
        newfirstname: [
          { required: this.isFirstNameRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newnationality: [
          { required: this.isNewNationalityRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newdocumenttype: [
          { required: this.isNewDocumentTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newdocumentnum: [
          { required: this.isNewDocumentNumRequired, trigger: 'change', message: 'Es obligatorio' }
        ],
        newfamilyname1: [
          { required: this.isNewFamilyName1Required, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected onSubmit(model) {
    // Form constants.
    const newnationality = model.newnationality
    const newdocumenttype = model.newdocumenttype
    localStorage.setItem('p42_newdocumenttype_json', newdocumenttype)

    // DOCUMENT TYPE LOGIC.
    if (newnationality !== '' && newdocumenttype !== '') {
      if (newnationality === 'ES') {
        if (newdocumenttype !== '01' && newdocumenttype !== '03') {
          this.$notify.error({
            title: 'Error en el tipo de documentación',
            message: 'Solamente puedes poner el 01(NIF) o el 03(PASAPORTE)'
          })
        } else {
          this.$emit('submit', model)
        }
      } else {
        if (newdocumenttype === '01') {
          this.$notify.error({
            title: 'Error en el tipo de documentación',
            message: 'No puedes seleccionar el 01(NIF)'
          })
        } else {
          this.$emit('submit', model)
        }
      }
    } else if (this.formData?.updatereason !== '01') {
      this.$emit('submit', model)
    }
  }

  @Watch('formData')
  protected onChangeFormData() {
    const titulartype = localStorage.getItem('p42_titulartype_json')
    if (this.formData?.updatereason === '01') {
      this.isNewNationalityRequired = true
      this.isNewDocumentTypeRequired = true
      this.isNewDocumentNumRequired = true
      this.isNewFamilyName1Required = true
    } else {
      this.isNewNationalityRequired = false
      this.isNewDocumentTypeRequired = false
      this.isNewDocumentNumRequired = false
      this.isNewFamilyName1Required = false
    }

    if (titulartype === 'F') {
      this.isFirstNameRequired = true
    } else {
      this.isFirstNameRequired = false
    }
  }
}
