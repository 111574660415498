import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Prop, Vue } from 'types-vue'
import { MasterConfig } from '../../models/Masters'

@Component
export default class EditDialog extends Vue {
  
  /**
   * PROPS
   */

  @Prop({ type: Boolean }) protected visible: boolean
  

  /**
   * STATE
   */

  protected formLoading: boolean = false


  /**
   * STORE
   */

  @MapGetter(Store.Master) protected editorElement: any
  @MapGetter(Store.Master) protected masterConfig: MasterConfig
  @MapGetter(Store.Master) protected masterName: any

  @MapAction(Store.Master) protected updateMasterItem: (item) => Promise<any>
  @MapAction(Store.Master) protected removeMasterItem: (item) => Promise<any>


  /**
   * GETTERS
   */

  protected get title(): string { return `Editar "${this.masterName}"` }
  protected get isSystemSubject(): boolean { return this.masterConfig.code === 'system-subjects' }
  

  
  /**
   * METHODS
   */

  protected hideDialog() {
    this.$emit('update:visible', false)
  }

  protected showConfirmRemove(): Promise<any> {
    return this.$confirm(
      'Seguro que quieres eliminar este elemento?',
      'Eliminar',
      { cancelButtonText: 'No borrar', confirmButtonText: 'Borrar'}
    )
  }

  protected showSuccessRemoveNotify() {
    this.$notify({ type: 'success', title: 'Eliminado', message: `Elemento eliminado correctamente`})
  }

  protected showErrorRemoveNotify() {
    this.$notify({ type: 'error', title: 'Error', message: `Error al eliminar el elemento`})
  }

  protected showSuccessModifyNotify() {
    this.$notify({ type: 'success', title: 'Modificado', message: `Elemento modificado correctamente`})
  }


  protected onBeforeClose() {
    this.hideDialog()
  }


  protected onCancel() {
    this.hideDialog()
  }

  protected async onRemove(item) {

    
    try {
    
      await this.showConfirmRemove()
      this.formLoading = true
      await this.removeMasterItem({...this.editorElement, ...item })
      this.hideDialog()
      this.showSuccessRemoveNotify()
    
    } catch (error) {

      if (error === 'cancel') return
      this.showErrorRemoveNotify()

    } finally {
      this.formLoading = false
    }
  }


  protected async onSubmit(form) {
    try {
      this.formLoading = true
      await this.updateMasterItem({...this.editorElement, ...form})
      this.hideDialog()
      this.showSuccessModifyNotify()
    } catch (error) {
      this.$notify({ type: 'error', title: 'Error', message: `Error al modificar el elemento`})
    } finally {
      this.formLoading = false
    }
  }

}