var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Informe PS",
        visible: _vm.showing,
        width: "45rem",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        },
        close: _vm.onCloseDialog
      }
    },
    [
      _vm.completed
        ? _c("Reports-SuccessMessageWithHistoricalLink", {
            attrs: { reportName: "PS" }
          })
        : _c("div", [
            _c(
              "p",
              { staticClass: "description" },
              [
                _vm._v("Una vez generado el informe podrás descargarlo en "),
                _c(
                  "router-link",
                  {
                    staticClass: "el-link",
                    attrs: { to: _vm.historicalLocation }
                  },
                  [_vm._v("la página de históricos")]
                ),
                _vm._v(".")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "", loading: _vm.loading },
                    on: { click: _vm.onClickGenerate }
                  },
                  [_vm._v("Generar informe PS")]
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }