import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Component, mixins, Prop, Vue } from 'types-vue'
import ChangePasswordFormModel from '../../models/ChangePasswordForm'
import PasswordChecker from '../PasswordChecker/PasswordChecker'

@Component
export default class ChangePasswordForm extends mixins(FormValidateMixin) {

  @Prop({ type: Boolean, default: false })
  protected loading: boolean

  protected form: ChangePasswordFormModel = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  }

  protected rules = {
    oldPassword: [
      { required: true, message: 'Please input your password', trigger: 'blur' },
    ],
    newPassword: [
      { required: true, message: 'Please input new password', trigger: 'blur' },
    ],
    repeatNewPassword: [
      { required: true, message: 'Please repeat new password', trigger: 'blur' },
      { validator: this.checkEqualPassword, trigger: 'blur' },
    ]
  }

  protected checkEqualPassword(rule, value, callback): void {
    if (value !== this.form.newPassword) {
      callback(new Error('Two passwords don\'t match!'));
    } else {
      callback();
    }
  }

  protected async onClickButton() {
    const passwordChecker = this.$refs.passwordChecker as PasswordChecker
    
    if (!await this.validateForm()) return
    if (!passwordChecker.checkPassword()) return
    
    this.$emit('submit', this.form)
    
    this.form.newPassword = ''
    this.form.oldPassword = ''
    this.form.repeatNewPassword = ''
  }
}