import { Component, Vue, Watch } from 'types-vue'
import { QueryParams } from '@/Modules/UsersManager/models'

@Component
export default class SortSelect extends Vue {
  protected sortByValue = 0
  protected sortDirValue = 0

  protected options = [
    { label: 'Email', sortBy: 'email' },
    { label: 'Rol', sortBy: 'rol' },
    { label: 'Permisos', sortBy: 'permission' }
  ]

  protected orderOptions = [
    { label: 'Ascendente', sortDir: 'ASC' },
    { label: 'Descendente', sortDir: 'DESC' }
  ]

  @Watch('sortByValue')
  protected onChangeProp() {
    const name = this.$route.name
    const query = {
      ...this.$route.query,
      [QueryParams.SortBy]: this.options[this.sortByValue].sortBy
    }

    this.$router.push({ name, query })
  }

  @Watch('sortDirValue')
  protected onChangeDir() {
    const name = this.$route.name
    const query = {
      ...this.$route.query,
      [QueryParams.SortDir]: this.orderOptions[this.sortDirValue].sortDir
    }

    this.$router.push({ name, query })
  }

  protected created() {
    const sortBy = this.$route.query[QueryParams.SortBy]
    const sortDir = this.$route.query[QueryParams.SortDir]
    const hasOrderProp = !!sortBy
    const hasOrderDirection = !!sortDir

    if (hasOrderProp) {
      this.sortByValue = this.options.findIndex(item => item.sortBy === sortBy)
    } else {
      this.onChangeProp()
    }

    if (hasOrderDirection) {
      this.sortDirValue = this.orderOptions.findIndex(item => item.sortDir === sortDir)
    } else {
      this.onChangeDir()
    }
  }
}
