var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "companies-section" },
    [
      _c("el-card", [
        _c("h3", [_vm._v("DISTRIBUIDORA")]),
        _vm._v(
          " " +
            _vm._s(_vm.distributorCode) +
            " - " +
            _vm._s(_vm.distributorName) +
            " "
        )
      ]),
      _c("el-card", [
        _c("h3", [_vm._v("COMERCIALIZADORA")]),
        _vm._v(
          " " +
            _vm._s(_vm.marketerCode) +
            " - " +
            _vm._s(_vm.marketerName) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }