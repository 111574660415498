import { Component, MapAction, Prop, Vue } from 'types-vue'
import { LopdItem } from '../../models'
import { Store } from '@/Core/Models/Stores'

@Component
export default class HistoryPSList extends Vue {
  @Prop({ required: true, type: Array }) protected elements: LopdItem[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean
  // @Prop({ required: false, type: Boolean, default: false }) protected clickableCups: boolean

  @MapAction(Store.HistoryPSList) private downloadHistoryPSItem: (id: number) => void

  /**
   * STATE
   */
  protected defaultOrder = { prop: null, order: null }
  protected showingMenuItem: LopdItem = null

  /**
   * EVENTS
   */

  protected onCellMouseEnter(item: LopdItem): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: LopdItem): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected async onClickDownload(id: number): Promise<void> {
    this.downloadHistoryPSItem(id)
    // const fileDownloadUrl = item.url
    // window.open(fileDownloadUrl, '_blank')
  }

  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return this.loading ? 'CARGANDO PS CON CONTRATO...' : 'NO HAY PS CON CONTRATO'
  }

  // /**
  //  * METHODS
  //  */

  // protected checkShowOptions(process: NoContractModel): boolean {
  //   return !!this.showingMenuItem && this.showingMenuItem.id === process.id
  // }
}
