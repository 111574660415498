import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';


const CORE_URL = process.env.VUE_APP_CORE_URL


class FilesApiConnection extends AbstractApi {


  public getFileContent(keyname: string): AxiosPromise {
    const method = Method.POST
    const url = `${CORE_URL}/files/download`
    const data = { keyname }
    
    return this.request({ method, url, data })
  }

  
  public updateFileContent(keyname: string, content: any): AxiosPromise<any> {
    const method = Method.PUT
    const url = `${CORE_URL}/files`
    const params = { keyname }
    const data = content

    return this.request({ method, url, params, data })
  }

}

export default new FilesApiConnection()
