var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-tree", {
        staticClass: "height-auto",
        attrs: { data: _vm.formData },
        on: { "node-click": function() {} },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var node = ref.node
              var data = ref.data
              return _c(
                "div",
                { staticClass: "custom-tree-node" },
                [
                  _c("span", { staticClass: "title" }, [
                    data.arrayElement
                      ? _c("span", [_vm._v("Elemento ")])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(node.label))]),
                    data.isArray ? _c("span", [_vm._v(" (lista)")]) : _vm._e(),
                    data.isFormElement ? _c("span", [_vm._v(":")]) : _vm._e()
                  ]),
                  data.isFormElement
                    ? _c("el-input", {
                        attrs: { size: "small", loading: _vm.loading },
                        model: {
                          value: data.value,
                          callback: function($$v) {
                            _vm.$set(data, "value", $$v)
                          },
                          expression: "data.value"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "el-button",
            {
              staticClass: "submit-button",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("Actualizar fichero")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "text", disabled: _vm.loading },
              on: { click: _vm.onClickReturn }
            },
            [_vm._v("Volver")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }