import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import { ActionContext } from 'vuex'
import { FlowProcessesApi } from '../connections/api/FlowProcesses'
import { ProcessModel } from '../models'
import { AxiosError } from 'axios'

@Module({ namespaced: true })
export default class SelectedProcesses extends VuexModule {
  private _processes: ProcessModel[] = []
  private _loading: boolean = false

  @Getter() public selectedProcesses(): ProcessModel[] {
    return this._processes
  }
  @Getter() public resendLoading(): boolean {
    return this._loading
  }

  @Mutation() protected setProcesses(value: ProcessModel[]) {
    this._processes = [...value]
  }
  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }

  @Action({ commit: 'setProcesses' })
  protected newSelectedProcesses(payload: { processes: ProcessModel[] }) {
    return payload.processes
  }

  @Action({ useContext: true })
  protected async resendB1(ctx: ActionContext<any, any>) {
    const selectedProcesses = ctx.getters.selectedProcesses as ProcessModel[]
    const ids = _.map(selectedProcesses, 'id')

    try {
      ctx.commit('setLoading', true)
      await FlowProcessesApi.resendB1(ids)
      Notification.success('Se ha reenviado B1 correctamente')
      ctx.commit('setProcesses', [])
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al reenviar B1'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async downloadFiles(
    ctx: ActionContext<any, any>,
    payload: { messagesTypesCodes: string[]; downloadAll?: boolean }
  ) {
    const flowProcessIds = ctx.getters.selectedProcesses.map((process) => process.id)
    const downloadAll = !!payload.downloadAll
    const filters = downloadAll ? ctx.rootGetters['ProcessesInprocess/allProcessFilters'] : {}
    const processMessages = payload.messagesTypesCodes

    try {
      ctx.commit('setLoading', true)
      const response = await FlowProcessesApi.downloadFlowProcessFiles(
        flowProcessIds,
        processMessages,
        filters,
        downloadAll
      )
      window.open(response.data)
      ctx.commit('setProcesses', [])
    } catch (err) {
      const error = err as AxiosError

      if (error?.response?.status === 404) {
        return Notification.warning({
          title: 'No se han encontrado ficheros',
          message: 'No se han encontrado ficheros con los datos introducidos.'
        })
      }

      if (error?.response?.status === 501) {
        const messagaError = error?.response?.data?.message || 'Error al generar el zip'
        return Notification.error({
          title: `Error ${error?.response?.status}`,
          message: messagaError,
          duration: 0
        })
      }

      const messagaError = error?.response?.data?.message || 'Error al generar el zip'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      throw messagaError
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true }) protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setLoading', false)
    ctx.commit('setProcesses', [])
  }
}
