var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Alertas")]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.alertsLoading,
            expression: "alertsLoading"
          }
        ],
        staticClass: "row"
      },
      [
        _c("Alerts-AlertsTable", {
          staticClass: "alerts-table",
          attrs: { alertsToRender: _vm.alertsToRender }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }