import { ChartData, ChartOptions } from 'chart.js';
import { Component, mixins, MapGetter, Watch } from 'types-vue'
import { Bar } from 'vue-chartjs';
import { Store } from '@/Core/Models/Stores';
import BillingModel from '@/Modules/Dashboard/models/MarketerBilling';
import moment from 'moment';
import { nFormatter } from '@/Core/Utils';

@Component({ extends: Bar })
export default class BillingBarChart extends mixins(Bar) {

  @MapGetter(Store.BillingWitget) protected billing: BillingModel[]
  @MapGetter(Store.BillingWitget) protected billingPeriod: string


  private get XScaleLabel(): string {
    return (this.billingPeriod === 'M') ? 'Mes': 'Año'
  }

  private get chartLabel(): string[] {
      if (this.billingPeriod === 'M') {
        const billingMonth = this.billing.map(data => moment(data.endPeriodDate).format('MMM YYYY'))
        return billingMonth
      }

      const billingYear = this.billing.map(data => new Date(data.endPeriodDate).getFullYear().toString())
      return billingYear
  }

  protected get chartData(): ChartData {
    const billingAmount = this.billing.map(data => data.amount)
    return {
      labels: this.chartLabel,
      datasets: [
        { label: 'Facturación', data: billingAmount, backgroundColor: '#064789'},
      ]
    }
  }

  protected get chartOptions(): ChartOptions {
    return {
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            // labelString: this.XScaleLabel
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            // labelS⁄tring: 'Facturación (€)'
          },
          ticks: {
            callback: function(label, index, labels) {
                return `${nFormatter(label, 2)} €`
            }
          },
        }]  
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      plugins: {
        title: {
          display: false,
        }
      },
      tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(parseFloat(tooltipItem.yLabel as string))
                return `${tooltipItem.yLabel}lssks`;
            }
        }
      }
    }
  }

  @Watch('billing')
  onBillingChange() {
    this.renderChart(this.chartData, this.chartOptions)
  }

}