import { Component, Prop, Vue, Watch } from 'types-vue'
import { ItemFormConfig } from '../../models'

const validator = (value: any) : boolean => true

@Component
export default class SwitchItem extends Vue {
  @Prop({ required: true, validator: validator }) protected config: ItemFormConfig


  protected model: boolean = false

  protected get disabled(): boolean { return this.config?.disabled || false }
  protected get defaultValue(): boolean { return this.config?.default }
  
  @Watch('model', { deep: true }) protected onChangeModel() {
    this.$emit('change', { item: this.config, value: this.model })
  }

  protected setDefaultValue() {
    this.model = this.config.default
  }

  created () {
    if (!!this.defaultValue) this.setDefaultValue()
  }

}