var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: _vm.description, placement: "top" }
        },
        [
          _c(
            "el-tag",
            { attrs: { effect: "plain", size: "small", type: _vm.tagType } },
            [_vm._v(_vm._s(_vm.code))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }