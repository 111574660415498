import CoreReports from '@/Modules/Reports/connections/api/CoreReports'
import { ReportsPages } from '@/Modules/Reports/router'
import { Component, Vue } from 'types-vue'
import { RawLocation, Route, RouteConfig } from 'vue-router'

@Component
export default class LopdReport extends Vue {
  
  // STATE
  protected showing: boolean = false
  protected loading: boolean = false
  protected completed: boolean = false
  

  // GETTERS
  protected get historicalLocation(): RawLocation {
    return { name: ReportsPages.Historical }
  }

  
  // EVENTS
  protected async onClickGenerate() {
    try {
      await this.showConfirmation()
      await this.generateReport()
    } catch (error) {}
  }

  protected onCloseDialog() {
    this.clean()
  }


  // METHODS
  private clean() {
    this.completed = false
    this.loading = false
    this.showing = false
  }

  protected showConfirmation() {
    return this.$confirm('¿Estás seguro de generar el informe LOPD?')
  }

  protected async generateReport() {
    try {
      this.loading = true
      await CoreReports.generateLopdReport()
      this.completed = true
    } catch (error) {
      this.$notify.error('Ha ocurrido un error al generar el informe LOPD.')
    } finally {
      this.loading = false
    }
  }


  // PUBLIC METHODS
  public show() {
    this.showing = true
  }
}