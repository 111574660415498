import { Store } from '@/Core/Models/Stores';
import { FormConfig } from '@/Modules/Forms/models';
import { ProcessModel } from '@/Modules/Processes/models';
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import NetworkNotification from '@/Core/components/NetworkNotification/NetworkNotification.vue';

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class SelectedFilesDropdown extends Vue {


  @MapAction(Store.SelectedProcesses) protected resendB1: () => void
  @MapAction(Store.SelectedProcesses) protected downloadFiles: (payload: { messagesTypesCodes: string[], downloadAll?: boolean }) => void
  @MapAction(Store.SelectedProcesses) protected clean: () => void


  @MapGetter(Store.SelectedProcesses) protected selectedProcesses: ProcessModel[]
  @MapGetter(Store.SelectedProcesses) protected resendLoading: boolean
  @MapGetter(Store.ProcessesInprocess) protected totalElements: number


  protected showFlagDownloadFilesDialog: boolean = false
  protected isDownloadAllFiles = false
  protected loadingDownload: boolean = false



  protected get totalElementsDownload(): number { return this.totalElements || 0}


  protected get downloadFormOptions(): FormConfig {
    return {
      label: 'Cliente',
      submitButton: 'Descargar',
      items: [
        { type: 'select', name: 'messageTypes', label: 'Selecciona los mensajes:', options: `${CORE_URL}/process-message-types`, valueQuery:'code', multiple: true },
      ],
      rules: {
        // messageTypes:   [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
      }
    }
  }



  protected get loading(): boolean {
    return this.resendLoading || this.loadingDownload
  }

  protected async onSubmitDownloadForm(form) {
    const messagesTypesCodes = form.messageTypes
    const downloadAll = this.isDownloadAllFiles
    
    if (downloadAll) await this.$confirm(
      '¿Estás seguro de descargar todos los ficheros?',
      'Descargar todos los ficheros',
      { confirmButtonText: 'Descargar', cancelButtonText: 'Cancelar' }
    )
    
    const notify = await (this.$refs.notify as any).notify(`Creando el zip de descarga`) 
    
    try {
      this.showFlagDownloadFilesDialog = false
      await this.downloadFiles({ messagesTypesCodes , downloadAll})
      notify.success('Zip creado correctamente.')
    } catch (errorMessage) {
      notify.error(errorMessage)
    } finally {
      this.isDownloadAllFiles = false
      
    }

  }

  protected onCloseDownloadFilesDialog() {
    this.isDownloadAllFiles = false
  }


  private async showDownloadFilesDialog() {
    this.showFlagDownloadFilesDialog = true
  }

  private async showDownloadAllFilesDialog() {
    
    this.showFlagDownloadFilesDialog = true
    this.isDownloadAllFiles = true

  }


  protected onCommandDropdown(comand: string) {
    switch (comand) {
      case 'resendB1':
        this.resendB1()
        break
    
      case 'download':
        this.showDownloadFilesDialog()
        break
      
      case 'downloadAll':
        this.showDownloadAllFilesDialog()
        break
    }
  }

  protected beforeDestroy() {
    this.clean()
  }
}