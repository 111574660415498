import FormValidateMixin from '@/Core/mixins/FormValidate'
import _ from 'lodash'
import { Component, mixins, Prop, Watch } from 'types-vue'
import { Marketer } from '../../models'

@Component
export default class Form extends mixins(FormValidateMixin) {

  @Prop({ type: String, default: 'Guardar' }) protected submitButtonText: string
  @Prop({ type: Boolean, default: false }) protected loading: boolean
  @Prop({ type: Boolean, default: false }) protected isEditor: boolean
  @Prop() protected value: Marketer


  protected disableSubmitButton: boolean = true
  protected form: Marketer = { code: '', name: '', email: '', description: '', active: true }
  protected rules = {
    code: [
      { required: true, message: 'El codigo es obligatorio.', trigger: 'blur' },
      { max: 10, message: 'No puede ser más grande de 10 caracteres.', trigger: ['change', 'blur'] }
    ],
    name: [{ required: true, message: 'El nombre es obligatorio.', trigger: 'blur' }],
    email: [
      { required: true, message: 'El correo electrónico es obligatorio.', trigger: 'blur' },
      { type: 'email', message: 'No es un correo electrónico valido.', trigger: ['blur'] }
    ],
    description: [{ required: true, trigger: 'blur', message: 'La descripcion es obligatoria.' }]
  }


  @Watch('value', { immediate: true })
  protected onChangeDefault() {
    if (_.isNil(this.value)) return
    
    this.form = {
      code: this.value.code,
      name: this.value.name,
      email: this.value.email,
      description: this.value.description,
      active: this.value.active,
      id: this.value.id
    }
  }


  protected async onSubmit() {
    if (!await this.validateForm()) return

    this.$emit('input', this.form)
    this.$emit('submit', this.form)
  }
  
  public clean() {
    this.form = { code: '', name: '', email: '', description: '', active: true, id: null }
  }

  protected onInput() {
    this.disableSubmitButton = false
  }
}