var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "header",
          on: {
            click: function($event) {
              _vm.show = !_vm.show
            }
          }
        },
        [
          _c("i", { class: _vm.icon }),
          _c("h3", [
            _vm._v("Filtros "),
            !!_vm.totalActiveFilter
              ? _c("span", [_vm._v("(" + _vm._s(_vm.totalActiveFilter) + ")")])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass: "filters-mobile"
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }