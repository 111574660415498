var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bill
    ? _c("div", { staticClass: "amount-section" }, [
        _c("span", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.amount) + " €")
        ]),
        _c("div", { staticClass: "result" }, [
          _vm.accepted
            ? _c("span", { staticClass: "accepted" }, [
                _c("i", { staticClass: "el-icon-circle-check" }),
                _vm._v(_vm._s(_vm.result))
              ])
            : _c("span", [
                _c("i", { staticClass: "el-icon-warning-outline" }),
                _vm._v(_vm._s(_vm.result) + _vm._s(_vm.reason))
              ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }