var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-size-select" },
    [
      _c("span", { staticClass: "label" }, [_vm._v("Usuarios por página:")]),
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            "allow-create": "",
            size: "mini",
            placeholder: "Page size"
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item,
            attrs: { label: item, value: item }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }