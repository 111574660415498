import { MapActionOptions } from 'types-vue/lib/vuex/MapAction'
import { MapGetterOptions } from 'types-vue/lib/vuex/MapGetter'

import VersionsStore from '@/Modules/Versioning/store/Versions.store'
import GroupsStore from '@/Modules/UsersManager/store/Groups.store'
import UsersStore from '@/Modules/UsersManager/store/Users.store'
import MarketersStore from '@/Modules/Marketers/store/Marketers.store'
import BillsStore from '@/Modules/Bills/store/Bills.store'
import BillDetailsStore from '@/Modules/Bills/store/BillDetails.store'
import S3ConnectorsStore from '@/Modules/S3Connector/store/S3Connectors.store'
import AtrContractsStore from '@/Modules/AtrContracts/store/AtrContracts.store'
import UnprocessedFilesStore from '@/Modules/UnprocessedFiles/store/UnprocessedFiles.store'
import UnprocessedMarketerFilesStore from '@/Modules/UnprocessedMarketerFiles/store/UnprocessedMarketerFiles.store'
import ResultStore from '@/Modules/Searcher/store/Result.store'
import AddressesStore from '@/Modules/Addresses/store/Addresses.store'
import SendCurStore from '@/Modules/CUR/store/SendCur.store'
import SelectedProcessesStore from '@/Modules/Processes/store/SelectedProcesses.store'
import ProcessesTableMetricsStore from '@/Modules/Dashboard/store/ProcessesTableMetrics.store'
import CreateA6161Store from '@/Modules/NoContract/store/CreateA6161.store'
import BillingWitgetStore from '@/Modules/Dashboard/store/BillingWitget.store'
import ProcessCountWitgetStore from '@/Modules/Dashboard/store/ProcessCountWitget.store'
import UploaderStore from '@/Modules/Uploader/store/Uploader.store'
import SigninStore from '@/Modules/Auth/store/Signin.store'
import UserGroupsStore from '@/Modules/Auth/store/UserGroups.store'
import SelectedBillsStore from '@/Modules/Bills/store/SelectedBills.store'
import MasterStore from '@/Modules/Masters/store/Master.store'
import FormCreateCupsWithContractStore from '@/Modules/LOPD/store/FormCreateCupsWithContract.store'
import HistoryPSListStore from '@/Modules/LOPD/store/HistoryPSList.store'
import LopdListStore from '@/Modules/LOPD/store/LopdList.store'
import BillingErrorsStore from '@/Modules/BillingErrors/store/BillingErrors.store'
import HistoryBillStore from '@/Modules/History/store/HistoryBills.store'
import HistoryContractsStore from '@/Modules/History/store/HistoryContracts.store'
import HistoryAtcomStore from '@/Modules/History/store/HistoryAtcom.store'
import AlertsStore from '@/Modules/Alerts/store/Alerts.store'

export namespace Store {
  const createOption = (namespace: string): MapActionOptions | MapGetterOptions => ({ namespace })

  export const Signin = createOption(SigninStore.name)
  export const UserGroups = createOption(UserGroupsStore.name)
  export const ForgotPassword = createOption('ForgotPassword')
  export const ChangePassword = createOption('ChangePassword')
  export const BillingWitget = createOption(BillingWitgetStore.name)
  export const ProcessCountWitget = createOption(ProcessCountWitgetStore.name)
  export const Uploader = createOption(UploaderStore.name)
  export const FileForm = createOption('FileForm')
  export const ProcessesInprocess = createOption('ProcessesInprocess')
  export const Details = createOption('Details')
  export const Cups = createOption('Cups')
  export const NoContract = createOption('NoContract')
  export const CupsDetails = createOption('CupsDetails')
  export const Master = createOption(MasterStore.name)
  export const Holidays = createOption('Holidays')
  export const ReportsHistorical = createOption('ReportsHistorical')
  export const Versions = createOption(VersionsStore.name)
  export const UserManagerGroups = createOption(GroupsStore.name)
  export const UserManagerUsers = createOption(UsersStore.name)
  export const Marketers = createOption(MarketersStore.name)
  export const Bills = createOption(BillsStore.name)
  export const BillDetails = createOption(BillDetailsStore.name)
  export const S3Connectors = createOption(S3ConnectorsStore.name)
  export const AtrContracts = createOption(AtrContractsStore.name)
  export const UnprocessedFiles = createOption(UnprocessedFilesStore.name)
  export const UnprocessedMarketerFiles = createOption(UnprocessedMarketerFilesStore.name)
  export const SearchResult = createOption(ResultStore.name)
  export const Addresses = createOption(AddressesStore.name)
  export const SendCur = createOption(SendCurStore.name)
  export const SelectedProcesses = createOption(SelectedProcessesStore.name)
  export const ProcessesTableMetrics = createOption(ProcessesTableMetricsStore.name)
  export const CreateA6161 = createOption(CreateA6161Store.name)
  export const SelectedBills = createOption(SelectedBillsStore.name)
  export const FormCreateCupsWithContract = createOption(FormCreateCupsWithContractStore.name)
  export const HistoryPSList = createOption(HistoryPSListStore.name)
  export const LopdList = createOption(LopdListStore.name)
  export const BillingErrors = createOption(BillingErrorsStore.name)
  export const HistoryBills = createOption(HistoryBillStore.name)
  export const HistoryContracts = createOption(HistoryContractsStore.name)
  export const HistoryAtcom = createOption(HistoryAtcomStore.name)
  export const Alerts = createOption(AlertsStore.name)
}
