import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { SystemSubject } from "../../models";


const CORE_URL = process.env.VUE_APP_CORE_URL


const Path = {
  systemSubjects: `${CORE_URL}/system-subjects`
}



class AuthMastersApiSingleton extends AbstractApi {
  private static _instance: AuthMastersApiSingleton
  private constructor() { super() }

  
  public static get instance(): AuthMastersApiSingleton {
    if (!AuthMastersApiSingleton._instance) AuthMastersApiSingleton._instance = new AuthMastersApiSingleton();
    return AuthMastersApiSingleton._instance;
  }
  

  public getSystemSubject(code: string): AxiosPromise<SystemSubject[]> {
    const method = Method.GET
    const url = Path.systemSubjects
    const params = { code }

    return this.request({ method, url, params })
  }
}

export const AuthMastersApi = AuthMastersApiSingleton.instance
