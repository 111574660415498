import { Component, Vue } from 'types-vue'
import { MatchMedia } from 'vue-component-media-queries';

@Component
export default class Header extends Vue {
  protected onClickLogo() {
    this.$router.push('/')
  }


  protected onClickMenu() {
    this.$emit('clickMenu')
  }
}