var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            loading: _vm.loading,
            placeholder: "Código de vía",
            "no-match-text": "No hay ningún callejero con tu búsqueda",
            size: "medium",
            "filter-method": _vm.updateFilter,
            filterable: "",
            clearable: ""
          },
          on: { focus: _vm.onFocus },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c(
            "el-option",
            {
              key: item.id,
              attrs: { label: item.description, value: item.id }
            },
            [
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "code" }, [
                  _vm._v(_vm._s(item.code))
                ]),
                _c("span", { staticClass: "description" }, [
                  _vm._v(_vm._s(item.description))
                ])
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }