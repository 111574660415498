import { Component, Vue } from 'types-vue'
import { CurApi } from '../../connections/api/Cur'

type TableData = {
  cups: string
}

@Component
export default class CupsModal extends Vue {

  protected visible: boolean = false
  protected loading: boolean = false
  protected originMarketer: string
  protected reqTransferDate: string
  protected search: string = ''
  protected cupsList: string[] = []
  protected selectedCups: string[] = []


  protected get dataTable(): TableData[] {
    if (!this.cupsList) return []
    return this.cupsList
      .filter(cups => !this.search || cups.toLowerCase().includes(this.search.toLowerCase()))
      .map(cups => ({ cups }))
  }

  protected get selectedCupsCount(): number {
    return this.selectedCups.length
  }

  protected handleClose(): void {
    this.hide()
  }

  protected onSelectionChange(tableDada: TableData[]): void {
    this.selectedCups = tableDada.map(el => el.cups)
  }

  protected onClickConfirm(): void {
    this.$emit('onSelectCups', this.selectedCups)
    this.hide()
  }

  private async obtainCups(originMarketer: string, reqTransferDate: string): Promise<void> {
    try {
      this.loading = true
      const { data } = await CurApi.getActiveCups(originMarketer, reqTransferDate)
      this.cupsList = data
    } catch (error) {
      this.$notify.error('Error al obtener los CUPS')
    } finally {
      this.loading = false
    }
  }

  public show(originMarketer: string, reqTransferDate: string): void {
    this.obtainCups(originMarketer, reqTransferDate)
    this.visible = true
  }

  public hide(): void {
    this.cupsList = null
    this.visible = false
  }
}