import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Main from './pages/Main/Main.vue'
import { Roles } from '../UsersManager/models'

export enum S3ConnectorPages {
  Main = 's3ConnectorPagesMain'
}

const routes: Array<RouteConfig> = [
  {
    path: '/connector/s3',
    component: Page,
    children: [
      {
        path: '',
        name: S3ConnectorPages.Main,
        meta: { menuActive: 's3-connector', denyRolesAcess: [Roles.Marketer.code] },
        component: Main
      }
    ]
  }
]

export default routes