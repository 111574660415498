import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import { Watch } from 'vue-property-decorator'
import { AtrContract } from '../../models'

@Component
export default class Main extends Vue {

  @MapAction(Store.AtrContracts) protected processUrlQueryParams: (queryParams: any) => void
  
  @MapGetter(Store.AtrContracts) protected listLoading: boolean
  @MapGetter(Store.AtrContracts) protected pageSize: boolean
  @MapGetter(Store.AtrContracts) protected totalElements: boolean
  @MapGetter(Store.AtrContracts) protected atrContracts: AtrContract[]
  @MapGetter(Store.AtrContracts) protected totalActiveFilter: number




  @Watch('$route.query', { deep: true, immediate: true })
  protected onChangeRoute() {
    this.processUrlQueryParams(this.$route.query)
  }
}