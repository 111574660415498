import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { SessionVariables } from '../enum/SessionVariables'
import Page from '../views/Page/Page.vue'
import modulesRoutes from './modules'
import { checkDenyRoleAccess, checkTokenExpired } from '../Utils'
import { DashboardPages } from '@/Modules/Dashboard/router'
import { AuthPages } from '@/Modules/Auth/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '/',
        name: 'Home',
        redirect: { name: DashboardPages.Dashboard }
      }
    ]
  },
  ...modulesRoutes,
  {
    path: '*',
    redirect: { name: 'Signin' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const saveCurrentUrl = (route: Route) =>
  sessionStorage.setItem(SessionVariables.LoginCallbackURL, route.fullPath)

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem(SessionVariables.Auth)
  const { isPublic, denyRolesAcess } = to.meta

  if (isPublic) next()
  else if (!token) {
    saveCurrentUrl(to)
    next({ name: AuthPages.Signin })
  } else if (checkTokenExpired(token)) next({ name: AuthPages.Signin })
  else if (checkDenyRoleAccess(denyRolesAcess, token)) next({ name: AuthPages.Signin })
  else next()
})

export default router
