var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "details-page"
    },
    [
      !!_vm.process
        ? _c("Processes-ProcessDetailsHeader", {
            attrs: { process: _vm.process }
          })
        : _vm._e(),
      !!_vm.distribution
        ? _c("Processes-DistributionDetails", {
            attrs: { distribution: _vm.distribution }
          })
        : _vm._e(),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.tabModel,
            callback: function($$v) {
              _vm.tabModel = $$v
            },
            expression: "tabModel"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Eventos", name: "timeline" } },
            [_c("Processes-TimeLine", { staticClass: "time-line" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Documentos", name: "documents", lazy: "" } },
            [_c("Processes-DocumentsTab")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }