var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "simple-witget",
      style: _vm.cardStyle,
      on: { click: _vm.onClick }
    },
    [
      _c("div", { staticClass: "left" }, [
        _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
        _c(
          "span",
          { staticClass: "value" },
          [
            _c("animated-number", {
              attrs: {
                value: _vm.value,
                round: 1,
                duration: _vm.animationDuration
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }