import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Main from './pages/Main/Main.vue'
import Create from './pages/Create/Create.vue'

export enum LOPDPages {
  Form = 'LopdForm',
  Main = 'LopdMain'
}

const routes: Array<RouteConfig> = [
  {
    path: '/lopd',
    component: Page,
    children: [
      {
        path: '',
        name: LOPDPages.Main,
        meta: { menuActive: 'LOPD' },
        component: Main
      },
      {
        path: 'create',
        name: LOPDPages.Form,
        meta: { menuActive: 'LOPD' },
        component: Create
      }
    ]
  }
]

export default routes
