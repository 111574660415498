import AbstractApi, { Method } from '@/Core/api/AbstractApi'

import { AxiosPromise } from 'axios'
import { SearchFlowCupsWithContractParams } from '../../models'

const CORE_URL = process.env.VUE_APP_CORE_URL

const Path = {
  List: `${CORE_URL}/flow-cups-with-contract/filtered`,
  Create: `${CORE_URL}/flow-cups-with-contract/query`
}

class FlowCupsWithContractSingleton extends AbstractApi {
  private static _instance: FlowCupsWithContractSingleton
  private constructor() {
    super()
  }

  public static get instance(): FlowCupsWithContractSingleton {
    if (!FlowCupsWithContractSingleton._instance)
      FlowCupsWithContractSingleton._instance = new FlowCupsWithContractSingleton()
    return FlowCupsWithContractSingleton._instance
  }

  public create(data: any, fileType: string): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: `${Path.Create}/${fileType}`,
      data
    })
  }

  public search(params: SearchFlowCupsWithContractParams = { pageNumber: 1 }): Promise<any> {
    const method = Method.GET
    const url = Path.List

    return this.request({ method, url, params })
  }
}

export const FlowCupsWithContractApi = FlowCupsWithContractSingleton.instance
