import _ from 'lodash';
import { Component, Filter, Vue } from 'types-vue';

@Component
export default class FiltersMixin extends Vue {
  @Filter()
  protected startCase(text: string): string {
    return _.startCase(text);
  }

  @Filter()
  protected upperCase(text: string): string {
    return _.upperCase(text)
  }

  @Filter()
  protected processCodeFilter(value) {
    switch (value) {
      case "09": return `B80_${value}`
      default: return `A1_${value}`
    }
  }

  @Filter()
  protected filterMoney(value: string): string {
    const num = parseFloat(value)
    return num.toLocaleString('es-ES', {
      style: 'currency',
      currency: 'EUR',
    });
  }
}