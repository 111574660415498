import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue, Watch } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClaimForm extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  @Prop({ required: true })
  protected onChangeClaimType: any

  protected get formOptions(): FormConfig {
    return {
      label: 'Reclamación',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'claimtype',
          label: 'Tipo de reclamación',
          options: `${CORE_URL}/claim-type`,
          placeholder: 'Selecciona el tipo de reclamación',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'claimsubtype',
          label: 'Subtipo de reclamación',
          options: `${CORE_URL}/claim-subtype`,
          placeholder: 'Selecciona el subtipo de reclamación',
          valueQuery: 'subtype'
        },
        {
          type: 'text',
          name: 'originreference',
          label: 'Referencia del organismo que reclama',
          placeholder: 'Introduce la referencia',
          maxCharacters: 35
        },
        {
          type: 'select',
          name: 'wrongattentiontype',
          label: 'Tipo de atención incorrecta',
          options: `${CORE_URL}/incorrect-attention-type`,
          placeholder: 'Selecciona el tipo atención incorrecta',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenumCM',
          label: 'Código de solicitud de comercializadora',
          placeholder: 'Introduce el código de solicitud de comercializadora',
          maxCharacters: 12
        },
        {
          type: 'text',
          name: 'targetclaimcomreferencenum',
          label: 'Referencia de reclamación',
          placeholder: 'Introduce la referencia de reclamación',
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'conceptcontract',
          label: 'Concepto de contratación',
          options: `${CORE_URL}/contracting-concepts`,
          placeholder: 'Selecciona el concepto de contratación',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'conceptfacturation',
          label: 'Concepto de facturación',
          options: `${CORE_URL}/billing-concepts`,
          placeholder: 'Selecciona el concepto de facturación',
          valueQuery: 'conceptCode'
        }
      ],
      rules: {
        claimtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        claimsubtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenumCM: [
          {
            required: p48required[this.claimTypes]?.comreferencenum,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        wrongattentiontype: [
          {
            required: p48required[this.claimTypes]?.wrongattentiontype,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        targetclaimcomreferencenum: [
          {
            required: p48required[this.claimTypes]?.targetclaimcomreferencenum,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        conceptcontract: [
          {
            required: p48required[this.claimTypes]?.conceptcontract,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  protected onChange(model) {
    this.onChangeClaimType(model)
  }
}
