import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { GenerateProcessResponse } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Files: `${CORE_SERVICE_URL_BASE}/files`
}

class FilesApiSingleton extends AbstractApi {

  private static _instance: FilesApiSingleton
  private constructor() { super() }

  public static get instance(): FilesApiSingleton {
    if (!FilesApiSingleton._instance) FilesApiSingleton._instance = new FilesApiSingleton();
    return FilesApiSingleton._instance;
  }

  public generateProcess(data: any): AxiosPromise<GenerateProcessResponse> {
    const method = Method.POST
    const url = Path.Files

    return this.request({ method, url, data })
  }


}

export const FilesApi = FilesApiSingleton.instance