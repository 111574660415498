import { Store } from '@/Core/Models/Stores'
import { UploadedFileFileResult } from '@/Modules/Uploader/models'
import _ from 'lodash'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class Result extends Vue {

  @MapGetter(Store.FileForm) protected validationResult: UploadedFileFileResult


  protected get validationErrors(): any {
    return this.validationResult.validationResponseVoList[0].body.error.filter(element => !element.includes('OK'))
  }


  protected onClickEditFile() {
    this.$router.go(-1)
  }

  protected onClickProcessList() {
    this.$router.go(-2)
  }


  created() {
    if (_.isEmpty(this.validationResult)) {
      this.$notify({ type: 'error', title: 'Error', message: 'Error al cargar los datos de la validacion' })
      this.$router.push('/')
    }
  }
}