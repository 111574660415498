import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import { ActionContext } from 'vuex'
import { Holiday, HolidayFilter } from '../models'
import { Holidays } from '../connection/api/Holidays'
import moment from 'moment'
import _ from 'lodash'
import { checkTokenExpired } from '@/Core/Utils'

@Module({ namespaced: true })
export default class extends VuexModule {


  protected _holidaysList: Holiday[] = null
  protected _loading: boolean = true
  protected _filter: HolidayFilter = { startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }


  @Getter() protected holidaysList(): Holiday[] { return this._holidaysList }
  @Getter() protected filter(): HolidayFilter { return this._filter}
  @Getter() protected loading(): boolean { return this._loading}

  @Mutation() protected setFilter(value: HolidayFilter) { this._filter = value }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setHolidaysList(value: Holiday[]) {
    this._holidaysList = value
    this._loading = false
  }


  @Action({ commit: 'setHolidaysList', useContext: true })
  protected async obtainHolidays(ctx: ActionContext<any, any>): Promise<Holiday[]> {
    ctx.commit('setLoading', true)
    
    const filters = ctx.getters.filter as HolidayFilter
    const response = await Holidays.getAll(filters.startDate, filters.endDate)

    return response.data.holidays
  }

  @Action({ useContext: true })
  protected updateFilter(ctx: ActionContext<any, any>, payload: { startDate: Date, endDate: Date }): void {
    const rawStart = moment(payload.startDate)
    const rawEnd = moment(payload.endDate)
    const startDate = moment([rawStart.year(), rawStart.month(), 1]).format('YYYY-MM-DD')
    const endDate = moment([rawEnd.year(), rawEnd.month(), 1]).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
    
    ctx.commit('setFilter', { startDate, endDate })
    ctx.dispatch('obtainHolidays')
  }


  @Action({ useContext: true })
  protected async createHoliday(ctx: ActionContext<any, any>, holiday: Holiday) {
    await Holidays.create(holiday)
    ctx.dispatch('obtainHolidays')
  }

  @Action({ useContext: true })
  protected async updateHoliday(ctx: ActionContext<any, any>, holiday: Holiday) {
    await Holidays.update(holiday)
    ctx.dispatch('obtainHolidays')
  }

  @Action({ useContext: true })
  protected async deleteHoliday(ctx: ActionContext<any, any>, holiday: Holiday) {
    await Holidays.delete(holiday)
    ctx.dispatch('obtainHolidays')
  }
}
