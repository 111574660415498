import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { AtrContractsPages } from '../../router'

@Component
export default class CupsDetails extends Vue {
  @MapAction(Store.AtrContracts) protected obtainDetails: (cups: string) => Promise<void>
  @MapGetter(Store.AtrContracts) protected details: any
  @MapGetter(Store.AtrContracts) protected detailsLoading: boolean

  protected activeTab: string = 'info'

  onClickReturn() {
    this.$router.push({ name: AtrContractsPages.Main })
  }

  @Watch('$route.params.cups', { immediate: true })
  protected async onChangeParamId(cups: string) {
    await this.obtainDetails(cups)
  }
}
