var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.uploaderUrl,
            drag: "",
            "show-file-list": false,
            "on-success": _vm.onSuccess,
            "on-error": _vm.onError,
            headers: _vm.headers,
            "before-upload": _vm.onBeforeUpload,
            multiple: ""
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", {
            staticClass: "el-upload__text",
            domProps: {
              innerHTML: _vm._s(_vm.$t("uploader uploader.uploaderSentence"))
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }