import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Component, mixins, Prop, Vue } from 'types-vue'
import ConfirmPasswordFormModel from '../../models/ConfirmPasswordForm'
import PasswordChecker from '../PasswordChecker/PasswordChecker'

@Component
export default class ConfirmPasswordForm extends mixins(FormValidateMixin) {
  
  
  @Prop({ type: Boolean, default: false })
  protected loading: boolean



  protected form: ConfirmPasswordFormModel = {
    newPassword: '',
    repeatNewPassword: ''
  }


  get rules() {
    return {
      newPassword: [
        { required: true, trigger: 'blur', message: this.$t('auth confirmPassword.validatorInputPassword') },
      ],
      repeatNewPassword: [
        { required: true, trigger: 'blur', message: this.$t('auth confirmPassword.repeatPasswordValidator') },
        { validator: this.checkEqualPassword, trigger: 'blur', message: this.$t('auth confirmPassword.noMatchValidator') },
      ]
    }
  }

  protected checkEqualPassword(rule, value, callback): void {
    if (value !== this.form.newPassword) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  }

  protected async onSubmit() {
    const passwordChecker = this.$refs.passwordChecker as PasswordChecker
    
    if (!await this.validateForm()) return
    if (!passwordChecker.checkPassword()) return

    this.$emit('submit', this.form)
  }
}