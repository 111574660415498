var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ForgotPasswordPage" } },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("auth forgotPassword.title")))]),
      _c("Auth-ForgotPasswordForm", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.onSubmit, back: _vm.onBack }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }