import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import moment from 'moment'
import { MastersListRequest } from '../../models/Masters'

const CORE_URL = process.env.VUE_APP_CORE_URL

const getISODate = () => {
  return moment()
    .add(moment.duration(1, 'hours'))
    .toISOString()
}

class MasterApiConnection extends AbstractApi {
  getList(masterSlug: string, params: MastersListRequest = { page: 0, size: 25 }): AxiosPromise {
    const url = `${CORE_URL}/${masterSlug}/paged`

    return this.request({ method: Method.GET, url, params })
  }

  getAllList(masterSlug: string, params: any = {}): AxiosPromise {
    const url = `${CORE_URL}/${masterSlug}`
    

    return this.request({ method: Method.GET, url, params })
  }

  createItem(masterSlug: string, item: any): AxiosPromise {
    const url = `${CORE_URL}/${masterSlug}`
    const currentDate = getISODate()
    const data = { ...item, createDate: currentDate, updateDate: currentDate }

    return this.request({ method: Method.POST, url, data })
  }

  updateItem(masterSlug: string, item: any): AxiosPromise {
    const url = `${CORE_URL}/${masterSlug}/${item.id}`
    const currentDate = getISODate()
    const data = { ...item, updateDate: currentDate }

    return this.request({ method: Method.PATCH, url, data })
  }

  removeItem(masterSlug: string, item: any): AxiosPromise {
    const url = `${CORE_URL}/${masterSlug}/${item.id}`
    return this.request({ method: Method.DELETE, url })
  }
}

export default new MasterApiConnection()
