import { render, staticRenderFns } from "./BillingSection.vue?vue&type=template&id=77f6a85d&scoped=true&"
import script from "./BillingSection.ts?vue&type=script&lang=ts&"
export * from "./BillingSection.ts?vue&type=script&lang=ts&"
import style0 from "./BillingSection.vue?vue&type=style&index=0&id=77f6a85d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f6a85d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77f6a85d')) {
      api.createRecord('77f6a85d', component.options)
    } else {
      api.reload('77f6a85d', component.options)
    }
    module.hot.accept("./BillingSection.vue?vue&type=template&id=77f6a85d&scoped=true&", function () {
      api.rerender('77f6a85d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Alerts/components/BillingSection/BillingSection.vue"
export default component.exports