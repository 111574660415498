var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "network-notify-containter" },
    [
      _c(
        "transition-group",
        { attrs: { name: "slide-fade" } },
        _vm._l(_vm.notifications, function(notification) {
          return _c("Core-Notification", {
            key: notification.id,
            ref: notification.id,
            refInFor: true,
            attrs: { defaultMessage: notification.message },
            on: {
              success: function($event) {
                return _vm.onNotificationSuccess(notification.id)
              },
              error: function($event) {
                return _vm.onNotificationError(notification.id)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }