import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class P41 extends Vue {
  protected showForm: boolean = true
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null

  // Personalized boolean variables.
  protected isDocumentTypeRequired: boolean = true
  protected isDocumentNumRequired: boolean = true
  protected isSurrogacyDisabled: boolean = false
  protected isNewNationalityDisabled: boolean = false
  protected isNewDocumentTypeDisabled: boolean = false
  protected isNewDocumentNumDisabled: boolean = false
  protected isNewFirstNameDisabled: boolean = false
  protected isNewFamilyName1Disabled: boolean = false
  protected isNewFamilyName2Disabled: boolean = false
  protected isNewTitularTypeDisabled: boolean = false
  protected isNewRegularAddressDisabled: boolean = false
  protected isNewTelephone1Disabled: boolean = false
  protected isNewTelephone2Disabled: boolean = false
  protected isNewEmailDisabled: boolean = false
  protected isNewLanguageDisabled: boolean = false
  protected isNewProvinceOwnerDisabled: boolean = false
  protected isNewCityOwnerDisabled: boolean = false
  protected isNewZipCodeOwnerDisabled: boolean = false
  protected isNewStreetTypeOwnerDisabled: boolean = false
  protected isNewStreetOwnerDisabled: boolean = false
  protected isNewStreetNumberOwnerDisabled: boolean = false
  protected isNewPortalDisabled: boolean = false
  protected isNewStairCaseDisabled: boolean = false
  protected isNewFloorDisabled: boolean = false
  protected isNewDoorDisabled: boolean = false
  protected isNewReqQdDisabled: boolean = false
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa Emisora del Paso',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa Receptora del Paso',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'nationality',
          label: 'Nacionalidad',
          placeholder: 'Selecciona la nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo Documento de Identificación',
          placeholder: 'Selecciona el tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Nº Documento',
          placeholder: 'Escribe el número de documento',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'modeffectdate',
          label: 'Modelo Fecha Efecto',
          options: `${CORE_URL}/contract-effect-date-models`,
          placeholder: 'Introduce modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'reqtransferdate',
          label: 'Fecha Efecto Solicitada',
          placeholder: 'Introduce fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'updatereason',
          label: 'Motivo Modificación',
          options: `${CORE_URL}/contract-modification-reason-in-marketer-change`,
          placeholder: 'Selecciona el motivo de la modificación',
          valueQuery: 'code'
        },
        {
          type: 'switch',
          name: 'surrogacy',
          label: 'Subrogación',
          placeholder: 'Introduce la subrogación',
          disabled: this.isSurrogacyDisabled
        },
        {
          type: 'select',
          name: 'newnationality',
          label: 'Nueva nacionalidad',
          placeholder: 'Selecciona la nueva nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code',
          disabled: this.isNewNationalityDisabled
        },
        {
          type: 'select',
          name: 'newdocumenttype',
          label: 'Nuevo tipo de documento',
          placeholder: 'Selecciona el nuevo tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code',
          disabled: this.isNewDocumentTypeDisabled
        },
        {
          type: 'text',
          name: 'newdocumentnum',
          label: 'Nuevo num. de documento',
          placeholder: 'Introduce el nuevo número de documento',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewDocumentNumDisabled
        },
        {
          type: 'text',
          name: 'newfirstname',
          label: 'Nuevo nombre',
          placeholder: 'Introduce el nuevo nombre',
          valueQuery: 'code',
          maxCharacters: 40,
          disabled: this.isNewFirstNameDisabled
        },
        {
          type: 'text',
          name: 'newfamilyname1',
          label: 'Nuevo primer apellido / Nueva razón social',
          placeholder: 'Introduce el nuevo primer apellido / nueva razón social',
          valueQuery: 'code',
          maxCharacters: 30,
          disabled: this.isNewFamilyName1Disabled
        },
        {
          type: 'text',
          name: 'newfamilyname2',
          label: 'Nuevo segundo Apellido',
          placeholder: 'Introduce el nuevo segundo apellido',
          valueQuery: 'code',
          maxCharacters: 30,
          disabled: this.isNewFamilyName2Disabled
        },
        {
          type: 'select',
          name: 'newtitulartype',
          label: 'Nuevo tipo de titular',
          placeholder: 'Selecciona el nuevo tipo de titular',
          options: `${CORE_URL}/client-person-types`,
          valueQuery: 'code',
          disabled: this.isNewTitularTypeDisabled
        },
        {
          type: 'switch',
          name: 'newregularaddress',
          label: 'Nueva vivienda habitual',
          placeholder: 'Selecciona si tiene nueva vivienda habitual',
          disabled: this.isNewRegularAddressDisabled
        },
        {
          type: 'text',
          name: 'newtelephone1',
          label: 'Nuevo teléfono 1',
          placeholder: 'Introduce el nuevo teléfono 1',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewTelephone1Disabled
        },
        {
          type: 'text',
          name: 'newtelephone2',
          label: 'Nuevo teléfono 2',
          placeholder: 'Introduce el nuevo teléfono 2',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewTelephone2Disabled
        },
        {
          type: 'text',
          name: 'newemail',
          label: 'Nuevo correo electrónico',
          placeholder: 'Introduce el nuevo email',
          valueQuery: 'code',
          maxCharacters: 60,
          disabled: this.isNewEmailDisabled
        },
        {
          type: 'select',
          name: 'newlanguage',
          label: 'Nuevo idioma',
          placeholder: 'Selecciona el nuevo idioma',
          options: `${CORE_URL}/general-languages`,
          valueQuery: 'code',
          disabled: this.isNewLanguageDisabled
        },
        {
          type: 'select',
          name: 'newprovinceowner',
          label: 'Nueva provincia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la nueva provincia',
          valueQuery: 'code',
          disabled: this.isNewProvinceOwnerDisabled
        },
        {
          type: 'select',
          name: 'newcityowner',
          label: 'Nuevo municipio',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el nuevo municipio',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription',
          disabled: this.isNewCityOwnerDisabled
        },
        {
          type: 'text',
          name: 'newzipcodeowner',
          label: 'Nuevo código postal',
          placeholder: 'Introduce el nuevo código postal',
          maxCharacters: 5,
          disabled: this.isNewZipCodeOwnerDisabled
        },
        {
          type: 'select',
          name: 'newstreettypeowner',
          label: 'Nuevo tipo de vía',
          options: `${CORE_URL}/location-street-types`,
          placeholder: 'Selecciona el nuevo tipo de via',
          valueQuery: 'code',
          disabled: this.isNewStreetTypeOwnerDisabled
        },
        {
          type: 'text',
          name: 'newstreetowner',
          label: 'Nueva vía',
          placeholder: 'Introduce el nombre nuevo de la via',
          maxCharacters: 60,
          disabled: this.isNewStreetOwnerDisabled
        },
        {
          type: 'text',
          name: 'newstreetnumberowner',
          label: 'Nuevo num. de finca',
          placeholder: 'Introduce el numero nuevo de la finca',
          maxCharacters: 4,
          disabled: this.isNewStreetNumberOwnerDisabled
        },
        {
          type: 'text',
          name: 'newportal',
          label: 'Nuevo portal',
          placeholder: 'Introduce el nuevo portal',
          maxCharacters: 5,
          disabled: this.isNewPortalDisabled
        },
        {
          type: 'text',
          name: 'newstaircase',
          label: 'Nueva escalera',
          placeholder: 'Introduce la nueva escalera',
          maxCharacters: 5,
          disabled: this.isNewStairCaseDisabled
        },
        {
          type: 'text',
          name: 'newfloor',
          label: 'Nuevo piso',
          placeholder: 'Introduce la nueva escalera',
          maxCharacters: 5,
          disabled: this.isNewFloorDisabled
        },
        {
          type: 'text',
          name: 'newdoor',
          label: 'Nueva puerta',
          placeholder: 'Introduce la nueva puerta',
          maxCharacters: 5,
          disabled: this.isNewDoorDisabled
        },
        {
          type: 'text',
          name: 'newreqqd',
          label: 'Nueva Qd',
          placeholder: 'Introduce la nueva Qd',
          valueQuery: 'code',
          disabled: this.isNewReqQdDisabled
        },
        {
          type: 'switch',
          name: 'disconnectedserviceaccepted',
          label: 'Contratar independientemente del estado',
          placeholder: 'Selecciona si deseas contratar independientemente del estado'
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Observaciones de la solicitud',
          placeholder: 'Introduce las observaciones de la solicitud',
          maxCharacters: 400
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del Documento',
          placeholder: 'Selecciona la fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de Documento',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona el tipo de documento',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de Descarga del Documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Información adicional',
          placeholder: 'Introduce la información acidional',
          maxCharacters: 255
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqhour: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documenttype: [
          { required: this.isDocumentTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        documentnum: [
          { required: this.isDocumentNumRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        modeffectdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        updatereason: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        disconnectedserviceaccepted: [
          { required: true, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '41',
        messagetype: 'A1'
      },
      a141: [
        {
          comreferencenum: this.formData?.comreferencenum,
          reqdate: currentDate,
          reqhour: currentHour,
          nationality: this.formData?.nationality,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          cups: this.formData?.cups,
          modeffectdate: this.formData?.modeffectdate,
          reqtransferdate: this.formData?.reqtransferdate
            ? moment(this.formData?.reqtransferdate).format('YYYY-MM-DD')
            : this.formData?.reqtransferdate,
          updatereason: this.formData?.updatereason,
          surrogacy: this.isSurrogacyDisabled ? null : this.formData?.surrogacy ? 'S' : 'N',
          newnationality: this.formData?.newnationality,
          newdocumenttype: this.formData?.newdocumenttype,
          newdocumentnum: this.formData?.newdocumentnum,
          newfirstname: this.formData?.newfirstname,
          newfamilyname1: this.formData?.newfamilyname1,
          newfamilyname2: this.formData?.newfamilyname2,
          newtitulartype: this.formData?.newtitulartype,
          newregularaddress: this.isNewRegularAddressDisabled
            ? null
            : this.formData?.newregularaddress
            ? 'S'
            : 'N',
          newtelephone1: this.formData?.newtelephone1,
          newtelephone2: this.formData?.newtelephone1,
          newemail: this.formData?.newemail,
          newlanguage: this.formData?.newlaguage,
          newprovinceowner: this.formData?.newprovinceowner,
          newcityowner: this.formData?.newcityowner,
          newzipcodeowner: this.formData?.newzipcodeowner,
          newstreettypeowner: this.formData?.newstreettypeowner,
          newstreetowner: this.formData?.newstreetowner,
          newstreetnumberowner: this.formData?.newstreetnumberowner,
          newportal: this.formData?.newportal,
          newstaircase: this.formData?.newstaircase,
          newfloor: this.formData?.newfloor,
          newdoor: this.formData?.newdoor,
          newreqqd: this.formData?.newreqqd,
          disconnectedserviceaccepted: this.formData?.disconnectedserviceaccepted ? 'S' : 'N',
          extrainfo: this.formData?.extrainfo,
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected async onSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()

    try {
      this.loading = true
      this.showForm = false

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  protected handleReturn() {
    this.showForm = true
  }

  protected async change(process: any) {
    // Form constants.
    const updatereason = process.updatereason

    if (updatereason !== '') {
      if (updatereason === '01') {
        this.logic01()
      } else if (updatereason === '02') {
        this.logic02()
      }
    }
  }

  protected logic01() {
    // Requireds.
    this.isDocumentTypeRequired = false
    this.isDocumentNumRequired = false
  }

  protected logic02() {
    this.isSurrogacyDisabled = true
    this.isNewNationalityDisabled = true
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamilyName1Disabled = true
    this.isNewFamilyName2Disabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewRegularAddressDisabled = true
    this.isNewTelephone1Disabled = true
    this.isNewTelephone2Disabled = true
    this.isNewEmailDisabled = true
    this.isNewLanguageDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalDisabled = true
    this.isNewStairCaseDisabled = true
    this.isNewFloorDisabled = true
    this.isNewDoorDisabled = true
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
