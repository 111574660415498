import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import _ from "lodash";
import { BillingError } from "../../models";
import { BillingErrorRequest,  BillingErrorResponse, BillingErrorUrlRequest, BillingErrorUrlResponse } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  BillingErrorsList: `${CORE_SERVICE_URL_BASE}/billing-error/paged`,
  DeleteErrors: `${CORE_SERVICE_URL_BASE}/billing-error`,
  BillDownload: `${CORE_SERVICE_URL_BASE}/billing-error/download`
}

class BillingErrorApi extends AbstractApi {


  public getBillingErrors(params: BillingErrorRequest = { page: 1 }): AxiosPromise<BillingErrorResponse> {
    const method = Method.GET
    const url = Path.BillingErrorsList

    return this.request({ method, url, params })
  }


  public getBillingErrorUrl(data: BillingError): AxiosPromise<string> {
    const method = Method.POST
    const url = Path.BillDownload
    
    return this.request({ method, url, data })
  }


  public removeById(id: number): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.DeleteErrors}/${id}`

    return this.request({ method, url })
  }

}

export default new BillingErrorApi()