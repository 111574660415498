import { Store } from '@/Core/Models/Stores'
import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
const CORE_URL = process.env.VUE_APP_CORE_URL
@Component
export default class Create extends Vue {

  protected currentStep: number = 0
  protected cupsFormModel: any = null
  protected generalFormModel: any = null
  protected directionFormModel: any = null
  protected provinceCode: string = null


  @MapAction(Store.CreateA6161) protected sendForm: (formModel: any) => Promise<void>


  protected cupsForm: FormConfig = {
    label: 'Cups form',
    submitButton: 'Siguiente',
    items: [
      { type: 'text', name: 'cups', label: 'CUPS', placeholder: 'Introduce el CUPS'}
    ]
  }

  protected generalForm: FormConfig = {
    label: 'Informacion general',
    submitButton: 'Siguiente',
    items: [
      { type: 'select', name: 'marketer', label: 'Comercializadora', options: `${CORE_URL}/system-subjects?categories=COM,CLI`, valueQuery:'code' },
      { type: 'select', name: 'distributor', label: 'Distribuidora',  options: `${CORE_URL}/system-subjects?categories=DIS,GRE`, valueQuery:'code', default: '0234' },
      { type: 'divider', name: 'asdf' },
      { type: 'select', name: 'psOrigin', label: 'Origen PS', options: `${CORE_URL}/supply-point-without-contract-origins`, valueQuery:'code' },
      { type: 'select', name: 'infrastructureStatus', label: 'Estado infraestructura', options: `${CORE_URL}/scr-infrastructure-status-transformations`, valueQuery:'code' },
      { type: 'select', name: 'contractingStatus', label: 'Estado contratación', options: `${CORE_URL}/reservation-contracting-status`, labelQuery: 'descripcion', valueQuery:'code' },
      { name: 'dateCupsEnabled', label: 'Fecha CUPS disponible', type: 'daterange' },
    ],
    rules: {
      marketer: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
      distributor: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }]
    }
  }

  protected get directionForm(): FormConfig {
    return {
      label: 'directionForm',
      submitButton: 'Siguiente',
      items: [
        { type: 'select', name: 'province', label: 'Provincia', options: `${CORE_URL}/location-province`, valueQuery:'code', default: '28' },
        {
          type: 'select', 
          name: 'town',
          label: 'Municipio',
          options: `${CORE_URL}/location-town?provinceId=${this.provinceCode}`,
          disabled: _.isNil(this.provinceCode),
          placeholder: 'Municipio'
        },
        { type: 'text', name: 'postalCode', label: 'Codigo Postal'},
        { type: 'divider', name: 'asdf' },
        { type: 'select', name: 'streetType', label: 'Tipo de vía', options: `${CORE_URL}/location-street-types`, valueQuery:'code'},
        { type: 'text', name: 'streetName', label: 'Nombre de la vía'},
        { type: 'text', name: 'streetNumber', label: 'Numero de la finca'},
        { type: 'textarea', name: 'moreDirection', label: 'Resto Dirección'},
      ]
    }
  }

  
  protected onSubmitCupsForm(form: any) {
    this.currentStep = 1
    this.cupsFormModel = form
  }

  protected onSubmitInformationForm(form: any) {
    this.currentStep = 2
    this.generalFormModel = form
  }

  protected async onSubmitDirectionForm(form: any) {
    this.directionFormModel = form
    this.currentStep = 3
    this.sendForm({ ...this.cupsFormModel, ...this.generalFormModel, ...this.directionFormModel })
  }

  protected onChangeDirectionForm(form: any) {
    const provinceCode = form?.province
    const hasChangeProvinceCodeChanged = !!provinceCode && provinceCode !== this.provinceCode
    if(hasChangeProvinceCodeChanged) this.provinceCode = provinceCode
  }
}