import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Prop, Vue } from 'types-vue'

@Component
export default class Pagination extends Vue {

  @Prop({ required: true, type: Number }) protected pageSize: number
  @Prop({ required: true, type: Number }) protected totalElements: number


  protected get currentPage(): number {
    return parseInt(this.$route.query.page as string)
  }


  protected onNewPage(pageNumber: number) {
    const name = this.$route.name
    const query = { ...this.$route.query, page: pageNumber.toString() }
    this.$router.push({ name: name, query})
  }
}