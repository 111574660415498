import { Store } from '@/Core/Models/Stores';
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin';
import { MessageBoxData } from 'element-ui/types/message-box';
import moment from 'moment';
import { Component, Filter, MapAction, MapGetter, mixins, Vue } from 'types-vue'
import { Holiday } from '../../models';

@Component
export default class HolidaysList extends mixins(PermissionMixin) {

  protected showingMenuItem: Holiday = null

  @MapGetter(Store.Holidays) protected holidaysList: Holiday[]
  @MapAction(Store.Holidays) protected updateFilter: (payload: { startDate: Date, endDate: Date }) => void
  @MapAction(Store.Holidays) protected deleteHoliday: (holiday: Holiday) => void
  @MapGetter(Store.Holidays) protected loading: boolean


  @Filter()
  protected formatDate(value: string) {
    return moment(value).format('LL')
  }

  protected get emptyText(): string {
    return (this.loading)
      ? 'CARGANDO...'
      : 'NO HAY FESTIVOS'
  }


  protected checkShowOptions(item: Holiday): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.date === item.date
  }


  protected onCellMouseEnter(item: Holiday): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: Holiday): void {
    if (this.showingMenuItem.date === item.date) this.showingMenuItem = null
  }

  protected onClickEdit(holiday: Holiday) {
    this.$emit('edit', holiday)
  }

  onRowClick(holiday: Holiday, column) {
    if (column.property === 'options') return
    this.$emit('edit', holiday)
  }

  protected async onClickDelete(holiday: Holiday) {
    try {
      await this.confirmDelete(holiday)
      
      try {
        await this.deleteHoliday(holiday)
        this.showSuccessDeleteNotify()
      } catch (error) {
        this.showErrorDeleteNotify(error)
      }

    } catch (error) {}
  }
  


  async created() {
    try {
      this.updateFilter({ startDate: new Date(), endDate: new Date() })
    } catch (error) {
      this.$notify({ type: 'error', title: 'Error', message: 'Ha ocurrido un error al obtener los datos.' })
    } finally {
    }
  }

  private confirmDelete(holiday: Holiday): Promise<MessageBoxData> {
    const day = moment(holiday.date).format('LL')
    return this.$confirm(`¿Estas seguro de eliminiar el dia ${day}?`, `Confirmación`, { confirmButtonText: 'Eliminar' })
  }

  private showSuccessDeleteNotify() {
    this.$notify({ type: 'success', title: 'Eliminado', message: `Se ha eliminado el festivo correctamente.`})
  }

  private showErrorDeleteNotify(error) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al intentar eliminar'})
  }
}