import _, { values } from 'lodash'
import { Action, Getter, MapAction, Module, Mutation, VuexModule } from 'types-vue'
import ProcessMetrics from '../connections/api/ProcessMetrics'
import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import { ProcessTableMetric } from '../models'
import moment from 'moment'


@Module({ namespaced: true })
export default class ProcessesTableMetrics extends VuexModule {

  private _metrics: ProcessTableMetric[] = []
  private _loading: boolean = null
  private _startDateFilter: string = null
  private _endDateFilter: string = null
  private _processTypeFilter: string[] = null



  @Getter() protected metrics(): ProcessTableMetric[] { return this._metrics }
  @Mutation() protected setMetrics(value: ProcessTableMetric[]) { this._metrics = value }
  
  @Getter() protected loading(): boolean { return this._loading }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }

  @Getter() protected processTypeFilter(): string[] { return this._processTypeFilter }
  @Mutation() protected setprocessTypeFilter(value: string[]) { this._processTypeFilter = value }
  
  @Getter() protected startDateFilter(): string { return this._startDateFilter }
  @Getter() protected endDateFilter(): string { return this._endDateFilter }
  @Mutation() protected setDateFilter(value: string) {
    this._endDateFilter = moment().format('YYYY-MM-DD')
    
    switch (value) {
      case 'week':
        this._startDateFilter = moment().subtract(7, 'days').format('YYYY-MM-DD')
        break;
      case 'month':
        this._startDateFilter = moment().subtract(1, 'month').format('YYYY-MM-DD')
        break;
      case 'year':
        this._startDateFilter = moment().subtract(1, 'year').format('YYYY-MM-DD')
        break;
    }
  }




  @Action({ useContext: true })
  protected async obtaintProcessTableMetricsData(ctx: ActionContext<any, any>) {
    const startDate = ctx.getters.startDateFilter
    const endDate = ctx.getters.endDateFilter
    const processCode = ctx.getters.processTypeFilter

    if (_.isNil(startDate) || _.isNil(endDate)) return

    try {
      ctx.commit('setLoading', true)
      const response = await ProcessMetrics.getTableMetrics({ startDate, endDate, processCode })
      ctx.commit('setMetrics', response.data.processMetrics)
    } catch (err) {
      ctx.commit('setMetrics', [])
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las metricas.'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async newFilterDate(ctx: ActionContext<any, any>, newValue: string) {
    ctx.commit('setDateFilter', newValue)
    ctx.dispatch('obtaintProcessTableMetricsData')
  }

  @Action({ useContext: true })
  protected async newFilterProcessType(ctx: ActionContext<any, any>, newValue: string) {
    ctx.commit('setprocessTypeFilter', newValue)
    ctx.dispatch('obtaintProcessTableMetricsData')
  }

}
