import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { ProcessStepModel } from "../../models";
import { ProcessStepDownloadResponse, ProcessStepsRequest } from '../../models/api';


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  FlowProcessesSteps: `${CORE_SERVICE_URL_BASE}/flow-process-steps`,
  FlowProcessesStepsDownload: `${CORE_SERVICE_URL_BASE}/flow-process-steps/download`

}

class FlowProcessesStepsSingleton extends AbstractApi {

  private static _instance: FlowProcessesStepsSingleton
  private constructor() { super() }

  public static get instance(): FlowProcessesStepsSingleton {
    if (!FlowProcessesStepsSingleton._instance) FlowProcessesStepsSingleton._instance = new FlowProcessesStepsSingleton();
    return FlowProcessesStepsSingleton._instance;
  }
  
  public getSteps(filter: ProcessStepsRequest = {}): AxiosPromise<any> {
    const method = Method.GET
    const url = Path.FlowProcessesSteps
    const params = filter

    return this.request({ method, url, params })
  }

  public getStepsByProcessId(id: string): AxiosPromise<any> {
    const method = Method.GET
    const url = `${Path.FlowProcessesSteps}`
    const params = {
      'flowProcess.id': `${id}`
    }

    return this.request({ method, url, params })
  }

  public downloadProcessById(id: string | number): AxiosPromise<any> {
    const method = Method.GET
    const url = `${Path.FlowProcessesStepsDownload}/${id}`

    return this.request({ method, url })
  }
}

// export default new FlowProcessSteps()
export const FlowProcessesStepsApi = FlowProcessesStepsSingleton.instance