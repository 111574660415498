import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { LopdItem } from '../models'
import { FlowCupsWithContractApi } from '../connections/api/FlowCupsWithContract'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'


@Module({ namespaced: true })
export default class LopdList extends VuexModule {

  private _items: LopdItem[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null
  private _listLoading: boolean = true



  @Getter() public orderProp(): string { return this._orderProp }
  @Getter() public orderDirection(): string { return this._orderDirection }
  @Getter() public items(): LopdItem[] { return this._items }
  @Getter() public pageSize(): number { return this._pageSize }
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }


  @Mutation() protected setOrderProp(value: string) { this._orderProp = value }
  @Mutation() protected setOrderDirection(value: string) { this._orderDirection = value }
  @Mutation() protected setPageSize(pageSize: number) { this._pageSize = pageSize }
  @Mutation() protected setListLoading(value: boolean) { this._listLoading = value }

 
  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = (isEmpty) ? 1 : parseInt(value)
  }

  @Mutation()
  protected setResponse(data) {
    this._items = [ ...data.flowAvailablePSVos ] 
    this._totalElements = data.totalElements
    this._listLoading = false
  }


  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setOrderProp', queryParams[QueryParams.OrderProp])
    ctx.commit('setOrderDirection', queryParams[QueryParams.OrderDirection])

    ctx.dispatch('obtain')
  }

  @Action({ useContext: true })
  protected async obtain(ctx: ActionContext<any, any>) {
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize
    const fieldOrder = ctx.getters.orderProp
    const orderASCorDESC = ctx.getters.orderDirection

    // if (!orderASCorDESC || !fieldOrder || _.isNil(pageNumber) || _.isNil(pageSize) || pageSize < 1) return

    try {
      ctx.commit('setListLoading', true)
      const response = await FlowCupsWithContractApi.search({ pageNumber, pageSize, fieldOrder, orderASCorDESC })
      ctx.commit('setResponse', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los datos'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }

  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ useContext: true })
  protected async removeNoContract(ctx: ActionContext<any, any>, file: LopdItem): Promise<any> {
    // const response = await NoContractApi.removeNoContractById(file.id)

    // if (response.status === 200)
    //   ctx.dispatch('obtain')
  }

}
