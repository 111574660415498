var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "file-data" }, [
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.description,
                placement: "top"
              }
            },
            [
              _c(
                "el-tag",
                {
                  staticClass: "code",
                  attrs: { size: "small", effect: "plain", type: "info" }
                },
                [_vm._v(_vm._s(_vm._f("processCodeFilter")(_vm.code)))]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }