import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import moment from 'moment'
import { AlertCongifuration } from '../../models'
const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Alerts: `${CORE_SERVICE_URL_BASE}/alert-information`,
  AlertsAdmin: `${CORE_SERVICE_URL_BASE}/alert-information/admin`,
  AlertsAdminFiltered: `${CORE_SERVICE_URL_BASE}/alert-information/admin/filtered`,
  AlertsSummary: `${CORE_SERVICE_URL_BASE}/alert-information/summary`,
  AlertsConfiguration: `${CORE_SERVICE_URL_BASE}/alert-configuration`,
  AlertsConfigurationAdmin: `${CORE_SERVICE_URL_BASE}/alert-configuration/admin`,
  AlertsConfigurationAdminEmail: `${CORE_SERVICE_URL_BASE}/alert-configuration/admin/email`,
  ValidSubjects: `${CORE_SERVICE_URL_BASE}/system-subjects`
}

class AlertsApi extends AbstractApi {
  public async getAlerts(): Promise<any> {
    const date = moment().subtract(1, 'days').format('YYYY-MM-DD')

    const method = Method.GET
    const url = Path.Alerts
    const params = {
      date
    }

    const response = await this.request({ method, url, params })

    return response
  }

  public async getAlertsAdmin(parameters: any): Promise<any> {
    const date = moment().subtract(1, 'days').format('YYYY-MM-DD')

    const method = Method.GET
    const url = Path.AlertsAdmin
    const params = {
      ...parameters,
      date
    }

    const response = await this.request({ method, url, params })

    return response
  }

  public async getAlertsAdminFiltered(parameters: any): Promise<any> {
    const date = moment().subtract(1, 'days').format('YYYY-MM-DD')
    const method = Method.GET
    const url = Path.AlertsAdminFiltered
    const params = {
      ...parameters,
      date
    }

    const response = await this.request({ method, url, params })

    return response
  }

  public async getAlertsSummary(): Promise<any> {
    const date = moment().subtract(1, 'days').format('YYYY-MM-DD')

    const method = Method.GET
    const url = Path.AlertsSummary
    const params = {
      date
    }

    const response = await this.request({ method, url, params })
  }

  public async getAlertsConfiguration(): Promise<any> {
    const method = Method.GET
    const url = Path.AlertsConfiguration

    const response = await this.request({ method, url })

    return response
  }

  public async getAlertsConfigurationAdmin(params: any): Promise<any> {
    const method = Method.GET
    const url = Path.AlertsConfigurationAdmin
    const response = await this.request({ method, url, params })
    return response
  }

  public async getAlertsConfigurationAdminEmail(params: any): Promise<any> {
    const method = Method.GET
    const url = Path.AlertsConfigurationAdminEmail
    const response = await this.request({ method, url, params })
    return response
  }

  public async patchAlertsConfiguration(config: AlertCongifuration): Promise<any> {
    const method = Method.PATCH
    const url = Path.AlertsConfiguration

    const response = await this.request({ method, url, data: { ...config } })

    return response
  }

  public async patchAlertsConfigurationAdmin(config: AlertCongifuration): Promise<any> {
    const method = Method.PATCH
    const url = Path.AlertsConfigurationAdmin

    const response = await this.request({ method, url, data: { ...config } })

    return response
  }

  public async postAlertsConfiguration(config: AlertCongifuration): Promise<any> {
    const method = Method.POST
    const url = Path.AlertsConfiguration

    const response = await this.request({ method, url, data: { ...config } })

    return response
  }

  public async postAlertsConfigurationAdmin(config: AlertCongifuration): Promise<any> {
    const method = Method.POST
    const url = Path.AlertsConfigurationAdmin

    const response = await this.request({ method, url, data: { ...config } })

    return response
  }

  public async getValidSubjects(): Promise<any> {
    const method = Method.GET
    const url = Path.ValidSubjects

    const response = await this.request({ method, url })

    return response
  }
}

export default new AlertsApi()
