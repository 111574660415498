import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import _ from "lodash";
import { Bill } from "../../models";
import { FilteredFlowBillsRequest, FilteredFlowBillsResponse, FlowBillsRequest } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  FlowBillsFiltered: `${CORE_SERVICE_URL_BASE}/flow-bills/filtered`,
  FlowBills: `${CORE_SERVICE_URL_BASE}/flow-bills`,
  DownloadFiles: `${CORE_SERVICE_URL_BASE}/flow-bills/download/zip`,
  DownloadFile: `${CORE_SERVICE_URL_BASE}/flow-bills/download`,
  DownloadAllFiles: `${CORE_SERVICE_URL_BASE}/flow-bills/download/all/zip`,
}

class BillsSingleton extends AbstractApi {
  private static _instance: BillsSingleton
  private constructor() { super() }

  public static get instance(): BillsSingleton {
    if (!BillsSingleton._instance) BillsSingleton._instance = new BillsSingleton();
    return BillsSingleton._instance;
  }
  




  public getFilteredFlowBills(params: FilteredFlowBillsRequest = { pageNumber: 1 }): AxiosPromise<FilteredFlowBillsResponse> {
    const method = Method.GET
    const url = Path.FlowBillsFiltered

    return this.request({ method, url, params })
  }


  public getFlowBills(params: FlowBillsRequest): AxiosPromise<Bill[]> {
    const method = Method.GET
    const url = Path.FlowBills

    return this.request({ method, url, params })
  }


  public removeById(id: string | number): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.FlowBills}/${id}`

    return this.request({ method, url })
  }

  public getUrlDownloadZip(billIds: string [] | number[] = [], processMessages: string[] = [], filters: object = {}, downloadAll: boolean = false): AxiosPromise {
    const method = Method.GET
    const url = (downloadAll) ? Path.DownloadAllFiles : Path.DownloadFiles
    const flowBillIds = (downloadAll) ? undefined : billIds.toString()
    const messageTypeCodes = (!processMessages.length) ? undefined : processMessages.toString()
    const params = _.omitBy({ ...filters, flowBillIds, messageTypeCodes }, _.isNil)

    return this.request({ method, url, params })
  }

  public downloadById(id: string | number): AxiosPromise<any> {
    const method = Method.GET
    const url = `${Path.DownloadFile}/${id}`

    return this.request({ method, url })
  }

}

export const BillsApi = BillsSingleton.instance