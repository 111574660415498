var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-page" }, [
    _c(
      "div",
      { staticClass: "auth-page_container" },
      [
        _c(
          "el-card",
          [
            _c("div", { staticClass: "logo-container" }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: "/logo.png", alt: "" }
              })
            ]),
            _c("router-view")
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }