import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { LOPDPages } from '../../router'
import { Store } from '@/Core/Models/Stores'

@Component
export default class Main extends Vue {
  /**
   * STORE
   */

  @MapAction(Store.HistoryPSList) private processUrlQueryParams: (queryParams: any) => Promise<any>
  @MapAction(Store.HistoryPSList) private fetchHistoryPSList: () => Promise<any>
  @MapAction(Store.HistoryPSList) private newPageSize: (pageSize: number) => number

  @MapGetter(Store.HistoryPSList) protected items: any[]
  @MapGetter(Store.HistoryPSList) protected loading: boolean
  @MapGetter(Store.HistoryPSList) protected pageSize: number
  @MapGetter(Store.HistoryPSList) protected totalElements: number

  /**
   * METHODS
   */
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' } })
    }
  }

  onClickCreateButton() {
    this.$router.push({ name: LOPDPages.Form })
  }

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    await this.fetchHistoryPSList()
  }

  /**
   * WATCHERS
   */

  @Watch('$route.query', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    await this.processUrlQueryParams(this.$route.query)
  }
}
