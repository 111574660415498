import { Component, Prop, Vue } from 'types-vue'
import { HistoryContractsApi } from '../../connections/api/HistoryContracts'

@Component
export default class ContractsTable extends Vue {
  @Prop({ default: [] }) contractsList: any[]

  protected async onClickDownload(id: number): Promise<void> {
    const url = await HistoryContractsApi.downloadHistoryContract(id)
    window.open(url, '_blank')
  }
}
