import { Cups } from '@/Modules/Cups/models'
import { Component, Prop, Vue } from 'types-vue'
import { AtrContract } from '../../models'
import { AtrContractsPages } from '../../router'

@Component
export default class ContractsTable extends Vue {
  @Prop({ required: true, type: Array }) protected atrContracts: AtrContract[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean

  protected get emptyText(): string {
    return this.loading ? 'CARGANDO CONTRATOS ATR EN CURSO...' : 'NO HAY CONTRATOS ATR EN CURSO'
  }

  protected onClickDetails(cups: string) {
    this.$router.push({ name: AtrContractsPages.Details, params: { cups } })
  }
}
