var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("Core-Title", { attrs: { loading: _vm.listLoading } }, [
            _c("h2", [_vm._v("Facturas")])
          ]),
          _c(
            "div",
            { staticClass: "header-buttons" },
            [
              _c("Uploader-Button", { staticClass: "header-buttons-child" }),
              _vm.selectedBills.length
                ? _c("Bills-SelectedFilesDropdown", {
                    staticClass: "header-buttons-child"
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Core-FiltersDrawer",
        {
          attrs: {
            totalActiveFilter: _vm.totalActiveFilter,
            buttonLabel: "Filtros de facturas"
          }
        },
        [_c("Bills-Filters")],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              staticClass: "is-table"
            },
            [
              _c("Bills-BillsList", {
                attrs: {
                  bills: _vm.billList,
                  loading: _vm.listLoading,
                  clickableCups: _vm.isCupsClickable,
                  "with-select": ""
                }
              }),
              _c("Core-Pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  totalElements: _vm.totalElements
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("Bills-PageSizeSelect", { on: { change: _vm.onChangePageSize } })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }