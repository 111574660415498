import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Prop, Vue } from 'types-vue'


@Component
export default class FiltersContainerMobile extends Vue {

  @Prop({type: String, default: 0}) protected totalActiveFilter: number

  // @MapGetter(Store.ProcessesInprocess) protected totalActiveFilter: number

  protected show: boolean = false

  protected get icon(): string {
    return this.show ? 'el-icon-arrow-down':'el-icon-arrow-right'
  }
}