import _ from "lodash"

export interface MasterConfig {
  code: string
  apiSlug: string
}

export interface MastersListRequest {
  page: number
  size: number
  sort?: string[]
}

interface MasterObject {
  ProcessResultReasons: MasterConfig
  MarketerOperationsSupplyStatus: MasterConfig
  ContractMeterCorrectorProperties: MasterConfig
  ContractUnsubscribeReasons: MasterConfig
  ProcessCommunicationCauses: MasterConfig
  GeneralActivationTypes: MasterConfig
  GeneralCnmcRejectionReasons: MasterConfig
  Process: MasterConfig
  ClaimSubtype: MasterConfig
  AdditionalInformationVariation: MasterConfig
  ProcessMessageTypes: MasterConfig
  ContractEffectDateModels: MasterConfig
  BilledConcepts: MasterConfig
  AplicantTypes: MasterConfig
  PeriodIrcCanon: MasterConfig
  ContractCancellationReasons: MasterConfig
  PeriodBilling: MasterConfig
  AttachedDocumentType: MasterConfig
  ContractCorrectorType: MasterConfig
  LocationProvince: MasterConfig
  BillTypes: MasterConfig
  ClaimType: MasterConfig
  MarketerOperationsServiceStatus: MasterConfig
  ContractUseGasType: MasterConfig
  AdditionalInformationProvided: MasterConfig
  SystemSubjects: MasterConfig
  GeneralInstallationAnomalies: MasterConfig
  ContractModificationReasons: MasterConfig
  BillingType: MasterConfig
  AdditionalInformation: MasterConfig
  GeneralInspectionResults: MasterConfig
  InterventionTypes: MasterConfig
  InspectionOrigin: MasterConfig
  ReadingReasons: MasterConfig
  BillingConceptLevels: MasterConfig
  LocationStreetTypes: MasterConfig
  PeriodOthers: MasterConfig
  PeriodRental: MasterConfig
  GeneralYesNoIndicative: MasterConfig
  GeneralMonth: MasterConfig
  IncorrectAttentionType: MasterConfig
  CancellationType: MasterConfig
  GeneralResultsIntervention: MasterConfig
  SystemCategorySubjectRelations: MasterConfig
  ContractReadTypes: MasterConfig
  OperationTypes: MasterConfig
  ContractSupplyStatusYesNo: MasterConfig
  ClientDocumentTypes: MasterConfig
  ClientPersonTypes: MasterConfig
  IncidenceReply: MasterConfig
  GeneralLanguages: MasterConfig
  BillingConcepts: MasterConfig
  PeriodReadings: MasterConfig
  IneNets: MasterConfig
  LocationNationalities: MasterConfig
  ContractModificationReasonInMarketerChange: MasterConfig
  ContractEffectDateModelExpected: MasterConfig
  IneMunicipalities: MasterConfig
  DeviceTypes: MasterConfig
  GeneralInformationIncidenceTypes: MasterConfig
  LocationTown: MasterConfig
  ContractingConcepts: MasterConfig
  SystemSubjectCategories: MasterConfig
  BillingPeriodicity: MasterConfig
  ContractTollTypes: MasterConfig
  ContractProductTypes: MasterConfig
  ProcessResults: MasterConfig
  ContractCommunityPressures: MasterConfig
  MarketerCommonClosingTypes: MasterConfig
  ResolutionDetails: MasterConfig
  NumeratorReadingTypes: MasterConfig

}

export namespace Masters {
  const masters: MasterObject = {
    ProcessResultReasons:             { code: 'process-result-reasons',               apiSlug: 'process-result-reasons' },
    MarketerOperationsSupplyStatus:   { code: 'marketer-operations-supply-status',    apiSlug: 'marketer-operations-supply-status' },
    ContractMeterCorrectorProperties: { code: 'contract-meter-corrector-properties',  apiSlug: 'contract-meter-corrector-properties' },
    ContractUnsubscribeReasons:       { code: 'contract-unsubscribe-reasons',         apiSlug: 'contract-unsubscribe-reasons' },
    ProcessCommunicationCauses:       { code: 'process-communication-causes',         apiSlug: 'process-communication-causes' },
    GeneralActivationTypes:           { code: 'general-activation-types',             apiSlug: 'general-activation-types' },
    GeneralCnmcRejectionReasons:      { code: 'general-cnmc-rejection-reasons',       apiSlug: 'general-cnmc-rejection-reasons' },
    Process:                          { code: 'process',                              apiSlug: 'process-process' },
    ClaimSubtype:                     { code: 'claim-subtype',                        apiSlug: 'claim-subtype' },
    AdditionalInformationVariation:   { code: 'additional-information-variation',     apiSlug: 'additional-information-variation' },
    ProcessMessageTypes:              { code: 'process-message-types',                apiSlug: 'process-message-types' },
    ContractEffectDateModels:         { code: 'contract-effect-date-models',          apiSlug: 'contract-effect-date-models' },
    BilledConcepts:                   { code: 'billed-concepts',                      apiSlug: 'billed-concepts' },
    AplicantTypes:                    { code: 'applicant-types',                      apiSlug: 'applicant-types' },
    PeriodIrcCanon:                   { code: 'period-irc-canon',                     apiSlug: 'period-irc-canon' },
    ContractCancellationReasons:      { code: 'contract-cancellation-reasons',        apiSlug: 'contract-cancellation-reasons' },
    PeriodBilling:                    { code: 'period-billing',                       apiSlug: 'period-billing' },
    AttachedDocumentType:             { code: 'attached-document-type',               apiSlug: 'attached-document-type' },
    ContractCorrectorType:            { code: 'contract-corrector-type',              apiSlug: 'contract-corrector-type' },
    LocationProvince:                 { code: 'location-province',                    apiSlug: 'location-province' },
    BillTypes:                        { code: 'bill-types',                           apiSlug: 'bill-types' },
    ClaimType:                        { code: 'claim-type',                           apiSlug: 'claim-type' },
    MarketerOperationsServiceStatus:  { code: 'marketer-operations-service-status',   apiSlug: 'marketer-operations-service-status' },
    ContractUseGasType:               { code: 'contract-use-gas-type',                apiSlug: 'contract-use-gas-type' },
    AdditionalInformationProvided:    { code: 'additional-information-provided',      apiSlug: 'additional-information-provided' },
    SystemSubjects:                   { code: 'system-subjects',                      apiSlug: 'system-subjects' },
    GeneralInstallationAnomalies:     { code: 'general-installation-anomalies',       apiSlug: 'general-installation-anomalies' },
    ContractModificationReasons:      { code: 'contract-modification-reasons',        apiSlug: 'contract-modification-reasons' },
    BillingType:                      { code: 'billing-type',                         apiSlug: 'billing-type' },
    AdditionalInformation:            { code: 'additional-information',               apiSlug: 'additional-information' },
    GeneralInspectionResults:         { code: 'general-inspection-results',           apiSlug: 'general-inspection-results' },
    InterventionTypes:                { code: 'intervention-types',                   apiSlug: 'intervention-types' },
    InspectionOrigin:                 { code: 'inspection-origin',                    apiSlug: 'inspection-origin' },
    ReadingReasons:                   { code: 'reading-reasons',                      apiSlug: 'reading-reasons' },
    BillingConceptLevels:             { code: 'billing-concept-levels',               apiSlug: 'billing-concept-levels' },
    LocationStreetTypes:              { code: 'location-street-types',                apiSlug: 'location-street-types' },
    PeriodOthers:                     { code: 'period-others',                        apiSlug: 'period-others' },
    PeriodRental:                     { code: 'period-rental',                        apiSlug: 'period-rental' },
    GeneralYesNoIndicative:           { code: 'general-yes-no-indicative',            apiSlug: 'general-yes-no-indicative' },
    GeneralMonth:                     { code: 'general-month',                        apiSlug: 'general-month' },
    IncorrectAttentionType:           { code: 'incorrect-attention-type',             apiSlug: 'incorrect-attention-type' },
    CancellationType:                 { code: 'cancellation-type',                    apiSlug: 'cancellation-type' },
    GeneralResultsIntervention:       { code: 'general-results-intervention',         apiSlug: 'general-results-intervention' },
    SystemCategorySubjectRelations:   { code: 'system-category-subject-relations',    apiSlug: 'system-category-subject-relations' },
    ContractReadTypes:                { code: 'contract-read-types',                  apiSlug: 'contract-read-types' },
    OperationTypes:                   { code: 'operation-types',                      apiSlug: 'operation-types' },
    ContractSupplyStatusYesNo:        { code: 'contract-supply-status-yes-no',        apiSlug: 'contract-supply-status-yes-no' },
    ClientDocumentTypes:              { code: 'client-document-types',                apiSlug: 'client-document-types' },
    IncidenceReply:                   { code: 'incidence-reply',                      apiSlug: 'incidence-reply' },
    GeneralLanguages:                 { code: 'general-languages',                    apiSlug: 'general-languages' },
    BillingConcepts:                  { code: 'billing-concepts',                     apiSlug: 'billing-concepts' },
    PeriodReadings:                   { code: 'period-readings',                      apiSlug: 'period-readings' },
    IneNets:                          { code: 'ine-nets',                             apiSlug: 'ine-nets' },
    LocationNationalities:            { code: 'location-nationalities',               apiSlug: 'location-nationalities' },
    ContractModificationReasonInMarketerChange: { code: 'contract-modification-reason-in-marketer-change', apiSlug: 'contract-modification-reason-in-marketer-change' },
    ContractEffectDateModelExpected:  { code: 'contract-effect-date-model-expected',  apiSlug: 'contract-effect-date-model-expected' },
    IneMunicipalities:                { code: 'ine-municipalities',                   apiSlug: 'ine-municipalities' },
    DeviceTypes:                      { code: 'device-types',                         apiSlug: 'device-types' },
    GeneralInformationIncidenceTypes: { code: 'general-information-incidence-types',  apiSlug: 'general-information-incidence-types' },
    LocationTown:                     { code: 'location-town',                        apiSlug: 'location-town' },
    ContractingConcepts:              { code: 'contracting-concepts',                 apiSlug: 'contracting-concepts' },
    SystemSubjectCategories:          { code: 'system-subject-categories',            apiSlug: 'system-subject-categories' },
    BillingPeriodicity:               { code: 'billing-periodicity',                  apiSlug: 'billing-periodicity' },
    ContractTollTypes:                { code: 'contract-toll-types',                  apiSlug: 'contract-toll-types' },
    ContractProductTypes:             { code: 'contract-product-types',               apiSlug: 'contract-product-types' },
    ProcessResults:                   { code: 'process-results',                      apiSlug: 'process-results' },
    ContractCommunityPressures:       { code: 'contract-community-pressures',         apiSlug: 'contract-community-pressures' },
    MarketerCommonClosingTypes:       { code: 'marketer-common-closing-types',        apiSlug: 'marketer-common-closing-types' },
    ResolutionDetails:                { code: 'resolution-details',                   apiSlug: 'resolution-details' },
    NumeratorReadingTypes:            { code: 'numerator-reading-types',              apiSlug: 'numerator-reading-types' },
    ClientPersonTypes:                { code: 'client-person-types',      apiSlug: 'client-person-types' },
  }

  const masterList = _.values(masters)

  export const All = masters
  export const list = masterList

  export function searchByCode(code: string): MasterConfig {
    return _.find(masterList, { code })
  }
}

