var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "upload",
      on: {
        dragover: function($event) {
          $event.preventDefault()
        },
        drop: function($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
        dragleave: function($event) {
          $event.preventDefault()
          return _vm.onDragLeave.apply(null, arguments)
        }
      }
    },
    [_vm._v("Suelta los ficheros aqui")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }