import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { BillConcept, ProcessMaster } from "../../models";
import { BillingConceptsParams } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Process: `${CORE_SERVICE_URL_BASE}/process-process`,
  BillingConcepts: `${CORE_SERVICE_URL_BASE}/billing-concepts`,
}

class BillMastersSingleton extends AbstractApi {
  private static _instance: BillMastersSingleton
  private constructor() { super() }

  public static get instance(): BillMastersSingleton {
    if (!BillMastersSingleton._instance) BillMastersSingleton._instance = new BillMastersSingleton();
    return BillMastersSingleton._instance;
  }
  

  public getProcessType(processCode: string): AxiosPromise<ProcessMaster[]> {
    const method = Method.GET
    const url = Path.Process
    const params = { code: processCode }

    return this.request({ method, url, params })
  }

  public getBillingConcepts(params: BillingConceptsParams): AxiosPromise<BillConcept[]> {
    const method = Method.GET
    const url = Path.BillingConcepts

    return this.request({ method, url, params })
  }
}

export const BillMastersApi = BillMastersSingleton.instance