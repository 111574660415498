var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.usersList, "row-class-name": "cursor-pointer" },
          on: {
            "row-click": _vm.onRowClick,
            "cell-mouse-leave": _vm.onCellMouseLeave,
            "cell-mouse-enter": _vm.onCellMouseEnter
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "enabled", width: "30" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.enabled ? "Activo" : "Inactivo",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "circle",
                          class: { enabled: scope.row.enabled }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "email", label: "CORREO ELECTRÓNICO", width: "350" }
          }),
          _c("el-table-column", {
            attrs: { prop: "clientMetadata.userGroups", label: "GRUPOS" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.clientMetadata &&
                    scope.row.clientMetadata.userGroups
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.clientMetadata.userGroups.toString() ||
                                "-"
                            )
                          )
                        ])
                      : scope.row.clientMetadata &&
                        scope.row.clientMetadata.userGroup
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.clientMetadata.userGroup || "-")
                          )
                        ])
                      : _c("span", [_vm._v("-")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "clientMetadata.userRol", label: "ROL" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.clientMetadata
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.getRol(scope.row.clientMetadata.userRol) ||
                                "-"
                            )
                          )
                        ])
                      : _c("span", [_vm._v("-")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "clientMetadata.userPermissions", label: "PERMISO" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.clientMetadata
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.getPermission(
                                scope.row.clientMetadata.userPermissions
                              ) || "-"
                            )
                          )
                        ])
                      : _c("span", [_vm._v("-")])
                  ]
                }
              }
            ])
          }),
          _vm.isAdminPermission
            ? _c("el-table-column", {
                attrs: { width: "280", prop: "options" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.removeLoadingEmail === scope.row.email
                            ? _c("i", { staticClass: "el-icon-loading" })
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkShowOptions(scope.row),
                                      expression: "checkShowOptions(scope.row)"
                                    }
                                  ],
                                  staticClass: "button-menu"
                                },
                                [
                                  _c("el-button", {
                                    attrs: { type: "text", size: "mini" }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickEdit(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("Editar")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        loading: _vm.removeLoadingEmail
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickDelete(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("Eliminar")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        loading: _vm.restorePasswordLoading
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleRestoreUserPassword(
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Recuperar contraseña")]
                                  )
                                ],
                                1
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1360647718
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("Core-Pagination", {
        attrs: { pageSize: _vm.pageSize, totalElements: _vm.totalElements }
      }),
      _vm.nextToken
        ? _c(
            "div",
            { staticClass: "load-user-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: { click: _vm.onClickLoadMoreUsers }
                },
                [_vm._v("Cargar más usuarios")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }