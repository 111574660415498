import { Store } from '@/Core/Models/Stores'
import { User, UserFormModel } from '@/Modules/UsersManager/models'
import { Component, MapAction, Vue } from 'types-vue'

@Component
export default class UserCreationDialog extends Vue {

  @MapAction(Store.UserManagerUsers) protected createUser: (user: UserFormModel) => Promise<any>

  protected showing: boolean = false
  protected loading: boolean = false

  private successNotify(user: UserFormModel) {
    this.$notify({ type: 'success', title: 'Se ha creado correctamente', message: `El usuario "${user.email}" se ha creado correctamente`})
  }

  private errorNotify(error, user: UserFormModel) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: `Ha ocurrido un error al crear el usuario ${user.email}`})
  }

  protected async onSubmitCreate(user: UserFormModel) {
    try {
      this.loading = true
      await this.createUser(user)
      this.successNotify(user)
      this.showing = false
    } catch (error) {
      this.errorNotify(error, user)
    } finally {
      this.loading = false
    }
  }

  public show() {
    this.showing = true
  }
}