import { Component, Prop, Vue, Watch } from 'types-vue'
import { ItemFormConfig } from '../../models'

const validator = (value: any): boolean => {
  return true
}

@Component
export default class Input extends Vue {
  @Prop({ required: true, validator: validator }) protected config: ItemFormConfig

  protected model: string = ''

  protected get type(): string {
    return this.config?.type || ''
  }
  protected get size(): string {
    return this.config?.size
  }
  protected get disabled(): boolean {
    return this.config?.disabled || false
  }
  protected get placeholder(): string {
    return this.config?.placeholder || this.config.label
  }
  protected get defaultValue(): string {
    return this.config?.default
  }
  protected get minCharacters(): number {
    return this.config?.minCharacters
  }
  protected get maxCharacters(): number {
    return this.config?.maxCharacters
  }

  @Watch('model', { deep: true }) protected onChangeModel() {
    this.$emit('change', { item: this.config, value: this.model })
  }

  private setDefaultValue() {
    this.model = this.defaultValue
  }

  created() {
    if (!!this.defaultValue) this.setDefaultValue()
  }

  @Watch('config.default', { deep: true }) protected onChangeDefault() {
    if (!!this.defaultValue) this.setDefaultValue()
  }
}
