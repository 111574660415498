var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-input-tag input-tag-wrapper",
      class: [_vm.size ? "el-input-tag--" + _vm.size : ""],
      on: { click: _vm.focusTagInput }
    },
    [
      _vm._l(_vm.innerTags, function(tag, idx) {
        return _c(
          "el-tag",
          _vm._b(
            {
              key: tag,
              attrs: {
                size: _vm.size,
                closable: !_vm.readOnly,
                "disable-transitions": false
              },
              on: {
                close: function($event) {
                  return _vm.remove(idx)
                }
              }
            },
            "el-tag",
            _vm.$attrs,
            false
          ),
          [_vm._v(" " + _vm._s(tag) + " ")]
        )
      }),
      !_vm.readOnly
        ? _c("input", {
            staticClass: "tag-input",
            attrs: { placeholder: _vm.placeholder },
            domProps: { value: _vm.newTag },
            on: {
              input: _vm.inputTag,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del"
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.removeLastTag.apply(null, arguments)
                },
                _vm.addNew
              ],
              blur: _vm.addNew
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }