import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { s3ConnectorApi } from '../connector/api/s3Connector'
import { S3Connector } from '../models'

@Module({ namespaced: true })
export default class S3Connectors extends VuexModule {


  protected _s3ConnectorList: S3Connector[] = null
  protected _loading: boolean = true



  @Getter() protected list(): S3Connector[] { return this._s3ConnectorList }
  @Getter() protected loading(): boolean { return this._loading }

  
  
  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setList(list: S3Connector[]) {
    this._s3ConnectorList = list
    this._loading = false
  }



  @Action({ commit: 'setList', useContext: true })
  protected async obtainS3Connectors(ctx: ActionContext<any, any>): Promise<S3Connector[]> {
    ctx.commit('setLoading', true)
    const response = await s3ConnectorApi.getAll()
    return response.data
  }

  @Action({ useContext: true })
  protected async removeS3Connectors(ctx: ActionContext<any, any>, s3Connector: S3Connector): Promise <void> {
    await s3ConnectorApi.delete(s3Connector.id)
    await ctx.dispatch('obtainS3Connectors')
  }

  @Action({ useContext: true })
  protected async createS3Connector(ctx: ActionContext<any, any>, s3Connector: S3Connector): Promise <void> {
    await s3ConnectorApi.create(s3Connector)
    await ctx.dispatch('obtainS3Connectors')
  }

  @Action({ useContext: true })
  protected async editS3Connector(ctx: ActionContext<any, any>, s3Connector: S3Connector): Promise <void> {
    await s3ConnectorApi.update(s3Connector)
    await ctx.dispatch('obtainS3Connectors')
  }

}
