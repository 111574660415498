import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { FiltersName } from '@/Modules/Reports/enums/Filters'
import _ from 'lodash'
import { Component, mixins, Watch } from 'types-vue'


@Component
export default class ReportTypeFilter extends mixins(FiltersMixin) {
  

  /**
   * STATE
   */

  protected loading: boolean = false
  protected selected: string = ''
  protected options: any[] = [
    {
      label: 'Usuario',
      value: 'USERNAME'
    },
    {
      label: 'Comercializadora',
      value: 'MARKETER'
    }
  ]



  /**
   * METHODS
   */

   protected addQueryParam() {
    const filter = this.selected.toString()
    const name = this.$route.name
    const query = (filter) 
      ? {...this.$route.query, [FiltersName.Filter]: filter }
      : _.omit(this.$route.query, [FiltersName.Filter]);
    
    if (!_.isEqual(query, this.$route.query))
      this.$router.push({ name, query })
  }

  protected setSelectedElementsByQueryParams() {
    const filter = (this.$route.query[FiltersName.Filter] as string)
    this.selected = filter
  }

  protected updateFilter(filter: string) {
    filter = filter.toLowerCase()
  }

  protected onFocus() {
    this.updateFilter('')
  }


  /**
   * WHATCHERS
   */
  @Watch(`$route.query.${FiltersName.Filter}`, { immediate: true })
  protected onchangeQueryParam() {
    const haveQueryParam = !!this.$route.query[FiltersName.Filter]
    if (haveQueryParam) this.setSelectedElementsByQueryParams()
  }


  @Watch('selected')
  protected onChangeSelected() {
    this.addQueryParam()
  }
}