import { Component, Vue } from 'types-vue';
import { CookieNames } from '../enum/CookieNames';

@Component
export default class ApiVersionMixin extends Vue {
  created() {
    const apiVersionCookie = this.$cookies.get(CookieNames.ApiVersion) 

    if (!apiVersionCookie) this.$cookies.set(CookieNames.ApiVersion, process.env.VUE_APP_DEFAULT_API_VERSION)
  }
}