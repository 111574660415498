import { Component, Prop, Vue } from 'types-vue'
import { NoContractModel } from '../../models'

@Component
export default class NoContractList extends Vue {

  @Prop({ required: true, type: Array }) protected noContract: NoContractModel[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean
  @Prop({ required: false, type: Boolean, default: false }) protected clickableCups: boolean



  /**
   * STATE
   */
  protected defaultOrder = { prop: null, order: null }
  protected showingMenuItem: NoContractModel = null


  /**
   * EVENTS
   */

  protected onCellMouseEnter(item: NoContractModel): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: NoContractModel): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected async onClickDownload(item: NoContractModel): Promise<void> {
    const fileDownloadUrl = item.url
    window.open(fileDownloadUrl, '_blank')
  }


  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return (this.loading)
      ? 'CARGANDO PS SIN CONTRATO...'
      : 'NO HAY PS SIN CONTRATO'
  }

  // /**
  //  * METHODS
  //  */

  // protected checkShowOptions(process: NoContractModel): boolean {
  //   return !!this.showingMenuItem && this.showingMenuItem.id === process.id
  // }

}