import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { NoContractPages } from '../../router'


@Component
export default class NoContract extends Vue {


  /**
   * STORE
   */

  @MapAction(Store.NoContract) private processUrlQueryParams: (queryParams: any) => Promise<any>
  @MapAction(Store.NoContract) private newPageSize: (pageSize: number) => number
  @MapAction(Store.NoContract) private obtainNoContracts: () => Promise<any>


  @MapGetter(Store.NoContract) protected listLoading: boolean
  @MapGetter(Store.NoContract) protected pageSize: number
  @MapGetter(Store.NoContract) protected totalElements: number
  @MapGetter(Store.NoContract) protected noContractsList: any[]



  /**
   * METHODS
   */
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' } })
    }
  }

  protected onClickCreateA6161() {
    this.$router.push({ name: NoContractPages.Create })
  }

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    await this.obtainNoContracts()
  }

  /**
   * WATCHERS
   */

  @Watch('$route.query', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    await this.processUrlQueryParams(this.$route.query)
  }
}