import _ from 'lodash';
import moment from 'moment';
import { Component, Filter, Vue } from 'types-vue';

@Component
export class DatesMixin extends Vue {

  protected getLocalDate(date: Date | number | string = new Date()): moment.Moment {
    const utcOffset = moment().utcOffset()
    return moment(date).add(utcOffset , 'minute')
  }
}