import _, { forEach } from 'lodash'
import { Component, MapAction, MapGetter, mixins, Vue, Watch } from 'types-vue'
import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import UserGroup from '@/Modules/Auth/models'

@Component
export default class Alerts extends mixins(PermissionMixin) {
  alertsToRender = {}

  @MapGetter(Store.Signin) protected username: any
  @MapGetter(Store.UserGroups) protected userGroupList: any
  @MapGetter(Store.UserGroups) protected systemSubject: any
  @MapGetter(Store.Alerts) protected validSubjects: any
  @MapGetter(Store.Alerts) protected alertsLoading: boolean
  @MapGetter(Store.Alerts) protected alerts: any
  @MapGetter(Store.Alerts) protected subject: any
  @MapGetter(Store.Alerts) protected configuration: any

  @MapAction(Store.Alerts) protected updateSubject: (subject: any) => void
  @MapAction(Store.Alerts) protected obtainAlerts: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsAdmin: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsAdminFiltered: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsSummary: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsConfiguration: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsConfigurationAdminEmail: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainValidSubjects: () => Promise<void>
  @MapAction(Store.UserGroups) protected getListCurrentUserGroups: () => Promise<any>
  @MapAction(Store.UserGroups) protected changeGroup: (payload: {
    group: UserGroup
    reload: boolean
  }) => Promise<any>

  protected isIterable(obj) {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function'
  }

  @Watch('alerts')
  protected async onChangeAlerts() {
    const currentAlertsConfig = {}

    const config = this.isIterable(this.configuration)
      ? this.configuration
      : [{ marketer: this.subject.code, alertConfiguration: { ...this.configuration } }]

    _.forEach(config, (value) => {
      const processes = {}

      _.forEach(value.alertConfiguration, (va, ke) => {
        const activatedAlerts = []
        if (ke !== 'sendEmail') {
          _.forEach(va, (v, k) => {
            if (!!v || k === 'delayedByDays') {
              activatedAlerts.push(k)
            }
          })
          if (!!activatedAlerts.length) {
            processes[ke] = { ...activatedAlerts }
          }
        }
        if (!!Object.keys(processes).length) {
          currentAlertsConfig[value.marketer] = { ...processes }
        }
      })
    })

    const alerts = this.isIterable(this.alerts) ? [...this.alerts] : [this.alerts]
    const filteredAlerts = {}
    const subjectsWithConfig = Object.keys(currentAlertsConfig)
    const alertsSubjects = alerts.map((el) => el.marketerCode)

    alerts.forEach((alert) => {
      const activatedAlerts = {}
      if (subjectsWithConfig.includes(alert.marketerCode)) {
        _.forEach(alert.information, (value, key) => {
          if (
            !!currentAlertsConfig[alert.marketerCode] &&
            !!currentAlertsConfig[alert.marketerCode][key]
          ) {
            const keys = _.map(currentAlertsConfig[alert.marketerCode][key], (el) => el)
            _.forEach(value, (va, ke) => {
              if (keys.includes(ke) && typeof va === 'number' && va > 0) {
                activatedAlerts[key] = { ...activatedAlerts[key], [ke]: va }
              }
              if (ke === 'delayedByDays' && !!va) {
                if (!_.every(va, (el) => el === 0)) {
                  activatedAlerts[key] = { ...activatedAlerts[key], [ke]: va }
                }
              }
            })
          }
          if (!!Object.keys(activatedAlerts).length) {
            const sub = this.validSubjects.find((s) => s.code === alert.marketerCode)
            filteredAlerts[`${sub.code} - ${sub.name}`] = { ...activatedAlerts }
          } else {
            const sub = this.validSubjects.find((s) => s.code === alert.marketerCode)
            filteredAlerts[`${sub.code} - ${sub.name}`] = {}
          }
        })
      }
    })

    subjectsWithConfig.forEach((el) => {
      if (!alertsSubjects.includes(el)) {
        const sub = this.validSubjects.find((s) => s.code === el)
        filteredAlerts[`${sub.code} - ${sub.name}`] = {}
      }
    })
    this.alertsToRender = { ...filteredAlerts }
  }

  @Watch('subject')
  protected async onChangeSubject() {
    if (this.isMarketerRol) {
      await this.obtainAlertsConfiguration()
      this.obtainAlerts()
    } else {
      await this.obtainAlertsConfigurationAdminEmail()
      this.obtainAlertsAdminFiltered()
    }
  }

  async created() {
    await this.obtainValidSubjects()

    this.updateSubject({
      name: this.systemSubject.description,
      code: this.systemSubject.code,
      email: this.username
    })
  }
}
