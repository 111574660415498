import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { BillMastersApi } from '../connections/api/BillMasters'
import { BillsApi } from '../connections/api/Bills'
import { Bill, BillConcept } from '../models'


@Module({ namespaced: true })
export default class BillDetails extends VuexModule {
  
  private _bill: Bill = null
  private _loading: boolean = false
  private _error: string = null

  
  @Getter() public bill(): Bill { return this._bill }
  @Getter() public loading(): boolean { return this._loading }
  @Getter() public error(): string { return this._error }
  @Getter() public concepts(): BillConcept[] { return this._bill.concepts }


  @Mutation() protected setBill(bill: Bill) { this._bill = bill }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setError(value: string) { this._error = value }


  @Action({ useContext: true })
  protected async initDetails(ctx: ActionContext<any, any>, id: number): Promise<any> {
    
    try {
      ctx.commit('setLoading', true)
      const response = await BillsApi.getFlowBills({ id })
      const noHaveResult = _.isEmpty(response.data)
      const bill = response.data[0]
      
      if (noHaveResult) return Notification({
        type: 'error',
        title: 'Error',
        message: 'No se ha encontrado ninguna factura.'
      })

      const processTypeResponse = await BillMastersApi.getProcessType(bill.processCode)
      bill.processMaster = processTypeResponse.data[0]
      
      ctx.commit('setBill', bill)
      
    } catch (err) {
      const axiosError = err as AxiosError
      
      if (axiosError.response.data.code === '16') {
        ctx.commit('setError', axiosError.response.data.message)
      } else {
        Notification.error({ title: `Error ${axiosError.response.status}`, message: 'Error al obtener los datos'})
      }
    } finally {
      ctx.commit('setLoading', false)
    }

  }
  
  @Action({ useContext: true })
  protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setBill', null)
    ctx.commit('setError', null)
  }
}
