var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "Editar festivo", visible: _vm.showing, width: "35%" },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _vm.showing
        ? _c("Holidays-Form", {
            attrs: {
              value: _vm.holiday,
              loading: _vm.loading,
              "disabled-date": ""
            },
            on: { submit: _vm.onSubmit }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }