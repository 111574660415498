var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Forms-Form",
        {
          attrs: { options: _vm.formOptions },
          on: { submit: _vm.onSubmit, change: _vm.onChange }
        },
        [_c("template", { slot: "buttons" }, [_vm._t("default")], 2)],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }