import Axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios'
import { getApiUrlWithCookieVersion } from '../Utils'

export enum Method {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export default abstract class AbstractApi {
  protected static readonly axios: AxiosInstance = Axios.create()
  protected static readonly headers: any = {}

  public static setAuthorization(token: string) {
    this.headers.Authorization = `Bearer ${token}`
  }

  protected request(
    config: AxiosRequestConfig,
    withoutAuthorization: boolean = false
  ): AxiosPromise<any> {
    return AbstractApi.axios({
      ...config,
      url: getApiUrlWithCookieVersion(config.url),
      headers: !withoutAuthorization
        ? { ...AbstractApi.headers, ...config.headers }
        : config.headers
    })
  }
}
