var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "signin-page" } },
    [
      _c("h2", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "auth signin.title",
            expression: "'auth signin.title'"
          }
        ]
      }),
      _c("Auth-SigninForm", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.onSubmit, forgotPassword: _vm.onForgotPassword }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }