var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bill
    ? _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "item date" }, [
          _vm._v("Creado el " + _vm._s(_vm.date))
        ]),
        _vm.bill.downloaded
          ? _c(
              "div",
              { staticClass: "item date" },
              [
                _c("el-alert", {
                  attrs: {
                    title: "Ya descargado",
                    type: "info",
                    closable: false,
                    description: "El fichero ha sido previamente descargado",
                    "show-icon": ""
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "file-options" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text", icon: "el-icon-download" },
                on: { click: _vm.downloadBill }
              },
              [_vm._v("Descargar fichero ")]
            ),
            _vm.isAdminPermission
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "text", icon: "el-icon-edit" },
                    on: { click: _vm.goToEdit }
                  },
                  [_vm._v("Editar")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }