import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Main from './pages/Main/Main.vue'
import Bill from './pages/Bill/Bill.vue'

export enum BillsPages {
  Main = 'bills-main',
  Bill = 'bills-details'
}

const routes: Array<RouteConfig> = [
  {
    path: '/bills',
    component: Page,
    children: [
      {
        path: '',
        name: BillsPages.Main,
        meta: { menuActive: 'bills' },
        component: Main
      },
      {
        path: ':id',
        name: BillsPages.Bill,
        meta: { menuActive: 'bills' },
        component: Bill
      }
    ]
  }
]

export default routes