var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.formModel,
            rules: _vm.rules,
            "hide-required-asterisk": _vm.hideRequiredAsterisk
          }
        },
        [
          _vm._l(_vm.items, function(itemConfig, index) {
            return [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: itemConfig.visible,
                      expression: "itemConfig.visible"
                    }
                  ],
                  key: index,
                  attrs: { prop: itemConfig.name, label: itemConfig.label }
                },
                [
                  _c(_vm.getComponent(itemConfig), {
                    tag: "component",
                    attrs: { config: itemConfig },
                    on: { change: _vm.onChangeItem }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "buttons-container" },
        [
          _vm._t("buttons"),
          !_vm.hideSubmit
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.loading,
                    disabled: _vm.disabled
                  },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v(_vm._s(_vm.submitButtonLabel))]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }