import { Component, Vue, Watch } from 'types-vue'
import { QueryParams } from '../../../definitions/QueryParams'

@Component
export default class SortSelect extends Vue {

  protected value = 0

  protected options = [
    { label: 'Los más recientes', orderDirection: 'DESC', orderProp: 'billDate' },
    { label: 'Los más antiguos', orderDirection: 'ASC', orderProp: 'billDate' },
    { label: 'Los CUPS más altos', orderDirection: 'ASC', orderProp: 'cups' },
    { label: 'Los CUPS más bajos', orderDirection: 'DESC', orderProp: 'cups' },
  ]


  @Watch('value')
  protected onChange() {
    const name = this.$route.name
    const query = { 
      ...this.$route.query,
      [QueryParams.OrderDirection]: this.options[this.value].orderDirection,
      [QueryParams.OrderProp]: this.options[this.value].orderProp
    }

    this.$router.push({ name, query })
  }

  protected created() {
    const orderDirection = this.$route.query[QueryParams.OrderDirection]
    const orderProp = this.$route.query[QueryParams.OrderProp]
    const hasOrderDirection = !!orderDirection
    const hasOrderProp = !!orderProp

    if (hasOrderDirection && hasOrderProp) {
      this.value = this.options.findIndex(item => item.orderDirection === orderDirection && item.orderProp === orderProp )
    } else {
      this.onChange()
    }
  }
}