import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import { ActionContext } from 'vuex'
import _, { omitBy, isNil, map } from 'lodash'
import { GetAllUsersResponse, User, UserFormModel, UsersFilters } from '../models'
import { UsersApi } from '../connection/api/Users'
import moment from 'moment'

@Module({ namespaced: true })
export default class Users extends VuexModule {
  protected _usersList: User[] = []
  protected _nextToken: string = null
  protected _loading: boolean = true
  protected _restorePasswordLoading: boolean = false
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _pageSize: number = 0

  private _sortBy: string = null
  private _sortDir: string = null

  protected _emailsFilter: string = null
  protected _groupsFilter: string = null
  protected _rolFilter: string = null
  protected _permissionFilter: string = null

  @Getter() public restorePasswordLoading(): boolean {
    return this._restorePasswordLoading
  }

  @Getter() public sortBy(): string {
    return this._sortBy
  }

  @Getter() public sortDir(): string {
    return this._sortDir
  }

  @Getter() protected usersList(): User[] {
    return this._usersList
  }

  @Getter() public currentPage(): number {
    return this._currentPage
  }

  @Getter() public totalElements(): number {
    return this._totalElements
  }

  @Getter() public pageSize(): number {
    return this._pageSize
  }

  @Getter() protected loading(): boolean {
    return this._loading
  }

  @Getter() protected nextToken(): string {
    return this._nextToken
  }

  @Getter() public allProcessFilters(): UsersFilters {
    const filters = {
      emails: this._emailsFilter,
      groups: this._groupsFilter,
      rol: this._rolFilter,
      permission: this._permissionFilter
    }

    return omitBy(filters, isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!isNil(this._emailsFilter)) result++
    if (!isNil(this._groupsFilter)) result++
    if (!isNil(this._rolFilter)) result++
    if (!isNil(this._permissionFilter)) result++

    return result
  }

  @Mutation() protected setRestorePasswordLoading(value: boolean) {
    this._restorePasswordLoading = value
  }

  @Mutation() protected setSortBy(value: string) {
    this._sortBy = value
  }

  @Mutation() protected setSortDir(value: string) {
    this._sortDir = value
  }

  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }

  @Mutation()
  protected setUsersListResponse(response: GetAllUsersResponse) {
    const chunkSize = this._pageSize
    const arr = [...response.users]
    const groups = arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null
      })
      .filter(e => {
        return e
      })

    const currentPage = groups.length > 1 ? this._currentPage - 1 : 0
    this._usersList = groups[currentPage]
    this._nextToken = response.paginationToken
    this._loading = false
    this._totalElements = response.totalUsers
  }

  @Mutation()
  protected cleanList() {
    this._usersList = []
    this._nextToken = null
    this._loading = true
  }

  @Mutation()
  protected updateUserInList(userForm: UserFormModel) {
    const userIndex = _.findIndex(this._usersList, { email: userForm.email })
    const user = this._usersList[userIndex]
    const isNewUser = _.isNil(user)
    const newUser: User = {
      ...user,
      email: userForm.email,
      enabled: userForm.enabled,
      givenName: userForm.givenName,
      familyName: userForm.familyName,
      username: userForm.email,
      lastModifiedDate: moment().toString(),
      clientMetadata: {
        userRol: userForm.userRol,
        userPermissions: userForm.userPermissions,
        userGroups: userForm.groupName,
        marketer: userForm.marketer
      }
    }

    if (isNewUser) this._usersList.push(newUser)
    else Vue.set(this._usersList, userIndex, newUser)
  }

  @Mutation()
  protected removeUserInList(userEmail: string) {
    const userIndex = _.findIndex(this._usersList, { email: userEmail })
    _.pullAt(this._usersList, [userIndex])
  }

  @Mutation()
  protected setEmailsFilter(value: string) {
    const hasChanged = value !== this._emailsFilter
    this._emailsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setGroupsFilter(value: string) {
    const hasChanged = value !== this._groupsFilter

    this._groupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setRolFilter(value: string) {
    const hasChanged = value !== this._rolFilter

    this._rolFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setPermissionFilter(value: string) {
    const hasChanged = value !== this._emailsFilter

    this._permissionFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = isEmpty ? 1 : parseInt(value)
  }

  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  @Action({ useContext: true })
  protected async processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setEmailsFilter', queryParams.emails)
    ctx.commit('setGroupsFilter', queryParams.groups)
    ctx.commit('setRolFilter', queryParams.rol)
    ctx.commit('setPermissionFilter', queryParams.permission)
    ctx.commit('setSortBy', queryParams.sortBy)
    ctx.commit('setSortDir', queryParams.sortDir)
    ctx.commit('setCurrentPage', queryParams.page)
  }

  @Action({ commit: 'setUsersListResponse', useContext: true })
  protected async obtainUsers(ctx: ActionContext<any, any>): Promise<GetAllUsersResponse> {
    ctx.commit('setLoading', true)

    const paginationToken = ctx.getters.nextToken
    const filters = ctx.getters.allProcessFilters
    const sortBy = ctx.getters.sortBy || 'email'
    const sortDir = ctx.getters.sortDir || 'ASC'
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize

    if (!Array.isArray(filters.emails) && !!filters.emails) {
      filters.emails = filters.emails.split(',')
    }

    if (!Array.isArray(filters.groups) && !!filters.groups) {
      filters.groups = filters.groups.split(',')
    }

    const response = _.isNil(paginationToken)
      ? await UsersApi.getAll({
          ...filters,
          sortBy,
          sortDir,
          pageNumber,
          pageSize
        })
      : await UsersApi.getAll({
          ...filters,
          sortBy,
          sortDir,
          pageNumber,
          pageSize,
          paginationToken
        })

    return response.data
  }

  @Action({ useContext: true })
  protected async createUser(ctx: ActionContext<any, any>, user: UserFormModel) {
    try {
      await UsersApi.signup({
        email: user.email,
        clientMetadata: {
          userPermissions: user.userPermissions,
          userRol: user.userRol,
          userGroups: user.groupName,
          marketer: user.marketer
        },
        userAttributes: {
          familyName: user.familyName,
          givenName: user.givenName,
          activeMarketer: user.groupName[0]
        }
      })

      ctx.commit('updateUserInList', user)
    } catch (error) {}
  }

  @Action({ useContext: true })
  protected async updateUser(ctx: ActionContext<any, any>, user: UserFormModel) {
    try {
      const result = await UsersApi.updateUser({
        email: user.email,
        clientMetadata: {
          userPermissions: user.userPermissions,
          userRol: user.userRol,
          userGroups: user.groupName,
          marketer: user.marketer
        },
        userAttributes: {
          familyName: user.familyName,
          givenName: user.givenName,
          activeMarketer: user.groupName[0]
        }
      })

      ctx.commit('updateUserInList', user)
    } catch (error) {}
  }

  @Action({ useContext: true })
  protected async deleteUser(ctx: ActionContext<any, any>, user: User) {
    try {
      await UsersApi.deleteUser(user.email)
      ctx.commit('removeUserInList', user.email)
    } catch (error) {}
  }

  @Action({ useContext: true })
  protected async cleanUserList(ctx: ActionContext<any, any>, user: UserFormModel) {
    ctx.commit('cleanList')
  }
  @Action()
  protected async downloadUserList() {
    return await UsersApi.downloadUserList()
  }

  @Action()
  protected restoreUserPassword(userEmail: string) {
    return UsersApi.restoreUserPassword(userEmail)
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainUsers')
  }

  @Action({ useContext: true })
  protected updateRestorePasswordLoading(ctx: ActionContext<any, any>, loading: boolean) {
    ctx.commit('setRestorePasswordLoading', loading)
  }
}
