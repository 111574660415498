import { Component, MapAction, MapGetter, Vue } from 'types-vue';
import _ from 'lodash'
import { Store } from '@/Core/Models/Stores';
import { SystemSubject } from '../models';

@Component
export default class ObtainSystemSubjectInfoMixin extends Vue {

  @MapGetter(Store.UserGroups) private systemSubject: SystemSubject
  @MapAction(Store.UserGroups) private obtainSystemSubjectInfo: () => void

  
  private async saveSystemSubjectInfo() {
    if (!_.isEmpty(this.systemSubject)) return
    await this.obtainSystemSubjectInfo()
  }

  
  created() {
    this.saveSystemSubjectInfo()
  }
}