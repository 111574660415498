import { checkTokenExpired } from '@/Core/Utils'
import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { NoContractModel } from '../models'
import { QueryParams } from '../definitions/QueryParams'
import NoContractApi from '../connections/api/NoContractApi'
import { CreateA6161Response, NoContractListResponse } from '../models/api'


@Module({ namespaced: true })
export default class CreateA6161 extends VuexModule {

  private _validationErrors: string[] = null
  private _validationResult: boolean = null
  private _presignedUrl: string = null
  private _loading: boolean = true




  @Getter() public validationErrors(): string[] { return this._validationErrors }
  @Getter() public validationResult(): boolean { return this._validationResult }
  @Getter() public presignedUrl(): string { return this._presignedUrl }
  @Getter() public loading(): boolean { return this._loading }
  

  @Mutation() public setPresignedUrl(value: string): void { this._presignedUrl = value }
  @Mutation() public setLoading(value: boolean): void { this._loading = value }
  

  @Mutation() public setUploadResult(data: CreateA6161Response): void { 
    this._presignedUrl = data.presignedURL
    this._validationErrors = data.validationResponse.body.error
    this._validationResult = data.validationResponse.body.result
  }


  @Action({ useContext: true })
  protected async sendForm(ctx: ActionContext<any, any>, formModel: any) {
    try {
      ctx.commit('setLoading', true)

      const response = await NoContractApi.createA6161({
        comcompany: formModel.marketer,
        discompany: formModel.distributor,
        cups: formModel.cups,
        availabledateini: formModel.dateCupsEnabled[0],
        availabledateend: formModel.dateCupsEnabled[1],
        province: formModel.province,
        city: '',
        zipcode: formModel.postalCode,
        streettype: formModel.streetType,
        street: formModel.streetName,
        streetnumber: formModel.streetNumber,
        restdirection: formModel.moreDirection,
        origenps: formModel.psOrigin,
        infraestructurestatus: formModel.infrastructureStatus,
        contractstatus: formModel.contractingStatus,
        includefreeps: ''
      })

      ctx.commit('setUploadResult', response.data)

    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al generar el fichero'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }
}
