import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import {
  AuthChallengeRequest,
  GetListCurrentUserGroupsResponse,
  RefreshSessionRequest,
  RefreshSessionResponse,
  SecretCodeRequest,
  SwitchCurrentUserGroupRequest,
  VerifyTokenRequest
} from '../../models/connections/api'
import ChangePasswordBody from '../../models/connections/api/ChangePasswordBody'
import ConfirmPasswordBody from '../../models/connections/api/ConfirmPasswordBody'
import ForgotPasswordBody from '../../models/connections/api/ForgotPasswordBody'
import SigninBody from '../../models/connections/api/SigninBody'
import SigninResponse from '../../models/connections/api/SigninResponse'

const AUTH_SERVICE_URL_BASE = process.env.VUE_APP_AUTH_URL
const Path = {
  Signin: `${AUTH_SERVICE_URL_BASE}/signIn`,
  ChangePassword: `${AUTH_SERVICE_URL_BASE}/changePassword`,
  ForgotPassword: `${AUTH_SERVICE_URL_BASE}/forgotPassword`,
  ConfirmPassword: `${AUTH_SERVICE_URL_BASE}/confirmPassword`,
  ListCurrentUserGroups: `${AUTH_SERVICE_URL_BASE}/listCurrentUserGroups`,
  SwitchCurrentUserGroup: `${AUTH_SERVICE_URL_BASE}/switchCurrentUserGroup`,
  RefreshSession: `${AUTH_SERVICE_URL_BASE}/refreshSession`,
  AssociateToken: `${AUTH_SERVICE_URL_BASE}/associateToken`,
  VerifyToken: `${AUTH_SERVICE_URL_BASE}/verifyToken`,
  AuthChallenge: `${AUTH_SERVICE_URL_BASE}/respondToAuthChallenge`
}

class AuthApiConnection extends AbstractApi {
  public async signin(data: SigninBody): Promise<SigninResponse> {
    const result = await this.request({
      method: Method.POST,
      url: Path.Signin,
      data
    })

    return result.data
  }

  public changePassword(data: ChangePasswordBody) {
    return this.request({
      method: Method.POST,
      url: Path.ChangePassword,
      data
    })
  }

  public forgotPassword(data: ForgotPasswordBody) {
    return this.request({
      method: Method.POST,
      url: Path.ForgotPassword,
      data
    })
  }

  public confirmPassword(data: ConfirmPasswordBody) {
    return this.request({
      method: Method.POST,
      url: Path.ConfirmPassword,
      data
    })
  }

  public getListCurrentUserGroups(): AxiosPromise<GetListCurrentUserGroupsResponse> {
    return this.request({
      method: Method.POST,
      url: Path.ListCurrentUserGroups
    })
  }

  public switchCurrentUserGroup(data: SwitchCurrentUserGroupRequest): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: Path.SwitchCurrentUserGroup,
      data
    })
  }

  public refreshSession(data: RefreshSessionRequest): AxiosPromise<RefreshSessionResponse> {
    return this.request({
      method: Method.POST,
      url: Path.RefreshSession,
      data
    })
  }

  public associateToken(data: SecretCodeRequest): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: Path.AssociateToken,
      data
    })
  }

  public verifyToken(data: VerifyTokenRequest): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: Path.VerifyToken,
      data
    })
  }

  public respondToAuthChallenge(data: AuthChallengeRequest): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: Path.AuthChallenge,
      data
    })
  }
}

export default new AuthApiConnection()
