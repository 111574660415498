var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "Bucket de entrada", prop: "inputFromSapBucket" }
            },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                model: {
                  value: _vm.form.inputFromSapBucket,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "inputFromSapBucket", $$v)
                  },
                  expression: "form.inputFromSapBucket"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Bucket de salida", prop: "outputToSapBucket" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                model: {
                  value: _vm.form.outputToSapBucket,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "outputToSapBucket", $$v)
                  },
                  expression: "form.outputToSapBucket"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "isActive" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { size: "small", disabled: _vm.loading },
                  model: {
                    value: _vm.form.isActive,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "isActive", $$v)
                    },
                    expression: "form.isActive"
                  }
                },
                [_vm._v("Activo")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          size: "medium"
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.submitButtonText))]
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }