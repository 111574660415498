var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "title-section" },
      [
        _c("Core-Title", { attrs: { loading: _vm.detailsLoading } }, [
          _c("h2", [_vm._v("Detalles producto")])
        ]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", round: "", size: "small" },
            on: { click: _vm.onClickReturn }
          },
          [_vm._v("Volver")]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.detailsLoading,
            expression: "detailsLoading"
          }
        ]
      },
      [
        _c("AtrContracts-Header", {
          attrs: {
            cupsId: _vm.details.cupsId,
            totalProducts:
              _vm.details && _vm.details.data && _vm.details.data.length
          }
        }),
        _c(
          "el-card",
          [
            _c(
              "el-table",
              {
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.details.data }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "active", width: "40" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: scope.row.active
                                  ? "Activo"
                                  : "Inactivo",
                                placement: "top"
                              }
                            },
                            [
                              _c("div", {
                                staticClass: "circle",
                                class: { enabled: scope.row.active }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "TIPO CONTRATO", prop: "type", width: "200" }
                }),
                _c("el-table-column", {
                  attrs: { label: "TIPO PEAJE", prop: "tolltype", width: "200" }
                }),
                true
                  ? _c("el-table-column", {
                      attrs: {
                        label: "FECHA INICIO",
                        prop: "startDate",
                        width: "190"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("Core-TimeColumn", {
                                  attrs: { time: scope.row.startDate }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2049535613
                      )
                    })
                  : _vm._e(),
                true
                  ? _c("el-table-column", {
                      attrs: {
                        label: "FECHA FIN",
                        prop: "endDate",
                        width: "190"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("Core-TimeColumn", {
                                  attrs: { time: scope.row.endDate }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4190846194
                      )
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: { label: "CAUDAL ANUAL", prop: "qa" }
                }),
                _c("el-table-column", {
                  attrs: { label: "CAUDAL DIARIO", prop: "qd" }
                }),
                _c("el-table-column", {
                  attrs: { label: "CAUDAL HORARIO", prop: "qh" }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }