import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import { FilteredAtrContractsRequest, FilteredAtrContractsResponse } from '../../models/api'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Filtered: `${CORE_SERVICE_URL_BASE}/atr-contracts/filtered`,
  Details: `${CORE_SERVICE_URL_BASE}/products`
}

class AtrContractsSingleton extends AbstractApi {
  private static _instance: AtrContractsSingleton
  private constructor() {
    super()
  }

  public static get instance(): AtrContractsSingleton {
    if (!AtrContractsSingleton._instance)
      AtrContractsSingleton._instance = new AtrContractsSingleton()
    return AtrContractsSingleton._instance
  }

  public getFiltered(
    params: FilteredAtrContractsRequest = { pageNumber: 1 }
  ): AxiosPromise<FilteredAtrContractsResponse> {
    const method = Method.GET
    const url = Path.Filtered

    return this.request({ method, url, params })
  }

  public getDetails(params: any): AxiosPromise<any> {
    const method = Method.GET
    const url = Path.Details

    return this.request({ method, url, params })
  }
}

export const AtrContractsApi = AtrContractsSingleton.instance
