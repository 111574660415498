import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import SigninFormUser from '../models/SigninFormUser'
import { ActionContext } from 'vuex'
import AuthApiConnection from '../connections/api/Auth'
import { SessionVariables } from '@/Core/enum/SessionVariables'
import SigninResponse from '../models/connections/api/SigninResponse'
import AbstractApi from '@/Core/api/AbstractApi'
import router from '@/Core/router'
import { AuthPages } from '../router'
import _ from 'lodash'
import { AuthMastersApi } from '../connections/api/AuthMasters'
import { decode } from 'jsonwebtoken'
import { SystemSubject } from '../models'

@Module({ namespaced: true })
export default class Signin extends VuexModule {
  protected _signinResult: SigninResponse = null
  protected _username: string = null
  protected _password: string = null
  protected _secretCode: string = null
  protected _associateToken: string = null
  protected _loading: boolean = false

  @Getter() protected signinResult(): SigninResponse {
    return this._signinResult
  }
  @Getter() protected username(): string {
    return this._username
  }
  @Getter() protected password(): string {
    return this._password
  }
  @Getter() protected secretCode(): string {
    return this._secretCode
  }

  @Getter() protected loading(): boolean {
    return this._loading
  }

  @Mutation() protected setUsername(username: string) {
    this._username = username
  }
  @Mutation() protected setPassword(password: string) {
    this._password = password
  }
  @Mutation() protected setSigninResult({
    result,
    username
  }: {
    result: SigninResponse
    username: string
  }) {
    this._signinResult = result
    this._username = username
  }

  @Mutation() protected setSecretCode(secretCode: string) {
    this._secretCode = secretCode
  }

  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }

  @Action({ useContext: true })
  protected async signin(ctx: ActionContext<any, any>, formUser: SigninFormUser): Promise<any> {
    const callbackURL = sessionStorage.getItem(SessionVariables.LoginCallbackURL) || '/'
    const result = await AuthApiConnection.signin({
      email: formUser.username,
      password: formUser.password
    })

    if (result.newPasswordRequired) {
      ctx.commit('setUsername', formUser.username)
      ctx.commit('setPassword', formUser.password)
      return router.push({ name: AuthPages.ChangePassword })
    }

    // sessionStorage.removeItem(SessionVariables.LoginCallbackURL)
    // sessionStorage.setItem(SessionVariables.Auth, result.id_token)
    // sessionStorage.setItem(SessionVariables.Refresh, result.refresh_token)
    ctx.commit('setSigninResult', { username: formUser.username, result })

    if (result.mfaActive) {
      sessionStorage.setItem(SessionVariables.Session, result.session)
      return router.push({ name: AuthPages.Mfa })
    }

    AbstractApi.setAuthorization(result.id_token)
    // ctx.commit('setPassword', null)
    // ctx.commit('setSigninResult', { username: formUser.username, result })
    // router.push({ name: AuthPages.SelectGroup })
    router.push({ name: AuthPages.MfaConfig })
  }

  @Action({ useContext: true })
  protected newUsername(ctx: ActionContext<any, any>, username: string): void {
    if (!_.isNil(username)) ctx.commit('setUsername', username)
  }

  @Action({ useContext: true })
  protected async associateToken(ctx: ActionContext<any, any>): Promise<any> {
    ctx.commit('setLoading', true)
    const accessToken = ctx.getters.signinResult.access_token
    try {
      const result = await AuthApiConnection.associateToken({
        accessToken
      })
      ctx.commit('setSecretCode', result.data.secretCode)
      ctx.commit('setLoading', false)
      return result
    } catch (err) {
      ctx.commit('setLoading', false)
      return err
    }
  }

  @Action({ useContext: true })
  protected async verifyToken(ctx: ActionContext<any, any>, userCode: string): Promise<any> {
    ctx.commit('setLoading', true)
    const accessToken = ctx.getters.signinResult.access_token
    try {
      const result = await AuthApiConnection.verifyToken({
        accessToken,
        userCode
      })
      sessionStorage.removeItem(SessionVariables.LoginCallbackURL)
      sessionStorage.setItem(SessionVariables.Auth, ctx.getters.signinResult.id_token)
      sessionStorage.setItem(SessionVariables.Refresh, ctx.getters.signinResult.refresh_token)
      ctx.commit('setPassword', null)
      ctx.commit('setLoading', false)

      return result
    } catch (err) {
      ctx.commit('setLoading', false)
      return err
    }
  }

  @Action({ useContext: true })
  protected async respondToAuthChallenge(ctx: ActionContext<any, any>, code: string): Promise<any> {
    ctx.commit('setLoading', true)
    const session = sessionStorage.getItem(SessionVariables.Session)
    const username = ctx.getters.username

    try {
      const result = await AuthApiConnection.respondToAuthChallenge({
        code,
        username,
        session
      })

      const { idToken, refreshToken } = result.data.authenticationResult

      sessionStorage.removeItem(SessionVariables.LoginCallbackURL)
      sessionStorage.setItem(SessionVariables.Auth, idToken)
      sessionStorage.setItem(SessionVariables.Refresh, refreshToken)

      AbstractApi.setAuthorization(idToken)
      ctx.commit('setPassword', null)
      ctx.commit('setLoading', false)
      return result
    } catch (err) {
      ctx.commit('setLoading', false)
      return err
    }
  }
}
