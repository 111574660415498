export namespace QueryParams {
  export const Page = 'page'
  export const Cups = 'cups'
  export const ProcessType = 'processCodes'
  export const MarketerCode = 'marketerCode'
  export const ReferenceNumber = 'numreference'
  export const MessageType = 'messageTypeCode'
  export const ProcessReason = 'processReason'
  export const FromDate = 'fromDate'
  export const ToDate = 'toDate'
  export const FromBillDate = 'fromBillDate'
  export const ToBillDate = 'toBillDate'
  export const BillType = 'billTypeCode'
  export const BillNumber = 'billNum'
  export const PseudoBillNumber = 'pseudoBillNum'
}
