var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            "empty-text": _vm.emptyText,
            data: _vm.metrics,
            "row-class-name": "cursor-pointer"
          },
          on: { "row-click": _vm.onClickRow }
        },
        [
          _c("el-table-column", {
            attrs: { label: "PROCESO" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("Processes-ProcessTypeColumn", {
                      attrs: { data: scope.row, "without-tooltip": "" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "CARGADOS", prop: "accepted" }
          }),
          _c("el-table-column", {
            attrs: { label: "ACEPTADOS", prop: "completed" }
          }),
          _c("el-table-column", {
            attrs: { label: "RECHAZADOS", prop: "rejected" }
          }),
          _c("el-table-column", {
            attrs: { label: "PENDIENTES", prop: "pending" }
          }),
          _c("el-table-column", {
            attrs: { label: "CANCELADOS", prop: "cancelled" }
          }),
          _c("el-table-column", {
            attrs: { label: "ACTIVADOS", prop: "uploaded" }
          }),
          _c("el-table-column", {
            attrs: { label: "DÍAS RESPUESTA", prop: "responseTimeInDays" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterTimeInDays")(
                            scope.row.responseTimeInDays
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }