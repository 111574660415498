import { Component, MapGetter, Prop, mixins, MapAction } from 'types-vue'

import { FormConfig } from '@/Modules/Forms/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class CommonFiltersContainer extends mixins(PermissionMixin) {
  @Prop({ type: Boolean, default: false }) protected billing: boolean
  @Prop({ type: Object, required: true }) protected formOptions: FormConfig

  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean
  // @MapAction(Store.HistoryContracts) protected magic: (processCode: string) => void

  protected messageTypeWithinIntervalEmpty: boolean = true

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected onChange(form: any) {
    // if (form.processCodes) {
    //   this.magic(form.processCodes)
    // }
    const messageTypeWithinInterval = form.messageTypeWithinInterval
    this.messageTypeWithinIntervalEmpty = _.isEmpty(messageTypeWithinInterval)
  }
}
