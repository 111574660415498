import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { LopdItem } from '../../models'
import { LOPDPages } from '../../router'


@Component
export default class List extends Vue {


  /**
   * STORE
   */

  @MapAction(Store.LopdList) private processUrlQueryParams: (queryParams: any) => Promise<any>
  @MapAction(Store.LopdList) private newPageSize: (pageSize: number) => number
  @MapAction(Store.LopdList) private obtain: () => Promise<any>


  @MapGetter(Store.LopdList) protected listLoading: boolean
  @MapGetter(Store.LopdList) protected pageSize: number
  @MapGetter(Store.LopdList) protected totalElements: number
  @MapGetter(Store.LopdList) protected items: LopdItem[]



  /**
   * METHODS
   */
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' } })
    }
  }

  onClickCreateButton() {
    this.$router.push({ name: LOPDPages.Form })
  }

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    await this.obtain()
  }

  /**
   * WATCHERS
   */

  @Watch('$route.query', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    await this.processUrlQueryParams(this.$route.query)
  }
}