var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MatchMedia",
    {
      attrs: { query: "(max-width: 768px)" },
      on: { change: _vm.onMediaMobile }
    },
    [
      _vm.systemSubjectLoading
        ? _c("div", [
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: true,
                  expression: "true"
                }
              ],
              staticClass: "loading-section"
            })
          ])
        : _c("div", { attrs: { id: "generalPage" } }, [
            _c(
              "div",
              { staticClass: "page-header" },
              [
                _vm.showTestMessage ? _c("Core-TestMessage") : _vm._e(),
                _c("Core-Header", { on: { clickMenu: _vm.onClickMenu } })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "page-content" },
              [
                _c("Core-NavMenu", { staticClass: "nav-menu" }),
                _c(
                  "div",
                  {
                    staticClass: "router-view-content",
                    class: _vm.classRouterViewContent
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [_c("router-view")],
                      1
                    ),
                    _c("div", { staticClass: "footer" })
                  ]
                )
              ],
              1
            )
          ]),
      _c("Core-NetworkNotification", {
        directives: [
          { name: "network-notification", rawName: "v-network-notification" }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }