import { Store } from '@/Core/Models/Stores';
import { Bill } from '@/Modules/Bills/models';
import { FormConfig } from '@/Modules/Forms/models';
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class SelectedFilesDropdown extends Vue {


  @MapAction(Store.SelectedBills) protected downloadFiles: (payload: { messagesTypesCodes: string[], downloadAll?: boolean }) => void
  @MapAction(Store.SelectedBills) protected clean: () => void


  @MapGetter(Store.SelectedBills) protected selectedBills: Bill[]
  @MapGetter(Store.SelectedBills) protected loading: boolean
  @MapGetter(Store.Bills) protected totalElements: number


  protected isDownloadAllFiles = false

  protected async onSubmitDownloadForm() {
    const messagesTypesCodes = ['B70']
    const downloadAll = this.isDownloadAllFiles
    
    if (downloadAll) await this.$confirm(
      '¿Estás seguro de descargar todos los ficheros?',
      'Descargar todos los ficheros',
      {
        confirmButtonText: 'Descargar',
        cancelButtonText: 'Cancelar'
      }
    )

    const notify = await (this.$refs.notify as any).notify(`Creando el zip de descarga`) 

    try {
      await this.downloadFiles({ messagesTypesCodes , downloadAll})
      notify.success('Zip creado correctamente.')
    } catch (errorMessage) {
      notify.error(errorMessage)
    } finally {
      this.isDownloadAllFiles = false
    }
  }


  private async showDownloadFilesDialog() {
    this.onSubmitDownloadForm()
  }

  private async showDowloadAllFilesDialog() {
    this.isDownloadAllFiles = true
    this.onSubmitDownloadForm()
  }

  
  protected onCommandDropdown(comand: string) {
    switch (comand) {
      case 'download':
        this.showDownloadFilesDialog()
        break;
      case 'downloadAll':
        this.showDowloadAllFilesDialog()
        break;
    }
  }

  protected beforeDestroy() {
    this.clean()
  }
}