import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import CupsDetails from './pages/CupsDetails/CupsDetails.vue'

export enum CupsPages {
  Searched = 'searchedCups',
  Details = 'cupsDetails'
}

const routes: Array<RouteConfig> = [
  {
    path: '/cups',
    component: Page,
    children: [
      {
        path: ':id',
        name: CupsPages.Details,
        component: CupsDetails,
      }
    ]
  }
]

export default routes