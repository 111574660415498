import { Store } from '@/Core/Models/Stores'
import { AxiosError } from 'axios'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import { Marketer } from '../../models'

@Component
export default class MarketersList extends Vue {

  protected showingMenuItem: Marketer = null
  protected removingItemId: string = null


  @MapGetter(Store.Marketers) protected marketersList: Marketer
  @MapAction(Store.Marketers) protected removeMarketer: (marketer: Marketer) => Promise<void>

  protected checkShowOptions(item: Marketer): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.id === item.id
  }

  protected onCellMouseEnter(item: Marketer): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: Marketer): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected onRowClick(item: Marketer, column) {
    if (column.property === 'options') return
    this.$emit('edit', item)
  }


  protected async onClickDelete(item: Marketer) {
    try {
      await this.$confirm(`¿Estas seguro de eliminiar ${item.name}?`, `Confirmación`, { confirmButtonText: 'Eliminar' })
      try {
        this.removingItemId = item.id
        await this.removeMarketer(item)
        this.$notify({ type: 'success', title: 'Eliminado', message: `"${item.name}" eliminado correctamente`})
      } catch (err) {
        const error = err as AxiosError
        const messagaError = error?.response?.data?.message || 'Error al eliminar.'
        
        this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      } finally {
        this.removingItemId = null
      }
    } catch (error) {}
  }


  protected onClickEdit(marketer: Marketer) {
    this.$emit('edit', marketer)
  }

}