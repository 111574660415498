import { nFormatter } from '@/Core/Utils'
import { Cups } from '@/Modules/Cups/models'
import { ChartData, ChartOptions } from 'chart.js'
import _ from 'lodash'
import moment from 'moment'
import { Component, mixins, Prop, Vue } from 'types-vue'
import { Bar } from 'vue-chartjs'

@Component
export default class ConsumptionChart extends mixins(Bar) {

  @Prop({ required: true }) cups: Cups

  
  protected get consumption(): number[] { return this.cups?.consumptions.map(period => parseInt(period.consumo)/100 || 0) }
  protected get averageConsumption(): number[] {
    const averageClude = this.consumption.map((_value, index) => this.consumption.slice(0, index + 1))
    return averageClude.map(sum => _.mean(sum))
  }


  protected get chartData(): ChartData {
    const labels = this.cups.consumptions.map(consumption => moment(consumption.fechaInicio).format('YYYY/MM/DD'))
    // const labels = _.range(1, this.periods+1).map(number => `${number}`);

    return {
      labels,
      datasets: [
        {
          label: 'Consumo',
          data: this.consumption,
          backgroundColor: '#064789D9',
          order: 1
        },

        {
          label: 'Consumo medio',
          data: this.averageConsumption,
          borderColor: '#3CB535D9',
          backgroundColor: '#ffffff00',
          type: 'line',
          fill: true,
          cubicInterpolationMode: 'monotone',
          
          order: 0
        }
      ]
    }
  }

  protected get chartOptions(): ChartOptions {
    return {
      responsive: true,
      legend: {
        display: true,
      },
      plugins: {
        title: {
          display: false,
        }
      },
      scales: {
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'kWh',
          },
          ticks: {
            callback: function(label, index, labels) {
                return `${nFormatter(label, 2)}`
            }
          },
        }]  
      },
      tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                return `${new Intl.NumberFormat('es-ES').format(parseFloat(tooltipItem.yLabel as string))} kWh`
            }
        }
      }
    }
  }
  
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
}