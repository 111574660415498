import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, mixins, Vue } from 'types-vue'

@Component
export default class Button extends mixins(PermissionMixin) {
  protected visible: boolean = false

  protected onClickUploadButton() {
    this.visible = true
  }
}