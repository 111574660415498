import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'
import { QueryParams } from '../../definitions/QueryParams'

@Component
export default class Filters extends Vue {


  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'text',
          name: QueryParams.DocumentNum,
          label: 'Num. documento titular',
          default: this.$route.query[QueryParams.DocumentNum]
        },
        {
          type: 'select',
          name: QueryParams.Active,
          label: 'Estado del contrato',
          default: this.$route.query[QueryParams.Active],
          options: [
            { label: 'Contratos activos',     value: '1' },
            { label: 'Contratos inactivos',   value: '0' },
          ],
          clearable: true
        },
        { 
          type: 'tag',
          name: QueryParams.Cups,
          label: 'Cups',
          default: (this.$route.query[QueryParams.Cups] as string)?.split(',') || []
        },
        { 
          type: 'date',
          name: QueryParams.FromDate,
          label: 'Fecha inicio',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.FromDate]
        },
        { 
          type: 'date',
          name: QueryParams.ToDate,
          label: 'Fecha fin',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.ToDate]
        }
      ]
    }
  }
}