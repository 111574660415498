import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, mixins, Prop, Vue, Watch } from 'types-vue'

@Component
export default class DefaultForm extends mixins(FiltersMixin) {


  /**
   * PROPS
   */

  @Prop({ default: 'Submit', type: String, }) protected submitLabel: string
  @Prop({ type: String, }) protected cancelLabel: string
  @Prop({ type: String, }) protected removeLabel: string
  @Prop({ type: Boolean, default: false }) protected loading: boolean
  @Prop() protected defaultValues: any



  /**
   * STATE
   */

  protected form = {}



  /**
   * STORE
   */

  @MapGetter(Store.Master)
  protected propertiesForForm: string[]


  
  /**
   * METHODS
   */

  private cleanForm() {
    Object.keys(this.form).forEach(key => {
      this.form[key] = ''
    })
  }


  protected isDate(label: string, value: any): boolean {
    const containDate = label.toLowerCase().includes('date')
    // const valueIsValidDate = moment(value).isValid()      he quitado esto ya que el id lo detecta como una fecha
    
    return containDate //|| valueIsValidDate
  }



  /**
   * EVENTS
   */

  protected onClickSubmit() {
    this.$emit('submit', this.form)
    this.cleanForm()
  }
  
  protected onClickCancel() {
    this.$emit('cancel', this.form)
  }

  protected onClickRemove() {
    this.$emit('remove', this.form)
  }

  protected onChangeDate(prop: string) {
    this.form[prop] = moment(this.form[prop]).add(1, 'hour').toDate()
  }


  @Watch('defaultValues')
  onChangeDefaultValues() {
    const form = {}
    
    this.propertiesForForm.forEach(prop => {
      const notDefault = _.isEmpty(this.defaultValues[prop])
      form[prop] = (notDefault) ? '' : this.defaultValues[prop]
    })

    this.form = form
  }


  /**
   * VUE EVENTS
   */

  created() {
    const form = {}
    
    this.propertiesForForm.forEach(prop => {
      const notDefault = !this.defaultValues
      form[prop] = (notDefault) ? '' : this.defaultValues[prop]
    })

    this.form = form
  }

}