import { Component, Vue } from 'types-vue'
import * as _ from 'lodash'


@Component
export default class BillingPeriodSelect extends Vue {

  protected model: string = ''
  protected options: any[] = [
      {
          label: 'Mensual',
          value: 'M',
      },
      {
          label: 'Anual',
          value: 'Y'
      }
  ]


  protected onChange() {
    this.$cookies.set('default_billing_period', this.model)
    this.$emit('change', this.model)
  }


  protected get defaultValue(): string {
    const cookie = this.$cookies.get('default_billing_period')
    return (_.isNil(cookie)) ? this.options[0].value : cookie
  }


  protected created() {
    this.model = this.defaultValue
    this.onChange()
  }
}