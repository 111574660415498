import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Alerts from './pages/Alerts/Alerts.vue'
import AlertsConfiguration from './pages/AlertsConfiguration/AlertsConfiguration.vue'
import { Roles } from '../UsersManager/models'

export enum AlertPages {
  Alerts = 'alerts',
  Configuration = 'alerts-configuration'
}

const routes: Array<RouteConfig> = [
  {
    path: '/alerts',
    component: Page,
    children: [
      {
        path: '',
        name: AlertPages.Alerts,
        component: Alerts
      }
    ]
  },
  {
    path: '/configuration/alerts',
    component: Page,
    children: [
      {
        path: '',
        name: AlertPages.Configuration,
        meta: { menuActive: 'alerts-configuration' },
        component: AlertsConfiguration
      }
    ]
  }
]

export default routes
