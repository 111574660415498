export namespace QueryParams {
  export const CurrentPage = 'page'
  export const BillNumber = 'billNum'
  export const Cups = 'cups'
  export const OrderProp = 'orderProp'
  export const OrderDirection = 'orderDirection'
  export const Marketer = 'comer'
  export const ProcessType = 'process_type'
  export const StartBillDate = 'startBillDate'
  export const EndBillDate = 'endBillDate'
  export const StartProcessDate = 'startProcessDate'
  export const EndProcessDate = 'endProcessDate'
  export const BillType = 'billType'
  export const Downloaded = 'downloaded'
  export const PseudoBill = 'pseudoBill'
}