import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { CsReportParams, ReportListRequest } from "../../models/api";

import { AxiosPromise } from 'axios';
import { GlobalReportParams } from "../../models/api";
import { ReportModel } from "../../models";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  lopdReport: `${CORE_SERVICE_URL_BASE}/reports/lopd/generate`,
  psReport: `${CORE_SERVICE_URL_BASE}/reports/ps/generate`,
  csReport: `${CORE_SERVICE_URL_BASE}/reports/cs/generate`,
  consumptionReport: `${CORE_SERVICE_URL_BASE}/reports/consumption/generate`,
  globalReport: `${CORE_SERVICE_URL_BASE}/reports/all/download`,
}

class CoreReports extends AbstractApi {
  
  public generateLopdReport(): AxiosPromise {
    const method = Method.GET
    const url = Path.lopdReport

    return this.request({ method, url })
  }

  public generatePsReport(): AxiosPromise {
    const method = Method.GET
    const url = Path.psReport

    return this.request({ method, url })
  }

  public generateConsumtionReport(): AxiosPromise {
    const method = Method.GET
    const url = Path.consumptionReport

    return this.request({ method, url })
  }

  public generateCsReport({ fromDate, toDate, distributorCodes, messageTypeCodes }: CsReportParams): AxiosPromise {
    const method = Method.GET
    const url = Path.csReport
    const params = {
      fromDate, toDate,
      distributorCodes: distributorCodes.join(','),
      messageTypeCodes: messageTypeCodes.join(',')
    }

    return this.request({ method, url, params })
  }
  
  public getGlobalReport( params: GlobalReportParams): AxiosPromise {
    return this.request({
      method: 'get',
      url: `${Path.globalReport}/${params.year}/${params.month}`
    })
  }

}

export default new CoreReports()