var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters" },
    [
      _c(
        "Core-FiltersSections",
        {
          attrs: {
            options: _vm.formOptions,
            setCookies: _vm.setCookies,
            onClickRestore: _vm.onClickRestore
          }
        },
        [
          _c(
            "div",
            { staticClass: "sort" },
            [_c("Bills-SortSelect"), _vm._v("z ")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }