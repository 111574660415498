import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, mixins, Prop } from 'types-vue'
import { NoContractModel } from '../../models'

enum Commands {
  delete = 'delete',
  download = 'download'
}

@Component
export default class ListItemMenu extends mixins(PermissionMixin) {
  
  @Prop({ required: true })
  protected noContract: NoContractModel

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.NoContract)
  protected removeNoContract: (noContract: NoContractModel) => Promise<any>


  get havePermissions(): boolean {
    return this.isSystemRol || this.isDistributorRol && this.isAdminPermission
  }
  

  get commandDelete(): string {
    return Commands.delete
  }

  get commandDownload(): string {
    return Commands.download
  }


  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
      case Commands.download:
        this.downloadItem()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar proceso`
    const message = `¿Estas seguro de eliminar el proceso ${this.noContract.keyname}?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'Proceso eliminado'
    const message = 'Se ha eliminado el proceso correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar proceso '
    const message = `Ha ocurrido un error al intentar eliminar el proceso ${this.noContract.keyname}.`
    
    this.$notify({ title, message, type: 'error' })
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeNoContract(this.noContract)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }

  protected async downloadItem() {
    const fileDownloadUrl = this.noContract.url
    window.open(fileDownloadUrl, '_blank')
  }
  
}