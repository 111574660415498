import { Component, Prop, Vue } from 'types-vue'
import { TranslateResult } from 'vue-i18n'
import { ProcessStates } from '@/Modules/Processes/enums/ProcessStates'

@Component
export default class StateColumn extends Vue {

  @Prop({ type: String, required: true })
  protected state: ProcessStates


  get style() {
    return {
      color: this.color
    }
  }

  get color(): string {
    const colors = {
      [ProcessStates.Completed]: '#04875A',
      [ProcessStates.Opened]: '#04875A',
      [ProcessStates.Rejected]: '#DF360E',
      [ProcessStates.CANCELED]: '#DF360E',
      [ProcessStates.DESISTED]: '#DF360E',
      [ProcessStates.SUSPENDED]: '#DF360E',
    }

    return colors[this.state] || '#04875A'
  }

  protected get icon(): string {
    const icons = {
      [ProcessStates.Completed]: 'el-icon-success',
      [ProcessStates.Opened]: 'el-icon-loading',
      [ProcessStates.Rejected]: 'el-icon-error',
      [ProcessStates.CANCELED]: 'el-icon-error',
      [ProcessStates.DESISTED]: 'el-icon-error',
      [ProcessStates.SUSPENDED]: 'el-icon-error',
    }
    
    return icons[this.state] || 'el-icon-error'
  }

  protected get stateLabel(): TranslateResult {
    const labelName = `processes processStates.${this.state}`
    return this.$t(labelName)
  }
}