import { Store } from '@/Core/Models/Stores'
import { Bill } from '@/Modules/Bills/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class CompaniesSection extends Vue {
  @MapGetter(Store.BillDetails) protected bill: Bill


  protected get distributorName(): string { return this.bill.distributor.description }
  protected get distributorCode(): string { return this.bill.distributor.code }

  protected get marketerName(): string { return this.bill.marketer.description }
  protected get marketerCode(): string { return this.bill.marketer.code }
}