import { ProcessStates } from '@/Modules/Processes/enums/ProcessStates'
import { FiltersName } from '@/Modules/Processes/enums/Filters'
import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapGetter, mixins, Vue } from 'types-vue'
import _ from 'lodash'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Store } from '@/Core/Models/Stores'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class FiltersContainer extends mixins(PermissionMixin) {
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  protected messageTypeWithinIntervalEmpty: boolean = true

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected rerenderKey: number = 0

  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'text',
          name: FiltersName.Reference,
          label: 'Referencia',
          default: this.$route.query[FiltersName.Reference],
          valueQuery: 'code'
        },
        {
          type: 'tag',
          name: FiltersName.Cups,
          label: 'Cups',
          default: (this.$route.query[FiltersName.Cups] as string)?.split(',') || [],
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: FiltersName.IngoingMarketer,
          label: 'Com. Solicitante',
          default: this.$route.query[FiltersName.IngoingMarketer] as string,
          clearable: true,
          valueQuery: 'code',
          options: `${CORE_URL}/system-subjects`,
          visible: this.showMarketerInfo
        },
        {
          type: 'select',
          name: FiltersName.ProcessType,
          label: 'Tipo de proceso',
          clearable: true,
          default: (this.$route.query[FiltersName.ProcessType] as string)?.split(','),
          options: `${CORE_URL}/process-process`,
          valueQuery: 'code',
          multiple: true
        },
        {
          type: 'text',
          name: FiltersName.ProcessReason,
          label: 'Motivo del proceso',
          default: this.$route.query[FiltersName.ProcessReason] as string,
          clearable: true,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: FiltersName.LastMessageType,
          label: 'Último mensaje',
          clearable: true,
          default: this.$route.query[FiltersName.LastMessageType] as string,
          options: `${CORE_URL}/process-message-types`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: FiltersName.Status,
          label: 'Estado',
          default: this.$route.query[FiltersName.Status],
          options: [
            { label: 'Abierto', value: ProcessStates.Opened },
            { label: 'Rechazado', value: ProcessStates.Rejected },
            { label: 'Completado', value: ProcessStates.Completed },
            { label: 'Cancelado', value: ProcessStates.CANCELED },
            { label: 'Desistido', value: ProcessStates.DESISTED },
            { label: 'Suspendido', value: ProcessStates.SUSPENDED }
          ],
          clearable: true,
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: FiltersName.FromDate,
          label: 'Fecha inicio proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.FromDate],
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: FiltersName.ToDate,
          label: 'Fecha fin proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.ToDate],
          valueQuery: 'code'
        },
        { type: 'divider', name: 'dividerMessage' },
        {
          type: 'select',
          name: FiltersName.MessageTypeWithinInterval,
          label: 'Tipo de mensaje con intervalo de fecha',
          clearable: true,
          default: this.$route.query[FiltersName.MessageTypeWithinInterval] as string,
          options: `${CORE_URL}/process-message-types`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: FiltersName.MessageTypeWithinIntervalDownloaded,
          label: 'Mensaje ha sido descargado',
          clearable: true,
          default: this.$route.query[FiltersName.MessageTypeWithinIntervalDownloaded],
          options: [
            { label: 'Sí', value: 'true' },
            { label: 'No', value: 'false' }
          ],
          disabled: this.messageTypeWithinIntervalEmpty,
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: FiltersName.FromMessageDate,
          label: 'Fecha inicio mensaje',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.FromMessageDate],
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: FiltersName.ToMessageDate,
          label: 'Fecha fin mensaje',
          format: 'YYYY-MM-DD',
          default: this.$route.query[FiltersName.ToMessageDate],
          valueQuery: 'code'
        }
      ]
    }
  }

  protected onChange(form: any) {
    const messageTypeWithinInterval = form.messageTypeWithinInterval
    this.messageTypeWithinIntervalEmpty = _.isEmpty(messageTypeWithinInterval)
  }

  protected setCookies(query: any) {
    this.$cookies.set('processes_filters', query)
  }

  protected onClickRestore() {
    const query = this.$cookies.get('processes_filters')
    this.$router.push({ ...this.$route, query })
    this.rerenderKey = this.rerenderKey + 1
  }
}
