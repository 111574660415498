var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("i", { staticClass: "el-icon-loading" }),
          _c("span", [_vm._v("Generando fichero")])
        ])
      : _vm._e(),
    !_vm.loading && _vm.validationResult
      ? _c("div", { staticClass: "validation correct" }, [
          _c("i", { staticClass: "el-icon-circle-check" }),
          _c("span", [_vm._v("El fichero se ha validado correctamente.")])
        ])
      : _vm._e(),
    !_vm.loading && !_vm.validationResult
      ? _c("div", { staticClass: "validation error" }, [
          _c("i", { staticClass: "el-icon-circle-close" }),
          _c("span", [_vm._v("El fichero no se ha validado.")])
        ])
      : _vm._e(),
    _c(
      "ul",
      _vm._l(_vm.validationErrors, function(error, index) {
        return _c("li", { key: index }, [_vm._v(_vm._s(error))])
      }),
      0
    ),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _vm.presignedUrl
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", round: "" },
                    on: { click: _vm.onClickDownload }
                  },
                  [_vm._v("Descargar fichero")]
                )
              : _vm._e(),
            _c(
              "el-button",
              { attrs: { type: "text" }, on: { click: _vm.onClickReturn } },
              [_vm._v("Crear otro contrato nuevo")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }