import { AxiosError } from 'axios'
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'types-vue'
import { formApi } from '@/Modules/Forms/connections/api/Form'
import { SelectFormItemConfig, SelectOption } from '@/Modules/Forms/models'

const configValidator = (config: SelectFormItemConfig) => {
  return true
}

@Component({})
export default class Select extends Vue {
  @Prop({ required: true, validator: configValidator }) protected config: SelectFormItemConfig

  /**
   * STATE
   */

  protected model: any = null
  protected remoteResponse: any = null
  protected loading: boolean = false
  protected filter: string = null

  /**
   * COMPUTEDS
   */

  protected get placeholder(): string {
    return this.config?.placeholder || 'Select'
  }
  protected get size(): string {
    return this.config?.size
  }
  protected get valueQuery(): string {
    return this.config?.valueQuery || 'id'
  }
  protected get labelQuery(): string {
    return this.config?.labelQuery || 'description'
  }
  protected get isRemote(): boolean {
    return typeof this.config.options === 'string'
  }

  protected get disabled(): boolean {
    return this.config.disabled
  }
  protected get default(): string {
    return this.config.default
  }
  protected get multiple(): boolean {
    return !!this.config.multiple
  }
  protected get clearable(): boolean {
    return !!this.config.clearable || false
  }

  protected get options(): SelectOption[] {
    const noHaveResponse = _.isNil(this.remoteResponse)

    if (!this.isRemote) {
      return this.config.options as SelectOption[]
    }
    if (noHaveResponse) return []

    let res: any = []

    if (typeof this.config.options === 'string' && this.config.options.includes('claim-subtype')) {
      res = this.remoteResponse.map((item) => ({
        value: _.get(item, this.valueQuery),
        label: `${item.subtype} - ${_.get(item, this.labelQuery)}`
      }))
    } else {
      res = this.remoteResponse.map((item) => ({
        value: _.get(item, this.valueQuery),
        label: item.code
          ? `${item.code} - ${_.get(item, this.labelQuery)}`
          : _.get(item, this.labelQuery)
      }))
    }

    res = [...res, { value: '', label: '-' }]

    return res
  }

  /**
   * METHODS
   */

  protected async prepareRemoteSelect() {
    try {
      this.loading = true
      this.model = null
      const url = this.config.options as string
      if (typeof url !== 'string') return
      const response = await formApi.getSelectContent({ method: 'GET', url })
      this.remoteResponse = response.data
      if (this.default) {
        const items = this.remoteResponse.filter((item) => {
          if (_.isArray(this.default)) {
            return this.default.includes(item.code)
          }
          return item.code === this.default
        })

        const itemCodes = _.map(items, 'code')

        if (this.multiple) this.model = itemCodes
        else this.model[0] || null

        // this.model = _.get(item, this.valueQuery) || null
      }
    } catch (err) {
      console.error(err)
      const error = err as AxiosError
      const messagaError =
        error?.response?.data?.message || `Error al cargar los datos de ${this.config.label}.`
      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  /**
   * EVENTS
   */
  @Watch('model', { deep: true })
  protected onChange() {
    const value = this.model
    this.$emit('change', { item: this.config, value })

    if (!value) {
      this.prepareRemoteSelect()
    }
  }

  /**
   *  WATCHERS
   */
  @Watch('config.options')
  @Watch('config.disabled', { immediate: true })
  protected onChangeDisabled() {
    if (!this.config.options) {
      this.loading = true
    } else {
      this.loading = false
    }
    const optionIsString = typeof this.config.options === 'string'
    const disabled = this.config.disabled

    if ((optionIsString && !disabled) || !!this.default) this.prepareRemoteSelect()
  }

  @Watch('config.default', { immediate: true })
  protected onChangeDefault() {
    if (!this.default) return
    this.model = this.default
  }
}
