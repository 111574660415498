var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.marketersList,
            "row-class-name": "cursor-pointer"
          },
          on: {
            "row-click": _vm.onRowClick,
            "cell-mouse-leave": _vm.onCellMouseLeave,
            "cell-mouse-enter": _vm.onCellMouseEnter
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "active", label: "", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.active ? "Activo" : "Inactivo",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "circle",
                          class: { active: scope.row.active }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "code", label: "CODIGO", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "NOMBRE", width: "250" }
          }),
          _c("el-table-column", {
            attrs: { prop: "email", label: "CORREO", width: "300" }
          }),
          _c("el-table-column", {
            attrs: { prop: "description", label: "DESCRIPCIÓN" }
          }),
          _c("el-table-column", {
            attrs: { width: "130", prop: "options", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.removingItemId === scope.row.id
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.checkShowOptions(scope.row),
                                expression: "checkShowOptions(scope.row)"
                              }
                            ],
                            staticClass: "button-menu"
                          },
                          [
                            _c("el-button", {
                              attrs: { type: "text", size: "mini" }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickEdit(scope.row)
                                  }
                                }
                              },
                              [_vm._v("Editar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickDelete(scope.row)
                                  }
                                }
                              },
                              [_vm._v("Eliminar")]
                            )
                          ],
                          1
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }