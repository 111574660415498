import { Store } from '@/Core/Models/Stores'
import { Group } from '@/Modules/UsersManager/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class GroupsTable extends Vue {

  protected showingMenuItem: Group = null


  @MapGetter(Store.UserManagerGroups) protected groupList: Group[]


  protected checkShowOptions(item: Group): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.name === item.name
  }


  protected onCellMouseEnter(item: Group): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: Group): void {
    if (this.showingMenuItem.name === item.name) this.showingMenuItem = null
  }

  protected onRowClick(item: Group, column) {
    if (column.property === 'options') return
    this.$emit('edit', item)
  }

  protected async onClickDelete(item: Group) {}
  protected onClickEdit(item: Group) {}

}