var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
    _c(
      "div",
      { staticClass: "billing-section" },
      [_c("Dashboard-BillingWidget")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }