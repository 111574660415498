var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Añadir comercializadora",
        visible: _vm.showing,
        width: "35rem"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _c("Marketers-Form", {
        ref: "form",
        attrs: { submitButtonText: "Añadir", loading: _vm.loading },
        on: { submit: _vm.onSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }