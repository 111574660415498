import { Component, Prop, Vue } from 'types-vue'
import { HistoryAtcomApi } from '../../connections/api/HistoryAtcom'

@Component
export default class AtcomTable extends Vue {
  @Prop({ default: [] }) atcomList: any[]

  protected async onClickDownload(id: number): Promise<void> {
    const url = await HistoryAtcomApi.downloadHistoryAtcom(id)
    window.open(url, '_blank')
  }
}
