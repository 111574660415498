import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, MapGetter, mixins, Vue } from 'types-vue'
import CreateHolidayDialog from '../../components/CreateHolidayDialog/CreateHolidayDialog'
import UpdateHolidayDialog from '../../components/UpdateHolidayDialog/UpdateHolidayDialog'
import { Holiday } from '../../models'

@Component
export default class List extends mixins(PermissionMixin) {


  @MapGetter(Store.Holidays) protected loading: boolean

  protected onClickCreate() {
    const createDialog = this.$refs.createDialog as CreateHolidayDialog
    createDialog.show()
  }

  protected onClickEdit(holiday: Holiday) {
    const updateDialog = this.$refs.updateDialog as UpdateHolidayDialog 
    updateDialog.show(holiday)
  }
}