import ApiVersionMixin from '@/Core/mixins/ApiVersionMixin';
import ShowVersionMixin from '@/Core/mixins/ShowVersion';
import TokenExpiredManagerMixin from '@/Core/mixins/TokenExpiredManagerMixin';
import { Store } from '@/Core/Models/Stores';
import ObtainSystemSubjectInfoMixin from '@/Modules/Auth/mixins/ObtainSystemSubjectInfo';
import ObtainTokenEmailMixin from '@/Modules/Auth/mixins/ObtainTokenEmail';
import { Component, MapGetter, mixins } from 'types-vue'


@Component
export default class Page extends mixins(ObtainTokenEmailMixin, ShowVersionMixin, ApiVersionMixin, TokenExpiredManagerMixin, ObtainSystemSubjectInfoMixin) {

  protected openedMenu: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubjectLoading: boolean


  protected get classRouterViewContent(): any {
    return {
      'mobile-hide-menu': !this.openedMenu
    }
  }

  protected get showTestMessage(): boolean {
    return process.env?.VUE_APP_SHOW_TEST_MESSAGE === 'true'
  }


  protected onClickMenu() {
    this.openedMenu = !this.openedMenu
  }

  protected onMediaMobile(event: MediaQueryListEvent) {
    if (event.matches) {
      this.openedMenu = false
    }
  }
}