var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Configuración de Alertas")]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "container"
      },
      [
        !_vm.singleSubject && !_vm.loading
          ? _c("Forms-Select", {
              attrs: { config: _vm.config },
              on: { change: _vm.onSelectSubject }
            })
          : _vm._e(),
        !!_vm.subject
          ? _c("Alerts-ConfigurationMenu", {
              attrs: { subjectName: _vm.subject.name, loading: _vm.loading }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }