import { Store } from '@/Core/Models/Stores'
import { Component, Filter, MapGetter, Vue } from 'types-vue'
import { BillingError } from '../../models'
import {   } from '../../router'
import BillingErrorApi from '../../connections/api/BillingErrors'
import { BillingErrorUrlResponse } from '../../models/api'

@Component
export default class BillingErrorsList extends Vue {


  /**
   * STATE
   */
  protected showingMenuItem: BillingError = null


  /**
   * STORE
   */

  @MapGetter(Store.BillingErrors) protected billingErrorsList: BillingError[]
  @MapGetter(Store.BillingErrors) protected listLoading: boolean


  /**
   * EVENTS
   */


  protected onCellMouseEnter(item: BillingError): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: BillingError): void {
    if (this.showingMenuItem.keyname === item.keyname) this.showingMenuItem = null
  }

  protected async onClickDownload(item: BillingError): Promise<void> {
    const result = await BillingErrorApi.getBillingErrorUrl(item)
    const fileDownloadUrl = result.data

    window.location.href = fileDownloadUrl
  }

  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return (this.listLoading)
      ? 'CARGANDO FACTURAS NO PROCESADAS POR ERROR...'
      : 'NO HAY FACTURAS NO PROCESADAS POR ERROR'
  }

  /**
   * METHODS
   */

  // protected checkShowOptions(process: UnprocessedFile): boolean {
  //   return !!this.showingMenuItem && this.showingMenuItem.keyname === process.keyname
  // }

}
