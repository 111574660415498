import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import { FlowProcessDistribution, ProcessModel } from '../../models'

@Component
export default class Details extends Vue {
  protected loading: boolean = true
  protected tabModel: string = 'timeline'

  @MapAction(Store.Details) protected initDetails: (processId: string) => Promise<any>
  @MapAction(Store.Details) protected clean: () => any
  @MapGetter(Store.Details) protected process: ProcessModel
  @MapGetter(Store.Details) protected distribution: FlowProcessDistribution


  get title(): string { return `${this?.process.processCode}` }


  async created() {
    try {
      this.loading = true
      const processId = this.$route.params.id as string
      await this.initDetails(processId)
    } catch (error) {
      this.$notify({ type: 'error', title: 'Error', message: 'Error al obtener los datos del proceso'})
    } finally {
      this.loading = false
    }
  }

  destroyed() {
    this.clean()
  }
  
}