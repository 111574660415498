var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-link",
        {
          attrs: { loading: "", icon: "el-icon-view" },
          on: { click: _vm.onClickButton }
        },
        [_vm._v("Ver resultado validacion")]
      ),
      _c(
        "el-drawer",
        {
          attrs: { visible: _vm.drawerVisible, direction: "rtl" },
          on: { close: _vm.onCloseDrawer }
        },
        [
          _c(
            "div",
            { staticClass: "drawer-container" },
            [
              _c("h2", [_vm._v("Resultado validación del fichero")]),
              _c("div", { staticClass: "filename" }, [
                _c("span", [
                  _c("i", { staticClass: "el-icon-tickets" }),
                  _vm._v(_vm._s(_vm.filename))
                ])
              ]),
              _c("Uploader-ValidationErrorList", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "error-list",
                attrs: { validationErrors: _vm.validationErrors }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }