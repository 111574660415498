import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import _ from 'lodash'
import { Group } from '../models'
import { GroupApi } from '../connection/api/Goups'

@Module({ namespaced: true })
export default class Groups extends VuexModule {


  protected _groupList: Group[] = []
  protected _loading: boolean = true


  @Getter() protected groupList(): Group[] { return this._groupList }
  @Getter() protected loading(): boolean { return this._loading }


  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setGroupList(value: Group[]) {
    this._groupList = value
    this._loading = false
  }


  @Action({ commit: 'setGroupList', useContext: true })
  protected async obtainGroups(ctx: ActionContext<any, any>): Promise<Group[]> {
    ctx.commit('setLoading', true)
    
    const response = await GroupApi.getAll()

    return response.data.groups
  }

  @Action({ useContext: true })
  protected async createGroup(ctx: ActionContext<any, any>, group: Group) {
    await GroupApi.create(group)
    ctx.dispatch('obtainGroups')
  }

  @Action({ useContext: true })
  protected async updateGroup(ctx: ActionContext<any, any>, group: Group) {
    // await GroupApi.update(group)
    // ctx.dispatch('obtainGroups')
  }

  @Action({ useContext: true })
  protected async deleteGroup(ctx: ActionContext<any, any>, group: Group) {
    // await GroupApi.delete(group)
    // ctx.dispatch('obtainGroups')
  }
}
