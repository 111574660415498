import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import { FileEditorPages } from '../../router'

@Component
export default class Init extends Vue {


  protected loading: boolean = true
  protected saveLoading: boolean = false


  @MapAction(Store.FileForm) protected obtainFileContent: (filekey: string) => Promise<any>
  @MapAction(Store.FileForm) protected confirmEditor: (fileContent: any) => Promise<any>
  @MapGetter(Store.FileForm) protected file: any

  protected get fileKey(): string {
    return this.$route?.query?.fileKey as string
  }

  private backPage() {
    this.$router.go(-1)
  }

  protected async onSubmit(file) {
    try {
      this.saveLoading = true
      await this.confirmEditor(file)
      
      this.$router.push({ name: FileEditorPages.Result, query: this.$route.query })
    } catch (error) {
      const message: string = error?.response?.data?.message as string | 'Error al guardar el fichero'
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message })
    } finally {
      this.saveLoading = false
    }
  }


  async created() {
    const fileKey = this.$route?.query?.fileKey as string
    const noFileKey = _.isEmpty(fileKey)


    if (noFileKey) this.backPage()


    try {
    
      this.loading = true
      await this.obtainFileContent(fileKey)
      
    } catch (error) {
      this.$notify({ type: 'error', title: 'Error', message: 'Error al intentar cargar los datos del fichero' })
      this.backPage()
    } finally {
      this.loading = false
    }
  }


}