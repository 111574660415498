import { Action, Module, VuexModule } from 'types-vue'
import AuthApiConnection from '../connections/api/Auth'
import ChangePasswordBody from '../models/connections/api/ChangePasswordBody'


@Module({ namespaced: true })
export default class extends VuexModule {


  @Action()
  protected async changePassword(data: ChangePasswordBody): Promise<any> {
    const result = await AuthApiConnection.changePassword(data)
    return result.data
  }
}
