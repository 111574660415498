import { Store } from '@/Core/Models/Stores'
import { Cups } from '@/Modules/Cups/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class Header extends Vue {
  @MapGetter(Store.CupsDetails) protected cups: Cups
  @MapGetter(Store.CupsDetails) protected loading: boolean

  protected get cupsId(): string { return this.cups?.generalData?.cups }
  protected get distributor(): string { return `${this.cups?.generalData?.distribuidor} - RAZONSOCIAL`}
}