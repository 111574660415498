import { Store } from '@/Core/Models/Stores'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { ProcessTableMetric } from '../../models'

@Component
export default class ProcessesTableSection extends Vue {
  
  @MapGetter(Store.ProcessesTableMetrics) protected metrics: ProcessTableMetric[]
  @MapGetter(Store.ProcessesTableMetrics) protected loading: boolean
  @MapAction(Store.ProcessesTableMetrics) protected obtaintProcessTableMetricsData: () => Promise<void>
  @MapAction(Store.ProcessesTableMetrics) protected newFilterDate: () => void
  @MapAction(Store.ProcessesTableMetrics) protected newFilterProcessType: (value) => void


  protected created() {
    this.obtaintProcessTableMetricsData()
  }

  @Watch(`$route.query.${QueryParams.ProcessType}`, { deep: true, immediate: true }) protected onChangeQuery() {
    const processType = this.$route.query[QueryParams.ProcessType]
    this.newFilterProcessType(processType)
  }
}