var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: {
          disabled: _vm.disabled,
          type: _vm.type,
          size: _vm.size,
          placeholder: _vm.placeholder,
          autosize: { minRows: 3 },
          minlength: _vm.minCharacters,
          maxlength: _vm.maxCharacters
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }