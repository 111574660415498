import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import _ from 'lodash'
import { Component, Filter, MapAction, MapGetter, mixins, Prop, Vue, Watch } from 'types-vue'
import {
  AlertCongifuration,
  allTrueConfiguration,
  defaultConfiguration,
  mainOptions
} from '../../models'

@Component
export default class ConfigurationMenu extends mixins(PermissionMixin) {
  @Prop({ required: true }) protected subjectName: string

  @MapAction(Store.Alerts) protected updateAlertsConfiguration: (
    config: AlertCongifuration | {}
  ) => Promise<void>
  @MapAction(Store.Alerts) protected updateAlertsConfigurationAdmin: (
    config: AlertCongifuration | {}
  ) => Promise<void>
  @MapAction(Store.Alerts) protected activateAlertsConfiguration: (config: any) => Promise<void>
  @MapAction(Store.Alerts) protected activateAlertsConfigurationAdmin: (
    config: any
  ) => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsConfiguration: () => Promise<void>
  @MapGetter(Store.Alerts) protected configuration: AlertCongifuration
  @MapGetter(Store.Alerts) protected configurationLoading: AlertCongifuration
  @MapGetter(Store.Alerts) protected firstTimeConfiguration: boolean
  @MapGetter(Store.Alerts) protected subject: any
  @MapGetter(Store.Alerts) protected validSubjects: any

  @MapGetter(Store.UserGroups) protected systemSubject: any

  form: AlertCongifuration = { ...defaultConfiguration }
  totalAlerts: number | null = null
  changes: boolean = false
  currentConfiguration: AlertCongifuration = { ...defaultConfiguration }
  subjectRol: { rol: string; admin: boolean } | null = null
  mainOptions = { ...mainOptions }

  showOptions = {
    process02: false,
    process03: false,
    process04: false,
    process05: false,
    process38: false,
    process41: false,
    process42: false,
    process43: false,
    process44Closing: false,
    process44Reopening: false,
    process48: false,
    process49: false,
    process50: false,
    sendEmail: false
  }

  unfoldButtonIcon = 'el-icon-caret-bottom'

  protected onClickItem(item: string) {
    this.showOptions[item] = !this.showOptions[item]
  }

  protected activateAlertsOptions() {
    if (this.isMarketerRol || (!this.isAdminPermission && this.isDistributorRol)) {
      this.activateAlertsConfiguration(this.form)
    }
    if ((this.isAdminPermission && this.isDistributorRol) || this.isSystemRol) {
      this.activateAlertsConfigurationAdmin({
        userEmail: this.subject.email,
        marketer: this.subject.code,
        alertConfiguration: this.form
      })
    }
  }

  protected updateAlertsOptions() {
    if (this.isMarketerRol || (!this.isAdminPermission && this.isDistributorRol)) {
      this.updateAlertsConfiguration(this.form)
    }
    if ((this.isAdminPermission && this.isDistributorRol) || this.isSystemRol) {
      this.updateAlertsConfigurationAdmin({
        userEmail: this.subject.email,
        marketer: this.subject.code,
        alertConfiguration: this.form
      })
    }
  }

  protected onSubmit() {
    try {
      this.firstTimeConfiguration ? this.activateAlertsOptions() : this.updateAlertsOptions()
      this.$notify({
        type: 'success',
        title: 'Configuración Guardada',
        message: 'La configuración se ha guardado correctamente'
      })
    } catch (err) {
      this.$notify({
        type: 'error',
        title: 'Error',
        message: 'No se pudo guardar la configuración'
      })
    }
  }

  protected onCancel() {
    this.obtainAlertsConfiguration()
  }

  protected onClickUnfolder() {
    if (_.every(this.showOptions, (el) => el === false)) {
      this.showOptions = {
        process02: true,
        process03: true,
        process04: true,
        process05: true,
        process38: true,
        process41: true,
        process42: true,
        process43: true,
        process44Closing: true,
        process44Reopening: true,
        process48: true,
        process49: true,
        process50: true,
        sendEmail: true
      }
    } else {
      this.showOptions = {
        process02: false,
        process03: false,
        process04: false,
        process05: false,
        process38: false,
        process41: false,
        process42: false,
        process43: false,
        process44Closing: false,
        process44Reopening: false,
        process48: false,
        process49: false,
        process50: false,
        sendEmail: false
      }
    }
  }

  protected onDesactivate() {
    this.form = { ...defaultConfiguration }
    this.showOptions = {
      process02: true,
      process03: true,
      process04: true,
      process05: true,
      process38: true,
      process41: true,
      process42: true,
      process43: true,
      process44Closing: true,
      process44Reopening: true,
      process48: true,
      process49: true,
      process50: true,
      sendEmail: true
    }
  }

  protected onActivate() {
    this.form = { ...allTrueConfiguration }
    this.showOptions = {
      process02: true,
      process03: true,
      process04: true,
      process05: true,
      process38: true,
      process41: true,
      process42: true,
      process43: true,
      process44Closing: true,
      process44Reopening: true,
      process48: true,
      process49: true,
      process50: true,
      sendEmail: true
    }
  }

  @Watch('form', { deep: true, immediate: true })
  protected onChangeForm(formCurrentState: AlertCongifuration) {
    _.forEach(formCurrentState, (element: any, key: any) =>
      _.forEach(element, (val, k) => {
        if (k === 'delayedByDays') {
          if (typeof val === 'string') {
            if (!!!val) {
              formCurrentState[key][k] = null
            }
          }
        }
      })
    )

    if (!this.configurationLoading) {
      this.changes = !_.isEqual(formCurrentState, this.currentConfiguration)
    }
  }

  @Watch('showOptions', { deep: true, immediate: true })
  protected onOpenOption() {
    this.unfoldButtonIcon = _.every(this.showOptions, (el) => el === false)
      ? 'el-icon-caret-bottom'
      : 'el-icon-caret-top'
  }

  @Watch('configuration', { deep: false, immediate: true })
  protected onChangeConfiguration() {
    this.currentConfiguration = _.cloneDeep(this.configuration)
    this.form = _.cloneDeep(this.configuration)

    let activeAlerts: number = 0
    _.forEach(this.configuration, (el: any) => {
      _.forEach(el, (element, key) => {
        if (!!element || (key === 'delayedByDays' && typeof element === 'number')) {
          activeAlerts++
        }
      })
    })

    this.totalAlerts = activeAlerts
  }
}
