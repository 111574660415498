export namespace QueryParams {
  export const CurrentPage = 'page'
  export const ProcessType = 'process_type'
  export const Reference = 'numreference'
  export const Cups = 'cups'
  export const OrderProp = 'orderProp'
  export const OrderDirection = 'orderDirection'
  export const Status = 'status'
  export const FromDate = 'fromDate'
  export const ToDate = 'toDate'
  export const LastMessageType ='lastMessageType'
  export const MessageTypeWithinInterval = 'messageTypeWithinInterval'
  export const MessageTypeDownloaded = 'downloaded'
  export const FromMessageDate = 'fromMessageDate'
  export const ToMessageDate = 'toMessageDate'
  export const ProcessReason = 'processReason'
  export const IngoingMarketer = 'marketerCode'
}