var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.listLoading } }, [
        _c("h2", [_vm._v("Contratos ATR")])
      ]),
      _c(
        "Core-FiltersDrawer",
        {
          attrs: {
            totalActiveFilter: _vm.totalActiveFilter,
            buttonLabel: "Filtros de contratos ATR"
          }
        },
        [_c("AtrContracts-FiltersContainer")],
        1
      ),
      _c(
        "el-card",
        { staticClass: "is-table" },
        [
          _c("AtrContracts-ContractsTable", {
            attrs: { atrContracts: _vm.atrContracts, loading: _vm.listLoading }
          })
        ],
        1
      ),
      _c("Core-Pagination", {
        attrs: { pageSize: _vm.pageSize, totalElements: _vm.totalElements }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }