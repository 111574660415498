var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Iniciar proceso")]),
    _c("p", [
      _vm._v("Selecciona el proceso que deseas iniciar de la siguiente lista.")
    ]),
    _c(
      "div",
      { staticClass: "process-list" },
      _vm._l(_vm.items, function(item) {
        return _c(
          "div",
          {
            key: item.code,
            staticClass: "element",
            on: {
              click: function($event) {
                return _vm.onClickElement(item.code)
              }
            }
          },
          [
            _c("div", { staticClass: "code" }, [
              _c("span", [_vm._v(_vm._s(item.codePrefix))]),
              _c("span", [_vm._v(_vm._s(item.code))])
            ]),
            _c("div", { staticClass: "description" }, [
              _c("span", [_vm._v(_vm._s(item.description))])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }