import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'types-vue'

@Component
export default class TreeForm extends Vue {

  @Prop({ required: true }) protected file: any
  @Prop({ default: false }) protected loading: boolean

  protected formData = null


  @Watch('file', { deep: true, immediate: true }) protected onChangeFile() {
    this.formData = this.parseToForm(this.file)
  }

  protected async onSubmit() {
    try {
      await this.$confirm('Estas seguro de actualizar este fichero?', 'Actualizar fichero', { confirmButtonText: 'Actualizar' })
      const fileContent = this.getFormData(this.formData)
      this.$emit('submit', fileContent)
    } catch (error) {}
  }

  protected async onClickReturn() {
    try {
      await this.$confirm('Estas seguro que quieres salir del editor', 'Salir del editor', { confirmButtonText: 'Salir del editor' })
      this.$router.go(-1)
    } catch (error) { }
  }

  private parseToForm(file: any): any[] {
    const elements = []
    _.forIn(file, (value, label) => {
      const isObject = _.isObject(value)
      const isNull = _.isNull(value)
      const isString = _.isString(value)
      const isArray = _.isArray(value)


      if (isObject) {
        const children = !isArray
          ? this.parseToForm(value)
          : this.parseToForm(value).map(element => ({ ...element, arrayElement: true }))

        elements.push({ label, isArray, children })
      } else if (isNull) {
        elements.push({ label, value: '', isFormElement: true })
      } else if (isString) {
        elements.push({ label, value, isFormElement: true })
      }
    })

    return elements
  }

  private getFormData(formData, saveArray = false) {
    const result = saveArray ? [] : {} as any

    formData.map(element => {
      if (element.isFormElement) {
        (saveArray) ? result.push(element.value) : result[element.label] = element.value
      }
      else if (element.isArray) {
        const data = this.getFormData(element.children, true)
        
        if (saveArray) result.push(data)
        else result[element.label] = data
      }

      else { // is object
        const data = this.getFormData(element.children)
        if (saveArray) result.push(data)
        else result[element.label] = data
      }

    })

    return result
  }
}