import { Store } from '@/Core/Models/Stores'
import { AtrContract } from '@/Modules/AtrContracts/models'
import { AtrContractsPages } from '@/Modules/AtrContracts/router'
import { Bill } from '@/Modules/Bills/models'
import { BillsPages } from '@/Modules/Bills/router'
import { ProcessModel } from '@/Modules/Processes/models'
import { ProcessesPages } from '@/Modules/Processes/router'
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { SearchType } from '../../definitions/SearchType'

@Component
export default class SearchResult extends Vue {


  protected limitShowButton: number = 3


  @MapAction(Store.SearchResult) protected search: (payload: { cups: string }) => Promise<void>
  @MapAction(Store.SearchResult) protected clean: () => void

  @MapGetter(Store.SearchResult) protected process: ProcessModel[]
  @MapGetter(Store.SearchResult) protected totalProcess: number
  @MapGetter(Store.SearchResult) protected bills: Bill[]
  @MapGetter(Store.SearchResult) protected totalBills: number
  @MapGetter(Store.SearchResult) protected atrContracts: AtrContract[]
  @MapGetter(Store.SearchResult) protected totalAtrContracts: number
  @MapGetter(Store.SearchResult) protected cupsInfo: any[]
  @MapGetter(Store.SearchResult) protected totalCupsInfo: number
  @MapGetter(Store.SearchResult) protected loading: boolean


  private routePushWithCups(name: string) {
    const cups = this.$route.params.cups
    this.$router.push({ name, query: { cups } })
  }

  private goToProcess() { this.routePushWithCups(ProcessesPages.InProcess) }
  private goToAtrContracts() { this.routePushWithCups(AtrContractsPages.Main) }
  private goToBills() { this.routePushWithCups(BillsPages.Main) }


  private redirectResult() {
    const { type, cups } = this.$route.params

    switch (type) {
      case SearchType.All: return this.search({ cups })
      case SearchType.Bills: return this.goToBills()
      case SearchType.Processes: return this.goToProcess()
      case SearchType.AtrContracts: return this.goToAtrContracts()
    }
  }

  @Watch('$route', { deep: true, immediate: true })
  protected onChangeRoute() {
    this.redirectResult()
  }

  protected beforeDestroy() {
    this.clean()
  }
}