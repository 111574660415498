import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Prop, Vue } from 'types-vue'
import { UnprocessedFile } from '../../models'
import UnprocessedFilesApi from '../../connections/api/UnprocessedFiles'


enum Commands {
  delete = 'delete',
  download = 'download'
}

@Component
export default class ListItemMenu extends Vue {
  
  @Prop({ required: true })
  protected unprocessedFile: UnprocessedFile

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.UnprocessedFiles) protected removeBill: (file: UnprocessedFile) => Promise<any>



  get commandDelete(): string {
    return Commands.delete
  }

  get commandDownload(): string {
    return Commands.download
  }


  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
      case Commands.download: 
        this.downloadItem()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar fichero no procesado ${this.unprocessedFile.keyname}`
    const message = `¿Estas seguro de eliminar el fichero ${this.unprocessedFile.keyname} que no fue procesado por el error "${this.unprocessedFile.error}"?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'fichero eliminado'
    const message = 'Se ha eliminado el fichero correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar el fichero'
    const message = `Ha ocurrido un error al intentar eliminar el fichero con keyname ${this.unprocessedFile.keyname}`
    
    this.$notify({ title, message, type: 'error' })
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeBill(this.unprocessedFile)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }

  protected async downloadItem() {
    const result = await UnprocessedFilesApi.getUnprocessedFileUrl(this.unprocessedFile)

    const fileDownloadUrl = result.data.url

    window.location.href = fileDownloadUrl
  }
  
}