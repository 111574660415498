var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-bar" },
    [
      _c(
        "el-input",
        {
          staticClass: "input-with-select",
          attrs: { placeholder: "CUPS" },
          on: { input: _vm.onInput },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.applySearch.apply(null, arguments)
            }
          },
          model: {
            value: _vm.inputModel,
            callback: function($$v) {
              _vm.inputModel = $$v
            },
            expression: "inputModel"
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { slot: "prepend", placeholder: "Select" },
              on: { change: _vm.onChangeType },
              slot: "prepend",
              model: {
                value: _vm.selectModel,
                callback: function($$v) {
                  _vm.selectModel = $$v
                },
                expression: "selectModel"
              }
            },
            _vm._l(_vm.searchTypes, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }