var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("el-date-picker", {
        attrs: {
          size: "small",
          type: "monthrange",
          align: "right",
          "unlink-panels": "",
          "range-separator": "a",
          "picker-options": _vm.pickerOptions,
          clearable: false
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }