import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import mime from 'mime-types'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL


const Path = {
  upload: id => `${CORE_SERVICE_URL_BASE}/flow-processes/${id}/attached-file-presigned-post`,
  getList: id => `${CORE_SERVICE_URL_BASE}/flow-processes/${id}/list-attached-files`,
  download: id => `${CORE_SERVICE_URL_BASE}/flow-processes/${id}/attached-file-presigned-get`,
  delete: (id, filename) => `${CORE_SERVICE_URL_BASE}/flow-processes/${id}/attached-file-presigned/${filename}`,
}



class ProcessDocumentsApiSingleton extends AbstractApi {
  
  private static _instance: ProcessDocumentsApiSingleton
  private constructor() { super() }

  public static get instance(): ProcessDocumentsApiSingleton {
    if (!ProcessDocumentsApiSingleton._instance) ProcessDocumentsApiSingleton._instance = new ProcessDocumentsApiSingleton();
    return ProcessDocumentsApiSingleton._instance;
  }



  public getPresignedUploadUrl(processId: number, filename: string): AxiosPromise<{ url: string }> {
    const method = Method.GET
    const url = `${Path.upload(processId)}`
    const params = { filename }

    return this.request({ method, url, params })
  }


  public uploadDocument(presignedUrl: string, file: File): AxiosPromise<void> {
    const method = Method.PUT
    const url = presignedUrl
    const data = file
    const contentType = mime.lookup(file.name)
    const headers = { 'content-type': contentType }
    
    return this.request({ method, url, data, headers }, true)
  }


  public deleteDocument(processId: number, filename: string): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.delete(processId, filename)}`

    return this.request({ method, url })
  }


  public getFileList(processId: number): AxiosPromise<{ objects: string[] }> {
    const method = Method.GET
    const url = `${Path.getList(processId)}`

    return this.request({ method, url })
  }


  public getPresignedDownloadUrl(processId: number, fileKeyname: string): AxiosPromise<{ url: string }> {
    const method = Method.GET
    const url = `${Path.download(processId)}/${fileKeyname}`

    return this.request({ method, url })
  }
}


export const ProcessDocumentsApi = ProcessDocumentsApiSingleton.instance
