var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.latest
    ? _c(
        "div",
        { staticClass: "version-button" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.tooltipContent,
                placement: "top"
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "version-label",
                  on: { click: _vm.onClickButton }
                },
                [_vm._v(_vm._s(_vm.version))]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }