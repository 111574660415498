var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters" },
    [
      _c("el-input", {
        attrs: {
          placeholder: "Filtrar por email",
          clearable: "",
          size: "small"
        },
        on: { input: _vm.onChangeInput },
        model: {
          value: _vm.emailModel,
          callback: function($$v) {
            _vm.emailModel = $$v
          },
          expression: "emailModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }