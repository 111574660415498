import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=678b5492&scoped=true&"
import script from "./Page.ts?vue&type=script&lang=ts&"
export * from "./Page.ts?vue&type=script&lang=ts&"
import style0 from "./Page.vue?vue&type=style&index=0&id=678b5492&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678b5492",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('678b5492')) {
      api.createRecord('678b5492', component.options)
    } else {
      api.reload('678b5492', component.options)
    }
    module.hot.accept("./Page.vue?vue&type=template&id=678b5492&scoped=true&", function () {
      api.rerender('678b5492', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/views/Page/Page.vue"
export default component.exports