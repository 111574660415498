import FormValidateMixin from '@/Core/mixins/FormValidate'
import _ from 'lodash'
import { Component, mixins, Prop, Vue, Watch } from 'types-vue'
import { S3Connector } from '../../models'

@Component
export default class Form extends mixins(FormValidateMixin) {

  @Prop({ type: String, default: 'Guardar' }) protected submitButtonText: string
  @Prop({ type: Boolean, default: false }) protected loading: boolean
  @Prop({ type: Boolean, default: false }) protected isEditor: boolean
  @Prop() protected value: S3Connector


  protected form: S3Connector = {
    inputFromSapBucket: '',
    outputToSapBucket: '',
    isActive: true
  }

  protected rules = {}



  @Watch('value', { immediate: true })
  protected onChangeDefault() {
    if (_.isNil(this.value)) return
    
    this.form = {
      inputFromSapBucket: this.value.inputFromSapBucket,
      outputToSapBucket: this.value.outputToSapBucket,
      isActive: this.value.isActive,
      id: this.value.id
    }
  }

  protected async onSubmit() {
    if (!await this.validateForm()) return

    this.$emit('input', this.form)
    this.$emit('submit', this.form)
  }

  public clean() {
    this.form = { inputFromSapBucket: '', isActive: true, outputToSapBucket: '' }
  }
}