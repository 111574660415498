import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import modules from './modules';

Vue.use(Vuex);

const store = new Store({
    state: {},
    modules
});

export default store;