import FormValidateMixin from '@/Core/mixins/FormValidate'
import _ from 'lodash'
import { Component, Prop, mixins } from 'types-vue'


@Component
export default class ForgotPasswordForm extends mixins(FormValidateMixin) {


  /**
   * PROPS
   */

  @Prop({ default: false })
  protected loading: boolean


  /**
   * STATE
   */

  protected form = {
    username: '',
  }

  protected rules = {
    username: [
      { required: true, message: 'Please input email', trigger: 'blur' },
      { type: 'email', message: 'Please input correct email address', trigger: 'blur' }
    ]
  }



  /**
   * EVENTS
   */

  protected async onClickSubmit() {
    if (!await this.validateForm()) return

    this.$emit('submit', this.form)
  }


  protected onClickReturn() {
    this.$emit('back')
  }



  /**
   *  VUE LIFE CYCLE
   */

  created() {
    if (_.isEmpty(this.$route.query.username)) return

    this.form.username = this.$route.query.username as string
  }
}