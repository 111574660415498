import { Component, MapAction, MapGetter, mixins, Watch } from 'types-vue'
import _ from 'lodash'
import { Store } from '@/Core/Models/Stores'
import { SelectFormItemConfig, SelectOption } from '@/Modules/Forms/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { AlertsConfigParams, ConfigSubject } from '../../models'
import UserGroup from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class Alerts extends mixins(PermissionMixin) {
  options: string | SelectOption[] = ''
  // `${CORE_URL}/system-subjects?categories=COM,DIS`
  config: SelectFormItemConfig | [] = []
  singleSubject: boolean = false
  loading: boolean = true

  @MapGetter(Store.Signin) protected username: any

  @MapGetter(Store.Alerts) protected configurationSelectedSubject: string
  @MapGetter(Store.Alerts) protected validSubjects: any
  @MapGetter(Store.Alerts) protected subject: any
  @MapAction(Store.Alerts) protected obtainAlertsConfiguration: (
    params?: AlertsConfigParams
  ) => Promise<void>
  @MapAction(Store.Alerts) protected obtainAlertsConfigurationAdmin: () => Promise<void>
  @MapAction(Store.Alerts) protected obtainValidSubjects: () => Promise<void>
  @MapAction(Store.Alerts) protected updateSubject: (subject: any) => void

  @MapGetter(Store.UserGroups) protected userGroupList: any
  @MapGetter(Store.UserGroups) protected systemSubject: any
  @MapAction(Store.UserGroups) protected getListCurrentUserGroups: () => Promise<any>
  @MapAction(Store.UserGroups) protected changeGroup: (payload: {
    group: UserGroup
    reload: boolean
  }) => Promise<any>

  onSelectSubject(selectedSubject) {
    const { label } = selectedSubject.item.options.find(
      (item) => item.value === selectedSubject.value
    )
    this.updateSubject({ name: label, code: selectedSubject?.value, email: this.username })
  }

  createOptions() {
    if (this.isMarketerRol || (!this.isAdminPermission && this.isDistributorRol)) {
      if (this.userGroupList.length > 1) {
        const validSubjectsIds = this.validSubjects.map((subject) => subject.code)
        const filteredSubjects = this.userGroupList.filter((subject) =>
          validSubjectsIds.includes(subject.name)
        )
        const options = filteredSubjects.map((subject) => {
          return {
            value: subject.name,
            label: `${subject.name} - ${subject.description}`
          }
        })
        this.config = {
          type: 'select',
          name: 'Comercializadora',
          options,
          multiple: false,
          placeholder: 'Selecciona una comercializadora',
          valueQuery: 'code',
          default: this.systemSubject.code
        }
      } else {
        this.singleSubject = true
        this.updateSubject({
          name: `${this.systemSubject.code} - ${this.systemSubject.description}`,
          code: this.systemSubject.code,
          email: this.username
        })
      }
    }

    if ((this.isAdminPermission && this.isDistributorRol) || this.isSystemRol) {
      const options = this.validSubjects.map((subject) => {
        return {
          value: subject?.code,
          label: `${subject?.code} - ${subject?.name}`
        }
      })

      if (this.validSubjects.map((el) => el.code).includes(this.systemSubject.code)) {
        this.config = {
          type: 'select',
          name: 'Comercializadora',
          options,
          multiple: false,
          placeholder: 'Selecciona una comercializadora',
          valueQuery: 'code',
          default: this.systemSubject.code
        }
      } else {
        this.config = {
          type: 'select',
          name: 'Comercializadora',
          options,
          multiple: false,
          placeholder: 'Selecciona una comercializadora',
          valueQuery: 'code'
        }
      }
    }
  }

  @Watch('subject', { deep: true, immediate: true })
  protected async onChangeSubject(subject) {
    if (this.isMarketerRol || (!this.isAdminPermission && this.isDistributorRol)) {
      if (this.systemSubject?.code !== subject?.code) {
        const group = { name: subject?.code, description: subject?.name }
        this.loading = true
        return await this.changeGroup({ group, reload: true })
      }
      this.obtainAlertsConfiguration()
    }

    if ((this.isAdminPermission && this.isDistributorRol) || this.isSystemRol) {
      this.obtainAlertsConfigurationAdmin()
    }
  }

  async created() {
    await this.getListCurrentUserGroups()
    await this.obtainValidSubjects()

    this.loading = false
    this.createOptions()
  }
}
