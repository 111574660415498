var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Cups-Header"),
      _vm.error
        ? _c(
            "div",
            [
              _c("el-alert", {
                attrs: {
                  title: "Error",
                  type: "error",
                  description: _vm.error,
                  "show-icon": "",
                  closable: false
                }
              })
            ],
            1
          )
        : !_vm.loading
        ? _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card", lazy: "" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Información general", name: "info" } },
                    [_c("Cups-GeneralTab", { attrs: { cups: _vm.cups } })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Periodos", name: "periods", lazy: "" } },
                    [_c("Cups-Periods", { attrs: { cups: _vm.cups } })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: { label: "Consumo", name: "consumption", lazy: "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "download-button-content" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.onClickDownloadButton }
                            },
                            [
                              _c("i", { staticClass: "el-icon-download" }),
                              _vm._v(" Descargar en csv")
                            ]
                          )
                        ],
                        1
                      ),
                      _c("Cups-ConsumptionChart", {
                        attrs: { height: 100, cups: _vm.cups }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Caudal", name: "flow", lazy: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "download-button-content" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.onClickDownloadButton }
                            },
                            [
                              _c("i", { staticClass: "el-icon-download" }),
                              _vm._v(" Descargar en csv")
                            ]
                          )
                        ],
                        1
                      ),
                      _c("Cups-CaudalChart", {
                        attrs: { height: 100, cups: _vm.cups }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }