import { Component, Prop, Vue } from 'types-vue'

@Component
export default class CreationResult extends Vue {
  
  @Prop({ type: Array }) protected validationErrors: string[]
  @Prop({ type: Boolean }) protected validationResult: boolean
  @Prop({ type: String }) protected keyname: string
  @Prop({ type: Boolean }) protected loading: boolean



  protected onClickDownload() {
    window.open(this.keyname, '_blank')
  }

  protected onClickReturn() {
    this.$emit('onReturn')
  }

  protected onClickNew() {
    location.reload();
  }
}