import { Component, Vue, Prop, Watch } from 'types-vue'

@Component
export default class Notify extends Vue {
  
  @Prop({ type: String, required: true })
  protected defaultMessage: string

  protected isSuccess: boolean = false
  protected isError: boolean = false
  protected message: string = ''

  public success(newMessage: string): void {
    this.isSuccess = true
    if (newMessage) this.message = newMessage
    
    this.$emit('success')
  }

  public error(newMessage?: string): void {
    this.isError = true
    if (newMessage) this.message = newMessage
    
    this.$emit('error')
  }

  @Watch('defaultMessage')
  onChangeDefaultMessage(): void {
    this.message = this.defaultMessage
  }

  created() {
    this.message = this.defaultMessage
  }
}