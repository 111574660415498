import { Store } from '@/Core/Models/Stores'
import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import router from '@/Core/router'
import { AuthPages } from '../../router'

@Component({})
export default class Mfa extends Vue {
  /**
   * STORE
   */

  @MapAction(Store.Signin)
  protected respondToAuthChallenge: (code: string) => Promise<any>

  @MapGetter(Store.Signin) protected signinResult: number
  @MapGetter(Store.Signin) protected loading: boolean

  input: string = ''

  protected async onSubmit() {

    const result = await this.respondToAuthChallenge(this.input)
    if (result.status === 200) {
      this.$notify({
        title: 'Usuario Autenticado',
        message: 'El usuario se ha autenticado correctamente',
        type: 'success'
      })
      router.push({ name: AuthPages.SelectGroup })
    } else {
      this.$notify({
        title: 'Error de Autenticación',
        message: `No se pudo configurar autenticar el usuario - ${result}`,
        type: 'error'
      })
    }
  }

  protected onCancel() {
    router.push({ name: AuthPages.Signin })
  }

  created() {
    if (!this.signinResult) {
      router.push({ name: AuthPages.Signin })
    }
  }
}
