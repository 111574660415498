var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Resultado validación del fichero")]),
      _c("Uploader-ValidationResult", {
        attrs: { result: _vm.validationResult.result }
      }),
      !_vm.validationResult.result
        ? _c(
            "el-card",
            [
              _c("Uploader-ValidationErrorList", {
                attrs: { validationErrors: _vm.validationErrors }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.validationResult.result
        ? _c(
            "div",
            { staticClass: "button-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onClickEditFile }
                },
                [_vm._v("Volver a editar el fichero")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.onClickProcessList }
                },
                [_vm._v("Ir al listado de procesos")]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "button-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onClickProcessList }
                },
                [_vm._v("Ir al listado de procesos")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }