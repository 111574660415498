import { Store } from '@/Core/Models/Stores'
import { ProcessDocumentsApi } from '@/Modules/Processes/connections/api/ProcessDocuments'
import { ProcessDocument, ProcessModel } from '@/Modules/Processes/models'
import { Component, MapGetter, Prop, Vue } from 'types-vue'

@Component
export default class DownloadButton extends Vue {

  @Prop({ required: true, type: Object }) protected document: ProcessDocument

  @MapGetter(Store.Details) protected process: ProcessModel


  protected loading: boolean = false
  

  protected async onClickDownloadFile(file: ProcessDocument) {
    try {
      this.loading = true
      const { data } = await ProcessDocumentsApi.getPresignedDownloadUrl(this.process.id, file.filename)
      window.open(data.url)
    } catch (error) {
      console.error(error)
      this.$notify.error('Error al descargar el fichero, intentalo más tarde')
    } finally {
      this.loading = false
    }
  }
}