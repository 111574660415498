import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import GroupCreationDialog from '../../dialogs/GroupCreationDialog/GroupCreationDialog'

@Component
export default class GroupsTab extends Vue {

  protected onClickCreateButton() {
    const createDialog = this.$refs.createDialog as GroupCreationDialog
    createDialog.show()
  }

}