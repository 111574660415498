var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", [
    _c(
      "div",
      [
        _c("Core-Title", [_c("h2", [_vm._v("Iniciar proceso A1-43")])]),
        _c("p", { staticClass: "description" }, [
          _vm._v("Solicitud de Nuevo Producto/Contrato")
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "steps-content" },
            [
              _c(
                "el-card",
                [
                  _vm.showForm
                    ? _c("Core-ProcessForm", {
                        attrs: { formOptions: _vm.formOptions },
                        on: { submit: _vm.onSubmit }
                      })
                    : _vm._e(),
                  !_vm.showForm
                    ? _c("ProcessesForms-CreationResult", {
                        attrs: {
                          validationErrors: _vm.errors,
                          validationResult: !_vm.errors.length,
                          loading: _vm.loading
                        },
                        on: { onReturn: _vm.handleReturn }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }