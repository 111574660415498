var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c("h4", [_vm._v("INFORMACIÓN DE REPARTO")]),
    _c("div", { staticClass: "content" }, [
      _c("div", [
        _c("span", [_vm._v("FECHA:")]),
        _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.date))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }