import { Store } from '@/Core/Models/Stores'
import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import router from '@/Core/router'
import { AuthPages } from '../../router'
import QrcodeVue from 'qrcode.vue'

@Component({ components: { QrcodeVue } })
export default class MfaConfig extends Vue {
  tooltipMessage = 'Click para copiar el código'
  showSecretCode = false
  qrErrorMessage = ''
  input = ''
  /**
   * STORE
   */
  @MapAction(Store.Signin)
  protected associateToken: () => Promise<any>
  @MapAction(Store.Signin)
  protected verifyToken: (userCode: string) => Promise<any>
  @MapAction(Store.Signin)
  protected setLoading: (value: boolean) => void

  @MapGetter(Store.Signin) protected signinResult: number
  @MapGetter(Store.Signin) protected secretCode: string
  @MapGetter(Store.Signin) protected username: string
  @MapGetter(Store.Signin) protected loading: boolean

  protected get formOptions(): FormConfig {
    return {
      label: '',
      submitButton: 'Asignar MFA',
      size: 'small',
      withoutSubmit: false,
      loading: this.loading,
      disabled: !!this.qrErrorMessage,
      items: [
        {
          type: 'text',
          name: 'userCode',
          label: '',
          default: ''
        }
      ]
    }
  }

  protected async onSubmit() {
    const result = await this.verifyToken(this.input)
    if (result.data?.status === 'SUCCESS') {
      this.$notify({
        type: 'success',
        title: 'MFA Configurado',
        message: 'La Autenticación Multifactor se ha configurado correctamente '
      })
      router.push({ name: AuthPages.SelectGroup })
    } else {
      this.$notify({
        type: 'error',
        title: 'Error en la configuración',
        message: `No se pudo configurar la Autenticación Multifactor - ${result}`
      })
    }
  }

  protected onChange(model) {
    this.$emit('change', model)
  }

  protected copyToClipboard() {
    navigator.clipboard.writeText(this.secretCode)
    this.tooltipMessage = '¡Código copiado!'
  }

  protected updateTooltipMessage() {
    this.tooltipMessage = 'Click para copiar el código'
  }

  protected handleShowSecretCode() {
    this.showSecretCode = true
  }

  protected handleCancel() {
    router.push({ name: AuthPages.Signin })
  }

  protected async handleAssociateToken() {
    const result = await this.associateToken()

    if (result.status !== 200) {
      this.qrErrorMessage = 'Error al cargar el código'
      this.setLoading(false)
    }
  }

  protected handleRetry() {
    this.qrErrorMessage = ''
    this.handleAssociateToken()
  }

  async created() {
    if (!this.signinResult) {
      return router.push({ name: AuthPages.Signin })
    }
    this.handleAssociateToken()
  }
}
