import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { Province, ReadingReason, Town } from "../../models";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Province: `${CORE_SERVICE_URL_BASE}/location-province`,
  Town: `${CORE_SERVICE_URL_BASE}/ine-municipalities`,
  StreetTypes: `${CORE_SERVICE_URL_BASE}/location-street-types`,
  ReadingReasons: `${CORE_SERVICE_URL_BASE}/reading-reasons`,
  ClientPersonTypes: `${CORE_SERVICE_URL_BASE}/client-person-types`,
  ClientDocumentTypes: `${CORE_SERVICE_URL_BASE}/client-document-types`,
}

class MastersSingleton extends AbstractApi {
  private static _instance: MastersSingleton
  private constructor() { super() }

  public static get instance(): MastersSingleton {
    if (!MastersSingleton._instance) MastersSingleton._instance = new MastersSingleton();
    return MastersSingleton._instance;
  }

  public getProvince(provinceCode: string): AxiosPromise<Province[]> {
    const method = Method.GET
    const url = Path.Province
    const params =  { code: provinceCode }

    return this.request({ method, url, params })
  }

  public getTown(townCode: string): AxiosPromise<Town[]> {
    const method = Method.GET
    const url = Path.Town
    const params =  { netMunicipalityCode: townCode }

    return this.request({ method, url, params })
  }

  public getTypeStreet(code: string): AxiosPromise<any[]> {
    const method = Method.GET
    const url = Path.StreetTypes
    const params =  { code }

    return this.request({ method, url, params })
  }
  
  public getAllReadingReasons(): AxiosPromise<ReadingReason[]> {
    const method = Method.GET
    const url = Path.ReadingReasons

    return this.request({ method, url })
  }

  public getPersonType(code: string): AxiosPromise<any[]> {
    const method = Method.GET
    const url = Path.ClientPersonTypes
    const params =  { code }

    return this.request({ method, url, params })
  }

  public getDocumentType(code: string): AxiosPromise<any[]> {
    const method = Method.GET
    const url = Path.ClientDocumentTypes
    const params =  { code }

    return this.request({ method, url, params })
  }

}

export const CupsMasterApi = MastersSingleton.instance