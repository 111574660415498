var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "auth forgotPassword.description",
            expression: "'auth forgotPassword.description'"
          }
        ]
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  id: "forgot-password-input",
                  placeholder: "Email",
                  disabled: _vm.loading
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onClickSubmit.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.username,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username"
                }
              })
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "button-content" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", disabled: _vm.loading },
                    on: { click: _vm.onClickReturn }
                  },
                  [_vm._v(_vm._s(_vm.$t("auth forgotPassword.returnButton")))]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "signinBtn",
                    attrs: {
                      id: "forgot-password-submit",
                      loading: _vm.loading,
                      size: "medium",
                      type: "primary"
                    },
                    on: { click: _vm.onClickSubmit }
                  },
                  [_vm._v(_vm._s(_vm.$t("auth forgotPassword.submitButton")))]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }