import { Store } from '@/Core/Models/Stores'
import { UserFormModel } from '@/Modules/UsersManager/models'
import { Component, MapAction, Prop, Vue } from 'types-vue'

@Component
export default class UserEditionDialog extends Vue {

  @MapAction(Store.UserManagerUsers) protected updateUser: (user: UserFormModel) => Promise<any>

  protected showing: boolean = false
  protected loading: boolean = false
  protected value: UserFormModel = null


  async onSubmit(formUser: UserFormModel) {
    try {
      this.loading = true
      await this.updateUser(formUser)
      this.showing = false
      this.$notify({ type: 'success', title: 'Guardado correctamente', message: 'El usuario se ha guardado correctamente' })
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al guardar el usuario'})
    } finally {
      this.loading = false
    }
  }

  public show(user) {
    this.value = user
    this.showing = true
  }

}