import { Action, Getter, Module, Mutation, VuexModule, Vue } from 'types-vue'
import { ActionContext } from 'vuex'
import { Version } from '../models'
import _ from 'lodash'
import { VersionsApi } from '../connection/api/Versions'

@Module({ namespaced: true })
export default class Versions extends VuexModule {


  protected _versionList: Version[] = []
  protected _loading: boolean = true


  @Getter() protected versionList(): Version[] { return this._versionList }
  @Getter() protected loading(): boolean { return this._loading }
  @Getter() protected latest(): Version { return _.last(this._versionList) }

  @Mutation() protected setLoading(value: boolean) { this._loading = value }
  @Mutation() protected setVersionList(value: Version[]) {
    this._versionList = _.sortBy(value, ['versionNumber']);
    this._loading = false
  }


  @Action({ commit: 'setVersionList', useContext: true })
  protected async obtainVersionList(ctx: ActionContext<any, any>): Promise<Version[]> {
    const haveVersions = !!(ctx.getters.versionList as Version[]).length

    if (haveVersions) return 
    
    ctx.commit('setLoading', true)
    const response = await VersionsApi.get()
    return response.data
  }
}

