
import { SessionVariables } from '@/Core/enum/SessionVariables'
import { Store } from '@/Core/Models/Stores'
import { decodeToken } from '@/Core/Utils'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import UserGroup from '../../models'

@Component
export default class SelectUserGroup extends Vue {

  @MapAction(Store.UserGroups) protected changeGroup: (payload: { group: UserGroup, reload: boolean }) => Promise<any>
  @MapAction(Store.UserGroups) protected getListCurrentUserGroups: () => Promise<any>
  @MapGetter(Store.UserGroups) protected userGroupList: UserGroup[]
  @MapGetter(Store.UserGroups) protected loading: boolean
  @MapGetter(Store.UserGroups) protected changeLoading: boolean


  protected model: string = null
  
  protected get showing(): boolean {
    return this.userGroupList.length > 1
  }

  protected async onChange() {
    const group = this.userGroupList.find(item => item.name === this.model)
    await this.changeGroup({ group, reload: true })
  }

  async created() {
    await this.getListCurrentUserGroups()
    const tokenInfo = decodeToken(sessionStorage.getItem(SessionVariables.Auth))

    this.model = tokenInfo['custom:active_marketer']
  }
}