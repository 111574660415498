import { nFormatter } from '@/Core/Utils'
import { Cups } from '@/Modules/Cups/models'
import { ChartData, ChartOptions } from 'chart.js'
import _ from 'lodash'
import moment from 'moment'
import { Component, mixins, Prop, Vue } from 'types-vue'
import { Bar } from 'vue-chartjs'

@Component
export default class CaudalChart extends mixins(Bar) {

  @Prop({ required: true }) cups: Cups

  protected get minimum(): number[] { return this.cups?.consumptions.map(period => parseInt(period.caudalMin) || 0) }
  protected get maximum(): number[] { return this.cups?.consumptions.map(period => parseInt(period.caudalMax) || 0) }
  protected get average(): number[] { return this.cups?.consumptions.map(period => parseInt(period.caudalMed) || 0) }


  protected get chartData(): ChartData {
    const labels = this.cups.consumptions.map(consumption => moment(consumption.fechaInicio).format('YYYY/MM/DD'))

    return {
      labels,
      datasets: [
        {
          label: 'Minimo',
          data: this.minimum,
          backgroundColor: '#ff8282',
          type: 'line',
          fill: true,
          cubicInterpolationMode: 'monotone',
          order: 0
        },

        {
          label: 'Media',
          data: this.average,
          backgroundColor: '#064789D9',
          type: 'line',
          fill: true,
          cubicInterpolationMode: 'monotone',
          
          order: 1
        },

        {
          label: 'Maximo',
          data: this.maximum,
          backgroundColor: '#3CB53580',
          type: 'line',
          fill: true,
          cubicInterpolationMode: 'monotone',
          order: 2
        }
      ]
    }
  }

  protected get chartOptions(): ChartOptions {
    return {
      responsive: true,
      legend: {
        display: true,
      },
      plugins: {
        title: {
          display: false,
        }
      },
      scales: {
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'm3/h',
          },
          ticks: {
            callback: function(label, index, labels) {
                return `${nFormatter(label, 2)}`
            }
          },
        }]  
      },
      tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                return `${new Intl.NumberFormat('es-ES').format(parseFloat(tooltipItem.yLabel as string))} m3/h`
            }
        }
      }
    }
  }
  
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  }
}