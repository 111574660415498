import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, Vue, Watch } from 'types-vue'

@Component
export default class Main extends Vue {
  @MapAction(Store.UserManagerUsers) protected obtainUsers: () => Promise<any>
  @MapAction(Store.UserManagerGroups) protected obtainGroups: () => Promise<void>
  @MapAction(Store.UserManagerUsers) protected processUrlQueryParams: (
    queryParams: any
  ) => Promise<void>
  @MapAction(Store.UserManagerUsers) protected cleanUserList: () => Promise<void>
  @MapAction(Store.UserManagerUsers) protected downloadUserList: () => Promise<any>
  @MapAction(Store.UserManagerUsers) private newPageSize: (pageSize: number) => number

  protected activeName: string = ''
  protected loading: boolean = false

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }

  protected async handleDownloadUserList() {
    try {
      const response = await this.downloadUserList()
      const fileDownloadUrl = response.data
      window.location.href = fileDownloadUrl
    } catch (err) {
      this.$notify({
        type: 'error',
        title: 'Error',
        message: this.$t('auth forgotPassword.requestError').toString()
      })
    }
  }

  @Watch('$route.query.tab', { immediate: true })
  protected onChangeQueryTab() {
    if (this.activeName !== this.$route.query.tab) this.activeName = this.$route.query.tab as string

    if (_.isEmpty(this.$route.query.tab)) this.goToNewTab('users')
  }

  protected onTabClick() {
    this.goToNewTab(this.activeName)
  }

  protected goToNewTab(tab: string) {
    this.$router.push({ ...this.$route, query: { ...this.$route.query, tab } })
  }

  @Watch('$route.query', { immediate: true })
  protected async onChangeRouteParams() {
    try {
      this.loading = true
      await this.processUrlQueryParams(this.$route.query)
      await this.cleanUserList()
      await this.obtainUsers()
    } catch (error) {
      console.error(error)
      this.$notify({
        type: 'error',
        title: `Error`,
        message: 'Ha ocurrido un error al obtener los datos de los usuarios'
      })
    } finally {
      this.loading = false
    }
  }

  protected async created() {
    try {
      this.loading = true
      await this.obtainGroups()
    } catch (error) {
      this.$notify({
        type: 'error',
        title: `Error ${error?.response?.status}`,
        message: 'Ha ocurrido un error al obtener los datos de los usuarios'
      })
    } finally {
      this.loading = false
    }
  }
}
