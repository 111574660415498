var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var tablet = ref.tablet
          return [
            _c(
              "transition",
              { attrs: { name: "fade-to-top", appear: "" } },
              [
                _c("el-card", { staticClass: "process-section" }, [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _c("Core-Title", { attrs: { loading: _vm.loading } }, [
                        _c("h3", [_vm._v("Metricas de los procesos")])
                      ]),
                      _c("Dashboard-ProcessMetricsTimeFilter", {
                        on: { change: _vm.newFilterDate }
                      }),
                      _c("Dashboard-ProcessTypeFilter", {
                        staticClass: "process-type-filter",
                        attrs: { size: "mini" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      tablet
                        ? _c("Dashboard-ProcessesMetricsTableMobile", {
                            attrs: {
                              metrics: _vm.metrics,
                              loading: _vm.loading
                            }
                          })
                        : _c("Dashboard-ProcessesMetricsTable", {
                            attrs: {
                              metrics: _vm.metrics,
                              loading: _vm.loading
                            }
                          })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }