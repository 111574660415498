import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import ChangePasswordBody from '../../models/connections/api/ChangePasswordBody'
import SigninFormUser from '../../models/SigninFormUser'
import { AuthPages } from '../../router'

@Component
export default class ChangePassword extends Vue {

  protected loading: boolean = false

  @MapAction(Store.ChangePassword) private changePassword: (data: ChangePasswordBody) => Promise<any>
  @MapAction(Store.Signin) private signin: (data: SigninFormUser) => Promise<any>
  @MapGetter(Store.Signin) protected username: string
  @MapGetter(Store.Signin) protected password: string
  
  protected async onSubmit(newPassword: string) {
    const email = this.username
    const oldPassword = this.password

    try {
      this.loading = true
      await this.changePassword({ email, oldPassword, newPassword })
      await this.signin({ password: newPassword, username: this.username})
    } catch (error) {
      this.$notify.error({ title: 'Error', message: 'Error al cambiar la contraseña, inténtalo de nuevo.'})
      this.$router.push({ name: AuthPages.Signin })
    } finally {
      this.loading = false
    }

  }

  created() {
    if(_.isNil(this.username) || _.isNil(this.password)) {
      this.$router.push({ name: AuthPages.Signin })
    }
  }
}