import { RouteConfig } from 'vue-router'
import AuthPage from './pages/AuthPage/AuthPage.vue'
import ChangePassword from './pages/ChangePassword/ChangePassword.vue'
import ConfirmPassword from './pages/ConfirmPassword/ConfirmPassword.vue'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword.vue'
import SelectGroup from './pages/SelectGroup/SelectGroup.vue'
import Signin from './pages/Signin/Signin.vue'
import Mfa from './pages/Mfa/Mfa.vue'
import MfaConfig from './pages/MfaConfig/MfaConfig.vue'
import { Store } from '@/Core/Models/Stores'
import { MapGetter } from 'types-vue'
import { SessionVariables } from '@/Core/enum/SessionVariables'
import router from '@/Core/router'

export enum AuthPages {
  Signin = 'Signin',
  Register = 'Register',
  ForgotPassword = 'ForgotPassword',
  ConfirmPassword = 'ConfirmPassword',
  ChangePassword = 'ChangePassword',
  SelectGroup = 'SelectGroup',
  Mfa = 'Mfa',
  MfaConfig = 'MfaConfig'
}

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: AuthPage,
    children: [
      {
        path: 'signin',
        name: AuthPages.Signin,
        component: Signin,
        meta: { isPublic: true }
      },
      {
        path: 'select-group',
        name: AuthPages.SelectGroup,
        meta: { isPublic: false },
        component: SelectGroup
      },
      {
        path: 'forgot-password',
        name: AuthPages.ForgotPassword,
        meta: { isPublic: true },
        component: ForgotPassword
      },
      {
        path: 'confirm-password',
        name: AuthPages.ConfirmPassword,
        meta: { isPublic: true },
        component: ConfirmPassword
      },
      {
        path: 'change-password',
        name: AuthPages.ChangePassword,
        meta: { isPublic: true },
        component: ChangePassword
      },
      {
        path: 'mfa',
        name: AuthPages.Mfa,
        meta: { isPublic: true },
        component: Mfa,
        beforeEnter: (to, from, next) => {
          const session = sessionStorage.getItem(SessionVariables.Session)

          if (!!session) {
            return next()
          }

          router.push({ name: AuthPages.Signin })
        }
      },
      {
        path: 'mfa-config',
        name: AuthPages.MfaConfig,
        meta: { isPublic: true },
        component: MfaConfig
      }
    ]
  }
]

export default routes
