import { SessionVariables } from '@/Core/enum/SessionVariables'
import { Component, Vue } from 'types-vue'
import { decode } from 'jsonwebtoken'
import { Permission, Rol, Roles, Permissions } from '@/Modules/UsersManager/models'
import _ from 'lodash'

@Component
export default class PermissionMixin extends Vue {
  public rol: Rol = null
  public permission: Permission = null

  public get isMarketerRol(): boolean {
    return _.isEqual(this.rol?.code, Roles.Marketer?.code)
  }
  public get isDistributorRol(): boolean {
    return _.isEqual(this.rol?.code, Roles.Distributor?.code)
  }
  public get isSystemRol(): boolean {
    return _.isEqual(this.rol?.code, Roles.System?.code)
  }

  public get isReadPermission(): boolean {
    return _.isEqual(this.permission?.code, Permissions.Read?.code)
  }
  public get isAdminPermission(): boolean {
    return _.isEqual(this.permission?.code, Permissions.Admin?.code)
  }

  private decodeToken() {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    const decodedToken = decode(token) as any

    this.rol = _.find(Roles.list, { code: decodedToken.userRol })
    this.permission = _.find(Permissions.list, { code: decodedToken.userPermissions })
  }

  created() {
    this.decodeToken()
  }
}
