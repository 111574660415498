import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Prop, Vue } from 'types-vue'
import { ReportModel } from '../../../models'

enum Commands {
  delete = 'delete'
}

@Component
export default class ListItemMenu extends Vue {
  
  @Prop({ required: true })
  protected report: ReportModel

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.ReportsHistorical)
  protected removeHistoricalReport: (report: ReportModel) => Promise<any>



  get commandDelete(): string {
    return Commands.delete
  }


  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar informe "${this.report.keyname}"`
    const message = `¿Estas seguro de eliminar el informe ${this.report.keyname}?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'Informe eliminado'
    const message = 'Se ha eliminado el informe correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar informe'
    const message = `Ha ocurrido un error al intentar eliminar el informe con keyname ${this.report.keyname}`
    
    this.$notify({ title, message, type: 'error' })
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeHistoricalReport(this.report)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }
  
}