var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-timeline-item",
    { attrs: { timestamp: _vm.timestamp, placement: "top" } },
    [
      _c("el-card", { staticClass: "is-table" }, [
        _c("div", { staticClass: "header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.versionNumber))]),
          _c("span", [_vm._v("-")]),
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ]),
        _c(
          "ul",
          _vm._l(_vm.items, function(item, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(item))])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }