import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import ProcessMetrics from '../connections/api/ProcessMetrics'
import ProcessMetricsResponse from '../models/api/ProcessMetricsResponse'
import { ActionContext } from 'vuex'
import moment from 'moment'


@Module({ namespaced: true })
export default class ProcessCountWitget extends VuexModule {
  
  private _processLoading: boolean = false
  private _openedProcessesCount: number = null
  private _closedProcessesCount: number = null
  private _notValidatedProcessesStepsCount: number = null
  private _processStartDate: string = '2019-01-01'
  private _processEndDate: string = moment().format('YYYY-MM-DD')



  @Getter() protected openedProcessesCount(): number { return this._openedProcessesCount }
  @Getter() protected closedProcessesCount(): number { return this._closedProcessesCount }
  @Getter() protected notValidatedProcessesStepsCount(): number { return this._notValidatedProcessesStepsCount }
  @Getter() protected processStartDate(): string { return this._processStartDate }
  @Getter() protected processEndDate(): string { return this._processEndDate }
  @Getter() protected processLoading(): boolean { return this._processLoading }


  

  @Mutation() protected setProcessStartDate(value: string) { this._processStartDate = value }
  @Mutation() protected setProcessEndDate(value: string) { this._processEndDate = value }
  @Mutation() protected setProcessLoading(value: boolean) { this._processLoading = value }

  @Mutation()
  protected setMetrics(metrics: ProcessMetricsResponse) {
    this._closedProcessesCount = metrics.closedProcessesCount
    this._openedProcessesCount = metrics.openedProcessesCount
    this._notValidatedProcessesStepsCount = metrics.notValidatedProcessesStepsCount
  }




  @Action({ useContext: true })
  protected async obtainMetrics(ctx: ActionContext<any, any>): Promise<void> {
    const startDate = ctx.getters.processStartDate
    const endDate = ctx.getters.processEndDate

    try {
      ctx.commit('setProcessLoading', true)
      const response = await ProcessMetrics.getMetrics(startDate, endDate)
      ctx.commit('setMetrics', response.data)
    } catch (error) {
      
    } finally {
      ctx.commit('setProcessLoading', false)
    }
  }

  @Action({ useContext: true })
  protected onChangeProcessDateFilter(ctx: ActionContext<any, any>, payload: { startDate: string, endDate: string}) {
    ctx.commit('setProcessStartDate', payload.startDate)
    ctx.commit('setProcessEndDate', payload.endDate)
    ctx.dispatch('obtainMetrics')
  }
}
