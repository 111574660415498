import { Store } from '@/Core/Models/Stores'
import { Bill } from '@/Modules/Bills/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class Header extends Vue {
  @MapGetter(Store.BillDetails) protected bill: Bill
  @MapGetter(Store.BillDetails) protected loading: boolean

  protected get type(): string { return this?.bill?.billType?.description || '' }
  protected get code(): string { return this?.bill?.billNum || 'Cargando factura' }
}