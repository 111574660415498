import { Store } from '@/Core/Models/Stores'
import moment from 'moment'
import { Component, MapAction, Prop, Vue } from 'types-vue'
import { Holiday, HolidayForm } from '../../models'

@Component
export default class CreateHolidayDialog extends Vue {

  @MapAction(Store.Holidays) protected createHoliday: (holiday: Holiday) => Promise<void>
  
  
  protected showing: boolean = false
  protected loading: boolean = false



  private formatHoliday(holidayForm: HolidayForm): Holiday {
    return {
      date: moment(holidayForm.date).format('YYYY-MM-DD'),
      description: holidayForm.description
    }
  }

  private successNotify(holiday: Holiday) {
    this.$notify({ type: 'success', title: 'Se ha creado correctamente', message: `El festivo "${holiday.description}" con fecha ${holiday.date} se ha creado correctamente`})
  }

  private errorNotify(error) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al crear el festivo'})
  }

  
  protected async onSubmitCreate(holidayForm: HolidayForm) {
    try {
      this.loading = true
      const holiday = this.formatHoliday(holidayForm)
      await this.createHoliday(holiday)
      this.successNotify(holiday)
      this.showing = false
    } catch (error) {
      this.errorNotify(error)
    } finally {
      this.loading = false
    }
  }

  
  public show() {
    this.showing = true
  }
}