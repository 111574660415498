import { Store } from '@/Core/Models/Stores'
import { Bill } from '@/Modules/Bills/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class ProcessSection extends Vue {
  @MapGetter(Store.BillDetails) protected bill: Bill


  protected get elements(): any[] {

    return [
      { name: 'Tipo de mensaje', value: `${this.bill.messageType.code} - ${this.bill.messageType.description}` },
      { name: 'Tipo de proceso', value: `${this.bill.processMaster.code} - ${this.bill.processMaster.description}` },
      { name: '', value: ''},
    ]
      
  }
}