var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: { "empty-text": _vm.emptyText, data: _vm.cups }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "CUPS", prop: "generalData.cups" }
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "DISTRIBUIDOR",
                            prop: "generalData.distribuidor"
                          }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "FECHA ALTA",
                            prop: "generalData.fechaAlta"
                          }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "DETALLES", width: "140" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickDetails(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Ver detalles "),
                                        _c("i", {
                                          staticClass: "el-icon-top-right"
                                        })
                                      ]
                                    ),
                                    _c("span")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }