import { Store } from '@/Core/Models/Stores'
import { QueryParams } from '@/Modules/Processes/definitions/QueryParams'
import { ProcessesPages } from '@/Modules/Processes/router'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

@Component
export default class ProcessesSection extends Vue {
  
  
  @MapGetter(Store.ProcessCountWitget) protected openedProcessesCount: number
  @MapGetter(Store.ProcessCountWitget) protected closedProcessesCount: number
  @MapGetter(Store.ProcessCountWitget) protected notValidatedProcessesStepsCount: number
  @MapGetter(Store.ProcessCountWitget) protected processLoading: boolean
  @MapAction(Store.ProcessCountWitget) protected obtainMetrics: () => Promise<any>
  @MapAction(Store.ProcessCountWitget) protected onChangeProcessDateFilter: (payload: { startDate: string, endDate: string}) => Promise<any>

  
  
  protected get showOpenedProcesses(): boolean { return _.isNumber(this.openedProcessesCount) }
  
  protected get showClosedProcesses(): boolean { return _.isNumber(this.closedProcessesCount) }
  
  protected get showNotValidatedProcessesSteps(): boolean { return _.isNumber(this.notValidatedProcessesStepsCount) }

  protected get showDoughnut(): boolean {
    return this.showOpenedProcesses && this.showClosedProcesses && this.showNotValidatedProcessesSteps
  }



  protected onClickOpenedProcesses() {
    this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'OPENED' } })
  }

  protected onClickClosedProcesses() {
    this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'COMPLETED' } })
  }

  protected onClickNotValidatedProcesses() {
    this.$router.push({ name: ProcessesPages.InProcess, query: { [QueryParams.Status]: 'REJECTED' } })
  }


  created() {
    this.obtainMetrics()
  }
}