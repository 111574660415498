var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        [
          _c(
            "Core-FiltersDrawer",
            {
              staticClass: "filter-button",
              attrs: {
                totalActiveFilter: _vm.totalActiveFilter,
                buttonLabel: "Filtros de usuarios"
              }
            },
            [
              _c("UsersManager-ProcessFilters", {
                attrs: { page: "users-manager" }
              })
            ],
            1
          ),
          _vm.isAdminPermission
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onClickCreateButton }
                },
                [_vm._v("Crear usuario")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("UsersManager-UsersTable", { on: { edit: _vm.onEditUser } }),
      _c("UsersManager-UserCreationDialog", { ref: "createDialog" }),
      _c("UsersManager-UserEditionDialog", { ref: "editDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }