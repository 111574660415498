import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
import { FormConfig } from '@/Modules/Forms/models'
import CoreReports from '@/Modules/Reports/connections/api/CoreReports'
import { ReportsApi } from '@/Modules/Reports/connections/api/Reports'
import { AxiosError } from 'axios'
import moment from 'moment'
import { Component, MapGetter, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class CsReport extends Vue {
  
  
  protected showing: boolean = false
  protected loading: boolean = false
  protected completed: boolean = false


  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean  
  

  protected get formOptions(): FormConfig {
    return {
      label: 'form',
      submitButton: 'Generar informe CS',
      loading: this.loading,
      items: [
        {
          name: 'date',
          type: 'daterange',
          label: 'Selecciona fecha inicio y fecha fin'
        },
        // { name: 'distributorCodes', type: 'select', label: 'Selecciona distribuidores', default: '0123', options: [], disabled: true },
        {
          type: 'select',
          name: 'messageTypeCodes',
          label: 'Selecciona los mensajes',
          options: `${CORE_URL}/process-message-types`,
          valueQuery:'code',
          multiple: true,
        },
      ],
      rules: {
        date:      [{ required: true, trigger: 'blur',   message: 'El mes es obligatorio' }],
        extension: [{ required: true, trigger: 'blur',   message: 'El tipo es obligatorio' }],
      }
    }
  }


  public show() {
    this.showing = true
  }

  protected async onSubmit(form: { date: [string, string], messageTypeCodes: string[] }) {
    const { date, messageTypeCodes } = form
    const [fromDateRaw, toDateRaw] = date
    const fromDate = moment(fromDateRaw).format('YYYY-MM-DD')
    const toDate = moment(toDateRaw).format('YYYY-MM-DD')
    const distributorCodes = ['0234']
    
    try {
      this.loading = true
      await CoreReports.generateCsReport({ fromDate, toDate, messageTypeCodes, distributorCodes })
      this.completed = true
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al generar el reporte.'
      
      console.error(err)
      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }
}