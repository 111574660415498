import { Component, mixins, Vue } from 'types-vue'
import ObtainTokenEmailMixin from '../../mixins/ObtainTokenEmail';
import { AuthPages } from '../../router';
import AuthPage from '../AuthPage/AuthPage';

@Component
export default class SelectGroup extends mixins(ObtainTokenEmailMixin) {


  protected onClickReturn() {
    this.$router.push({ name: AuthPages.Signin })
  }
}