import { DatesMixin } from '@/Core/mixins/Dates'
import { FileEditorPages } from '@/Modules/FilesEditor/router'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import moment from 'moment'
import { Component, mixins, Prop, Vue } from 'types-vue'
import { ProcessModel, ProcessStepModel } from '@/Modules/Processes/models'
import { FlowProcessesStepsApi } from '@/Modules/Processes/connections/api/FlowProcessSteps'
import { ProcessStepDownloadResponse } from '@/Modules/Processes/models/api'

@Component
export default class TimeLineItem extends mixins(PermissionMixin, DatesMixin) {
  
  @Prop({ required: true }) protected process: ProcessModel
  @Prop({ required: true }) protected item: ProcessStepModel
  @Prop({ required: false, default: false }) protected isLast: boolean
  

  get customClass(): object { return { invalid: !this.item.validated } }
  get timelineColor(): string { return (this.item.validated) ? 'green' : 'red' }
  get dateFromNow(): string { return this.getLocalDate(this.item.createdAt).fromNow() }
  get date(): string { return this.getLocalDate(this.item.createdAt).format('LLL') }
  get title(): string { return this.item.messageTypeDescription }
  get code(): string { return `${this.item.messageTypeCode}_${this.process.processCode}` }
  get validatedIcon(): string { return this.getCheckIcon(this.item.validated) }
  get validatedLabel(): string { return (this.item.validated) ? 'Fichero validado' : 'Fichero invalido' }
  get canEdit(): boolean { return !this.item.validated }

  // METHODS

  private getCheckIcon(validator: boolean): string {
    return (validator) ? 'el-icon-check' : 'el-icon-close'
  }

  protected goToEdit() {
    this.$router.push({ name: FileEditorPages.Init, query: { fileKey: this.item.keyname }})
  }

  protected async downloadProcessStep() {
    const downloadProcessStepsResponse = await FlowProcessesStepsApi.downloadProcessById(this.item.id) as ProcessStepDownloadResponse
    window.open(downloadProcessStepsResponse.data, '_blank').focus();

  }
  
}