import { Component, Prop, Vue } from 'types-vue'
import { RawLocation } from 'vue-router'
import { ReportsPages } from '../../router'

@Component
export default class SuccessMessageWithHistoricalLink extends Vue {
  @Prop({ required: true, type: String }) protected reportName: string

  // GETTERS
  protected get historicalLocation(): RawLocation {
    return { name: ReportsPages.Historical }
  }
}