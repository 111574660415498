import AbstractApi, { Method } from "@/Core/api/AbstractApi"
import { AxiosPromise } from "axios"
import { GetHolidaysRequestParams, GetHolidaysResponse, Holiday } from "../../models"

const CORE_URL = process.env.VUE_APP_CORE_URL

const Path = {
  Holidays: `${CORE_URL}/holidays`,
}

class HolidaysSingleton extends AbstractApi{
  private static _instance: HolidaysSingleton
  private constructor() { super() }

  public static get instance(): HolidaysSingleton {
    if (!HolidaysSingleton._instance) HolidaysSingleton._instance = new HolidaysSingleton();
    return HolidaysSingleton._instance;
  }


  public getAll(startDate: string, endDate: string): AxiosPromise<GetHolidaysResponse> {
    const params: GetHolidaysRequestParams = { startDate, endDate }
    
    return this.request({
      method: Method.GET,
      url: Path.Holidays,
      params
    })
  }

  public create(data: Holiday): AxiosPromise<any> {
    return this.request({
      method: Method.POST,
      url: Path.Holidays,
      data
    })
  }

  public update(data: Holiday): AxiosPromise<any> {
    return this.request({
      method: Method.PATCH,
      url: Path.Holidays,
      data
    })
  }

  public delete(data: Holiday): AxiosPromise<any> {
    return this.request({
      method: Method.DELETE,
      url: Path.Holidays,
      data
    })
  }
}

export const Holidays = HolidaysSingleton.instance