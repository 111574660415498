import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import List from './pages/MarketerList/MarketerList.vue'
import { Roles } from '../UsersManager/models'

export enum UnprocessedFilesPages {
  List = 'unprocessed-marketer-list',
  MarketerList = 'unprocessed-marketer-list'
}

const routes: Array<RouteConfig> = [
  {
    path: '/unprocessedMarketer',
    component: Page,
    children: [
      {
        path: '',
        name: UnprocessedFilesPages.List,
        meta: { menuActive: 'unprocessedMarketer' },
        component: List
      }
    ]
  }
]

export default routes
