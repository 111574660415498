import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'


@Component
export default class BillingWidget extends Vue {

  @MapAction(Store.BillingWitget) private newBillingPeriod: (period: string) => Promise<any>
  @MapAction(Store.BillingWitget) private obtainBilling: () => Promise<any>

  @MapGetter(Store.BillingWitget) protected billingLoading: boolean

  

  protected async onChangeBillingPeriod(period: string) {
    this.newBillingPeriod(period)
    await this.obtainBilling()
  }

}