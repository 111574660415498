var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.metrics, function(metric, i) {
      return _c("div", { key: i }, [
        _c("h4", [
          _vm._v(_vm._s(metric.messageType) + "_" + _vm._s(metric.processCode))
        ]),
        _c("div", { staticClass: "list-items" }, [
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "CARGADOS")
                }
              }
            },
            [
              _c("span", [_vm._v("CARGADOS")]),
              _c("span", [_vm._v(_vm._s(metric.completed))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "ACEPTADOS")
                }
              }
            },
            [
              _c("span", [_vm._v("ACEPTADOS")]),
              _c("span", [_vm._v(_vm._s(metric.accepted))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "RECHAZADOS")
                }
              }
            },
            [
              _c("span", [_vm._v("RECHAZADOS")]),
              _c("span", [_vm._v(_vm._s(metric.rejected))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "PENDIENTES")
                }
              }
            },
            [
              _c("span", [_vm._v("PENDIENTES")]),
              _c("span", [_vm._v(_vm._s(metric.pending))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "CANCELADOS")
                }
              }
            },
            [
              _c("span", [_vm._v("CANCELADOS")]),
              _c("span", [_vm._v(_vm._s(metric.cancelled))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "ACTIVADOS")
                }
              }
            },
            [
              _c("span", [_vm._v("ACTIVADOS")]),
              _c("span", [_vm._v(_vm._s(metric.uploaded))])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.goToProcess(metric, "")
                }
              }
            },
            [
              _c("span", [_vm._v("DÍAS RESPUESTA")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("filterTimeInDays")(metric.responseTimeInDays))
                )
              ])
            ]
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }