var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "init-page" } }, [
    _vm.loading
      ? _c("span", { staticClass: "loading" }, [
          _c("i", { staticClass: "el-icon-loading" }),
          _vm._v(
            ' Cargando los datos del fichero "' + _vm._s(_vm.fileKey) + '"'
          )
        ])
      : _c(
          "div",
          [
            _c("Core-Title", { attrs: { loading: _vm.saveLoading } }, [
              _c("div", { staticClass: "title-content" }, [
                _c("h2", [_vm._v("Editar fichero")]),
                _c("span", [_vm._v(_vm._s(_vm.fileKey))])
              ])
            ]),
            _c(
              "el-card",
              [
                _c("FilesEditor-TreeForm", {
                  attrs: { file: _vm.file, loading: _vm.saveLoading },
                  on: { submit: _vm.onSubmit }
                })
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }