import { Store } from '@/Core/Models/Stores';
import _ from 'lodash';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { QueryParams } from '../../definitions/QueryParams';
import { Bill } from '../../models';
import Bills from '../../store/Bills.store';

@Component
export default class Main extends Vue {

  @MapAction(Store.Bills) protected obtainBills: () => Promise<void>
  @MapAction(Store.Bills) private processUrlQueryParams: (queryParams: any) => Promise<any>
  @MapAction(Store.Bills) private newPageSize: (pageSize: number) => number
  
  @MapGetter(Store.Bills) protected listLoading: boolean
  @MapGetter(Store.Bills) protected pageSize: number
  @MapGetter(Store.Bills) protected totalElements: number
  @MapGetter(Store.Bills) protected billList: Bill[]
  @MapGetter(Store.Bills) protected isCupsClickable: boolean
  @MapGetter(Store.Bills) protected totalActiveFilter: number
  @MapGetter(Store.SelectedBills) protected selectedBills: Bills[]

    
  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }


  /**
   * WATCHERS
   */

  @Watch('$route', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    const orderPropIsNull = _.isNil(this.$route.query[QueryParams.OrderProp])
    const orderDirectionIsNull = _.isNil(this.$route.query[QueryParams.OrderDirection])
    
    if (orderPropIsNull) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, [QueryParams.OrderProp]: 'createdAt' }})
      return
    }

    if (orderDirectionIsNull) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, [QueryParams.OrderDirection]: 'DESC' }})
      return
    }

    await this.processUrlQueryParams(this.$route.query)
  }
}