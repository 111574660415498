var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Core-Title", { attrs: { loading: _vm.loading } }, [
    _c("div", { staticClass: "bill-header" }, [
      _c("h2", [_vm._v(_vm._s(_vm.code))]),
      _c("span", [_vm._v(_vm._s(_vm.type))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }