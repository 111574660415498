var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link" },
            [
              _c("el-button", {
                attrs: {
                  id: "item-menu-" + _vm.rowIndex,
                  type: "text",
                  icon: "el-icon-more-outline"
                }
              })
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                {
                  attrs: {
                    id: "see-details-button-" + _vm.rowIndex,
                    command: _vm.commandSeeDetails
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-view" }),
                  _vm._v(" Ver detalles")
                ]
              ),
              _vm.haveRemovePermissions
                ? _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        id: "remove-button-" + _vm.rowIndex,
                        command: _vm.commandDelete
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-delete" }),
                      _vm._v(" Eliminar")
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }