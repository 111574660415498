import { Store } from '@/Core/Models/Stores'
import moment from 'moment'
import { Component, MapAction, Vue } from 'types-vue'
import { Holiday, HolidayForm } from '../../models'

@Component
export default class UpdateHolidayDialog extends Vue {

  @MapAction(Store.Holidays) protected updateHoliday: (holiday: Holiday) => Promise<void>


  protected holiday: Holiday = null
  protected showing: boolean = false
  protected loading: boolean = false

  
  private formatHoliday(holidayForm: HolidayForm): Holiday {
    return {
      date: moment(holidayForm.date).format('YYYY-MM-DD'),
      description: holidayForm.description
    }
  }

  private successNotify(holiday: Holiday) {
    this.$notify({ type: 'success', title: 'Se ha guardado correctamente', message: `El festivo "${holiday.description}" con fecha ${holiday.date} se ha guardado correctamente`})
  }

  private errorNotify(error) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al guardar el festivo'})
  }

  
  protected async onSubmit(holidayForm: HolidayForm) {
    try {
      this.loading = true
      const holiday = this.formatHoliday(holidayForm)
      await this.updateHoliday(holiday)
      this.successNotify(holiday)
      this.showing = false
    } catch (error) {
      this.errorNotify(error)
    } finally {
      this.loading = false
    }
  }


  public show(holiday: Holiday) {
    this.holiday = holiday
    this.showing = true
  }
}