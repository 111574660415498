import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { FlowProcessesApi } from '../connections/api/FlowProcesses'
import { ProcessesListResponse } from '../models/api'
import { ProcessFilters, ProcessModel } from '../models'
import { AxiosError } from 'axios'
import { QueryParams } from '../definitions/QueryParams'
import { ProcessStates } from '../enums/ProcessStates'

@Module({ namespaced: true })
export default class extends VuexModule {
  private _processes: ProcessModel[] = []
  private _processTypeFilter: string[] = []
  private _statusFilter: ProcessStates | null = null
  private _referenceFilter: string = null
  private _cupsFilter: string = null
  private _fromDateFilter: string = null
  private _toDateFilter: string = null
  private _lastMessageTypeFilter: string = null
  private _messageTypeWithinInterval: string = null
  private _messageTypeDownloadedFilter: string = null
  private _processReasonFilter: string = null
  private _ingoingMarketerFilter: string = null
  private _fromMessageDate: string = null
  private _toMessageDate: string = null
  private _pageSize: number = 0
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null
  private _listLoading: boolean = true
  private _showDownloadHistory: boolean = false
  private _initialRender: boolean = true

  @Getter() public orderProp(): string {
    return this._orderProp
  }
  @Getter() public orderDirection(): string {
    return this._orderDirection
  }
  @Getter() public processesList(): ProcessModel[] {
    return this._processes
  }
  @Getter() public processTypeFilter(): any[] {
    return this._processTypeFilter
  }
  @Getter() public statusFilter(): ProcessStates | '' {
    return this._statusFilter
  }
  @Getter() public referenceFilter(): any {
    return this._referenceFilter
  }
  @Getter() public cupsFilter(): string {
    return this._cupsFilter
  }
  @Getter() public fromDateFilter(): string {
    return this._fromDateFilter
  }
  @Getter() public toDateFilter(): string {
    return this._toDateFilter
  }
  @Getter() public lastMessageTypeFilter(): string {
    return this._lastMessageTypeFilter
  }
  @Getter() public processReasonFilter(): string {
    return this._processReasonFilter
  }
  @Getter() public ingoingMarketerFilter(): string {
    return this._ingoingMarketerFilter
  }
  @Getter() public messageTypeWithinInterval(): string {
    return this._messageTypeWithinInterval
  }
  @Getter() public messageTypeDownloadedFilter(): string {
    return this._messageTypeDownloadedFilter
  }
  @Getter() public fromMessageDate(): string {
    return this._fromMessageDate
  }
  @Getter() public toMessageDate(): string {
    return this._toMessageDate
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public listLoading(): boolean {
    return this._listLoading
  }
  @Getter() public showDownloadHistory(): boolean {
    return this._showDownloadHistory
  }

  @Getter() public initialRender(): boolean {
    return this._initialRender
  }

  @Getter() public allProcessFilters(): ProcessFilters {
    const filters = {
      processCodes: this._processTypeFilter,
      numReference: this._referenceFilter,
      cupsList: this._cupsFilter,
      statusList: this._statusFilter,
      toDate: this._toDateFilter,
      fromDate: this._fromDateFilter,
      lastMessageType: this._lastMessageTypeFilter,
      messageTypeWithinInterval: this._messageTypeWithinInterval,
      downloaded: this._messageTypeDownloadedFilter,
      fromMessageDate: this._fromMessageDate,
      toMessageDate: this._toMessageDate,
      processReason: this._processReasonFilter,
      marketerCode: this._ingoingMarketerFilter
    }

    return _.omitBy(filters, _.isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!_.isNil(this._cupsFilter)) result++
    if (!_.isNil(this._statusFilter)) result++
    if (!_.isNil(this._referenceFilter)) result++
    if (!_.isNil(this._fromDateFilter)) result++
    if (!_.isNil(this._toDateFilter)) result++
    if (!_.isNil(this._lastMessageTypeFilter)) result++
    if (!_.isNil(this._messageTypeWithinInterval)) result++
    if (!_.isNil(this._messageTypeDownloadedFilter)) result++
    if (!_.isNil(this._fromMessageDate)) result++
    if (!_.isNil(this._toMessageDate)) result++
    if (!_.isNil(this._processReasonFilter)) result++
    if (!_.isNil(this._ingoingMarketerFilter)) result++
    if (!!this._processTypeFilter?.length) result++

    return result
  }

  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }
  @Mutation() protected setListLoading(value: boolean) {
    this._listLoading = value
  }
  @Mutation() protected setOrderProp(value: string) {
    this._orderProp = value
  }
  @Mutation() protected setOrderDirection(value: string) {
    this._orderDirection = value
  }

  @Mutation() protected setProcessTypeFilter(value) {
    const hasChanged = value !== this._processTypeFilter

    this._processTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setStatusFilter(value) {
    const hasChanged = value !== this._statusFilter

    this._statusFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setReferenceFilter(value) {
    const hasChanged = value !== this._referenceFilter

    this._referenceFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setCupsFilter(value: string) {
    const hasChanged = value !== this._cupsFilter

    this._cupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setFromDateFilter(value: string) {
    const hasChanged = value !== this._fromDateFilter

    this._fromDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setToDateFilter(value: string) {
    const hasChanged = value !== this._toDateFilter

    this._toDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setLastMessageTypeFilter(value: string) {
    const hasChanged = value !== this._toDateFilter

    this._lastMessageTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMessageTypeWithinInterval(value: string) {
    const hasChanged = value !== this._messageTypeWithinInterval

    this._showDownloadHistory = !_.isEmpty(value)
    this._messageTypeWithinInterval = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMessageTypeDownloadedFilter(value: string) {
    const hasChanged = value !== this._messageTypeDownloadedFilter

    this._messageTypeDownloadedFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessReasonFilterFilter(value: string) {
    const hasChanged = value !== this._processReasonFilter

    this._processReasonFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setIngoingMarketerFilter(value: string) {
    const hasChanged = value !== this._ingoingMarketerFilter

    this._ingoingMarketerFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setFromMessageDate(value: string) {
    const hasChanged = value !== this._fromMessageDate

    this._fromMessageDate = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setToMessageDate(value: string) {
    const hasChanged = value !== this._toMessageDate

    this._toMessageDate = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = isEmpty ? 1 : parseInt(value)
  }

  @Mutation()
  protected setProcesses(data: ProcessesListResponse) {
    this._processes = [...data.flowProcessVos]
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setInitialRender(value: boolean) {
    this._initialRender = value
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setReferenceFilter', queryParams[QueryParams.Reference])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])
    ctx.commit('setStatusFilter', queryParams[QueryParams.Status])
    ctx.commit('setFromDateFilter', queryParams[QueryParams.FromDate])
    ctx.commit('setToDateFilter', queryParams[QueryParams.ToDate])
    ctx.commit('setLastMessageTypeFilter', queryParams[QueryParams.LastMessageType])
    ctx.commit('setOrderProp', queryParams[QueryParams.OrderProp])
    ctx.commit('setOrderDirection', queryParams[QueryParams.OrderDirection])
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setMessageTypeWithinInterval', queryParams[QueryParams.MessageTypeWithinInterval])
    ctx.commit('setMessageTypeDownloadedFilter', queryParams[QueryParams.MessageTypeDownloaded])
    ctx.commit('setFromMessageDate', queryParams[QueryParams.FromMessageDate])
    ctx.commit('setToMessageDate', queryParams[QueryParams.ToMessageDate])
    ctx.commit('setProcessReasonFilterFilter', queryParams[QueryParams.ProcessReason])
    ctx.commit('setIngoingMarketerFilter', queryParams[QueryParams.IngoingMarketer])

    ctx.dispatch('obtainProcesses')
  }

  @Action({ useContext: true })
  protected async obtainProcesses(ctx: ActionContext<any, any>) {
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize
    const fieldOrder = ctx.getters.orderProp
    const orderASCorDESC = ctx.getters.orderDirection
    const filters = ctx.getters.allProcessFilters

    if (!orderASCorDESC || !fieldOrder || _.isNil(pageNumber) || _.isNil(pageSize) || pageSize < 1)
      return

    try {
      ctx.commit('setListLoading', true)
      if (ctx.getters.initialRender) {
        ctx.commit('setInitialRender', false)
      }
      const response = await FlowProcessesApi.getProcessesList({
        ...filters,
        pageNumber,
        pageSize,
        fieldOrder,
        orderASCorDESC
      })
      ctx.commit('setProcesses', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los processos.'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainProcesses')
  }

  @Action({ useContext: true })
  protected async removeProcess(ctx: ActionContext<any, any>, process: ProcessModel): Promise<any> {
    const response = await FlowProcessesApi.removeById(process.id)

    if (response.status === 200) ctx.dispatch('obtainProcesses')
  }

  @Action({ useContext: true })
  protected async updateInitialRender(ctx: ActionContext<any, any>, value: boolean) {
    ctx.commit('setInitialRender', value)
  }
}
