import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, MapGetter, mixins, Vue } from 'types-vue'
import { S3Connector } from '../../models'

@Component
export default class BucketsTable extends mixins(PermissionMixin) {

  @MapAction(Store.S3Connectors) protected obtainS3Connectors: () => Promise<void>
  @MapAction(Store.S3Connectors) protected removeS3Connectors: (s3Connector: S3Connector) => Promise<void>
  @MapGetter(Store.S3Connectors) protected list: S3Connector[]
  
  
  protected showingMenuItem: S3Connector = null
  protected removeLoadingId: number = null

  protected checkShowOptions(item: S3Connector): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.id === item.id
  }

  protected onCellMouseEnter(item: S3Connector): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: S3Connector): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected onRowClick(item: S3Connector, column) {
    if (column.property === 'options') return
    this.$emit('edit', item)
  }

  protected async onClickDelete(item: S3Connector) {
    try {
      await this.$confirm(`¿Estas seguro de eliminiar este conector?`, `Confirmación`, { confirmButtonText: 'Eliminar' })
      try {
        this.removeLoadingId = item.id
        await this.removeS3Connectors(item)
        this.$notify({ type: 'success', title: 'Eliminado', message: `Se ha eliminado el conector correctamente`})
      } catch (error) {
        this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Error al borrar el connector'})
      } finally {
        this.removeLoadingId = null
      }
    } catch (error) {}
  }
  
  protected onClickEdit(item: S3Connector) {
    this.$emit('edit', item)
  }


  async created() {
    await this.obtainS3Connectors()
  }

}