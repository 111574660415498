import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Component, mixins, Prop, Vue } from 'types-vue'
import PasswordChecker from '../PasswordChecker/PasswordChecker'

@Component
export default class NewChangePasswordForm extends mixins(FormValidateMixin) {

  @Prop({ type: Boolean, default: true })
  protected loading: boolean

  protected form = {
    password: '',
    repeatPassword: ''
  }

  protected rules = {
    password: [
      { required: true, message: 'Please input password', trigger: 'blur' },
    ],
    repeatPassword: [
      { required: true, message: 'Please repeat password', trigger: 'blur' },
      { validator: this.checkEqualPassword, trigger: 'blur' },
    ]
  }

  protected checkEqualPassword(rule, value, callback): void {
    if (value !== this.form.password) {
      callback(new Error('Two passwords don\'t match!'));
    } else {
      callback();
    }
  }

  protected async onClickButton() {
    const passwordChecker = this.$refs.passwordChecker as PasswordChecker
    
    if (!await this.validateForm()) return
    if (!passwordChecker.checkPassword()) return
    
    this.$emit('submit', this.form.password)
  }
}