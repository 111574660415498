var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Modificar comercializadora",
        visible: _vm.showing,
        width: "35rem"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _c("Marketers-Form", {
        ref: "form",
        attrs: { loading: _vm.loading, isEditor: "" },
        on: { submit: _vm.onSubmit },
        model: {
          value: _vm.marketer,
          callback: function($$v) {
            _vm.marketer = $$v
          },
          expression: "marketer"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }