import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import _ from "lodash";
import { UnprocessedFilesRequest, UnprocessedFilesResponse, UnprocessedFileUrlRequest, UnprocessedFileUrlResponse } from "../../models/api";


const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  FileList: `${CORE_SERVICE_URL_BASE}/sap-file-errors`,
  FileDownload: `${CORE_SERVICE_URL_BASE}/sap-file-errors/url`
}

class UnprocessedFilesApi extends AbstractApi {


  public getUnprocessedFiles(params: UnprocessedFilesRequest = { page: 1 }): AxiosPromise<UnprocessedFilesResponse> {
    const method = Method.GET
    const url = Path.FileList

    return this.request({ method, url, params })
  }


  public getUnprocessedFileUrl(params: UnprocessedFileUrlRequest): AxiosPromise<UnprocessedFileUrlResponse> {
    const method = Method.GET
    const url = Path.FileDownload

    return this.request({ method, url, params })
  }


  public removeById(id: string): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.FileList}/${id}`

    return this.request({ method, url })
  }

}

export default new UnprocessedFilesApi()