import { Notification } from 'element-ui'
import _, { forEach, orderBy } from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { AtrContractsApi } from '../connections/api/AtrContracts'
import { QueryParams } from '../definitions/QueryParams'
import { AtrContract } from '../models'
import { FilteredAtrContractsResponse } from '../models/api'
import { AxiosError } from 'axios'

@Module({ namespaced: true })
export default class AtrContracts extends VuexModule {
  private _atrContracts: AtrContract[] = null
  private _pageSize: number = 15
  private _currentPage: number = 1
  private _documentnumFilter: string = null
  private _cupsFilter: string = null
  private _activeFilter: string = null
  private _fromDateFilter: string = null
  private _toDateFilter: string = null
  private _totalElements: number = 0
  private _orderProp: string = 'effectiveStartDate'
  private _orderDirection: string = 'DESC'
  private _listLoading: boolean = false
  private _isCupsClickable: boolean = true
  private _details: any = {}
  private _detailsLoading: boolean = false

  @Getter() public atrContracts(): AtrContract[] {
    return this._atrContracts || []
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public documentnumFilter(): string {
    return this._documentnumFilter
  }
  @Getter() public cupsFilter(): string {
    return this._cupsFilter
  }
  @Getter() public activeFilter(): string {
    return this._activeFilter
  }
  @Getter() public fromDateFilter(): string {
    return this._fromDateFilter
  }
  @Getter() public toDateFilter(): string {
    return this._toDateFilter
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public orderProp(): string {
    return this._orderProp
  }
  @Getter() public orderDirection(): string {
    return this._orderDirection
  }
  @Getter() public listLoading(): boolean {
    return this._listLoading
  }
  @Getter() public isCupsClickable(): boolean {
    return this._isCupsClickable
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!_.isNil(this._cupsFilter)) result++
    if (!_.isNil(this._activeFilter)) result++
    if (!_.isNil(this._documentnumFilter)) result++
    if (!_.isNil(this._fromDateFilter)) result++
    if (!_.isNil(this._toDateFilter)) result++

    return result
  }

  @Getter() public details(): AtrContract[] {
    return this._details || []
  }

  @Getter() public detailsLoading(): boolean {
    return this._detailsLoading
  }

  @Mutation() protected setPageSize(value: number) {
    this._pageSize = value
  }
  @Mutation() protected setOrderProp(value: string) {
    this._orderProp = value
  }
  @Mutation() protected setOrderDirection(value: string) {
    this._orderDirection = value
  }
  @Mutation() protected setListLoading(value: boolean) {
    this._listLoading = value
  }

  @Mutation() protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = isEmpty ? 1 : parseInt(value)
  }

  @Mutation() protected setDocumentnumFilter(value: string) {
    const hasChanged = value !== this._documentnumFilter

    this._documentnumFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setCupsFilter(value: string) {
    const hasChanged = value !== this._cupsFilter

    this._cupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setActiveFilter(value: string) {
    const hasChanged = value !== this._activeFilter

    this._activeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setFromDateFilter(value: string) {
    const hasChanged = value !== this._fromDateFilter

    this._fromDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setToDateFilter(value: string) {
    const hasChanged = value !== this._toDateFilter

    this._toDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setAtrContractsResponse(data: FilteredAtrContractsResponse) {
    this._atrContracts = [...data.atrContracts]
    this._totalElements = data.totalElements
  }

  @Mutation() protected setDetailsResponse(data: any) {
    forEach(data, el => {
      if (el.type === '01') {
        el.type = 'Indefinido'
      }
      if (el.type === '02') {
        el.type = 'Anual'
      }
      if (el.type === '03') {
        el.type = 'Trimestral'
      }
      if (el.type === '04') {
        el.type = 'Mensual'
      }
      if (el.type === '05') {
        el.type = 'Diario'
      }
      if (el.type === '06') {
        el.type = 'Intradiario'
      }
    })

    const sortedData = orderBy(data, ['startDate'], ['desc'])

    this._details = { cupsId: data[0].cups, data: sortedData }
  }

  @Mutation() protected setDetailsLoading(value: boolean) {
    this._detailsLoading = value
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setDocumentnumFilter', queryParams[QueryParams.DocumentNum])
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setOrderProp', queryParams[QueryParams.OrderProp])
    ctx.commit('setOrderDirection', queryParams[QueryParams.OrderDirection])
    ctx.commit('setActiveFilter', queryParams[QueryParams.Active])
    ctx.commit('setFromDateFilter', queryParams[QueryParams.FromDate])
    ctx.commit('setToDateFilter', queryParams[QueryParams.ToDate])
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])

    ctx.dispatch('obtainAtrContracts')
  }

  @Action({ useContext: true })
  protected async obtainAtrContracts(ctx: ActionContext<any, any>) {
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize
    const fieldOrder = ctx.getters.orderProp
    const orderASCorDESC = ctx.getters.orderDirection
    const documentnum = ctx.getters.documentnumFilter
    const cupsList = ctx.getters.cupsFilter
    const fromEffectiveStartDate = ctx.getters.fromDateFilter
    const toEffectiveStartDate = ctx.getters.toDateFilter

    let isActive = null

    if (_.isEqual(ctx.getters.activeFilter, '1')) {
      isActive = true
    } else if (_.isEqual(ctx.getters.activeFilter, '0')) {
      isActive = false
    }

    try {
      ctx.commit('setListLoading', true)
      const response = await AtrContractsApi.getFiltered({
        pageNumber,
        pageSize,
        fieldOrder,
        orderASCorDESC,
        documentnum,
        cupsList,
        isActive,
        fromEffectiveStartDate,
        toEffectiveStartDate
      })
      ctx.commit('setAtrContractsResponse', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los contratos ATR.'

      Notification.error({
        title: `Error ${error?.response?.status}`,
        message: messagaError
      })
    } finally {
      ctx.commit('setListLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainDetails(ctx: ActionContext<any, any>, cups: string) {
    ctx.commit('setDetailsLoading', true)
    try {
      const response = await AtrContractsApi.getDetails({ cups })
      ctx.commit('setDetailsResponse', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los detalles.'

      Notification.error({
        title: `Error ${error?.response?.status}`,
        message: messagaError
      })
    } finally {
      ctx.commit('setDetailsLoading', false)
    }
  }
}
