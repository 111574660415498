import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import { Marketer } from '../../models'
import Form from '../Form/Form'

@Component
export default class CreateMarketerDialog extends Vue {

  protected showing: boolean = false
  protected loading: boolean = false


  @MapAction(Store.Marketers) protected createMarketer: (marketer: Marketer) => Promise<void>


  protected async onSubmit(marketer: Marketer) {
    const form = this.$refs.form as Form

    try {
    
      this.loading = true
      await this.createMarketer(marketer)
    
      this.showing = false
      form.clean()
      this.$notify({ type: 'success', title: 'Se ha añadido correctamente', message: `Se ha añadido la comercializadora ${marketer.name} correctamente` })
    
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al intentar crear la comercializadora'})
    } finally {
      this.loading = false
    }
  }



  public show() {
    this.showing = true
  }
}