import Vue, { PluginObject } from 'vue';
// import NetworkNotify from '../../Components/NetworkNotification/NetworkNotification';

interface Options {}

const ProcessNoficationPlugin: PluginObject<Options> = {
  install(Vue, options) {
    Vue.directive('network-notification', {
      bind(_el, _binding, vnode) {
        Vue.prototype.$networkNotify = vnode.componentInstance.notify
      }
    })
  }
}

Vue.use(ProcessNoficationPlugin)