import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { SystemSubject } from "../../models";
import { SystemSubjectGetRequest } from "../../models/api";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  SystemSubjects: `${CORE_SERVICE_URL_BASE}/system-subjects`,
}

class SystemSubjectsSingleton extends AbstractApi {
  private static _instance: SystemSubjectsSingleton
  private constructor() { super() }

  public static get instance(): SystemSubjectsSingleton {
    if (!SystemSubjectsSingleton._instance) SystemSubjectsSingleton._instance = new SystemSubjectsSingleton();
    return SystemSubjectsSingleton._instance;
  }
  

  public get(data: SystemSubjectGetRequest): AxiosPromise<SystemSubject[]> {
    const method = Method.GET
    const url = `${Path.SystemSubjects}`
    const params = data
    
    return this.request({ method, url, params })
  }

}

export const SystemSubjectsApi = SystemSubjectsSingleton.instance