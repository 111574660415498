import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClientForm_48 extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  protected get formOptions(): FormConfig {
    return {
      label: 'Dirección del cliente',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'province',
          label: 'Provincia de la dirección del cliente',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la provincia del cliente',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'city',
          label: 'Municipio de la dirección del cliente',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el municipio del cliente',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription'
        },
        {
          type: 'text',
          name: 'zipcode',
          label: 'Código postal de la dirección del cliente',
          placeholder: 'Introduce el código postal del cliente',
          maxCharacters: 5
        },
        {
          type: 'select',
          name: 'streettype',
          label: 'Tipo de vía de la direccióndel cliente',
          options: `${CORE_URL}/location-street-types`,
          placeholder: 'Selecciona el municipio del cliente',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'street',
          label: 'Vía de la dirección del cliente',
          placeholder: 'Introduce la vía del cliente',
          maxCharacters: 60
        },
        {
          type: 'text',
          name: 'streetnumber',
          label: 'Número de vía de la dirección del cliente',
          placeholder: 'Introduce el número de vía del cliente',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'portal',
          label: 'Portal de la dirección del cliente',
          placeholder: 'Introduce el portal del cliente',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'staircase',
          label: 'Escalera de la dirección del cliente',
          placeholder: 'Introduce la escalera del cliente',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'floor',
          label: 'Piso de la dirección del cliente',
          placeholder: 'Introduce el piso del cliente',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'door',
          label: 'Puerta de la dirección del cliente',
          placeholder: 'Introduce la puerta del cliente',
          maxCharacters: 5
        }
      ],
      rules: {
        province: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ],
        city: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ],
        zipcode: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ],
        streettype: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ],
        street: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ],
        streetnumber: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
