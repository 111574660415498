import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import HistoricalReports from './pages/HistoricalReports/HistoricalReports.vue'
import Downloadable from './pages/Downloadable/Downloadable.vue'
// import OcsumReport from './pages/OcsumReport/OcsumReport.vue'

export enum ReportsPages {
  Downloadable = 'report-downloadable',
  Ocsum = 'report-downloadable-acsum',
  Historical = 'historicalReports',
  Details = 'details'
}

const routes: Array<RouteConfig> = [
  {
    path: '/reports',
    component: Page,
    children: [
      {
        path: 'downloadable',
        name: ReportsPages.Downloadable,
        meta: { menuActive: 'downloadable' },
        component: Downloadable
      },
      {
        path: 'historical',
        name: ReportsPages.Historical,
        meta: { menuActive: 'historical' },
        component: HistoricalReports
      },
    ]
  }
]

export default routes
