import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import { SearchFlowCupsWithContractParams } from '../../models'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  historyPSList: `${CORE_SERVICE_URL_BASE}/flow-cups-with-contract/filtered`,
  historyPSItemDownload: `${CORE_SERVICE_URL_BASE}/flow-cups-with-contract/download`
}

class HistoryPSSingleton extends AbstractApi {
  private static _instance: HistoryPSSingleton
  private constructor() {
    super()
  }

  public static get instance(): HistoryPSSingleton {
    if (!HistoryPSSingleton._instance) HistoryPSSingleton._instance = new HistoryPSSingleton()
    return HistoryPSSingleton._instance
  }

  public getHistoryPSList(
    params: SearchFlowCupsWithContractParams = { pageNumber: 1 }
  ): AxiosPromise<any> {
    const method = Method.GET
    const url = Path.historyPSList
    return this.request({ method, url, params })
  }

  public downloadHistoryPSItem(id: number): AxiosPromise<any> {
    const method = Method.POST
    const url = Path.historyPSItemDownload

    return this.request({ method, url, data: id, headers: { 'Content-Type': 'application/json' } })
  }
}

export const HistoryPSApi = HistoryPSSingleton.instance
