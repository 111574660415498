import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import SearchResult from './pages/SearchResult/SearchResult.vue'

export enum SearcherPages {
  Result = 'searchResult',
}

const routes: Array<RouteConfig> = [
  {
    path: '/search',
    component: Page,
    children: [
      {
        path: ':type/result/:cups',
        name: SearcherPages.Result,
        meta: { },
        component: SearchResult
      }
    ]
  }
]

export default routes