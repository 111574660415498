var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          staticClass: "master-form",
          attrs: { model: _vm.form, "label-position": "top", "status-icon": "" }
        },
        [
          _vm._l(Object.keys(_vm.form), function(item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: { label: _vm._f("startCase")(item), prop: item }
              },
              [
                _vm.isDate(item, _vm.form[item])
                  ? _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        size: "medium",
                        placeholder: "Selecciona un dia"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeDate(item)
                        }
                      },
                      model: {
                        value: _vm.form[item],
                        callback: function($$v) {
                          _vm.$set(_vm.form, item, $$v)
                        },
                        expression: "form[item]"
                      }
                    })
                  : _c("el-input", {
                      attrs: {
                        size: "medium",
                        type: "textarea",
                        disabled: _vm.loading,
                        autosize: ""
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onClickSubmit.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.form[item],
                        callback: function($$v) {
                          _vm.$set(_vm.form, item, $$v)
                        },
                        expression: "form[item]"
                      }
                    })
              ],
              1
            )
          }),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                !!_vm.removeLabel
                  ? _c(
                      "el-button",
                      {
                        staticClass: "remove",
                        attrs: {
                          disabled: _vm.loading,
                          type: "text",
                          size: "medium"
                        },
                        on: { click: _vm.onClickRemove }
                      },
                      [_vm._v(_vm._s(_vm.removeLabel))]
                    )
                  : _c("span"),
                _c(
                  "div",
                  [
                    !!_vm.cancelLabel
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.loading,
                              type: "text",
                              size: "medium"
                            },
                            on: { click: _vm.onClickCancel }
                          },
                          [_vm._v(_vm._s(_vm.cancelLabel))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          size: "medium"
                        },
                        on: { click: _vm.onClickSubmit }
                      },
                      [_vm._v(_vm._s(_vm.submitLabel))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }