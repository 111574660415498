var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.loading } }, [
        _c("h2", [_vm._v("Comercializadoras")])
      ]),
      _c("div", { staticClass: "header" }, [
        _c("p", [
          _vm._v("Gestiona todas las comercializadoras de la aplicación.")
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onClickShowCreateForm }
              },
              [_vm._v("Añadir comercializadora")]
            )
          ],
          1
        )
      ]),
      _c(
        "el-card",
        { staticClass: "is-table" },
        [
          _c("Marketers-MarketersList", {
            on: { edit: _vm.onClickEditMarketer }
          })
        ],
        1
      ),
      _c("Marketers-CreateMarketerDialog", { ref: "createDialog" }),
      _c("Marketers-EditorMarketerDialog", { ref: "editorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }