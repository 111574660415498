import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Permissions, Roles, User } from '@/Modules/UsersManager/models'
import { AxiosError } from 'axios'
import moment from 'moment'
import { Component, Filter, MapAction, MapGetter, mixins, Vue } from 'types-vue'

@Component
export default class UsersTable extends mixins(PermissionMixin) {
  protected showingMenuItem: User = null
  protected removeLoadingEmail: string = null

  @MapGetter(Store.UserManagerUsers) protected usersList: User[]
  @MapGetter(Store.UserManagerUsers) protected nextToken: string
  @MapGetter(Store.UserManagerUsers) protected pageSize: number
  @MapGetter(Store.UserManagerUsers) protected totalElements: number
  @MapGetter(Store.UserManagerUsers) protected restorePasswordLoading: number

  @MapAction(Store.UserManagerUsers) protected deleteUser: (user: User) => Promise<void>
  @MapAction(Store.UserManagerUsers) protected obtainUsers: () => Promise<any>
  @MapAction(Store.UserManagerUsers) protected restoreUserPassword: (
    userEmail: string
  ) => Promise<any>
  @MapAction(Store.UserManagerUsers) protected updateRestorePasswordLoading: (
    loading: boolean
  ) => void

  protected async handleRestoreUserPassword(item: User) {
    this.updateRestorePasswordLoading(true)
    try {
      const response = await this.restoreUserPassword(item.email)
      this.updateRestorePasswordLoading(false)
      if (response.status === 200)
        this.$notify({
          type: 'success',
          title: 'Enviado',
          message: this.$t('auth forgotPassword.requestSuccess').toString()
        })
    } catch (err) {
      this.updateRestorePasswordLoading(false)
      this.$notify({
        type: 'error',
        title: 'Error',
        message: this.$t('auth forgotPassword.requestError').toString()
      })
    }
  }

  protected checkShowOptions(item: User): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.username === item.username
  }

  protected onCellMouseEnter(item: User): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: User): void {
    if (this.showingMenuItem.username === item.username) this.showingMenuItem = null
  }

  protected onRowClick(item: User, column) {
    if (column.property === 'options') return
    this.$emit('edit', item)
  }

  protected async onClickDelete(item: User) {
    try {
      await this.$confirm(`¿Estas seguro de eliminiar el usuario?`, `Confirmación`, {
        confirmButtonText: 'Eliminar'
      })
      try {
        this.removeLoadingEmail = item.email
        await this.deleteUser(item)
        this.$notify({
          type: 'success',
          title: 'Eliminado',
          message: `El usuario "${item.email}" eliminado correctamente`
        })
      } catch (err) {
        const error = err as AxiosError
        const messagaError = error?.response?.data?.message || 'Error al eliminar el usuario.'

        this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      } finally {
        this.removeLoadingEmail = null
      }
    } catch (error) {}
  }

  protected onClickEdit(item: User) {
    this.$emit('edit', item)
  }

  protected onClickLoadMoreUsers() {
    this.obtainUsers()
  }

  protected getPermission(permisionCode: string) {
    return Permissions.list.find(per => per.code === permisionCode)?.name || '-'
  }

  protected getRol(roleCode: string) {
    return Roles.list.find(rol => rol.code === roleCode)?.name || '-'
  }
}
