import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { HistoryContractsApi } from '../connections/api/HistoryContracts'
import { Notification } from 'element-ui'
import { QueryParams } from '../definitions/QueryParams'
import { omitBy, isEmpty, isNil } from 'lodash'

@Module({ namespaced: true })
export default class HistoryContracts extends VuexModule {
  // STATE

  private _historyContracts: any[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _isLoading: boolean = false
  private _cupsFilter: string = null
  private _processTypeFilter: string = null
  private _marketerCodeFilter: string = null
  private _referenceNumber: string = null
  private _messageType: string = null
  private _processReason: string = null

  // GETTERS

  @Getter() public historyContracts(): any[] {
    return this._historyContracts
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public isLoading(): boolean {
    return this._isLoading
  }
  @Getter() public cupsFilter(): string {
    return this._cupsFilter
  }

  @Getter() public allProcessFilters(): {} {
    const filters = {
      cups: this._cupsFilter,
      processCodes: this._processTypeFilter,
      marketerCode: this._marketerCodeFilter,
      numreference: this._referenceNumber,
      messageTypeCode: this._messageType,
      processReason: this._processReason
    }

    return omitBy(filters, isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!isNil(this._cupsFilter)) result++
    if (!isNil(this._processTypeFilter)) result++
    if (!isNil(this._marketerCodeFilter)) result++
    if (!isNil(this._referenceNumber)) result++
    if (!isNil(this._messageType)) result++
    if (!isNil(this._processReason)) result++

    return result
  }

  // MUTATIONS

  @Mutation()
  protected setHistoryContracts(data: any) {
    this._historyContracts = data.content
    this._totalElements = data.totalElements
  }

  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }
  @Mutation() protected setLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmptyValue = isEmpty(value)
    this._currentPage = isEmptyValue ? 1 : parseInt(value)
  }

  @Mutation() protected setCupsFilter(value: string) {
    const hasChanged = value !== this._cupsFilter

    this._cupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessTypeFilter(value: string) {
    const hasChanged = value !== this._processTypeFilter

    this._processTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMarketerCodeFilter(value: string) {
    const hasChanged = value !== this._marketerCodeFilter

    this._marketerCodeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setReferenceNumberFilter(value: string) {
    const hasChanged = value !== this._referenceNumber

    this._referenceNumber = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMessageTypeFilter(value: string) {
    const hasChanged = value !== this._messageType

    this._messageType = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessReasonFilter(value: string) {
    const hasChanged = value !== this._processReason

    this._processReason = value
    if (hasChanged) this._currentPage = 1
  }
  // ACTIONS

  @Action({ useContext: true })
  protected async obtainHistoryContracts(ctx: ActionContext<any, any>) {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const filters = ctx.getters.allProcessFilters

    const params = { ...filters, page, size }
    if (isNil(page) || isNil(size) || page < 0) return

    try {
      ctx.commit('setLoading', true)
      const response = await HistoryContractsApi.getHistoryContractList(params)
      ctx.commit('setHistoryContracts', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los contratos.'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.Page])
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])
    ctx.commit('setMarketerCodeFilter', queryParams[QueryParams.MarketerCode])
    ctx.commit('setReferenceNumberFilter', queryParams[QueryParams.ReferenceNumber])
    ctx.commit('setMessageTypeFilter', queryParams[QueryParams.MessageType])
    ctx.commit('setProcessReasonFilter', queryParams[QueryParams.ProcessReason])

    ctx.dispatch('obtainHistoryContracts')
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainHistoryContracts')
  }

  // @Action()
  // protected magic(processCode: string) {
  //   switch (processCode) {
  //     case '04':
  //       return console.log('cancel reason')

  //     case '24':
  //     case '25':
  //     case '26':
  //       return console.log('communication reason')

  //     case '05':
  //       return console.log('contract modification reason')
  //     case '41':
  //     case '42':
  //       return console.log('marketer change')

  //     case '44':
  //     case '45':
  //       return console.log('operation type')

  //     case '03':
  //     case '46':
  //       return console.log('anulment reason')

  //     case '48':
  //       return console.log('claim type')

  //     case '50':
  //       return console.log('transfer reason')

  //     default:
  //       return null
  //   }
  // }
}
