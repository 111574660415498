var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          attrs: { trigger: "click", placement: "right" },
          on: { command: _vm.onClickCommand }
        },
        [
          _c(
            "el-button",
            {
              staticClass: "el-dropdown-link",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                plain: "",
                loading: _vm.loading
              }
            },
            [_vm._v("Exportar")]
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "pdf" } }, [
                _vm._v("Exportar en PDF ")
              ]),
              _c("el-dropdown-item", { attrs: { command: "xlsx" } }, [
                _vm._v("Exportar en Excel")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }