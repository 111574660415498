import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClientForm3 extends Vue {

  private provinceCode: string = null;
  private sameDirection: boolean = false;

  protected get formOptions(): FormConfig {
    const config: FormConfig = {
      label: 'Supply point form',
      submitButton: 'Siguiente',
      items: []
    }

    config.items = this.sameDirection ? [{ type: 'switch', name: 'regularaddress', label: 'Utilizar la misma dirección que el servicio' }] : [
      {
        type: 'switch',
        name: 'regularaddress',
        label: 'Utilizar la misma dirección que el servicio',
      },
      {
        type: 'select',
        name: 'provinceowner',
        label: 'Provincia',
        options: `${CORE_URL}/location-province`,
        placeholder: 'Selecciona provincia',
        disabled: this.sameDirection,
        valueQuery: 'code',
      },
      {
        type: 'select',
        name: 'cityowner',
        label: 'Municipio',
        options: `${CORE_URL}/ine-municipalities`,
        disabled: this.sameDirection,
        placeholder: 'Selecciona municipio',
        valueQuery: 'netMunicipalityCode',
        labelQuery: 'municipalityIneDescription'
      },
      {
        type: 'text',
        name: 'zipcodeowner',
        label: 'Codigo postal',
        placeholder: 'Introduce el codigo postal',
        disabled: this.sameDirection,
        maxCharacters: 5
      },
      {
        type: 'select',
        name: 'streettypeowner',
        label: 'Tipo de via',
        options: `${CORE_URL}/location-street-types`,
        placeholder: 'Selecciona tipo de via',
        valueQuery: 'code',
        disabled: this.sameDirection,
      },
      {
        type: 'text',
        name: 'streetowner',
        label: 'Via',
        placeholder: 'Introduce el nombre de la via',
        disabled: this.sameDirection,
        maxCharacters: 60
      },
      {
        type: 'text',
        name: 'streetnumberowner',
        label: 'Num. finca',
        placeholder: 'Introduce el numero de la finca',
        disabled: this.sameDirection,
        maxCharacters: 4
      },
      {
        type: 'text',
        name: 'portalowner',
        label: 'Portal',
        placeholder: 'Introduce el portal',
        disabled: this.sameDirection,
        maxCharacters: 5
      },
      {
        type: 'text',
        name: 'staircaseowner',
        label: 'Escalera',
        placeholder: 'Introduce la escalera',
        disabled: this.sameDirection,
        maxCharacters: 5
      },
      {
        type: 'text',
        name: 'floorowner',
        label: 'Piso',
        placeholder: 'Introduce la escalera',
        disabled: this.sameDirection,
        maxCharacters: 5
      },
      {
        type: 'text',
        name: 'doorowner',
        label: 'Puerta',
        placeholder: 'Introduce la puerta',
        disabled: this.sameDirection,
        maxCharacters: 5
      },
    ]

    config.rules = this.sameDirection ? {} : {
      provinceowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
      cityowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
      zipcodeowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
      streettypeowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
      streetowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
      streetnumberowner: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
    }

    return config
  }

  protected change(formModel: any) {
    const provinceCode = formModel?.province;
    const sameDirection = formModel?.regularaddress;
    const hasChangeProvinceCodeChanged = !!provinceCode && provinceCode !== this.provinceCode;

    const jsonP38_SupplyPointForm1 = JSON.parse(localStorage.getItem('p38_supplypointform1_json'));
    const jsonP38_SupplyPointForm2 = JSON.parse(localStorage.getItem('p38_supplypointform2_json'));

    if (hasChangeProvinceCodeChanged) this.provinceCode = provinceCode
    this.sameDirection = !!sameDirection
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}