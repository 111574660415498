import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import AuthApiConnection from '../connections/api/Auth'
import { SessionVariables } from '@/Core/enum/SessionVariables'
import _ from 'lodash'
import UserGroup, { SystemSubject } from '../models'
import AbstractApi from '@/Core/api/AbstractApi'
import router from '@/Core/router'
import { decode } from 'jsonwebtoken'
import { AuthMastersApi } from '../connections/api/AuthMasters'

@Module({ namespaced: true })
export default class UserGroups extends VuexModule {
  protected _loading: boolean = true
  protected _changeLoading: boolean = false
  protected _userGroupList: UserGroup[] = []
  protected _systemSubject: SystemSubject = null

  @Getter() public loading(): boolean {
    return this._loading
  }
  @Getter() public changeLoading(): boolean {
    return this._changeLoading
  }
  @Getter() public userGroupList(): UserGroup[] {
    return this._userGroupList
  }
  @Getter() protected systemSubject(): SystemSubject {
    return this._systemSubject
  }
  @Getter() protected systemSubjectIsMarketer(): boolean {
    return !!this._systemSubject?.categories.find((item) =>
      ['COM', 'TRA', 'CLI', 'OCS'].includes(item.code)
    )
  }
  @Getter() protected systemSubjectLoading(): boolean {
    return _.isNil(this._systemSubject)
  }

  @Mutation() protected setSystemSubject(systemSubject: SystemSubject) {
    this._systemSubject = systemSubject
  }
  @Mutation() protected setUserGroupList(userGroupList: UserGroup[]) {
    this._userGroupList = userGroupList
  }
  @Mutation() protected setLoading(value: boolean) {
    this._loading = value
  }
  @Mutation() protected setChangeLoading(value: boolean) {
    this._changeLoading = value
  }

  @Action({ useContext: true })
  protected async getListCurrentUserGroups(ctx: ActionContext<any, any>): Promise<any> {
    try {
      ctx.commit('setUserGroupList', [])
      ctx.commit('setLoading', true)
      const result = await AuthApiConnection.getListCurrentUserGroups()
      const userGroupList = result.data.groups
      ctx.commit('setUserGroupList', userGroupList)
    } catch (error) {
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async changeGroup(
    ctx: ActionContext<any, any>,
    payload: { group: UserGroup; reload?: boolean }
  ): Promise<any> {
    try {
      ctx.commit('setChangeLoading', true)
      await AuthApiConnection.switchCurrentUserGroup({ activeMarketer: payload.group.name })
      await ctx.dispatch('updateToken', { reload: !!payload.reload })
    } catch (error) {
    } finally {
      ctx.commit('setChangeLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async updateToken(
    ctx: ActionContext<any, any>,
    payload: { reload: boolean }
  ): Promise<void> {
    const refreshToken = sessionStorage.getItem(SessionVariables.Refresh)
    const email = ctx.rootGetters['Signin/username']

    try {
      const response = await AuthApiConnection.refreshSession({ refreshToken, email })
      sessionStorage.setItem(SessionVariables.Auth, response.data.id_token)
      sessionStorage.setItem(SessionVariables.Refresh, response.data.refresh_token)
      ctx.dispatch('obtainSystemSubjectInfo')
      AbstractApi.setAuthorization(response.data.id_token)
      if (payload.reload) router.go(0)
      else router.push('/')
    } catch (error) {}
  }

  @Action({ useContext: true })
  protected async obtainSystemSubjectInfo(ctx: ActionContext<any, any>) {
    const token = sessionStorage.getItem(SessionVariables.Auth)
    const decodedToken = decode(token) as any
    const groupCode = decodedToken['custom:active_marketer']
    const result = await AuthMastersApi.getSystemSubject(groupCode)
    sessionStorage.setItem(
      SessionVariables.categories,
      result.data[0].categories.map(({ code }) => code).toString()
    )
    ctx.commit('setSystemSubject', result.data[0])
  }
}
