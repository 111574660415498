import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, MapGetter, Prop, Vue } from 'types-vue'


@Component
export default class FiltersDrawer extends Vue {

  @Prop({type: Number, default: 0}) protected totalActiveFilter: number
  @Prop({ type: String, default: 'Filtros' }) protected buttonLabel: string

  protected dialogVisible: boolean = false
}