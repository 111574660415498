import { Store } from '@/Core/Models/Stores';
import _ from 'lodash';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import { Cups, Period } from '../../models';

@Component
export default class CupsDetails extends Vue {

  @MapAction(Store.CupsDetails) protected initDetails: (id: string) => Promise<void>
  @MapAction(Store.CupsDetails) protected clean: () => void
  @MapAction(Store.CupsDetails) protected downloadConsumitionCSV: () => Promise<void>
  @MapGetter(Store.CupsDetails) protected cups: Cups
  @MapGetter(Store.CupsDetails) protected loading: boolean
  @MapGetter(Store.CupsDetails) protected error: boolean
  @MapGetter(Store.CupsDetails) protected periods: Period[]


  protected activeTab: string = 'info'


  @Watch('$route.params.id', { immediate: true })
  protected async onChangeParamId(id: string) {
    await this.initDetails(id)
  }

  protected async onClickDownloadButton() {
    await this.downloadConsumitionCSV()
  }

  protected beforeDestroy() {
    this.clean()
  }
}