import FiltersMixin from '@/Core/mixins/FiltersMixin'
import { NoContractModel } from '@/Modules/NoContract/models'
import { Component, mixins, Prop } from 'types-vue'

@Component
export default class ContractTypeColumn extends mixins(FiltersMixin) {

  @Prop({ required: true })
  protected data: NoContractModel


  protected get code(): string {
    return this.data.processCode
  }

  protected get description(): string {
    return `${this.processCodeFilter(this.code)}: ${this.data.messageTypeCode}`
  }
}