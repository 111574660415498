import _ from 'lodash';
import { Component, Prop, Vue } from 'types-vue'
import { UploadedFile, UploadedFileState } from '@/Modules/Uploader/models';

@Component
export default class FileResult extends Vue {

  @Prop({ type: Object }) protected fileData: UploadedFile

  

  protected get isUploaded(): boolean {
    return this.fileData.state === UploadedFileState.uploading
  }

  protected get isError(): boolean {
    return this.fileData.state === UploadedFileState.error
  }

  protected get isDone(): boolean {
    return this.fileData.state === UploadedFileState.done
  }

  protected get isInvalid(): boolean {
    return this.fileData.state === UploadedFileState.invalid
  }




  protected get errorMessage(): string {
    return this.fileData?.error
  }
  
  protected get filename(): string {
    return this.fileData.file.name
  }

  protected get validationResultList(): any[] {
    return this.fileData?.result?.validationResponseVoList || []
  }
}