import { Component, Vue, Prop } from 'types-vue'
import { SearcherPages } from '@/Modules/Searcher/router'

@Component
export default class Header extends Vue {
  @Prop({ required: true })
  protected cupsId: string

  @Prop({ required: true })
  protected totalProducts: number

  protected onCupsClick(cups: string) {
    this.$router.push({ name: SearcherPages.Result, params: { type: 'all', cups: cups } })
  }
}
