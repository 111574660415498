import { render, staticRenderFns } from "./ObservationsForm_42.vue?vue&type=template&id=64c466a7&scoped=true&"
import script from "./ObservationsForm_42.ts?vue&type=script&lang=ts&"
export * from "./ObservationsForm_42.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c466a7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64c466a7')) {
      api.createRecord('64c466a7', component.options)
    } else {
      api.reload('64c466a7', component.options)
    }
    module.hot.accept("./ObservationsForm_42.vue?vue&type=template&id=64c466a7&scoped=true&", function () {
      api.rerender('64c466a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/ProcessesForms/components/P42/ObservationsForm_42/ObservationsForm_42.vue"
export default component.exports