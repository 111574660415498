import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import _ from 'lodash'
import { FlowProcessDistribution, ProcessModel } from '../../models'
import { ProcessesListRequest } from '../../models/api'

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  FlowProcessesFiltered: `${CORE_SERVICE_URL_BASE}/flow-processes/filtered`,
  FlowProcesses: `${CORE_SERVICE_URL_BASE}/flow-processes`,
  FlowProcessDistribution: `${CORE_SERVICE_URL_BASE}/flow-process-repartos`,
  Resend: `${CORE_SERVICE_URL_BASE}/flow-processes/resend`,
  DownloadFiles: `${CORE_SERVICE_URL_BASE}/files/zip`,
  DownloadAllFiles: `${CORE_SERVICE_URL_BASE}/flow-processes/download/all/zip`
}

class FlowProcessesSingleton extends AbstractApi {
  private static _instance: FlowProcessesSingleton
  private constructor() {
    super()
  }

  public static get instance(): FlowProcessesSingleton {
    if (!FlowProcessesSingleton._instance)
      FlowProcessesSingleton._instance = new FlowProcessesSingleton()
    return FlowProcessesSingleton._instance
  }

  public getProcessesList(params: ProcessesListRequest = { pageNumber: 1 }): AxiosPromise {
    const method = Method.GET
    const url = Path.FlowProcessesFiltered

    return this.request({ method, url, params })
  }

  public getProcessById(id: string | number): AxiosPromise<ProcessModel[]> {
    const method = Method.GET
    const url = Path.FlowProcesses
    const params = { id }

    return this.request({ method, url, params })
  }

  public removeById(id: string | number): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.FlowProcesses}/${id}`

    return this.request({ method, url })
  }

  public getFlowProcessDistribution(
    flowProcessId: string | number
  ): AxiosPromise<FlowProcessDistribution[]> {
    const method = Method.GET
    const url = Path.FlowProcessDistribution
    const params = { flowProcessIds: flowProcessId }

    return this.request({ method, url, params })
  }

  public resendB1(ids: string[] | number[]): AxiosPromise<any> {
    const method = Method.POST
    const url = Path.Resend
    const data = { ids }

    return this.request({ method, url, data })
  }

  public downloadFlowProcessFiles(
    flowProcessIdsParam: number[] = [],
    messageListParam: string[] = [],
    filters: object = {},
    downloadAll: boolean = false
  ): AxiosPromise<string> {
    const method = Method.GET
    const url = downloadAll ? Path.DownloadAllFiles : Path.DownloadFiles
    const flowProcessIds = downloadAll ? undefined : flowProcessIdsParam.toString()
    const messageList = !messageListParam.length ? undefined : messageListParam.toString()
    const params = _.omitBy({ ...filters, flowProcessIds, messageList }, _.isNil)

    return this.request({ method, url, params })
  }
}

export const FlowProcessesApi = FlowProcessesSingleton.instance
