import { CookieNames } from '@/Core/enum/CookieNames'
import { Component, Vue, mixins, Watch } from 'types-vue'
import PermissionMixin from '../../mixins/PermissionMixin'

@Component
export default class ApiVersionCard extends mixins(PermissionMixin) {

  protected selectModel = null
  protected options = [ { label: 'V1', value: 'v1' }, { label: 'V2', value: 'v2' } ]

  protected get apiVersionCookie(): string { return this.$cookies.get(CookieNames.ApiVersion) }

  protected get enabled(): boolean {
    return this.isSystemRol
      || this.isDistributorRol && this.isAdminPermission
  }


  protected async onChangeSelect() {
    try {
      await this.$confirm('¿Estas seguro que quieres cambiar la versión de la API? Esto puede cambiar el comportamiento de la aplicación.', 'Cambiar versión API', { confirmButtonText: 'Cambiar' })
      this.$cookies.set(CookieNames.ApiVersion, this.selectModel)
      this.$router.push('/')
      this.$notify.success(`Se ha cambiado la versión de la API ${this.selectModel}`)
    } catch (err) {
      this.selectModel = this.apiVersionCookie
    }
  }


  @Watch('apiVersionCookie', { immediate: true }) protected onChangeApiVersionCookies() {
    this.selectModel = this.apiVersionCookie
  }
}