import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { SystemSubject } from "../../models";
import { HandoverCurRequest } from "../../models/api";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Handover: `${CORE_SERVICE_URL_BASE}/traspaso-a-cur`,
  ActiveCups: `${CORE_SERVICE_URL_BASE}/active-cur-cups`
}

class CurSingleton extends AbstractApi {
  private static _instance: CurSingleton
  private constructor() { super() }

  public static get instance(): CurSingleton {
    if (!CurSingleton._instance) CurSingleton._instance = new CurSingleton();
    return CurSingleton._instance;
  }
  

  public handover(data: HandoverCurRequest): AxiosPromise<SystemSubject[]> {
    const method = Method.POST
    const url = `${Path.Handover}`

    return this.request({ method, url, data })
  }

  public getActiveCups(originMarketer: string, reqTransferDate: string): AxiosPromise<string[]> {
    const method = Method.GET
    const url = Path.ActiveCups
    const params = { originMarketer, reqTransferDate }

    return this.request({ method, url, params })
  }
}

export const CurApi = CurSingleton.instance