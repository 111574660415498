import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'

import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { HistoryAtcomApi } from '../connections/api/HistoryAtcom'
import { Notification } from 'element-ui'
import { QueryParams } from '../definitions/QueryParams'
import { omitBy, isNil, isEmpty } from 'lodash'

interface HistoryAtcomFilters {
  cups?: string[]
  processType?: string[]
}

const HISTORY_ATCOM_PROCESS_CODES = ['44', '45', '46', '47', '48']

@Module({ namespaced: true })
export default class HistoryAtcom extends VuexModule {
  // STATE

  private _historyAtcom: any[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _isLoading: boolean = false
  private _cupsFilter: string = null
  private _processTypeFilter: string = null
  private _marketerCodeFilter: string = null
  private _referenceNumber: string = null
  private _messageType: string = null
  private _processReason: string = null

  // GETTERS

  @Getter() public historyAtcom(): any[] {
    return this._historyAtcom
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public isLoading(): boolean {
    return this._isLoading
  }

  @Getter() public allProcessFilters(): HistoryAtcomFilters {
    const filters = {
      cups: this._cupsFilter,
      processCodes: this._processTypeFilter,
      marketerCode: this._marketerCodeFilter,
      numreference: this._referenceNumber,
      messageTypeCode: this._messageType,
      processReason: this._processReason
    }

    return omitBy(filters, isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!isNil(this._cupsFilter)) result++
    if (!isNil(this._processTypeFilter)) result++
    if (!isNil(this._marketerCodeFilter)) result++
    if (!isNil(this._referenceNumber)) result++
    if (!isNil(this._messageType)) result++
    if (!isNil(this._processReason)) result++

    return result
  }

  // MUTATIONS

  @Mutation() protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }
  @Mutation() protected setLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }

  @Mutation()
  protected setHistoryAtcom(data: any) {
    this._historyAtcom = data.content
    this._totalElements = data.totalElements
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmptyValue = isEmpty(value)
    this._currentPage = isEmptyValue ? 1 : parseInt(value)
  }

  @Mutation() protected setCupsFilter(value: string) {
    const hasChanged = value !== this._cupsFilter

    this._cupsFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessTypeFilter(value: string) {
    const hasChanged = value !== this._processTypeFilter

    this._processTypeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMarketerCodeFilter(value: string) {
    const hasChanged = value !== this._marketerCodeFilter

    this._marketerCodeFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setReferenceNumberFilter(value: string) {
    const hasChanged = value !== this._referenceNumber

    this._referenceNumber = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setMessageTypeFilter(value: string) {
    const hasChanged = value !== this._messageType

    this._messageType = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setProcessReasonFilter(value: string) {
    const hasChanged = value !== this._processReason

    this._processReason = value
    if (hasChanged) this._currentPage = 1
  }

  // ACTIONS

  @Action({ useContext: true })
  protected async obtainHistoryAtcom(ctx: ActionContext<any, any>) {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const filters = ctx.getters.allProcessFilters
    const params = { ...filters, page, size }

    if (isNil(page) || isNil(size) || page < 0) return

    try {
      ctx.commit('setLoading', true)
      const response = await HistoryAtcomApi.getHistoryAtcomList(params)
      ctx.commit('setHistoryAtcom', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los ATCOM'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      ctx.commit('setLoading', false)
    }
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.Page])
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])
    ctx.commit('setMarketerCodeFilter', queryParams[QueryParams.MarketerCode])
    ctx.commit('setReferenceNumberFilter', queryParams[QueryParams.ReferenceNumber])
    ctx.commit('setMessageTypeFilter', queryParams[QueryParams.MessageType])
    ctx.commit('setProcessReasonFilter', queryParams[QueryParams.ProcessReason])

    ctx.dispatch('obtainHistoryAtcom')
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainHistoryAtcom')
  }
}
