import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'

import { Store } from '@/Core/Models/Stores'

@Component
export default class Billing extends Vue {
  /**
   * STORE
   */

  // Getters
  @MapGetter(Store.HistoryBills) protected historyBills: any[]
  @MapGetter(Store.HistoryBills) protected totalElements: number
  @MapGetter(Store.HistoryBills) protected pageSize: number
  @MapGetter(Store.HistoryBills) protected isLoading: boolean
  @MapGetter(Store.HistoryBills) protected totalActiveFilter: number

  //Actions
  @MapAction(Store.HistoryBills) protected obtainHistoryBills: () => Promise<void>
  @MapAction(Store.HistoryBills) protected newPageSize: (pageSize: number) => Promise<void>
  @MapAction(Store.HistoryBills) protected processUrlQueryParams: (queryParams: any) => void

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }

  /**
   * WATCHERS
   */

  @Watch('$route', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    this.processUrlQueryParams(this.$route.query)
  }
}
