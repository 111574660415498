import { Component, Prop, Vue } from 'types-vue'
import { ItemFormConfig } from '../../models'

@Component
export default class Divider extends Vue {

  @Prop({ required: true }) protected config: ItemFormConfig

  
  protected get title(): string { return this.config.label }
}