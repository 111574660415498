var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("h3", [_vm._v("Versión API")])]
      ),
      _vm.enabled
        ? _c("el-alert", {
            attrs: {
              title: "¡Cuidado!",
              type: "warning",
              description:
                "Si modificas la versión de la API es posible que la aplicación deje de funcionar.",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _c(
        "el-select",
        {
          attrs: {
            placeholder: "Selecciona la versión",
            disabled: !_vm.enabled
          },
          on: { change: _vm.onChangeSelect },
          model: {
            value: _vm.selectModel,
            callback: function($$v) {
              _vm.selectModel = $$v
            },
            expression: "selectModel"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }