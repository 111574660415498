import { Vue } from 'types-vue'
import * as _ from 'lodash'

const requireModule = require.context('../../', true,  /\.vue$/);

requireModule.keys().filter(fileName => fileName.includes('/components/'))
  .map(filename => {
    const separateFileName = filename.split('/')
    const componentname = (_.last(separateFileName) as string).split('.')[0]
    const modulename = (separateFileName[1] === 'Modules')
      ? separateFileName[2] // es un modulo
      : separateFileName[1] // es el core
    

    return { filename, modulename, componentname }
  })
  .forEach(({ filename, modulename, componentname }) => {
    const component = requireModule(filename).default || requireModule(filename)
    Vue.component(`${modulename}-${componentname}`, component)
  });