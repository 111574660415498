// import Page from '@/Core/views/Page/Page.vue'
import { RouteConfig } from 'vue-router'
// import Main from './pages/Main/Main.vue'

export enum MarketersPages {
  Main = 'main',
  Master = 'master'
}

const routes: Array<RouteConfig> = [
  // {
  //   path: '/marketers',
  //   component: Page,
  //   children: [
  //     {
  //       path: '',
  //       name: MarketersPages.Main,
  //       component: Main
  //     },
  //   ]

  // }
]

export default routes