var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade-to-top", appear: "" } },
    [
      _c("el-card", { staticClass: "process-section" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("Core-Title", { attrs: { loading: _vm.processLoading } }, [
              _c("h3", [_vm._v("Procesos")])
            ])
          ],
          1
        ),
        _vm.showDoughnut
          ? _c("div", { staticClass: "processes-container" }, [
              _c(
                "div",
                { staticClass: "simple-widget-container" },
                [
                  _vm.showOpenedProcesses
                    ? _c("Dashboard-SimpleWitget", {
                        attrs: {
                          label: "Abiertos",
                          color: "#7A65E6",
                          icon: "el-icon-tickets",
                          value: _vm.openedProcessesCount
                        },
                        on: { click: _vm.onClickOpenedProcesses }
                      })
                    : _vm._e(),
                  _vm.showClosedProcesses
                    ? _c("Dashboard-SimpleWitget", {
                        attrs: {
                          label: "Cerrados",
                          color: "#30D09A",
                          icon: "el-icon-check",
                          value: _vm.closedProcessesCount
                        },
                        on: { click: _vm.onClickClosedProcesses }
                      })
                    : _vm._e(),
                  _vm.showNotValidatedProcessesSteps
                    ? _c("Dashboard-SimpleWitget", {
                        attrs: {
                          label: "Rechazados",
                          color: "#EF3F55",
                          icon: "el-icon-warning-outline",
                          value: _vm.notValidatedProcessesStepsCount
                        },
                        on: { click: _vm.onClickNotValidatedProcesses }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart" },
                [
                  _vm.showDoughnut
                    ? _c("Dashboard-ProcessesDoughnut", {
                        attrs: {
                          width: 230,
                          open: _vm.openedProcessesCount,
                          closed: _vm.closedProcessesCount,
                          nonValidated: _vm.notValidatedProcessesStepsCount
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }