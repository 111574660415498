import { render, staticRenderFns } from "./MenuItem.vue?vue&type=template&id=55b6b81c&scoped=true&"
import script from "./MenuItem.ts?vue&type=script&lang=ts&"
export * from "./MenuItem.ts?vue&type=script&lang=ts&"
import style0 from "./MenuItem.vue?vue&type=style&index=0&id=55b6b81c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b6b81c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b6b81c')) {
      api.createRecord('55b6b81c', component.options)
    } else {
      api.reload('55b6b81c', component.options)
    }
    module.hot.accept("./MenuItem.vue?vue&type=template&id=55b6b81c&scoped=true&", function () {
      api.rerender('55b6b81c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Alerts/components/ConfigurationMenu/components/MenuItem/MenuItem.vue"
export default component.exports