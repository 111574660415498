var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "signin-page" } },
    [
      _c("h2", [_vm._v("Tus comercializadoras")]),
      _c("p", [
        _vm._v("Selecciona la comercializadora en la que quieres entrar:")
      ]),
      _c("Auth-GroupsList"),
      _c(
        "div",
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.onClickReturn } },
            [_vm._v("Volver al login")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }