import FiltersMixin from '@/Core/mixins/FiltersMixin'
import Master from '@/Modules/Masters/connections/api/Master'
import { FiltersName } from '@/Modules/Processes/enums/Filters'
import _ from 'lodash'
import { Component, mixins, Prop, Watch } from 'types-vue'

const MASTER_SLUG = 'process-process'

@Component
export default class ProcessTypeFilter extends mixins(FiltersMixin) {


  @Prop({ default: 'medium', type: String }) protected size
  

  /**
   * STATE
   */

  protected loading: boolean = true
  protected list: any[] = []
  protected selected: any[] = []
  protected options: any[] = []



  /**
   * METHODS
   */

  protected addQueryParam() {
    const codes = this.selected.toString()
    const name = this.$route.name
    const query = (codes.length) 
      ? {...this.$route.query, [FiltersName.ProcessType]: codes }
      : _.omit(this.$route.query, [FiltersName.ProcessType]);
    
    if (!_.isEqual(query, this.$route.query))
      this.$router.push({ name, query })
  }

  protected setSelectedElementsByQueryParams() {
    const codes = (this.$route.query[FiltersName.ProcessType] as string).split(',')
    this.selected = codes
  }

  private async obtainList() {
    try {
      this.loading = true
      const result = await Master.getAllList(MASTER_SLUG)
      this.list = result.data.filter(item => ['02', '03', '04', '05', '38', '41', '43', '44', '48', '49', '50'].includes(item.code))
    } catch (error) {
      
    } finally {
      this.loading = false
    }
  }

  protected updateFilter(filter: string) {
    filter = filter.toLowerCase()
    this.options = this.list
      .filter(item => item.code.toLowerCase().includes(filter) || item.description.toLowerCase().includes(filter))
  }


  protected onFocus() {
    this.updateFilter('')
  }


  /**
   * WHATCHERS
   */
  @Watch(`$route.query.${FiltersName.ProcessType}`, { immediate: true })
  protected onChangeQueryParam() {
    const haveQueryParam = !!this.$route.query[FiltersName.ProcessType]
    if (haveQueryParam) this.setSelectedElementsByQueryParams()
  }

  @Watch('selected')
  protected onChangeSelected() {
    this.addQueryParam()
  }

  @Watch('list')
  protected onChangeList() {
    this.updateFilter('')
  }


  /**
   * VUE LIFE CYCLE
   */
  
  protected async created() {    
    await this.obtainList()
  }
}