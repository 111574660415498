import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import _ from 'lodash'

interface GetHistoryContractsListParams {
  processCodes: string[]
  page: number
  size: number
}

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  HistoryContracts: `${CORE_SERVICE_URL_BASE}/hist-flow-process/paged`,
  Download: `${CORE_SERVICE_URL_BASE}/hist-flow-process/download`
}

class HistoryContractsApiSingleton extends AbstractApi {
  private static _instance: HistoryContractsApiSingleton
  private constructor() {
    super()
  }

  public static get instance(): HistoryContractsApiSingleton {
    if (!HistoryContractsApiSingleton._instance)
      HistoryContractsApiSingleton._instance = new HistoryContractsApiSingleton()
    return HistoryContractsApiSingleton._instance
  }

  public getHistoryContractList({ ...rawParams }: GetHistoryContractsListParams): AxiosPromise {
    const method = Method.GET
    const url = Path.HistoryContracts
    const params = {
      ...rawParams,
      processCodes: rawParams.processCodes || '02,03,04,05,19,24,25,26,27,38,39,41,42,43,49,50'
    }
    return this.request({ method, url, params })
  }

  public async downloadHistoryContract(id: number): Promise<string> {
    const method = Method.GET
    const url = Path.Download
    const params = {
      id
    }

    const { data } = await this.request({ method, url, params })

    return data
  }
}

export const HistoryContractsApi = HistoryContractsApiSingleton.instance
