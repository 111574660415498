import { Component, Prop, Vue } from 'types-vue'
import { Cups } from '../../models'
import { CupsPages } from '../../router'

@Component
export default class ProcessesList extends Vue {

  @Prop({ required: true, type: Array }) protected cups: any[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean


  /**
   * EVENTS
   */

  protected onClickDetails(cups: Cups) {
    this.$router.push({ name: CupsPages.Details, params: { id: cups.generalData.cups }})
  }


  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return (this.loading)
      ? 'CARGANDO CUPS...'
      : 'NO HAY CUPS'
  }

}