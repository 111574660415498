import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import ConfirmPasswordFormModel from '../../models/ConfirmPasswordForm'
import ConfirmPasswordBody from '../../models/connections/api/ConfirmPasswordBody'
import { AuthPages } from '../../router'

@Component
export default class ConfirmPassword extends Vue {
  


  /**
   * STATE
   */

  protected loading: boolean = false
  protected email: string = ''
  protected code: string = ''
  


  /**
   * STORE
   */

  @MapAction(Store.ForgotPassword)
  protected confirmPassword: (payload: ConfirmPasswordBody) => Promise<any>



  /**
   * EVENTS
   */

  protected async onSubmit(form: ConfirmPasswordFormModel) {
    try {
      
      this.loading = true
      await this.confirmPassword({ code: this.code, email: this.email, newPassword: form.newPassword })
      this.successNotify()
      this.goToLogin()

    } catch {
      
      this.errorNotify()
    
    } finally {
      this.loading = false
    }
  }



  /**
   * METHODS
   */

  private goToLoginWithError() {
    this.$notify({ type: 'error', title: 'Error', message: this.$t('auth confirmPassword.paramsError').toString() })
    this.goToLogin()
  }

  private goToLogin() {
    this.$router.push({ name: AuthPages.Signin })
  }

  private successNotify() {
    const title = this.$t('auth confirmPassword.succesTitle').toString()
    const message = this.$t('auth confirmPassword.successMessage').toString()
    
    this.$notify({ type: 'success', title, message })
  }

  private errorNotify() {
    const title = this.$t('auth confirmPassword.errorTitle').toString()
    const message = this.$t('auth confirmPassword.errorMessage').toString()

    this.$notify({ type: 'error', title, message })
  }

  /**
   * VUE LIFE CYCLE
   */
  
  protected created() {
    if (!this.$route.query.email || !this.$route.query.code) this.goToLoginWithError()

    this.email = this.$route.query.email as string
    this.code = this.$route.query.code as string
  }
}