import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { BillsApi } from '../connections/api/Bills'
import { QueryParams } from '../definitions/QueryParams'
import { Bill } from '../models'
import { FilteredFlowBillsResponse } from '../models/api'

@Module({ namespaced: true })
export default class Bills extends VuexModule {
  
  private _billList: Bill[] = []
  private _billNumFilter: string = ''
  private _cupsFilter: string = ''
  private _comerFilter: string = ''
  private _processTypeFilter: string = ''
  private _startBillDateFilter: string = ''
  private _endBillDateFilter: string = ''
  private _startProcessDateFilter: string = ''
  private _endProcessDateFilter: string = ''
  private _billTypeFilter: string = ''
  private _pseudoBillFilter: string = ''
  private _downloadedFilter: string = ''
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null
  private _listLoading: boolean = true
  private _isCupsClickable: boolean = true



  @Getter() public orderProp(): string { return this._orderProp || 'billDate' }
  @Getter() public orderDirection(): string { return this._orderDirection || 'DESC' }
  @Getter() public billList(): Bill[] { return this._billList }
  @Getter() public billNumFilter(): any { return this._billNumFilter }
  @Getter() public billTypeFilter(): any { return this._billTypeFilter }
  @Getter() public cupsFilter(): any { return this._cupsFilter }
  @Getter() public comerFilter(): any { return this._comerFilter }
  @Getter() public startBillDateFilter(): any { return this._startBillDateFilter }
  @Getter() public endBillDateFilter(): any { return this._endBillDateFilter }
  @Getter() public startProcessDateFilter(): any { return this._startProcessDateFilter }
  @Getter() public endProcessDateFilter(): any { return this._endProcessDateFilter }
  @Getter() public processTypeFilter(): any { return this._processTypeFilter }
  @Getter() public downloadedFilter(): string { return this._downloadedFilter }
  @Getter() public pseudoBillFilter(): string { return this._pseudoBillFilter }
  @Getter() public pageSize(): number { return this._pageSize } 
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }
  @Getter() public isCupsClickable(): boolean { return this._isCupsClickable}

  @Getter() public billFilters(): object {
    const downloaded = (!_.isNil(this._downloadedFilter)) ? this._downloadedFilter === '1' : null
    const filters = {
      billNum: this._billNumFilter,
      cupsList: this._cupsFilter,
      marketerCode: this._comerFilter,
      processCode: this._processTypeFilter,
      startDate: this._startBillDateFilter,
      endDate: this._endBillDateFilter,
      fromCreatedAt: this._startProcessDateFilter,
      toCreatedAt: this._endProcessDateFilter,
      billTypeCode: this._billTypeFilter,
      pseudoBillNum: this._pseudoBillFilter,
      downloaded
    }

    return _.omitBy(filters, _.isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0
    
    if (!_.isNil(this._cupsFilter)) result++
    if (!_.isNil(this._comerFilter)) result++
    if (!_.isNil(this._billNumFilter)) result++
    if (!_.isNil(this._billTypeFilter)) result++
    if (!_.isNil(this._endBillDateFilter)) result++
    if (!_.isNil(this._startBillDateFilter)) result++
    if (!_.isNil(this._endProcessDateFilter)) result++
    if (!_.isNil(this._startProcessDateFilter)) result++
    if (!_.isNil(this._pseudoBillFilter)) result++
    if (!_.isNil(this._downloadedFilter)) result++
    if (!!this._processTypeFilter?.length) result++

    return result
  }



  @Mutation() protected setOrderProp(value: string)       { this._orderProp = value }
  @Mutation() protected setOrderDirection(value: string)  { this._orderDirection = value }
  @Mutation() protected setPageSize(pageSize: number)     { this._pageSize = pageSize }
  @Mutation() protected setListLoading(value: boolean)    { this._listLoading = value }

  @Mutation()
  protected setBills(data: FilteredFlowBillsResponse) {
    this._billList = [ ...data.flowBillVos ]
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setCupsFilter(newValue) {
    const hasChanged = newValue !== this._cupsFilter

    this._cupsFilter = newValue
    if (hasChanged) this._currentPage = 1
  }
  
  @Mutation()
  protected setComerFilter(newValue) {
    const hasChanged = newValue !== this._comerFilter

    this._comerFilter = newValue
    if (hasChanged) this._currentPage = 1
  }
  
  @Mutation()
  protected setProcessTypeFilter(newValue) {
    const hasChanged = newValue !== this._processTypeFilter

    this._processTypeFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setBillNumFilter(newValue) {
    const hasChanged = newValue !== this._billNumFilter

    this._billNumFilter = newValue
    if (hasChanged) this._currentPage = 1
  }
  
  @Mutation()
  protected setPseudoBillFilter(newValue) {
    const hasChanged = newValue !== this._pseudoBillFilter

    this._pseudoBillFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setBillStartDateFilter(newValue) {
    const hasChanged = newValue !== this._startBillDateFilter

    this._startBillDateFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setBillEndDateFilter(newValue) {
    const hasChanged = newValue !== this._endBillDateFilter

    this._endBillDateFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setProcessStartDateFilter(newValue) {
    const hasChanged = newValue !== this._startProcessDateFilter

    this._startProcessDateFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setProcessEndDateFilter(newValue) {
    const hasChanged = newValue !== this._endProcessDateFilter

    this._endProcessDateFilter = newValue
    if (hasChanged) this._currentPage = 1
  }
  
  @Mutation()
  protected setDownloadedFilter(newValue) {
    const hasChanged = newValue !== this._downloadedFilter

    this._downloadedFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  @Mutation()
  protected setCurrentPage(value) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = (isEmpty) ? 1 : parseInt(value) 
  }

  @Mutation()
  protected setBillTypeFilter(newValue) {
    const hasChanged = newValue !== this._billTypeFilter

    this._billTypeFilter = newValue
    if (hasChanged) this._currentPage = 1
  }

  
  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setBillTypeFilter', queryParams[QueryParams.BillType])
    ctx.commit('setBillNumFilter', queryParams[QueryParams.BillNumber])
    ctx.commit('setOrderProp', queryParams[QueryParams.OrderProp])
    ctx.commit('setOrderDirection', queryParams[QueryParams.OrderDirection])
    ctx.commit('setComerFilter', queryParams[QueryParams.Marketer])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])
    ctx.commit('setBillStartDateFilter', queryParams[QueryParams.StartBillDate])
    ctx.commit('setBillEndDateFilter', queryParams[QueryParams.EndBillDate])
    ctx.commit('setProcessStartDateFilter', queryParams[QueryParams.StartProcessDate])
    ctx.commit('setProcessEndDateFilter', queryParams[QueryParams.EndProcessDate])
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setDownloadedFilter', queryParams[QueryParams.Downloaded])
    ctx.commit('setPseudoBillFilter', queryParams[QueryParams.PseudoBill])

    ctx.dispatch('obtainBills')
  }

  @Action({ useContext: true })
  protected async obtainBills(ctx: ActionContext<any, any>): Promise<FilteredFlowBillsResponse> {
    const systemSubjectIsMarketer = ctx.rootGetters['Signin/systemSubjectIsMarketer']
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize
    const fieldOrder = ctx.getters.orderProp
    const orderASCorDESC = ctx.getters.orderDirection
    const filters = ctx.getters.billFilters
    const validated = systemSubjectIsMarketer ? true : null


    if (!orderASCorDESC || !fieldOrder || _.isNil(pageNumber) || _.isNil(pageSize) || pageSize < 1) return

    try {
      ctx.commit('setListLoading', true)
      const response = await BillsApi.getFilteredFlowBills({ ...filters, pageNumber, pageSize, fieldOrder, orderASCorDESC, validated })
      ctx.commit('setBills', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }
    
  }

  @Action({ useContext: true })
  protected async newPageSize(ctx: ActionContext<any, any>, pageSize: number): Promise<void> {
    const isSamePageSize = ctx.getters.pageSize === pageSize

    if (isSamePageSize) return

    ctx.commit('setPageSize', pageSize)
    await ctx.dispatch('obtainBills')
  }
  

  @Action({ useContext: true })
  protected async removeBill(ctx: ActionContext<any, any>, bill: Bill): Promise<any> {
    const response = await BillsApi.removeById(bill.id)

    if (response.status === 200)
      ctx.dispatch('obtainBills')
  }
}
