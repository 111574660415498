import { SearcherPages } from '@/Modules/Searcher/router'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class CupsColumn extends Vue {
  @Prop({ required: true })
  protected cups: string

  @Prop({ required: false, default: true })
  protected isButton: boolean

  @Prop({ required: true })
  protected index: number

  
  protected onCupsClick(cups: string) {
      this.$router.push({ name: SearcherPages.Result, params: {type: 'all', cups: cups}})
  }
}