import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'

@Component
export default class UploadButton extends Vue {

  @MapAction(Store.Details) protected uploadFiles: (eventFiles) => Promise<void>
  @MapGetter(Store.Details) protected uploadDocumentsLoading: boolean
  
  protected async onUploadFiles(event) {
    await this.uploadFiles(event.target.files)
    event.target.value = ''
  }


  protected onClickUploadButton() {
    const fileInput = this.$refs.fileInput as any
    fileInput.click()
  }
}