import { Component, Vue } from 'types-vue'
import * as _ from 'lodash'
@Component
export default class PageSizeSelect extends Vue {
  protected model: string = ''
  protected options: string[] = ['10', '25', '50', '100']
  protected initialized: boolean = false

  protected onChange() {
    const name = this.$route.name
    const query = { ...this.$route.query, page: '1' }
    this.$router.push({ name: name, query })
    this.$cookies.set('default_page_size', this.model)
    const pageSize = parseInt(this.model)
    this.$emit('change', pageSize)
  }

  protected get defaultValue(): string {
    return this.$cookies.get('default_page_size')
  }

  protected created() {
    if (!_.isNull(this.defaultValue)) {
      const haveDefault = !!this.options.find(opt => opt === this.defaultValue)
      if (!haveDefault) this.options.push(this.defaultValue)
      this.options = _.sortBy(this.options, item => parseInt(item))

      this.model = this.defaultValue
    } else {
      this.model = this.options[0]
      this.$cookies.set('default_page_size', this.model)
    }
    this.onChange()
  }
}
