import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import { CreateA6161Request, CreateA6161Response, NoContractListRequest, NoContractListResponse } from "../../models/api";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  NoContract: `${CORE_SERVICE_URL_BASE}/flow-available-PS`,
  NoContractFiltered: `${CORE_SERVICE_URL_BASE}/flow-available-PS/filtered`,
  NoContractDownload: `${CORE_SERVICE_URL_BASE}/TO-BE-ADDED`,
  CreateA6161: `${CORE_SERVICE_URL_BASE}/flow-available-PS/query`
}

class NoContractApi extends AbstractApi {
  
  public getNoContractList(params: NoContractListRequest = { pageNumber: 1 }): AxiosPromise<NoContractListResponse> {
    const method = Method.GET
    const url = Path.NoContractFiltered

    return this.request({ method, url, params })
  }

  public removeNoContractById(id: number): AxiosPromise {
    const method = Method.DELETE
    const url = `${Path.NoContract}/${id}`

    return this.request({ method, url })
  }

  public createA6161(data: CreateA6161Request): AxiosPromise<CreateA6161Response> {
    const method = Method.POST
    const url = Path.CreateA6161

    return this.request({ method, url, data })  
  }
}

export default new NoContractApi()