import { Component, Vue } from 'types-vue'
import { ProcessesFormsPages } from '../../router'

@Component
export default class List extends Vue {
  protected get items(): any[] {
    return [
      { codePrefix: 'A1', code: '02', description: 'Cambio de Comercializador' },
      {
        codePrefix: 'A1',
        code: '03',
        description: 'Anulación de Solicitud de Contratación'
      },
      {
        codePrefix: 'A1',
        code: '04',
        description: 'Baja'
      },
      {
        codePrefix: 'A1',
        code: '05',
        description: 'Modificación Contractual'
      },
      { codePrefix: 'A1', code: '38', description: 'Puesta en Servicio Nuevos Suministros' },
      {
        codePrefix: 'A1',
        code: '41',
        description: 'Cambio de Comercializador con Modificación en Contrato Acceso'
      },
      {
        codePrefix: 'A1',
        code: '42',
        description: 'Cambio de Comercializador con Nuevos Productos Adicionales'
      },
      {
        codePrefix: 'A1',
        code: '43',
        description: 'Nuevo Producto / Contrato'
      },
      {
        codePrefix: 'A1',
        code: '44',
        description: 'Actuación: Operación en Campo / Actualización de Datos'
      },
      {
        codePrefix: 'A1',
        code: '48',
        description: 'Reclamación'
      },
      {
        codePrefix: 'A1',
        code: '49',
        description: 'Desestimiento'
      }
    ]
  }

  private getPageName(code: string) {
    switch (code) {
      case '02':
        return ProcessesFormsPages.P02
      case '03':
        return ProcessesFormsPages.P03
      case '04':
        return ProcessesFormsPages.P04
      case '05':
        return ProcessesFormsPages.P05
      case '38':
        return ProcessesFormsPages.P38
      case '41':
        return ProcessesFormsPages.P41
      case '42':
        return ProcessesFormsPages.P42
      case '43':
        return ProcessesFormsPages.P43
      case '44':
        return ProcessesFormsPages.P44
      case '48':
        return ProcessesFormsPages.P48
      case '49':
        return ProcessesFormsPages.P49
    }
  }

  protected onClickElement(code: string) {
    this.$router.push({ name: this.getPageName(code) })
  }
}
