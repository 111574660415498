import { Store } from '@/Core/Models/Stores'
import { BillsPages } from '@/Modules/Bills/router'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapAction, mixins, Prop, Vue } from 'types-vue'
import { Bill } from '../../../models'

enum Commands {
  seeDetails = 'seeDetails',
  delete = 'delete'
}

@Component
export default class ListItemMenu extends mixins(PermissionMixin) {
  
  @Prop({ required: true })
  protected bill: Bill

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.Bills) protected removeBill: (bill: Bill) => Promise<any>



  get havePermissions(): boolean {
    return this.isSystemRol || this.isDistributorRol && this.isAdminPermission
  }



  get commandDelete(): string {
    return Commands.delete
  }

  get commandSeeDetails(): string {
    return Commands.seeDetails
  }



  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
      case Commands.seeDetails:
        this.goToDetails()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar factura ${this.bill.billNum}`
    const message = `¿Estas seguro de eliminar el factura ${this.bill.billNum} con CUPS "${this.bill.cups}"?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'factura eliminado'
    const message = 'Se ha eliminado el factura correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar factura'
    const message = `Ha ocurrido un error al intentar eliminar el factura con referencia ${this.bill.billNum}`
    
    this.$notify({ title, message, type: 'error' })
  }

  protected goToDetails() {
    this.$router.push({ name: BillsPages.Bill, params: { id: this.bill.id.toString() }})
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeBill(this.bill)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }
  
}