var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c("el-pagination", {
        attrs: {
          "page-size": _vm.pageSize,
          "pager-count": 11,
          "current-page": _vm.currentPage,
          layout: "prev, pager, next",
          "hide-on-single-page": "",
          total: _vm.totalElements
        },
        on: { "current-change": _vm.onNewPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }