import { Store } from '@/Core/Models/Stores'
import { ElTable } from 'element-ui/types/table'
import { Component, MapAction, MapGetter, Prop, Vue, Watch, mixins} from 'types-vue'
import { ProcessModel } from '@/Modules/Processes/models'
import { ProcessesPages } from '@/Modules/Processes/router'
import PermissionMixin from "@/Modules/User/mixins/PermissionMixin"

@Component
export default class ProcessesList extends mixins(PermissionMixin) {

  @Prop({ required: true, type: Array }) protected processes: ProcessModel[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean
  @Prop({ required: false, type: Boolean, default: false }) protected withSelect: boolean



  /**
   * STATE
   */
  protected defaultOrder = { prop: null, order: null }
  protected showingMenuItem: ProcessModel = null
  

  @MapAction(Store.SelectedProcesses) protected newSelectedProcesses: (payload: {processes: ProcessModel[]}) => void
  @MapGetter(Store.SelectedProcesses) protected selectedProcesses: ProcessModel[]
  @MapGetter(Store.ProcessesInprocess) protected showDownloadHistory: boolean
  @MapGetter(Store.ProcessesInprocess) protected messageTypeWithinInterval: string
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  /**
   * EVENTS
   */

  protected onClickDetails(process: ProcessModel) {
    this.$router.push({ name: ProcessesPages.Details, params: { id: process.id.toString() }})
  }

  protected onCellMouseEnter(item: ProcessModel): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: ProcessModel): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected onSelectionChange(items: ProcessModel[]) {
    this.newSelectedProcesses({ processes: items })
  }

  /**
   * GETTERS
   */
  protected get emptyText(): string {
    return (this.loading)
      ? 'CARGANDO PROCESOS EN CURSO...'
      : 'NO HAY PROCESOS EN CURSO'
  }

  protected get messageTypeDownloadedColumnLabel(): string {
    return this.messageTypeWithinInterval + " DESCARGADO"
  }

  protected get showMarketerInfo(): boolean {
    return ((this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol)
  }

  /**
   * METHODS
   */
  protected checkShowOptions(process: ProcessModel): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.id === process.id
  }

  /**
   * WATCHERS
   */
  @Watch('selectedProcesses', { deep: true }) protected onChangeSelectedProcesses() {
    if (this.selectedProcesses.length === 0) {
      const table = this.$refs.table as ElTable
      table.clearSelection()
    }
  }
}