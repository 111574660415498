export interface ProcessTableMetric {
  accepted: number
  cancelled: number
  completed: number
  messageType: string
  pending: number
  processCode: string
  rejected: number
  responseTimeInDays: number
  uploaded: number
}

export interface AlertCongifuration {
  process02: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process03: {
    completed: boolean
    rejectedA2: boolean
  }
  process04: {
    acceptedA2: boolean
    completed: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
  }
  process05: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process38: {
    acceptedA2: boolean
    completed: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
  }
  process41: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process42: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process43: {
    acceptedA2: boolean
    completed: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
  }
  process44Closing: {
    acceptedA2: boolean
    completed: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
  }
  process44Reopening: {
    acceptedA2: boolean
    completed: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
  }
  process48: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process49: {
    acceptedA2: boolean
    acceptedA2S: boolean
    completedA3: boolean
    completedA3S: boolean
    delayedByDays: null | number
    rejectedA2: boolean
    rejectedA4: boolean
    rejectedA4S: boolean
  }
  process50: {
    completedA15: boolean
    completedA15S: boolean
  }
  sendEmail: boolean
}

export interface AlertsConfigParams {
  userEmail: string
  marketer: string
}

export interface ConfigSubject {
  name: string
  code: string
}

export const defaultConfiguration: AlertCongifuration = {
  process02: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process03: {
    completed: false,
    rejectedA2: false
  },
  process04: {
    completed: false,
    rejectedA2: false,
    rejectedA4: false,
    acceptedA2: false,
    delayedByDays: null
  },
  process05: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process38: {
    completed: false,
    rejectedA2: false,
    rejectedA4: false,
    acceptedA2: false,
    delayedByDays: null
  },
  process41: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process42: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process43: {
    completed: false,
    rejectedA2: false,
    rejectedA4: false,
    acceptedA2: false,
    delayedByDays: null
  },
  process44Closing: {
    completed: false,
    rejectedA2: false,
    rejectedA4: false,
    acceptedA2: false,
    delayedByDays: null
  },
  process44Reopening: {
    completed: false,
    rejectedA2: false,
    rejectedA4: false,
    acceptedA2: false,
    delayedByDays: null
  },
  process48: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process49: {
    completedA3: false,
    completedA3S: false,
    rejectedA2: false,
    rejectedA4: false,
    rejectedA4S: false,
    acceptedA2: false,
    acceptedA2S: false,
    delayedByDays: null
  },
  process50: {
    completedA15: false,
    completedA15S: false
  },
  sendEmail: false
}
export const allTrueConfiguration: AlertCongifuration = {
  process02: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process03: {
    completed: true,
    rejectedA2: true
  },
  process04: {
    completed: true,
    rejectedA2: true,
    rejectedA4: true,
    acceptedA2: true,
    delayedByDays: null
  },
  process05: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process38: {
    completed: true,
    rejectedA2: true,
    rejectedA4: true,
    acceptedA2: true,
    delayedByDays: null
  },
  process41: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process42: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process43: {
    completed: true,
    rejectedA2: true,
    rejectedA4: true,
    acceptedA2: true,
    delayedByDays: null
  },
  process44Closing: {
    completed: true,
    rejectedA2: true,
    rejectedA4: true,
    acceptedA2: true,
    delayedByDays: null
  },
  process44Reopening: {
    completed: true,
    rejectedA2: true,
    rejectedA4: true,
    acceptedA2: true,
    delayedByDays: null
  },
  process48: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process49: {
    completedA3: true,
    completedA3S: true,
    rejectedA2: true,
    rejectedA4: true,
    rejectedA4S: true,
    acceptedA2: true,
    acceptedA2S: true,
    delayedByDays: null
  },
  process50: {
    completedA15: true,
    completedA15S: true
  },
  sendEmail: true
}

export const mainOptions = {
  process02: 'Proceso 02',
  process03: 'Proceso 03',
  process04: 'Proceso 04',
  process05: 'Proceso 05',
  process38: 'Proceso 38',
  process41: 'Proceso 41',
  process42: 'Proceso 42',
  process43: 'Proceso 43',
  process44Closing: 'Proceso 44 Cierre por impago',
  process44Reopening: 'Proceso 44 Reapertura',
  process48: 'Proceso 48',
  process49: 'Proceso 49',
  process50: 'Proceso 50',
  sendEmail: 'Email'
}

export const subOptions = {
  acceptedA2: 'Aceptado A2',
  acceptedA2S: 'Aceptado A2S',
  completed: 'Completado',
  completedA3: 'Completado A3',
  completedA3S: 'Completado A3S',
  completedA15: 'Completado A15',
  completedA15S: 'Completado A15S',
  rejectedA2: 'Rechazado A2',
  rejectedA4: 'Rechazado A4',
  rejectedA4S: 'Rechazado A4S',
  numberOfDays: 'Días',
  count: 'Alertas'
}
