export default {
  navMenu: {
    "processes": "Procesos",
    "history": "Histórico",
    "bills": "Facturas",
    "inProcess": "En curso",
    "historical": "Histórico",
    "configuration": "Configuración",
    "dashboard": "Dashboard",
    "masters": "Maestros",
    "holidays": "Festivos",
    "usersManager": "Usuarios",
    "marketersManager": "Comercializadoras",
    "s3Connector": "Conector S3",
    "atrContracts": "Contratos ATR",
    "reports": "Informes",
    "unprocessed": "No procesados",
    "createProcess": "Iniciar proceso",
    "searchCups": "Consulta CUPS",
    "adresses": "Callejeros",
    "withoutContract": "CUPS Disponibles",
    "cur": "Envío a CUR",
    "downloadable": "Generar informe",
    "delivery": "Reparto",
    "LOPD": "Consulta de PS",
    "billingErrors": "Facturas no procesadas",
    "historyContracts": "Contratos",
    "historyBilling": "Facturación",
    "historyAtcom": "ATCOM",
    "unprocessedMarketer": "Comer. No procesado",
    "alerts": "Alertas"
  }
}