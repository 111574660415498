import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ProcessForm extends Vue {
  @Prop({ required: true })
  protected formOptions: FormConfig

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  protected onChange(model) {
    this.$emit('change', model)
  }
}
