import { AuthPages } from '@/Modules/Auth/router'
import { UserPages } from '@/Modules/User/router'
import { Component, MapGetter, Vue } from 'types-vue'
import querystring from 'querystring'
import { Store } from '@/Core/Models/Stores'

enum Commands {
  Config = 'config',
  Logout = 'logout'
}

@Component
export default class UserMenu extends Vue {


  protected items = {
    [Commands.Config]: { label: 'Configuración', icon: 'el-icon-setting',       enabled: true },
    [Commands.Logout]: { label: 'Logout',        icon: 'el-icon-switch-button', enabled: true }
  }


  /**
   * STORE
   */

  @MapGetter(Store.Signin) private username: string



  /**
   * GETTERS
   */

  get menuItems() {
    return Object.keys(this.items).map((command: Commands) => ({ command , ...this.items[command]}))
  }

  get avatarSrc(): string {

    const color = 'fff'
    const background = '427aa1'
    const name = this.username.split('@')[0].replace('.', '+')
    const params = querystring.stringify({ name, background, color })

    return `https://eu.ui-avatars.com/api/?${params}`
  }



  /**
   * METHODS
   */

  private logout() {
    this.$router.push({ name: AuthPages.Signin })
  }

  private goToUserConfig() {
    this.$router.push({ name: UserPages.UserConfiguration })
  }


  /**
   * EVENTS
   */

  protected onCommand(command: Commands) {
    switch (command) {
      case Commands.Config:
        this.goToUserConfig()
        break;
      case Commands.Logout:
        this.logout()
        break;
    
      default:
        break;
    }
  }

}