import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class SupplyPointForm1 extends Vue {

  private provinceCode: string = null

  protected get formOptions(): FormConfig {
    return {
      label: 'Supply point form',
      submitButton: 'Siguiente',
      items: [
        { type: 'text', name: 'cups', label: 'CUPS', placeholder: 'Introduce el CUPS', maxCharacters: 20 },
        { type: 'select', name: 'province', label: 'Provincia', options: `${CORE_URL}/location-province`, placeholder: 'Selecciona provincia', valueQuery: 'code' },
        { type: 'select', name: 'city', label: 'Municipio', options: `${CORE_URL}/ine-municipalities`, placeholder: 'Selecciona municipio', valueQuery: 'netMunicipalityCode', labelQuery: 'municipalityIneDescription' },
        { type: 'text', name: 'zipcode', label: 'Codigo postal', placeholder: 'Introduce el codigo postal', maxCharacters: 5 },
      ],
      rules: {
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        province: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        city: [{ required: true, trigger: 'change', message: 'Es obligatorio' }]
      }
    }
  }

  protected change(process: any) {
    if (process.province !== "" && process.city !== "" && process.zipcode !== "") {
      const jsonP38_SupplyPointForm1 = {
        province: process.province,
        city: process.city,
        zipcode: process.zipcode
      };
      localStorage.setItem('p38_supplypointform1_json', JSON.stringify(jsonP38_SupplyPointForm1));
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}