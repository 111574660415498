import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Prop, Vue } from 'types-vue'

@Component
export default class Drawer extends Vue {
  
  @Prop({ required: true }) protected visible: boolean

  @MapGetter(Store.Uploader) protected showResults: boolean
  @MapAction(Store.Uploader) protected clean: () => void

  protected onClose() {
    this.$emit('update:visible', false)
  }

  protected onClickOtherFile() {
    this.clean()
  }

  created() {
    this.clean()
  }
}