var render, staticRenderFns
import script from "./ConsumptionChart.ts?vue&type=script&lang=ts&"
export * from "./ConsumptionChart.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13df4a96')) {
      api.createRecord('13df4a96', component.options)
    } else {
      api.reload('13df4a96', component.options)
    }
    
  }
}
component.options.__file = "src/Modules/Cups/components/charts/ConsumptionChart/ConsumptionChart.vue"
export default component.exports