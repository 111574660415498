var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-checker" },
    [
      _c("span", { staticClass: "title" }, [
        _vm._v("Requisitos de la contraseña:")
      ]),
      _vm._l(_vm.checks, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "element",
            class: { accomplished: item.result }
          },
          [
            _c("div", { staticClass: "result" }, [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.result,
                    expression: "item.result"
                  }
                ],
                staticClass: "el-icon-check"
              })
            ]),
            _c("span", [_vm._v(_vm._s(item.label))])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }