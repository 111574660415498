import { checkTokenExpired } from '@/Core/Utils'
import { AxiosError } from 'axios'
import { Notification } from 'element-ui'
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import ReportHistories from '../connections/api/ReportHistories'
import { QueryParams } from '../definitions/QueryParams'
import { ReportModel } from '../models'
import { ReportListResponse } from '../models/api'



@Module({ namespaced: true })
export default class extends VuexModule {

  private _historicalReports: ReportModel[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null
  private _listLoading: boolean = true
  private _filterBy: any = undefined


  @Getter() public orderProp(): string { return this._orderProp }
  @Getter() public orderDirection(): string { return this._orderDirection }
  @Getter() public historicalReportsList(): ReportModel[] { return this._historicalReports }
  @Getter() public typeFilter(): any { return this._filterBy }
  @Getter() public pageSize(): number { return this._pageSize }
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }



  @Mutation() protected setOrderProp(value: string) { this._orderProp = value }
  @Mutation() protected setOrderDirection(value: string) { this._orderDirection = value }
  @Mutation() protected setPageSize(pageSize: number) { this._pageSize = pageSize }
  @Mutation() protected setListLoading(value: boolean) { this._listLoading = value }



  @Mutation()
  protected setHistoricalReports(data: ReportListResponse) {
    this._historicalReports = [...data.content]
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setTypeFilter(newValue) {
    this._filterBy = newValue
  }

  @Mutation()
  protected setCurrentPage(currentPage: number) {
    const isEmpty = _.isEmpty(currentPage)
    this._currentPage = (isEmpty) ? 1 : currentPage
  }


  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setTypeFilter', queryParams[QueryParams.Type])

    ctx.dispatch('obtainHistoricalReports')
  }

  @Action({ useContext: true })
  protected async obtainHistoricalReports(ctx: ActionContext<any, any>) {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const filter = ctx.getters.typeFilter

    if (_.isNil(page) || _.isNil(size) || size < 1) return

    try {
      ctx.commit('setListLoading', true)
      const response = await ReportHistories.getHistoricalReportsList({ page, size, filter })
      ctx.commit('setHistoricalReports', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los processos.'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }
  }


  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ useContext: true })
  protected async removeHistoricalReport(ctx: ActionContext<any, any>, report: ReportModel): Promise<any> {
    const response = await ReportHistories.removeById(report.id)

    if (response.status === 200)
      ctx.dispatch('obtainHistoricalReports')

  }
}
