import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ExpedientIncidenceForm2 extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  // Personalized boolean variables.
  protected isFirstNameRequired: boolean = true
  protected isLastNameRequired: boolean = true
  protected isSecondNameRequired: boolean = true

  protected get formOptions(): FormConfig {
    return {
      label: 'Incidencia y cliente',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'date',
          name: 'readingdate',
          label: 'Fecha de la lectura actual',
          placeholder: 'Introduce la fecha',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'readingvalue',
          label: 'Lectura actual del contador',
          placeholder: 'Introduce la lectura actual del contador'
        },
        {
          type: 'date',
          name: 'incidentdate',
          label: 'Fecha de la incidencia',
          placeholder: 'Introduce la fecha de la incidencia',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo de documento del cliente',
          options: `${CORE_URL}/client-document-types`,
          placeholder: 'Selecciona el tipo de documento del cliente',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Número de documento del cliente',
          placeholder: 'Introduce el número de documento del cliente',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'titulartype',
          label: 'Tipo de titular del cliente',
          options: `${CORE_URL}/client-person-types`,
          placeholder: 'Selecciona el tipo de titular cliente',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'firstname',
          label: 'Nombre del cliente',
          placeholder: 'Introduce el número de documento del cliente',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'familyname1',
          label: 'Apellido 1 del cliente',
          placeholder: 'Introduce el apellido 1',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'familyname2',
          label: 'Apellido 2 del cliente',
          placeholder: 'Introduce el apellido 2',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'businessName',
          label: 'Razón social del cliente',
          placeholder: 'Introduce el nombre del cliente',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'telephoneprefixC',
          label: 'Prefijo del teléfono del cliente',
          placeholder: 'Introduce el prefijo del teléfono del cliente',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'telephonenumberC',
          label: 'Teléfono del cliente',
          placeholder: 'Introduce el teléfono del cliente',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'email',
          label: 'Email del cliente',
          placeholder: 'Introduce el correo electrónico del cliente',
          maxCharacters: 100
        }
      ],
      rules: {
        readingdate: [
          {
            required: p48required[this.claimTypes]?.readingdate,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        readingvalue: [
          {
            required: p48required[this.claimTypes]?.readingvalue,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        incidentdate: [
          {
            required: p48required[this.claimTypes]?.incidentdate,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        documenttype: [
          {
            required: p48required[this.claimTypes]?.documentnum,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        documentnum: [
          {
            required: p48required[this.claimTypes]?.documentnum,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        bussinesName: [
          {
            required: p48required[this.claimTypes]?.businessName,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        firstname: [
          {
            required: !!!p48required[this.claimTypes] && this.isFirstNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        familyname1: [
          {
            required: !!!p48required[this.claimTypes] && this.isLastNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        familyname2: [
          {
            required: !!!p48required[this.claimTypes] && this.isSecondNameRequired,
            trigger: 'blur',
            message: 'Es obligatorio en caso de no introducir razón social'
          }
        ],
        telephonenumberC: [
          { required: !!!p48required[this.claimTypes], trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected change(process: any): void {
    const { businessName } = process

    if (!!businessName && !!!p48required[this.claimTypes]) {
      this.isFirstNameRequired = false
      this.isLastNameRequired = false
      this.isSecondNameRequired = false
    }
    if (!!!businessName && !!!p48required[this.claimTypes]) {
      this.isFirstNameRequired = true
      this.isLastNameRequired = true
      this.isSecondNameRequired = true
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
