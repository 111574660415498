import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ClientForm2 extends Vue {

  // Personalized boolean variables.
  protected isFirstNameRequired: boolean = false;

  protected get formOptions(): FormConfig {
    return {
      label: 'Cliente',
      submitButton: 'Siguiente',
      items: [
        { type: 'text', name: 'firstname', label: 'Nombre', placeholder: 'Introduce el nombre', maxCharacters: 40 },
        { type: 'text', name: 'familyname1', label: 'Primer Apellido / Razón social', placeholder: 'Introduce el primer apellido o razón social', maxCharacters: 30 },
        { type: 'text', name: 'familyname2', label: 'Segundo Apellido', placeholder: 'Introduce el segundo apellido', maxCharacters: 30 },
        { type: 'text', name: 'telephone1', label: 'Teléfono', placeholder: 'Introduce el teléfono', maxCharacters: 20 },
        { type: 'text', name: 'telephone2', label: 'Teléfono móvil', placeholder: 'Introduce el teléfono móvil', maxCharacters: 20 },
        { type: 'text', name: 'fax', label: 'Fax', placeholder: 'Introduce el fax', maxCharacters: 20 },
        { type: 'text', name: 'email', label: 'Correo electrónico', placeholder: 'Introduce correo electrónico', maxCharacters: 60 },
      ],
      rules: {
        firstname: [{ required: this.isFirstNameRequired, trigger: 'blur', message: 'Es obligatorio' }],
        familyname1: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        telephone1: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  protected change(process: any) {
    const isFirstNameRequired = localStorage.getItem('p38_titulartype_json');
    if (isFirstNameRequired === "F") {
      this.isFirstNameRequired = true;
    } else {
      this.isFirstNameRequired = false
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}