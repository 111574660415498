import { render, staticRenderFns } from "./NewClientForm2.vue?vue&type=template&id=01b28761&scoped=true&"
import script from "./NewClientForm2.ts?vue&type=script&lang=ts&"
export * from "./NewClientForm2.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b28761",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01b28761')) {
      api.createRecord('01b28761', component.options)
    } else {
      api.reload('01b28761', component.options)
    }
    module.hot.accept("./NewClientForm2.vue?vue&type=template&id=01b28761&scoped=true&", function () {
      api.rerender('01b28761', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/ProcessesForms/components/P42/NewClientForm2/NewClientForm2.vue"
export default component.exports