import FiltersMixin from '@/Core/mixins/FiltersMixin'
import _ from 'lodash'
import { Component, mixins, Vue } from 'types-vue'
import { MasterConfig, Masters } from '../../models/Masters'
import { MasterPages } from '../../router'

@Component
export default class MasterList extends mixins(FiltersMixin) {

  // protected masterList = Masters.list
  protected searchModel: string = ''

  protected onRowClick(master: MasterConfig) {
    this.$router.push({ name: MasterPages.Master, params: { master: master.code }})
  }

  get masterList(): MasterConfig[] {
    if (_.isEmpty(this.searchModel)) return Masters.list
    return Masters.list.filter(master => _.lowerCase(master.code).includes(this.searchModel))
  }
}