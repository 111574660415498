import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Prop, Vue, Watch } from 'types-vue'
import { mainOptions, subOptions } from '../../models'

@Component
export default class AlertsTable extends Vue {
  @Prop({ required: true })
  protected alertsToRender: any

  @MapGetter(Store.Alerts) protected alerts: any
  @MapGetter(Store.Alerts) protected configuration: any
  @MapGetter(Store.Alerts) protected alertsLoading: boolean
  @MapGetter(Store.Alerts) protected subject: any

  cards: {} = {}
  noCards: boolean = false
  mainOptions = { ...mainOptions }
  subOptions = { ...subOptions }
  date = moment().subtract(1, 'days').format('YYYY-MM-DD')
}
