import { Cups, Period } from '@/Modules/Cups/models'
import moment from 'moment'
import { Component, Filter, Prop, Vue } from 'types-vue'

@Component
export default class Periods extends Vue {
  
  @Prop({ required: true }) cups: Cups


  protected get periods() {
    return this.cups.consumptions.map((per, i) => ({...per, number: i +1 }))
  }


  @Filter()
  protected dateFilter(value: string) {
    const date = moment(value)

    return (date.isValid()) ? date.format('YYYY/MM/DD') : '-'
  }

}