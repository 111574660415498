import AbstractApi, { Method } from "@/Core/api/AbstractApi"
import { AxiosPromise } from "axios"
import { Version } from "../../models"

const CORE_URL = process.env.VUE_APP_CORE_URL

const Path = {
  versions: `${CORE_URL}/app-versions`,
}

class VersionsSingleton extends AbstractApi{
  private static _instance: VersionsSingleton
  private constructor() { super() }

  public static get instance(): VersionsSingleton {
    if (!VersionsSingleton._instance) VersionsSingleton._instance = new VersionsSingleton();
    return VersionsSingleton._instance;
  }


  public get(): AxiosPromise<Version[]> {
    return this.request({ method: Method.GET, url: Path.versions })
  }
}

export const VersionsApi = VersionsSingleton.instance