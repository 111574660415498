import { RouteConfig } from 'vue-router'

import Page from '@/Core/views/Page/Page.vue'
import Contracts from './pages/Contracts/Contracts.vue'
import Billing from './pages/Billing/Billing.vue'
import Atcom from './pages/Atcom/Atcom.vue'


export enum HistoryPages {
  Contracts = 'HistoryPageContracts',
  Billing = 'HistoryPageBilling',
  Atcom = 'HistoryPageAtcom'
}


const routes: Array<RouteConfig> = [
  {
    path: '/history',
    component: Page,
    children: [
      {
        path: 'contracts',
        name: HistoryPages.Contracts,
        component: Contracts
      },
      {
        path: 'billing',
        name: HistoryPages.Billing,
        component: Billing
      },
      {
        path: 'atcom',
        name: HistoryPages.Atcom,
        component: Atcom
      },

    ]
  }
]

export default routes