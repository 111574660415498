var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Dashboard")]),
    _c(
      "div",
      { staticClass: "row row-1" },
      [
        _c(
          "div",
          { staticClass: "processes" },
          [
            _c("Dashboard-ProcessesSection", {
              staticClass: "processes-section"
            }),
            _c("Dashboard-ProcessTimerSection")
          ],
          1
        ),
        _c("Dashboard-BillingSection", { staticClass: "billing" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("Dashboard-ProcessesTableSection", {
          staticClass: "processes-table"
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }