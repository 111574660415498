var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "process-section"
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "span",
            { staticClass: "label", on: { click: _vm.onClickLabel } },
            [
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.counter))
              ]),
              _vm._v(
                " procesos abiertos hace más " +
                  _vm._s(_vm.visibleDays) +
                  " días."
              )
            ]
          ),
          _c(
            "el-popover",
            {
              attrs: { placement: "top", width: "160" },
              model: {
                value: _vm.popoverVisible,
                callback: function($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible"
              }
            },
            [
              _c(
                "div",
                { staticClass: "popover-content" },
                [
                  _c("p", [_vm._v("Introduce los días:")]),
                  _c("el-input", {
                    attrs: { size: "mini" },
                    on: { input: _vm.onInput },
                    model: {
                      value: _vm.daysModelInput,
                      callback: function($$v) {
                        _vm.daysModelInput = $$v
                      },
                      expression: "daysModelInput"
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right", margin: "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: {
                            click: function($event) {
                              _vm.popoverVisible = false
                            }
                          }
                        },
                        [_vm._v("cancelar")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            disabled: !_vm.isValidatedInput
                          },
                          on: { click: _vm.onClickConfirm }
                        },
                        [_vm._v("Confirmar")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("i", {
                staticClass: "el-icon-setting button",
                attrs: { slot: "reference" },
                slot: "reference"
              })
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }