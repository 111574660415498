import UserConfiguration from './pages/UserConfiguration/UserConfiguration.vue'
import Page from '@/Core/views/Page/Page.vue'
import { RouteConfig } from 'vue-router'

export enum UserPages {
  UserConfiguration = 'UserConfiguration'
}

const routes: Array<RouteConfig> = [
  {
    path: '/user',
    component: Page,
    children: [
      {
        path: 'configuration',
        name: UserPages.UserConfiguration,
        component: UserConfiguration
      }
    ]
  }
]

export default routes