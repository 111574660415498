var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [_c("h3", [_vm._v("CONCEPTOS FACTURADOS")]), _c("Bills-BillConceptsTable")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }