import { Store } from '@/Core/Models/Stores'
import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, MapAction, Vue } from 'types-vue'
import { QueryParams } from '../../../definitions/QueryParams'
import { FiltersName } from '../../../enums/Filters'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class Filters extends Vue {
  @MapAction(Store.Bills) protected newProceesTypeFilterValue: (newValues) => any
  @MapAction(Store.Bills) protected newReferenceFilter: (newValues) => any
  @MapAction(Store.Bills) protected newCupsFilter: (newValues) => any

  /**
   * GETTERS
   */
  protected get billNumberFilterName(): string {
    return FiltersName.BillNumber
  }
  protected get cupsFilterName(): string {
    return FiltersName.Cups
  }
  protected get processTypeName(): string {
    return QueryParams.ProcessType
  }
  protected get marketerName(): string {
    return QueryParams.Marketer
  }
  protected get startBillDateName(): string {
    return QueryParams.StartBillDate
  }
  protected get endBillDateName(): string {
    return QueryParams.EndBillDate
  }
  protected get startProcessDateName(): string {
    return QueryParams.StartProcessDate
  }
  protected get endProcessDateName(): string {
    return QueryParams.EndProcessDate
  }
  protected get billTypeName(): string {
    return QueryParams.BillType
  }

  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'tag',
          name: FiltersName.Cups,
          label: 'Cups',
          default: (this.$route.query[FiltersName.Cups] as string)?.split(',') || []
        },
        {
          type: 'text',
          name: FiltersName.BillNumber,
          label: 'Numero factura',
          default: this.$route.query[FiltersName.BillNumber]
        },
        {
          type: 'text',
          name: QueryParams.PseudoBill,
          label: 'Numero pseudo factura',
          default: this.$route.query[QueryParams.PseudoBill]
        },
        {
          type: 'select',
          name: QueryParams.Marketer,
          label: 'Comercializadora',
          default: (this.$route.query[QueryParams.Marketer] as string)?.split(','),
          options: `${CORE_URL}/system-subjects?categories=COM`,
          valueQuery: 'code',
          clearable: true,
          multiple: true
        },
        {
          type: 'select',
          name: QueryParams.BillType,
          label: 'Tipo de factura',
          default: this.$route.query[QueryParams.BillType],
          options: `${CORE_URL}/bill-types`,
          valueQuery: 'code',
          clearable: true
        },
        {
          type: 'select',
          name: QueryParams.ProcessType,
          label: 'Tipo de proceso',
          default: this.$route.query[QueryParams.ProcessType],
          options: [
            { value: '31', label: '31 - Facturación individual por CUPS' },
            { value: '32', label: '32 - Detalle por CUPS de la facturación ATR agregada' },
            { value: '33', label: '33 - Facturación agregada (datos por factura)' }
          ],
          clearable: true
        },
        {
          type: 'select',
          name: QueryParams.Downloaded,
          label: 'Facturas descargadas',
          clearable: true,
          default: this.$route.query[QueryParams.Downloaded],
          options: [
            { label: 'Sí', value: '1' },
            { label: 'No', value: '0' }
          ]
        },
        {
          type: 'date',
          name: QueryParams.StartProcessDate,
          label: 'Fecha inicio proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.StartProcessDate]
        },
        {
          type: 'date',
          name: QueryParams.EndProcessDate,
          label: 'Fecha fin proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.EndProcessDate]
        },
        {
          type: 'date',
          name: QueryParams.StartBillDate,
          label: 'Fecha inicio facturación',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.StartBillDate]
        },
        {
          type: 'date',
          name: QueryParams.EndBillDate,
          label: 'Fecha fin facturación',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.EndBillDate]
        }
      ]
    }
  }

  protected setCookies(query: any) {
    this.$cookies.set('bills_filters', query)
  }

  protected onClickRestore() {
    const query = this.$cookies.get('bills_filters')
    this.$router.push({ ...this.$route, query })
  }
}
