import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

@Component
export default class ContactForm extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  protected get formOptions(): FormConfig {
    return {
      label: 'Contacto',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'text',
          name: 'contactname',
          label: 'Nombre del contacto',
          placeholder: 'Introduce el nombre del contacto',
          maxCharacters: 100
        },
        {
          type: 'text',
          name: 'telephoneprefix',
          label: 'Prefijo del teléfono del contacto',
          placeholder: 'Introduce el prefijo del contacto',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'telephonenumber',
          label: 'Teléfono del contacto',
          placeholder: 'Introduce el teléfono del contacto',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'contactemail',
          label: 'Email del contacto',
          placeholder: 'Introduce el correo electrónico del contacto',
          maxCharacters: 100
        }
      ],
      rules: {
        contactname: [
          {
            required: p48required[this.claimTypes]?.contactname,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        telephonenumber: [
          {
            required: p48required[this.claimTypes]?.telephonenumber,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ]
      }
    }
  }

  protected onSubmit(model) {
    const contactemail = model.contactemail

    if (contactemail !== '') {
      const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

      if (regexp.test(contactemail)) {
        this.$emit('submit', model)
      } else {
        this.$notify.error({
          title: `Error en el correo electrónico`,
          message: 'Por favor pon un email correcto'
        })
      }
    } else {
      this.$emit('submit', model)
    }
  }

}
