import { Component, MapGetter, mixins, Prop } from 'types-vue'

import { FormConfig } from '@/Modules/Forms/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { QueryParams, Roles, Permissions } from '@/Modules/UsersManager/models'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ProcessFilters extends mixins(PermissionMixin) {
  @Prop({ required: true })
  protected page: string

  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'tag',
          name: QueryParams.Emails,
          label: 'Email',
          default: (this.$route.query[QueryParams.Emails] as string)?.split(',') || [],
          multiple: true
        },
        {
          type: 'select',
          name: QueryParams.Groups,
          label: 'Grupos',
          default: (this.$route.query[QueryParams.Groups] as string)?.split(',') || [],
          options: `${CORE_URL}/system-subjects`,
          valueQuery: 'code',
          multiple: true
        },
        {
          type: 'select',
          name: QueryParams.Rol,
          label: 'Rol',
          default: this.$route.query[QueryParams.Rol] as string,
          clearable: true,
          valueQuery: 'code',
          options: Roles.options
        },
        {
          type: 'select',
          name: QueryParams.Permission,
          label: 'Permisos',
          default: this.$route.query[QueryParams.Permission] as string,
          clearable: true,
          valueQuery: 'code',
          options: Permissions.options
        }
      ]
    }
  }
}
