import AbstractApi, { Method } from "@/Core/api/AbstractApi";
import { AxiosPromise } from 'axios';
import ProcessMetricsResponse, { ProcessTableMetricsRequest, ProcessTableMetricsResponse } from "../../models/api/ProcessMetricsResponse";

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  Metrics: `${CORE_SERVICE_URL_BASE}/process-metrics`,
  MetricsTable: `${CORE_SERVICE_URL_BASE}/process-metrics/processes`,
}

class ProcessMetrics extends AbstractApi {

  public getMetrics(startDate: string, endDate: string): AxiosPromise<ProcessMetricsResponse> {
    const url = Path.Metrics
    const method = Method.GET
    const params = { fromCreatedAt: startDate, toCreatedAt: endDate }

    return this.request({ method, url, params })
  }


  public getTableMetrics( params: ProcessTableMetricsRequest ): AxiosPromise<ProcessTableMetricsResponse> {
    const url = Path.MetricsTable
    const method = Method.GET
    
    return this.request({ method, url, params })
  }

}

export default new ProcessMetrics()
