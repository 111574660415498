import _ from 'lodash'
import { Component, Vue } from 'types-vue'
import { FiltersName } from '../../enums/Filters'


@Component
export default class FiltersContainer extends Vue {

  /**
   * GETTERS
   */

  protected get cupsFilterName(): string {
    return FiltersName.Cups
  }

}