import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'

import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

@Component
export default class Atcom extends Vue {
  /**
   * STORE
   */

  // Getters
  @MapGetter(Store.HistoryAtcom) protected historyAtcom: any[]
  @MapGetter(Store.HistoryAtcom) protected totalElements: number
  @MapGetter(Store.HistoryAtcom) protected pageSize: number
  @MapGetter(Store.HistoryAtcom) protected isLoading: boolean
  @MapGetter(Store.HistoryAtcom) protected totalActiveFilter: number

  //Actions
  @MapAction(Store.HistoryAtcom) protected obtainHistoryAtcom: () => Promise<void>
  @MapAction(Store.HistoryAtcom) protected newPageSize: (pageSize: number) => Promise<void>
  @MapAction(Store.HistoryAtcom) protected processUrlQueryParams: (queryParams: any) => void

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }

  /**
   * WATCHERS
   */

  @Watch('$route', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    this.processUrlQueryParams(this.$route.query)
  }
}
