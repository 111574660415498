var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: _vm.type,
          size: _vm.size,
          "range-separator": "-",
          placeholder: "Fecha",
          "start-placeholder": "Inicio",
          "end-placeholder": "Fin",
          "picker-options": _vm.pickerOptions,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }