var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("h3", [_vm._v("Facturación")]),
        _c("Dashboard-BillingPeriodSelect", {
          on: { change: _vm.onChangeBillingPeriod }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.billingLoading,
            expression: "billingLoading"
          }
        ],
        staticClass: "chart"
      },
      [_c("Dashboard-BillingBarChart")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }