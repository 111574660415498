import { Component, Vue, Watch } from 'types-vue'

@Component
export default class ProcessMetricsTimeFilter extends Vue {

  protected model: string = 'week'

  protected options = [
    { label: 'Última semana', value: 'week' },
    { label: 'Último mes', value: 'month' },
    { label: 'Último año', value: 'year' }
  ]

  @Watch('model', { deep: true, immediate: true}) protected onChangeModel() {
    this.$emit('change', this.model)
  }
}