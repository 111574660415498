import { Store } from '@/Core/Models/Stores';
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import CreateMarketerDialog from '../../components/CreateMarketerDialog/CreateMarketerDialog';
import EditorMarketerDialog from '../../components/EditorMarketerDialog/EditorMarketerDialog';
import { Marketer } from '../../models';

@Component
export default class Main extends Vue {

  @MapGetter(Store.Marketers) protected loading: boolean
  @MapAction(Store.Marketers) protected obtainMarketers: () => Promise<any>


  protected onClickShowCreateForm() {
    const createDialog = this.$refs.createDialog as CreateMarketerDialog
    createDialog.show()
  }

  protected onClickEditMarketer(marketer: Marketer) {
    const createDialog = this.$refs.editorDialog as EditorMarketerDialog
    createDialog.show(marketer)
  }


  protected async created() {
    try {
      await this.obtainMarketers()
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al obtener los datos.'})
    }
  }
}