import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class P05 extends Vue {
  protected showForm: boolean = true
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null
  protected titularAutonomoCases = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '18',
    '19',
    '20',
    '21',
    '22'
  ]

  // Personalized string variables.
  protected areDocumentTypesVerified: string = ''
  protected areDocumentTypesVerified2: string = ''

  // Personalized boolean variables.

  // Disabled
  protected isModelEffectDateRequired: boolean = true
  protected isReqTransferDateDisabled: boolean = true
  protected isModelEffectDateDisabled: boolean = true
  protected isNewDocumentTypeDisabled: boolean = true
  protected isNewDocumentNumDisabled: boolean = true
  protected isNewFirstNameDisabled: boolean = true
  protected isNewFamiliyName1Disabled: boolean = true
  protected isNewToolTypeDisabled: boolean = true
  protected isNewReqQDDisabled: boolean = true
  protected isNewReqEstimatedQADisabled: boolean = true
  protected isNewNationalityDisabled: boolean = true
  protected isNewFamiliyName2Disabled: boolean = true
  protected isNewTelephoneDisabled: boolean = true
  protected isNewFaxDisabled: boolean = true
  protected isNewEmailDisabled: boolean = true
  protected isNewCaeCodeDisabled: boolean = true
  protected isNewProvinceOwnerDisabled: boolean = true
  protected isNewCityOwnerDisabled: boolean = true
  protected isNewTitularTypeDisabled: boolean = true
  protected isNewZipCodeOwnerDisabled: boolean = true
  protected isNewStreetTypeOwnerDisabled: boolean = true
  protected isNewStreetOwnerDisabled: boolean = true
  protected isNewStreetNumberOwnerDisabled: boolean = true
  protected isNewPortalOwnerDisabled: boolean = true
  protected isNewStaircaseOwnerDisabled: boolean = true
  protected isNewFloorOwnerDisabled: boolean = true
  protected isNewDoorOwnerDisabled: boolean = true
  protected isNewFactMethodDisabled: boolean = true
  protected isNewGasStationTypeDisabled: boolean = true
  protected isTitularAutonomoDisabled: boolean = true

  // Required
  protected isNewCaeCodeRequired: boolean = false
  protected isNewFirstNameRequired: boolean = false
  protected isNewFamilyName1Required: boolean = false
  protected isNewTelephoneRequired: boolean = false
  protected isNewNationalityRequired: boolean = false
  protected isNewDocumentTypeRequired: boolean = false
  protected isNewDocumentNumRequired: boolean = false
  protected isNewProvinceOwnerRequired: boolean = false
  protected isNewCityOwnerRequired: boolean = false
  protected isNewZipCodeOwnerRequired: boolean = false
  protected isNewStreetTypeOwnerRequired: boolean = false
  protected isNewStreetOwnerRequired: boolean = false
  protected isNewStreetNumberOwnerRequired: boolean = false
  protected isNewToolTypeRequired: boolean = false
  protected isNewReqEstimatedQARequired: boolean = false
  protected isNewReqQdRequired: boolean = false
  protected isNewFactMethodRequired: boolean = false
  protected isNewGasStationTypeRequired: boolean = false

  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa Emisora del Paso',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa emisora del paso',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa Receptora del Paso',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'nationality',
          label: 'Nacionalidad',
          placeholder: 'Selecciona la nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo Documento de Identificación',
          placeholder: 'Selecciona el tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Nº Documento',
          placeholder: 'Escribe el número de documento',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'modeffectdate',
          label: 'Modelo Fecha Efecto',
          options: `${CORE_URL}/contract-effect-date-models`,
          placeholder: 'Introduce modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'reqtransferdate',
          label: 'Fecha Efecto Solicitada',
          placeholder: 'Introduce fecha efecto',
          valueQuery: 'code',
          disabled: this.isReqTransferDateDisabled
        },
        {
          type: 'select',
          name: 'updatereason',
          label: 'Motivo Modificación',
          options: `${CORE_URL}/contract-modification-reasons`,
          placeholder: 'Selecciona el motivo de la modificación',
          valueQuery: 'code'
        },
        {
          type: 'switch',
          name: 'surrogacy',
          label: 'Subrogación',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'newtolltype',
          label: 'Nuevo tipo de peaje',
          options: `${CORE_URL}/contract-toll-types`,
          placeholder: 'Selecciona el nuevo tipo de peaje',
          valueQuery: 'code',
          disabled: this.isNewToolTypeDisabled
        },
        {
          type: 'text',
          name: 'newreqqd',
          label: 'Nueva Qd',
          placeholder: 'Introduce la nueva Qd',
          valueQuery: 'code',
          disabled: this.isNewReqQDDisabled
        },
        {
          type: 'text',
          name: 'newreqestimatedqa',
          label: 'Nuevo consumo caudal anual estimado',
          placeholder: 'Introduce el nuevo consumo caudal estimado',
          disabled: this.isNewReqEstimatedQADisabled
        },
        {
          type: 'select',
          name: 'newnationality',
          label: 'Nueva nacionalidad',
          placeholder: 'Selecciona la nueva nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code',
          disabled: this.isNewNationalityDisabled
        },
        {
          type: 'select',
          name: 'newdocumenttype',
          label: 'Nuevo tipo de documento de identificación',
          placeholder: 'Selecciona el nuevo tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code',
          disabled: this.isNewDocumentTypeDisabled
        },
        {
          type: 'text',
          name: 'newdocumentnum',
          label: 'Nuevo nº de documento',
          placeholder: 'Introduce el número tipo de documento',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewDocumentNumDisabled
        },
        {
          type: 'select',
          name: 'newtitulartype',
          label: 'Nuevo tipo de titular',
          placeholder: 'Selecciona el nuevo tipo de titular',
          options: `${CORE_URL}/client-person-types`,
          valueQuery: 'code',
          disabled: this.isNewTitularTypeDisabled
        },
        {
          type: 'switch',
          name: 'titularautonomo',
          label: 'Autónomo',
          valueQuery: 'code',
          disabled: this.isTitularAutonomoDisabled
        },
        {
          type: 'text',
          name: 'newfirstname',
          label: 'Nuevo nombre',
          placeholder: 'Introduce el nuevo nombre',
          valueQuery: 'code',
          maxCharacters: 40,
          disabled: this.isNewFirstNameDisabled
        },
        {
          type: 'text',
          name: 'newfamilyname1',
          label: 'Nuevo primer apellido / Nueva razón social',
          placeholder: 'Introduce el nuevo primer apellido / razón social',
          valueQuery: 'code',
          maxCharacters: 30,
          disabled: this.isNewFamiliyName1Disabled
        },
        {
          type: 'text',
          name: 'newfamilyname2',
          label: 'Nuevo segundo Apellido',
          placeholder: 'Introduce el nuevo segundo apellido',
          valueQuery: 'code',
          maxCharacters: 30,
          disabled: this.isNewFamiliyName2Disabled
        },
        {
          type: 'switch',
          name: 'newregularaddress',
          label: 'Nuevo uso de la vivienda del PS',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'newtelephone',
          label: 'Nuevo teléfono',
          placeholder: 'Introduce el nuevo teléfono',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewTelephoneDisabled
        },
        {
          type: 'text',
          name: 'newfax',
          label: 'Nuevo fax',
          placeholder: 'Introduce el nuevo fax',
          valueQuery: 'code',
          maxCharacters: 20,
          disabled: this.isNewFaxDisabled
        },
        {
          type: 'text',
          name: 'newemail',
          label: 'Nuevo email',
          placeholder: 'Introduce el nuevo email',
          valueQuery: 'code',
          maxCharacters: 60,
          disabled: this.isNewEmailDisabled
        },
        {
          type: 'text',
          name: 'newcaecode',
          label: 'Nuevo código CAE',
          placeholder: 'Introduce el nuevo código CAE',
          valueQuery: 'code',
          maxCharacters: 13,
          disabled: this.isNewCaeCodeDisabled
        },
        {
          type: 'select',
          name: 'newprovinceowner',
          label: 'Nueva provincia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la nueva provincia',
          valueQuery: 'code',
          disabled: this.isNewProvinceOwnerDisabled
        },
        {
          type: 'select',
          name: 'newcityowner',
          label: 'Nuevo municipio',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el nuevo municipio',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription',
          disabled: this.isNewCityOwnerDisabled
        },
        {
          type: 'text',
          name: 'newzipcodeowner',
          label: 'Nuevo código postal',
          placeholder: 'Introduce el codigo postal',
          maxCharacters: 5,
          disabled: this.isNewZipCodeOwnerDisabled
        },
        {
          type: 'select',
          name: 'newstreettypeowner',
          label: 'Nuevo tipo de via',
          options: `${CORE_URL}/location-street-types`,
          placeholder: 'Tipos de via',
          valueQuery: 'code',
          disabled: this.isNewStreetTypeOwnerDisabled
        },
        {
          type: 'text',
          name: 'newstreetowner',
          label: 'Nueva vía',
          placeholder: 'Introduce el nombre de la via',
          maxCharacters: 60,
          disabled: this.isNewStreetOwnerDisabled
        },
        {
          type: 'text',
          name: 'newstreetnumberowner',
          label: 'Nuevo nº de finca',
          placeholder: 'Introduce el numero de la finca',
          maxCharacters: 4,
          disabled: this.isNewStreetNumberOwnerDisabled
        },
        {
          type: 'text',
          name: 'newportalowner',
          label: 'Nuevo portal',
          placeholder: 'Introduce el portal',
          maxCharacters: 5,
          disabled: this.isNewPortalOwnerDisabled
        },
        {
          type: 'text',
          name: 'newstaircaseowner',
          label: 'Nueva escalera',
          placeholder: 'Introduce la nueva escalera',
          maxCharacters: 5,
          disabled: this.isNewStaircaseOwnerDisabled
        },
        {
          type: 'text',
          name: 'newfloorowner',
          label: 'Nuevo piso',
          placeholder: 'Introduce el nuevo piso',
          maxCharacters: 5,
          disabled: this.isNewFloorOwnerDisabled
        },
        {
          type: 'text',
          name: 'newdoorowner',
          label: 'Nueva puerta',
          placeholder: 'Introduce la nueva puerta',
          maxCharacters: 5,
          disabled: this.isNewDoorOwnerDisabled
        },
        {
          type: 'select',
          name: 'newfactmethod',
          label: 'Nuevo método de facturación',
          options: `${CORE_URL}/billing-method`,
          placeholder: 'Selecciona el método de facturación',
          valueQuery: 'code',
          disabled: this.isNewFactMethodDisabled
        },
        {
          type: 'select',
          name: 'gasstationtype',
          label: 'Identificador de gasineras',
          options: `${CORE_URL}/gas-station-types`,
          placeholder: 'Selecciona el identificador de gasineras',
          valueQuery: 'code',
          disabled: this.isNewGasStationTypeDisabled
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Observaciones de la solicitud',
          placeholder: 'Introduce las observaciones de la solicitud',
          maxCharacters: 400
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del Documento',
          placeholder: 'Selecciona la fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de documento anexado',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona el tipo de documento',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de Descarga del documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Observaciones adicionales',
          placeholder: 'Introduce la información adicional',
          maxCharacters: 255
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqhour: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        nationality: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documenttype: [{ required: false, trigger: 'blur', message: 'Es obligatorio' }],
        documentnum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        modeffectdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        updatereason: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqtransferdate: [
          { required: this.isModelEffectDateRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newcaecode: [
          { required: this.isNewCaeCodeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newnationality: [
          { required: this.isNewNationalityRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newdocumenttype: [
          { required: this.isNewDocumentTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newdocumentnum: [
          { required: this.isNewDocumentNumRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newfirstname: [
          { required: this.isNewFirstNameRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newfamilyname1: [
          { required: this.isNewFamilyName1Required, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newtelephone: [
          { required: this.isNewTelephoneRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newprovinceowner: [
          { required: this.isNewProvinceOwnerRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newcityowner: [
          { required: this.isNewCityOwnerRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newzipcodeowner: [
          { required: this.isNewZipCodeOwnerRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newstreettypeowner: [
          {
            required: this.isNewStreetTypeOwnerRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        newstreetowner: [
          { required: this.isNewStreetOwnerRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newstreetnumberowner: [
          {
            required: this.isNewStreetNumberOwnerRequired,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        newtolltype: [
          { required: this.isNewToolTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newreqestimatedqa: [
          { required: this.isNewReqEstimatedQARequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newreqqd: [
          { required: this.isNewReqQdRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        newfactmethod: [
          { required: this.isNewFactMethodRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        gasstationtype: [
          { required: this.isNewGasStationTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '05',
        messagetype: 'A1'
      },
      a105: [
        {
          comreferencenum: this.formData?.comreferencenum,
          reqdate: currentDate,
          reqhour: currentHour,
          nationality: this.formData?.nationality,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          cups: this.formData?.cups,
          modeffectdate: this.formData?.modeffectdate,
          reqtransferdate: this.formData?.reqtransferdate
            ? moment(this.formData?.reqtransferdate).format('YYYY-MM-DD')
            : this.formData?.reqtransferdate,
          updatereason: this.formData?.updatereason,
          surrogacy: this.formData?.surrogacy ? 'S' : 'N',
          newtolltype: this.formData?.newtolltype,
          newreqqd: this.formData?.newreqqd,
          newreqestimatedqa: this.formData?.newreqestimatedqa,
          newnationality: this.formData?.newnationality,
          newdocumenttype: this.formData?.newdocumenttype,
          newdocumentnum: this.formData?.newdocumentnum,
          newfirstname: this.formData?.newfirstname,
          newfamilyname1: this.formData?.newfamilyname1,
          newfamilyname2: this.formData?.newfamilyname2,
          newtitulartype: this.formData?.newtitulartype,
          titularautonomo: this.formData?.titularautonomo ? 'S' : 'N',
          newregularaddress: this.formData?.newregularaddress ? 'S' : 'N',
          newtelephone: this.formData?.newtelephone,
          newfax: this.formData?.newfax,
          newemail: this.formData?.newemail,
          newcaecode: this.formData?.newcaecode,
          newprovinceowner: this.formData?.newprovinceowner,
          newcityowner: this.formData?.newcityowner,
          newzipcodeowner: this.formData?.newzipcodeowner,
          newstreettypeowner: this.formData?.newstreettypeowner,
          newstreetowner: this.formData?.newstreetowner,
          newstreetnumberowner: this.formData?.newstreetnumberowner,
          newportalowner: this.formData?.newportalowner,
          newstaircaseowner: this.formData?.newstaircaseowner,
          newfloorowner: this.formData?.newfloorowner,
          newdoorowner: this.formData?.newdoorowner,
          newfactmethod: this.formData?.newfactmethod,
          gasstationtype: this.formData?.gasstationtype,
          extrainfo: this.formData?.extrainfo,
          registerdoclist: {
            registerdoc: [
              {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            ]
          }
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected async onSubmit(newData: any) {
    if (this.areDocumentTypesVerified === '' && this.areDocumentTypesVerified2 === '') {
      this.updateFormData(newData)
      const process = this.getProcessData()

      try {
        this.loading = true
        this.showForm = false

        if (!this.titularAutonomoCases.includes(process.a105[0].updatereason)) {
          delete process.a105[0].titularautonomo
        }

        // More LOGIC.
        if (
          process.a105[0].updatereason === '09' ||
          process.a105[0].updatereason === '10' ||
          process.a105[0].updatereason === '11' ||
          process.a105[0].updatereason === '13' ||
          process.a105[0].updatereason === '18' ||
          process.a105[0].updatereason === '26' ||
          process.a105[0].updatereason === '27'
        ) {
          process.a105[0].newregularaddress = ''
        }

        if (process.a105[0].updatereason === '04') {
          delete process.a105[0].surrogacy
          delete process.a105[0].newregularaddress
        }

        if (process.a105[0].updatereason === '11') {
          delete process.a105[0].surrogacy
        }

        if (
          process.a105[0].registerdoclist.registerdoc[0].date === '' &&
          process.a105[0].registerdoclist.registerdoc[0].doctype &&
          process.a105[0].registerdoclist.registerdoc[0].extrainfo
        ) {
          delete process.a105[0].registerdoclist
        }

        const response = await FilesApi.generateProcess(process)

        this.errors = response.data.body.error
        this.keyname = response.data.keyname
      } catch (err) {
        const error = err as AxiosError
        const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

        this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      } finally {
        this.loading = false
      }
    } else {
      // ERRORS ALERTS
      if (this.areDocumentTypesVerified === 'documentTypeError1') {
        this.$notify.error({
          title: 'Error en el tipo de documentación',
          message: 'Solamente puedes poner el 01(NIF) o el 03(PASAPORTE)'
        })
      } else if (this.areDocumentTypesVerified === 'documentTypeError2') {
        this.$notify.error({
          title: 'Error en el tipo de documentación',
          message: 'No puedes seleccionar el 01(NIF)'
        })
      }
      if (this.areDocumentTypesVerified2 === 'newDocumentTypeError1') {
        this.$notify.error({
          title: 'Error en el tipo de documentación',
          message: 'Solamente puedes poner el 01(NIF) o el 03(PASAPORTE)'
        })
      } else if (this.areDocumentTypesVerified2 === 'newDocumentTypeError2') {
        this.$notify.error({
          title: 'Error en el tipo de documentación',
          message: 'No puedes seleccionar el 01(NIF)'
        })
      }
    }
  }

  protected async change(process: any) {
    // Form constants.
    const {
      nationality,
      documenttype,
      modeffectdate,
      updatereason,
      newnationality,
      newdocumenttype
    } = process

    if (this.titularAutonomoCases.includes(updatereason)) {
      this.isTitularAutonomoDisabled = false
    } else {
      this.isTitularAutonomoDisabled = true
    }

    // DOCUMENT TYPE LOGIC.
    if (nationality !== '' && documenttype !== '') {
      if (nationality === 'ES') {
        if (documenttype !== '01' && documenttype !== '03') {
          this.areDocumentTypesVerified = 'documentTypeError1'
        } else {
          this.areDocumentTypesVerified = ''
        }
      } else {
        if (documenttype === '01') {
          this.areDocumentTypesVerified = 'documentTypeError2'
        } else {
          this.areDocumentTypesVerified = ''
        }
      }
    }

    // REQ. TRANSFER DATE LOGIC.
    if (modeffectdate !== '') {
      if (modeffectdate === '04') {
        this.isModelEffectDateDisabled = false
        this.isModelEffectDateRequired = true
        this.isReqTransferDateDisabled = false
      } else if (modeffectdate === '01' || modeffectdate === '02' || modeffectdate === '05') {
        this.isModelEffectDateDisabled = true
        this.isModelEffectDateRequired = false
        this.isReqTransferDateDisabled = true
      } else {
        this.isModelEffectDateDisabled = true
        this.isModelEffectDateRequired = false
        this.isReqTransferDateDisabled = false
      }
    }

    // LOGICS.
    if (newnationality !== '' && newdocumenttype !== '') {
      if (newnationality === 'ES') {
        if (newdocumenttype !== '01' && newdocumenttype !== '03') {
          this.areDocumentTypesVerified2 = 'newDocumentTypeError1'
        } else {
          this.areDocumentTypesVerified2 = ''
        }
      } else {
        if (newdocumenttype === '01') {
          this.areDocumentTypesVerified2 = 'newDocumentTypeError2'
        } else {
          this.areDocumentTypesVerified2 = ''
        }
      }
    }
    if (updatereason !== '') {
      if (updatereason === '01') {
        this.logic01()
      } else if (updatereason === '04') {
        this.logic04()
      } else if (updatereason === '09') {
        this.logic09()
      } else if (updatereason === '10') {
        this.logic10()
      } else if (updatereason === '11') {
        this.logic11()
      } else if (updatereason === '12') {
        this.logic12()
      } else if (updatereason === '13') {
        this.logic13()
      } else if (updatereason === '14') {
        this.logicAll()
      } else if (updatereason === '15') {
        this.logicAll()
      } else if (updatereason === '16') {
        this.logicAll()
      } else if (updatereason === '17') {
        this.logic17()
      } else if (updatereason === '18') {
        this.logic18()
      } else if (updatereason === '19') {
        this.logic19()
      } else if (updatereason === '20') {
        this.logicAll()
      } else if (updatereason === '21') {
        this.logicAll()
      } else if (updatereason === '22') {
        this.logicAll()
      } else if (updatereason === '23') {
        this.logicAll()
      } else if (updatereason === '24') {
        this.logicAll()
      } else if (updatereason === '26') {
        this.logic26()
      } else if (updatereason === '27') {
        this.logic27()
      }
    }
  }

  protected handleReturn() {
    this.showForm = true
  }

  /**
   * LOGIC for Update Reason 01.
   */
  protected logic01() {
    // Disableds
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = true
    this.isNewFamilyName1Required = true
    this.isNewTelephoneRequired = true
    this.isNewNationalityRequired = true
    this.isNewDocumentTypeRequired = true
    this.isNewDocumentNumRequired = true
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 04.
   */
  protected logic04() {
    // Disableds
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 09.
   */
  protected logic09() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = false
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 10.
   */
  protected logic10() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = true
    this.isNewCityOwnerRequired = true
    this.isNewZipCodeOwnerRequired = true
    this.isNewStreetTypeOwnerRequired = true
    this.isNewStreetOwnerRequired = true
    this.isNewStreetNumberOwnerRequired = true
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 11.
   */
  protected logic11() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = false
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = false
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = true
    this.isNewReqEstimatedQARequired = true
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 12.
   */
  protected logic12() {
    // Disableds.
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = false
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = false
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = false

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = true
    this.isNewTelephoneRequired = true
    this.isNewNationalityRequired = true
    this.isNewDocumentTypeRequired = true
    this.isNewDocumentNumRequired = true
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = true
    this.isNewReqEstimatedQARequired = true
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 13.
   */
  protected logic13() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = false
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = true
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 17.
   */
  protected logic17() {
    // Disableds.
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = true
    this.isNewTelephoneRequired = true
    this.isNewNationalityRequired = true
    this.isNewDocumentTypeRequired = true
    this.isNewDocumentNumRequired = true
    this.isNewProvinceOwnerRequired = true
    this.isNewCityOwnerRequired = true
    this.isNewZipCodeOwnerRequired = true
    this.isNewStreetTypeOwnerRequired = true
    this.isNewStreetOwnerRequired = true
    this.isNewStreetNumberOwnerRequired = true
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 18.
   */
  protected logic18() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = false
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = false
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = true
    this.isNewCityOwnerRequired = true
    this.isNewZipCodeOwnerRequired = true
    this.isNewStreetTypeOwnerRequired = true
    this.isNewStreetOwnerRequired = true
    this.isNewStreetNumberOwnerRequired = true
    this.isNewToolTypeRequired = true
    this.isNewReqEstimatedQARequired = true
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 19.
   */
  protected logic19() {
    // Disableds.
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = false
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = false
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = true
    this.isNewTelephoneRequired = true
    this.isNewNationalityRequired = true
    this.isNewDocumentTypeRequired = true
    this.isNewDocumentNumRequired = true
    this.isNewProvinceOwnerRequired = true
    this.isNewCityOwnerRequired = true
    this.isNewZipCodeOwnerRequired = true
    this.isNewStreetTypeOwnerRequired = true
    this.isNewStreetOwnerRequired = true
    this.isNewStreetNumberOwnerRequired = true
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = true
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 26.
   */
  protected logic26() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = false
    this.isNewGasStationTypeDisabled = true

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = true
    this.isNewGasStationTypeRequired = false
  }

  /**
   * LOGIC for Update Reason 27.
   */
  protected logic27() {
    // Disableds.
    this.isNewDocumentTypeDisabled = true
    this.isNewDocumentNumDisabled = true
    this.isNewFirstNameDisabled = true
    this.isNewFamiliyName1Disabled = true
    this.isNewToolTypeDisabled = true
    this.isNewReqQDDisabled = true
    this.isNewReqEstimatedQADisabled = true
    this.isNewNationalityDisabled = true
    this.isNewFamiliyName2Disabled = true
    this.isNewTelephoneDisabled = true
    this.isNewFaxDisabled = true
    this.isNewTitularTypeDisabled = true
    this.isNewEmailDisabled = true
    this.isNewCaeCodeDisabled = true
    this.isNewProvinceOwnerDisabled = true
    this.isNewCityOwnerDisabled = true
    this.isNewZipCodeOwnerDisabled = true
    this.isNewStreetTypeOwnerDisabled = true
    this.isNewStreetOwnerDisabled = true
    this.isNewStreetNumberOwnerDisabled = true
    this.isNewPortalOwnerDisabled = true
    this.isNewStaircaseOwnerDisabled = true
    this.isNewFloorOwnerDisabled = true
    this.isNewDoorOwnerDisabled = true
    this.isNewFactMethodDisabled = true
    this.isNewGasStationTypeDisabled = false

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = true
  }

  /**
   * LOGIC for Update Reason ALL.
   */
  protected logicAll() {
    // Disableds.
    this.isNewDocumentTypeDisabled = false
    this.isNewDocumentNumDisabled = false
    this.isNewFirstNameDisabled = false
    this.isNewFamiliyName1Disabled = false
    this.isNewToolTypeDisabled = false
    this.isNewReqQDDisabled = false
    this.isNewReqEstimatedQADisabled = false
    this.isNewNationalityDisabled = false
    this.isNewFamiliyName2Disabled = false
    this.isNewTelephoneDisabled = false
    this.isNewFaxDisabled = false
    this.isNewTitularTypeDisabled = false
    this.isNewEmailDisabled = false
    this.isNewCaeCodeDisabled = false
    this.isNewProvinceOwnerDisabled = false
    this.isNewCityOwnerDisabled = false
    this.isNewZipCodeOwnerDisabled = false
    this.isNewStreetTypeOwnerDisabled = false
    this.isNewStreetOwnerDisabled = false
    this.isNewStreetNumberOwnerDisabled = false
    this.isNewPortalOwnerDisabled = false
    this.isNewStaircaseOwnerDisabled = false
    this.isNewFloorOwnerDisabled = false
    this.isNewDoorOwnerDisabled = false
    this.isNewFactMethodDisabled = false
    this.isNewGasStationTypeDisabled = false

    // Requireds.
    this.isNewCaeCodeRequired = false
    this.isNewFirstNameRequired = false
    this.isNewFamilyName1Required = false
    this.isNewTelephoneRequired = false
    this.isNewNationalityRequired = false
    this.isNewDocumentTypeRequired = false
    this.isNewDocumentNumRequired = false
    this.isNewProvinceOwnerRequired = false
    this.isNewCityOwnerRequired = false
    this.isNewZipCodeOwnerRequired = false
    this.isNewStreetTypeOwnerRequired = false
    this.isNewStreetOwnerRequired = false
    this.isNewStreetNumberOwnerRequired = false
    this.isNewToolTypeRequired = false
    this.isNewReqEstimatedQARequired = false
    this.isNewReqQdRequired = false
    this.isNewFactMethodRequired = false
    this.isNewGasStationTypeRequired = false
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
