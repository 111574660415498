import { RouteConfig } from 'vue-router'

import Page from '@/Core/views/Page/Page.vue'
import StepHeading from './pages/StepHeading/StepHeading.vue'
import StepContent from './pages/StepContent/StepContent.vue'
import Resume from './pages/Resume/Resume.vue'
import Init from './pages/Init/Init.vue'
import Result from './pages/Result/Result.vue'


export enum FileEditorPages {
  Init = 'FileEditorInit',
  StepHeading = 'StepHeading',
  StepContent = 'StepContent',
  Resume = 'FileEditorResume',
  Result = 'FileEditorResult'
}


const routes: Array<RouteConfig> = [
  {
    path: '/file-editor',
    component: Page,
    children: [
      {
        path: '',
        name: FileEditorPages.Init,
        component: Init
      },
      {
        path: 'result',
        name: FileEditorPages.Result,
        component: Result
      }
    ]
  }
]

export default routes