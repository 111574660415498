import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { Component, MapGetter, mixins, Vue } from 'types-vue'
import CreateDialog from '../../components/CreateDialog/CreateDialog'
import EditorDialog from '../../components/EditorDialog/EditorDialog'
import { S3Connector } from '../../models'

@Component
export default class Main extends mixins(PermissionMixin) {

  @MapGetter(Store.S3Connectors) protected loading: boolean


  onClickCreateButton() {
    const createDialog = this.$refs.createDialog as CreateDialog
    createDialog.show()
  }

  onEdit(item: S3Connector) {
    const editorDialog = this.$refs.editorDialog as EditorDialog
    editorDialog.show(item)
  }
}