var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Maestros")]),
      _c("p", [_vm._v("Listado de todos los maestros disponibles:")]),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("el-input", {
            attrs: {
              placeholder: "Buscar maestro",
              size: "small",
              clearable: ""
            },
            model: {
              value: _vm.searchModel,
              callback: function($$v) {
                _vm.searchModel = $$v
              },
              expression: "searchModel"
            }
          })
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-card",
            { staticClass: "is-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.masterList,
                    "row-class-name": "cursor-pointer"
                  },
                  on: { "row-click": _vm.onRowClick }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "MAESTRO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("startCase")(scope.row.code))
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "", width: "120" } },
                    [
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v("Ver maestro")
                        ])
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }