import { ProcessModel } from '@/Modules/Processes/models'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class LastStepColumn extends Vue {
  @Prop({ required: true })
  protected data: ProcessModel


  protected get description(): string {
    return `${this.code}: ${this.data.lastStep.messageTypeDescription}`
  }

  protected get code (): string {
    return `${this.data.lastStep.messageTypeCode}_${this.data.processCode}`
  }


  protected get tagType(): string {
    return (this.data.lastStep.validated)
      ? ''
      : 'danger'
  }
}