var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Subir ficheros a la CNMC",
        visible: _vm.showing,
        width: "45rem",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.showing = $event
        }
      }
    },
    [
      _vm.step === 1
        ? _c(
            "div",
            [
              _c("Forms-Form", {
                attrs: { options: _vm.formOptions },
                on: { submit: _vm.onSubmitForm }
              })
            ],
            1
          )
        : _vm.step === 2
        ? _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.uploaderUrl,
                    drag: "",
                    data: {
                      reportType: _vm.reportType,
                      reportName: _vm.reportName
                    },
                    "show-file-list": false,
                    "on-success": _vm.onSuccess,
                    "on-error": _vm.onError,
                    headers: _vm.headers,
                    "before-upload": _vm.onBeforeUpload,
                    multiple: ""
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", {
                    staticClass: "el-upload__text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("uploader uploader.uploaderSentence")
                      )
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }