import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'

@Component
export default class P38 extends Vue {
  protected currentStep: number = 0
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '38',
        messagetype: 'A1'
      },
      a138: [
        {
          reqdate: currentDate,
          reqhour: currentHour,
          comreferencenum: this.formData?.comreferencenum,
          titulartype: this.formData?.titulartype,
          nationality: this.formData?.nationality,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          firstname: this.formData?.firstname,
          familyname1: this.formData?.familyname1,
          familyname2: this.formData?.familyname2,
          telephone1: this.formData?.telephone1,
          telephone2: this.formData?.telephone2,
          fax: this.formData?.fax,
          email: this.formData?.email,
          language: this.formData?.language,
          province: this.formData?.province,
          city: this.formData?.city,
          zipcode: this.formData?.zipcode,
          streettype: this.formData?.streettype,
          street: this.formData?.street,
          streetnumber: this.formData?.streetnumber,
          portal: this.formData?.portal,
          staircase: this.formData?.staircase,
          floor: this.formData?.floor,
          door: this.formData?.door,
          regularaddress: this.formData?.regularaddress ? 'S' : 'N',
          provinceowner: this.formData?.regularaddress
            ? this.formData?.province
            : this.formData?.provinceowner,
          cityowner: this.formData?.regularaddress ? this.formData?.city : this.formData?.cityowner,
          zipcodeowner: this.formData?.regularaddress
            ? this.formData?.zipcode
            : this.formData?.zipcodeowner,
          streettypeowner: this.formData?.regularaddress
            ? this.formData?.streettype
            : this.formData?.streettypeowner,
          streetowner: this.formData?.regularaddress
            ? this.formData?.street
            : this.formData?.streetowner,
          streetnumberowner: this.formData?.regularaddress
            ? this.formData?.streetnumber
            : this.formData?.streetnumberowner,
          portalowner: this.formData?.regularaddress
            ? this.formData?.portal
            : this.formData?.portalowner,
          staircaseowner: this.formData?.regularaddress
            ? this.formData?.staircase
            : this.formData?.staircaseowner,
          floorowner: this.formData?.regularaddress
            ? this.formData?.floor
            : this.formData?.floorowner,
          doorowner: this.formData?.regularaddress ? this.formData?.door : this.formData?.doorowner,
          cups: this.formData?.cups,
          reqqd: this.formData?.reqqd,
          reqqh: this.formData?.reqqh,
          reqestimatedqa: this.formData?.reqestimatedqa,
          reqoutgoingpressure: this.formData?.reqoutgoingpressure,
          telemetering: this.formData?.telemetering,
          factmethod: this.formData?.factmethod,
          gasusetype: this.formData?.gasusetype,
          gasstationtype: this.formData?.gasstationtype,
          tolltype: this.formData?.tolltype,
          counterproperty: this.formData?.counterproperty,
          aptransind: this.formData?.aptransind,
          aptransnumber: this.formData?.aptransnumber,
          reig: this.formData?.reig,
          designpower: this.formData?.designpower,
          iricertificatedate: this.formData?.iricertificatedate,
          terminstexist: this.formData?.terminstexist,
          modeffectdate: this.formData?.modeffectdate,
          reqactivationdate: this.formData?.reqactivationdate
            ? moment(this.formData?.reqactivationdate).format('YYYY-MM-DD')
            : this.formData?.reqactivationdate,
          extrainfo: this.formData?.extrainfo,
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ],
          productlist: [
            {
              product: {
                producttype: this.formData?.producttype,
                producttolltype: this.formData?.producttolltype,
                productqd: this.formData?.productqd,
                productqa: this.formData?.productqa
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected onSubmitForm(newData: any) {
    this.currentStep++
    this.updateFormData(newData)
  }

  protected async onFinalSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()
    localStorage.setItem('p38_supplypointform1_json', '')
    localStorage.setItem('p38_supplypointform2_json', '')
    localStorage.setItem('p38_titulartype_json', '')

    this.currentStep++
    try {
      this.loading = true

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  /**
   * VUE EVENTS
   */
  protected created() {
    if (this.$route.query?.step) {
      this.currentStep = parseInt(this.$route.query?.step as string)
    }
  }
}
