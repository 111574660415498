import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'

@Component
export default class P42 extends Vue {
  protected currentStep: number = 0
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '42',
        messagetype: 'A1'
      },
      a142: [
        {
          reqdate: currentDate,
          reqhour: currentHour,
          comreferencenum: this.formData?.comreferencenum,
          titulartype: this.formData?.titulartype,
          nationality: this.formData?.nationality,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          cups: this.formData?.cups,
          modeffectdate: this.formData?.modeffectdate,
          reqtransferdate: this.formData?.reqtransferdate
            ? moment(this.formData?.reqtransferdate).format('YYYY-MM-DD')
            : this.formData?.reqtransferdate,
          updatereason: this.formData?.updatereason,
          surrogacy: this.formData?.surrogacy ? 'S' : 'N',
          newowner: {
            newclient: {
              newnationality: this.formData?.newnationality,
              newdocumenttype: this.formData?.newdocumenttype,
              newdocumentnum: this.formData?.newdocumentnum,
              newfirstname: this.formData?.newfirstname,
              newfamilyname1: this.formData?.newfamilyname1,
              newfamilyname2: this.formData?.newfamilyname2,
              newtitulartype: this.formData?.newtitulartype,
              newtelephone1: this.formData?.newtelephone1,
              newtelephone2: this.formData?.newtelephone2,
              newtelephone3: this.formData?.newtelephone3,
              newemail: this.formData?.newemail,
              newlanguage: this.formData?.newlanguage
            },
            newregularaddress: this.formData?.newregularaddress ? 'S' : 'N',
            typefiscaladdress: this.formData?.typefiscaladdress,
            addressPS: {
              province: this.formData?.provincePS,
              city: this.formData?.cityPS,
              zipcode: this.formData?.zipcodePS,
              street: {
                streettype: this.formData?.streettypePS,
                street: this.formData?.streetPS,
                streetnumber: this.formData?.streetnumberPS,
                portal: this.formData?.portalPS,
                staircase: this.formData?.staircasePS,
                floor: this.formData?.floorPS,
                door: this.formData?.doorPS
              }
            },
            address: {
              province: this.formData?.province,
              city: this.formData?.city,
              zipcode: this.formData?.zipcode,
              street: {
                streettype: this.formData?.streettype,
                street: this.formData?.street,
                streetnumber: this.formData?.streetnumber,
                portal: this.formData?.portal,
                staircase: this.formData?.staircase,
                floor: this.formData?.floor,
                door: this.formData?.door
              }
            }
          },
          disconnectedserviceaccepted: this.formData?.disconnectedserviceaccepted,
          extrainfo: this.formData?.extrainfo,
          productlist: [
            {
              product: {
                reqtype: this.formData?.reqtype,
                productcode: this.formData?.productcode,
                producttype: this.formData?.producttype,
                producttolltype: this.formData?.producttolltype,
                productqd: this.formData?.productqd,
                productqa: this.formData?.productqa
              }
            }
          ],
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected onSubmitForm(newData: any) {
    // if (this.currentStep === 0 && newData.updatereason === "") {
    //   this.currentStep = 4;
    // }
    this.currentStep++
    this.updateFormData(newData)
  }

  protected async onFinalSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()

    if (process.a142[0].updatereason === '') {
      process.a142[0].surrogacy = ''
    }

    localStorage.setItem('p42_newdocumenttype_json', '')
    localStorage.setItem('p42_titulartype_json', '')
    localStorage.setItem('p42_typefiscaladdress_json', '')

    this.currentStep++

    try {
      this.loading = true

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  /**
   * VUE EVENTS
   */
  protected created() {
    if (this.$route.query?.step) {
      this.currentStep = parseInt(this.$route.query?.step as string)
    }
  }
}
