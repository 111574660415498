import { UploadedFileFileResult } from '@/Modules/Uploader/models';
import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import FilesApiConnection from '../connection/api/Files'


@Module({ namespaced: true })
export default class extends VuexModule {
  
  private _fileKey: string = null
  private _fileContent: any = null
  private _validationResult: UploadedFileFileResult = null


  /**
   * GETTERS
   */

  @Getter() public file(): any { return this._fileContent }
  @Getter() public fileKey(): string { return this._fileKey }
  @Getter() public validationResult(): UploadedFileFileResult { return this._validationResult }
  
  
  /**
   * MUTATIONS
   */
  
  
  @Mutation() protected setFileContent(fileContent: any): void { this._fileContent = fileContent }
  @Mutation() protected setFileKey(filekey: string): void { this._fileKey = filekey }
  @Mutation() protected setValidationResult(validationResult: UploadedFileFileResult): void { this._validationResult = validationResult }




  /**
   * ACTIONS
   */

  @Action({ commit: 'setFileContent', useContext: true })
  protected async obtainFileContent(ctx: ActionContext<any, any>, fileKey: string): Promise<any> {
    ctx.commit('setFileKey', fileKey)
    
    const result = await FilesApiConnection.getFileContent(fileKey)

    return result.data
  }


  @Action({ useContext: true, commit: 'setValidationResult' })
  protected async confirmEditor(ctx: ActionContext<any, any>, fileContent): Promise<UploadedFileFileResult> {
    ctx.commit('setFileContent', fileContent)

    const keyname = ctx.getters.fileKey
    const response = await FilesApiConnection.updateFileContent(keyname, fileContent)
    
    return response.data.body
  }
}
