const requireModule = require.context('../../Modules', true,  /router\.ts$/);
let modules: any = [];

requireModule.keys().forEach((filename: string) => {
  const moduleName = filename 
    .replace(/^\w/, c => c.toUpperCase())

  const content = requireModule(filename).default || requireModule(filename)

  modules = [
    ...modules,
    ...content
  ]
});

export default modules;