var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c("el-card", [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "section" }, [
            _c("span", [_vm._v("CUPS")]),
            _c(
              "span",
              {
                staticClass: "cups-button content-child",
                on: {
                  click: function($event) {
                    return _vm.onCupsClick(_vm.cupsId)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.cupsId))]
            )
          ]),
          _c("div", { staticClass: "section" }, [
            _c("span", [_vm._v("PRODUCTOS")]),
            _c("span", { staticClass: "content-child" }, [
              _vm._v(_vm._s(_vm.totalProducts))
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }