import { Store } from '@/Core/Models/Stores';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'

@Component
export default class Main extends Vue {

  @MapAction(Store.UnprocessedFiles) protected obtainUnprocessedFiles: () => Promise<void>
  @MapAction(Store.UnprocessedFiles) private updateCurrentPage: (pageNumber) => void
  @MapAction(Store.UnprocessedFiles) private newPageSize: (pageSize: number) => number
  
  @MapGetter(Store.UnprocessedFiles) protected pageSize: number
  @MapGetter(Store.UnprocessedFiles) protected totalElements: number
  @MapGetter(Store.UnprocessedFiles) protected listLoading: boolean


  /**
   * METHODS
   */
    
  private goToPageOne() {
    if (this.$route.query.page !== '1') {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page: '1' }})
    }
  }

  protected async processCurrentPage() {
    const pageNumber = parseInt(this.$route.query.page as string) || 1
    this.updateCurrentPage(pageNumber)
    await this.obtainUnprocessedFiles()

  }

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
    this.goToPageOne()
    this.processCurrentPage()
  }

  /**
   * WATCHERS
   */

  @Watch('$route.query.page', { immediate: true })
  protected async onChangeQueryPage() {
    await this.processCurrentPage()
  }
}