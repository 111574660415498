var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          {
            staticClass: "title-container",
            attrs: { loading: _vm.listLoading }
          },
          [
            _c(
              "h2",
              {
                class:
                  this.$route.query.fromDate &&
                  this.$route.query.toDate &&
                  "main-title"
              },
              [_vm._v("Procesos en curso")]
            ),
            this.$route.query.fromDate && this.$route.query.toDate
              ? _c("h4", [
                  _vm._v(
                    "Inciados entre el " +
                      _vm._s(this.$route.query.fromDate) +
                      " y el " +
                      _vm._s(this.$route.query.toDate)
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          { staticClass: "header-buttons" },
          [
            _c("Uploader-Button", { staticClass: "header-buttons-child" }),
            _vm.selectedProcesses.length
              ? _c("Processes-SelectedFilesDropdown", {
                  staticClass: "header-buttons-child"
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "Core-FiltersDrawer",
        {
          attrs: {
            totalActiveFilter: _vm.totalActiveFilter,
            buttonLabel: "Filtros de procesos"
          }
        },
        [_c("Processes-FiltersContainer")],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          [
            _c(
              "el-card",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                staticClass: "is-table"
              },
              [
                _c("Processes-ProcessesList", {
                  attrs: {
                    processes: _vm.processesList,
                    loading: _vm.listLoading,
                    "with-select": ""
                  }
                }),
                _c("Core-Pagination", {
                  attrs: {
                    pageSize: _vm.pageSize,
                    totalElements: _vm.totalElements
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("Processes-ExportButton"),
            _c("Processes-PageSizeSelect", {
              on: { change: _vm.onChangePageSize }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }