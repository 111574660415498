import { AtrContractsPages } from '@/Modules/AtrContracts/router'
import { BillsPages } from '@/Modules/Bills/router'
import { DashboardPages } from '@/Modules/Dashboard/router'
import { MasterPages } from '@/Modules/Masters/router'
import { ProcessesPages } from '@/Modules/Processes/router'
import { S3ConnectorPages } from '@/Modules/S3Connector/router'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { UnprocessedFilesPages } from '@/Modules/UnprocessedFiles/router'
import { UsersManagerPages } from '@/Modules/UsersManager/router'
import { ReportsPages } from '@/Modules/Reports/router'
import _ from 'lodash'
import { Component, mixins, Watch } from 'types-vue'
import { NoContractPages } from '@/Modules/NoContract/router'
import { CurPages } from '@/Modules/CUR/router'
import { ProcessesFormsPages } from '@/Modules/ProcessesForms/router'
import { LOPDPages } from '@/Modules/LOPD/router'
import { BillingErrorsPages } from '@/Modules/BillingErrors/router'
import { HistoryPages } from '@/Modules/History/router'
import { AlertPages } from '@/Modules/Alerts/router'

enum Submenus {
  Processes = 'processes',
  History = 'history',
  Configuration = 'configuration',
  Dashboard = 'dashboard',
  Users = 'usuarios',
  Marketers = 'comercializadoras',
  AtrContracts = 'AtrContracts',
  Reports = 'reports',
  SearchCups = 'searchCups',
  Billing = 'billing',
  Atcom = 'atcom',
  Alerts = 'alerts'
}

@Component
export default class NavMenu extends mixins(PermissionMixin) {
  /**
   * STATE
   */

  protected active: string = '0'

  /**
   * GETTERS
   */

  protected get elements() {
    return [
      {
        key: 'inProgress',
        label: this.$t('core navMenu.inProcess'),
        route: { name: ProcessesPages.InProcess },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'bills',
        label: this.$t('core navMenu.bills'),
        route: { name: BillsPages.Main },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'withoutContract',
        label: this.$t('core navMenu.withoutContract'),
        route: { name: NoContractPages.Main },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'LOPD',
        label: this.$t('core navMenu.LOPD'),
        route: { name: LOPDPages.Main },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'delivery',
        label: this.$t('core navMenu.delivery'),
        route: { name: ProcessesPages.InProcess, query: { process_type: '09' } },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'cur',
        label: this.$t('core navMenu.cur'),
        route: { name: CurPages.Send },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => this.isDistributorRol || this.isSystemRol
      },
      {
        key: 'unprocessed',
        label: this.$t('core navMenu.unprocessed'),
        route: { name: UnprocessedFilesPages.List },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => (this.isDistributorRol && this.isAdminPermission) || this.isSystemRol
      },
      {
        key: 'unprocessedMarketer',
        label: this.$t('core navMenu.unprocessedMarketer'),
        route: { name: UnprocessedFilesPages.MarketerList },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'billingErrors',
        label: this.$t('core navMenu.billingErrors'),
        route: { name: BillingErrorsPages.List },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => (this.isDistributorRol && this.isAdminPermission) || this.isSystemRol
      },
      {
        key: 'createProcess',
        label: this.$t('core navMenu.createProcess'),
        route: { name: ProcessesFormsPages.List },
        parent: Submenus.Processes,
        disabled: false,
        checkPermisions: () => this.isAdminPermission
      },
      {
        key: 'historyContracts',
        label: this.$t('core navMenu.historyContracts'),
        route: { name: HistoryPages.Contracts },
        parent: Submenus.History,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'historyBilling',
        label: this.$t('core navMenu.historyBilling'),
        route: { name: HistoryPages.Billing },
        parent: Submenus.History,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'historyAtcom',
        label: this.$t('core navMenu.historyAtcom'),
        route: { name: HistoryPages.Atcom },
        parent: Submenus.History,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'masters',
        label: this.$t('core navMenu.masters'),
        route: { name: MasterPages.Masters },
        parent: Submenus.Configuration,
        disabled: false,
        checkPermisions: () => this.isDistributorRol || this.isSystemRol
      },
      {
        key: 's3-connector',
        label: this.$t('core navMenu.s3Connector'),
        route: { name: S3ConnectorPages.Main },
        parent: Submenus.Configuration,
        disabled: false,
        checkPermisions: () => (this.isDistributorRol && this.isAdminPermission) || this.isSystemRol
      },
      {
        key: 'alerts-configuration',
        label: this.$t('core navMenu.alerts'),
        route: { name: AlertPages.Configuration },
        parent: Submenus.Configuration,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'downloadable',
        label: this.$t('core navMenu.downloadable'),
        route: { name: ReportsPages.Downloadable },
        parent: Submenus.Reports,
        disabled: false,
        checkPermisions: () => true
      },
      {
        key: 'historical',
        label: this.$t('core navMenu.historical'),
        route: { name: ReportsPages.Historical },
        parent: Submenus.Reports,
        disabled: false,
        checkPermisions: () => true
      }
    ]
  }

  protected get submenus(): any {
    return {
      [Submenus.Dashboard]: {
        icon: 'el-icon-odometer',
        title: this.$t('core navMenu.dashboard'),
        route: { name: DashboardPages.Dashboard },
        checkPermisions: () => true
      },
      [Submenus.Alerts]: {
        icon: 'el-icon-bell',
        title: this.$t('core navMenu.alerts'),
        route: { name: AlertPages.Alerts },
        checkPermisions: () => true
      },
      // [Submenus.SearchCups]: {
      //   icon: 'el-icon-search',
      //   title: this.$t('core navMenu.searchCups'),
      //   route: { name: CupsPages.Searched },
      //   checkPermisions: () => true
      // },
      [Submenus.Processes]: {
        icon: 'el-icon-document',
        title: this.$t('core navMenu.processes'),
        children: _.filter(this.elements, { parent: Submenus.Processes }),
        checkPermisions: () => true
      },
      [Submenus.History]: {
        icon: 'el-icon-collection',
        title: this.$t('core navMenu.history'),
        children: _.filter(this.elements, { parent: Submenus.History }),
        checkPermisions: () => true
      },
      [Submenus.AtrContracts]: {
        icon: 'el-icon-suitcase',
        title: this.$t('core navMenu.atrContracts'),
        route: { name: AtrContractsPages.Main },
        checkPermisions: () => true
      },
      [Submenus.Reports]: {
        icon: 'el-icon-folder',
        title: this.$t('core navMenu.reports'),
        children: _.filter(this.elements, { parent: Submenus.Reports }),
        checkPermisions: () => true
      },
      [Submenus.Configuration]: {
        icon: 'el-icon-setting',
        title: this.$t('core navMenu.configuration'),
        children: _.filter(this.elements, { parent: Submenus.Configuration }),
        checkPermisions: () => true
      },
      [Submenus.Users]: {
        icon: 'el-icon-user',
        title: this.$t('core navMenu.usersManager'),
        route: { name: UsersManagerPages.Main },
        checkPermisions: () => (this.isDistributorRol && this.isAdminPermission) || this.isSystemRol
      }
    }
  }

  /**
   * MENU EVENTS
   */

  protected onSelectElement(elementKey) {
    let element = _.find(this.elements, { key: elementKey })
    const route = _.isEmpty(element) ? this.submenus[elementKey].route : element.route

    if (this.$route.name !== route.name) this.$router.push(route)
  }

  @Watch('$route', { deep: true, immediate: true })
  protected onChangeRoute() {
    const routeWithMetaMenuActive = _.find(this.$route.matched, (rute) => !!rute?.meta?.menuActive)
    this.active = routeWithMetaMenuActive?.meta?.menuActive
  }
}
