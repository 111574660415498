import { render, staticRenderFns } from "./DataSection.vue?vue&type=template&id=5fe9c4f5&scoped=true&"
import script from "./DataSection.ts?vue&type=script&lang=ts&"
export * from "./DataSection.ts?vue&type=script&lang=ts&"
import style0 from "./DataSection.vue?vue&type=style&index=0&id=5fe9c4f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe9c4f5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fe9c4f5')) {
      api.createRecord('5fe9c4f5', component.options)
    } else {
      api.reload('5fe9c4f5', component.options)
    }
    module.hot.accept("./DataSection.vue?vue&type=template&id=5fe9c4f5&scoped=true&", function () {
      api.rerender('5fe9c4f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Core/components/DataSection/DataSection.vue"
export default component.exports