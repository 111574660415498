import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import List from './pages/List/List.vue'
import { Roles } from '../UsersManager/models'

export enum AddressesPages {
  List = 'addresses-list',
}

const routes: Array<RouteConfig> = [
  {
    path: '/addresses',
    component: Page,
    children: [
      {
        path: '',
        name: AddressesPages.List,
        meta: { menuActive: 'addresses', denyRolesAcess: [Roles.Marketer.code] },
        component: List
      },
    ]
  }
]

export default routes