var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("h3", [_vm._v("Resultado:")]),
        parseInt(_vm.totalPages)
          ? _c("h4", [_vm._v("Número de páginas: " + _vm._s(_vm.totalPages))])
          : _vm._e()
      ]),
      _vm.information
        ? _c("el-tree", {
            attrs: { data: _vm.information },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    return _c("div", { staticClass: "custom-tree-node" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(data.label) + " "),
                        data.value ? _c("span", [_vm._v(": ")]) : _vm._e()
                      ]),
                      _c("span", [_vm._v(_vm._s(data.value))]),
                      _c("span", [_vm._v(_vm._s(data.value))])
                    ])
                  }
                }
              ],
              null,
              false,
              3977019993
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }