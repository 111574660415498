import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Prop, Vue } from 'types-vue'
import { BillingError } from '../../models'
import BillingErrorApi from '../../connections/api/BillingErrors'


enum Commands {
  delete = 'delete',
  download = 'download'
}

@Component
export default class ListItemMenu extends Vue {
  
  @Prop({ required: true })
  protected billingError: BillingError

  @Prop({ required: true })
  protected rowIndex: number


  /**
   * STORE
   */

  @MapAction(Store.BillingErrors) protected removeBillingError: (file: BillingError) => Promise<any>


  get commandDelete(): string {
    return Commands.delete
  }

  get commandDownload(): string {
    return Commands.download
  }


  protected handleCommand(command: Commands): void {
    switch (command) {
      case Commands.delete:
        this.deleteItem()
        break;
      case Commands.download: 
        this.downloadItem()
        break;
    }
  }


  /**
   * METHODS
   */

  protected confirmDelete(): Promise<any> {
    const title = `Eliminar la factura con error ${this.billingError.keyname}`
    const message = `¿Estas seguro de eliminar la factura ${this.billingError.keyname} que no fue procesada por el error "${this.billingError.error}"?`

    return this.$confirm(message, title, { cancelButtonText: 'Cancelar', confirmButtonText: 'Eliminar', type: 'warning' })
  }

  
  protected showSuccessNotify(): void {
    const title = 'Factura eliminada'
    const message = 'Se ha eliminado la factura correctamente'

    this.$notify({ type: 'success', title, message })
  }


  protected showErrorNotify(): void {
    const title = 'Error al eliminar la factura'
    const message = `Ha ocurrido un error al intentar eliminar la factura con keyname ${this.billingError.keyname}`
    
    this.$notify({ title, message, type: 'error' })
  }


  protected async deleteItem() {
    try {
      await this.confirmDelete()
      await this.removeBillingError(this.billingError)
      this.showSuccessNotify()
    } catch (error) {
      if (error !== 'cancel') this.showErrorNotify()
    }
  }

  protected async downloadItem() {
    const result = await BillingErrorApi.getBillingErrorUrl(this.billingError)
    const fileDownloadUrl = result.data

    window.location.href = fileDownloadUrl
  }
  
}