import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'
import { Component, MapAction, Vue } from 'types-vue'

@Component
export default class FiltersContainer extends Vue {

  @MapAction(Store.ReportsHistorical)
  protected newTypeFilterValue: (newValues: 'USERNAME' | 'MARKETER') => any

}
