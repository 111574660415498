import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class P44 extends Vue {
  protected showForm: boolean = true
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa Emisora del Paso',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa Receptora del Paso',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'sourcetype',
          label: 'Tipo de solicitante',
          placeholder: 'Selecciona el tipo de solicitante',
          options: `${CORE_URL}/applicant-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'firstname',
          label: 'Nombre del cliente',
          placeholder: 'Introduce el nombre',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'lastname',
          label: 'Apellido 1 / Razón Social',
          placeholder: 'Introduce el apellido o razón social',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'secondname',
          label: 'Apellido 2',
          placeholder: 'Introduce el apellido',
          maxCharacters: 30
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'operationtype',
          label: 'Tipo de Actuación',
          options: `${CORE_URL}/operation-types`,
          placeholder: 'Introduce modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'description',
          label: 'Descripción de la Ausencia Solicitada',
          placeholder: 'Introduce la descripción',
          maxCharacters: 400
        },
        {
          type: 'text',
          name: 'operationnum',
          label: 'Número de Operación Generado',
          placeholder: 'Introduce el número de operación',
          maxCharacters: 40
        },
        {
          type: 'text',
          name: 'prefixtel1',
          label: 'Prefijo Nacional del Número de Teléfono',
          placeholder: 'Introduce el prefijo del número de teléfono',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'telephone1',
          label: 'Teléfono',
          placeholder: 'Introduce el teléfono',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'prefixtel2',
          label: 'Prefijo Nacional del Número de Teléfono Adicional',
          placeholder: 'Introduce el prefijo del número de teléfono',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'telephone2',
          label: 'Teléfono Adicional',
          placeholder: 'Introduce el teléfono',
          maxCharacters: 20
        },
        {
          type: 'date',
          name: 'readingdate',
          label: 'Fecha de Lectura Actual',
          placeholder: 'Introduce la fecha',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'readingvalue',
          label: 'Lectura Actual Contador',
          placeholder: 'Introduce el teléfono',
          maxCharacters: 20
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Observaciones',
          placeholder: 'Introduce las observaciones'
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del Documento',
          placeholder: 'Selecciona la fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de Documento',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona el tipo de documento',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de Descarga del Documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Información adicional',
          placeholder: 'Introduce la información acidional',
          maxCharacters: 255
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqhour: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        operationtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '44',
        messagetype: 'A1'
      },
      a1: [
        {
          comreferencenum: this.formData?.comreferencenum,
          reqdate: currentDate,
          reqhour: currentHour,
          sourcetype: this.formData?.sourcetype,
          firstname: this.formData?.firstname,
          lastname: this.formData?.lastname,
          secondname: this.formData?.secondname,
          cups: this.formData?.cups,
          operationtype: this.formData?.operationtype,
          description: this.formData?.description,
          operationnum: this.formData?.operationnum,
          prefixtel1: this.formData?.prefixtel1,
          telephone1: this.formData?.telephone1,
          prefixtel2: this.formData?.prefixtel2,
          telephone2: this.formData?.telephone2,
          readingdate: this.formData?.readingdate
            ? moment(this.formData?.readingdate).format('YYYY-MM-DD')
            : this.formData?.readingdate,
          readingvalue: this.formData?.readingvalue,
          extrainfo: this.formData?.extrainfo,
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected async onSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()

    try {
      this.loading = true
      this.showForm = false

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  protected handleReturn() {
    this.showForm = true
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
