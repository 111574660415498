import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Vue } from 'types-vue'
import UserGroup from '../../models'

@Component
export default class GroupsList extends Vue {
  /**
   * STORE
   */
  @MapAction(Store.UserGroups) protected changeGroup: (payload: {
    group: UserGroup
    reload?: boolean
  }) => Promise<any>
  @MapAction(Store.UserGroups) protected getListCurrentUserGroups: () => Promise<any>
  @MapGetter(Store.UserGroups) protected userGroupList: UserGroup[]
  @MapGetter(Store.UserGroups) protected loading: boolean

  private internalLoading: boolean = false

  protected get localLoading(): boolean {
    return this.loading || this.internalLoading
  }

  async created() {
    try {
      this.internalLoading = true
      await this.getListCurrentUserGroups()
      if (this.userGroupList.length === 1) await this.changeGroup({ group: this.userGroupList[0] })
    } catch (error) {
    } finally {
      this.internalLoading = false
    }
  }
}
