import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class P43 extends Vue {
  protected showForm: boolean = true
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa Emisora del Paso',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa Receptora del Paso',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'titulartype',
          label: 'Tipo de titular',
          options: `${CORE_URL}/client-person-types`,
          placeholder: 'Selecciona el tipo de titular',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'nationality',
          label: 'Nacionalidad',
          placeholder: 'Selecciona la nacionalidad',
          options: `${CORE_URL}/location-nationalities`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo Documento de Identificación',
          placeholder: 'Selecciona el tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Nº Documento',
          placeholder: 'Escribe el número de documento',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'modeffectdate',
          label: 'Modelo Fecha Efecto',
          options: `${CORE_URL}/contract-effect-date-models?code=04`,
          placeholder: 'Selecciona el modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'reqtransferdate',
          label: 'Fecha Efecto Solicitada',
          placeholder: 'Introduce fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'reqtype',
          label: 'Tipo de solicitud',
          options: `${CORE_URL}/product-type-requests`,
          placeholder: 'Selecciona tipo de solicitud',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'productcode',
          label: 'Código del producto',
          placeholder: 'Introduce el código del producto',
          maxCharacters: 26
        },
        {
          type: 'select',
          name: 'producttype',
          label: 'Tipo de producto',
          options: `${CORE_URL}/contract-product-types`,
          placeholder: 'Selecciona tipo de producto',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'producttolltype',
          label: 'Tipo de peaje del producto',
          options: `${CORE_URL}/contract-toll-types`,
          placeholder: 'Selecciona tipo de peaje del producto',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'productqd',
          label: 'Caudal diario',
          placeholder: 'Introduce el caudal diario'
        },
        {
          type: 'text',
          name: 'productqa',
          label: 'Caudal estimado anual qa',
          placeholder: 'Introduce el caudal estimado anual'
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del Documento',
          placeholder: 'Selecciona la fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de Documento',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona el tipo de documento',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de Descarga del Documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Información adicional',
          placeholder: 'Introduce la información acidional',
          maxCharacters: 255
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqhour: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        nationality: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documenttype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documentnum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        modeffectdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqtype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],

        producttype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        producttolltype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqtransferdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '43',
        messagetype: 'A1'
      },
      a143: [
        {
          comreferencenum: this.formData?.comreferencenum,
          reqdate: currentDate,
          reqhour: currentHour,
          titulartype: this.formData?.titulartype,
          nationality: this.formData?.nationality,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          cups: this.formData?.cups,
          modeffectdate: this.formData?.modeffectdate,
          reqtransferdate: this.formData?.reqtransferdate
            ? moment(this.formData?.reqtransferdate).format('YYYY-MM-DD')
            : this.formData?.reqtransferdate,
          extrainfo: this.formData?.extrainfo,
          productlist: [
            {
              product: {
                reqtype: this.formData?.reqtype,
                productcode: this.formData?.productcode,
                producttype: this.formData?.producttype,
                producttolltype: this.formData?.producttolltype,
                productqd: this.formData?.productqd,
                productqa: this.formData?.productqa
              }
            }
          ],
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected async onSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()

    try {
      this.loading = true
      this.showForm = false

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  protected handleReturn() {
    this.showForm = true
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
