var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Your new password")]),
      _c("p", [_vm._v("You need to change your password.")]),
      _c("Auth-NewChangePasswordForm", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.onSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }