var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "send-page" }, [
    _c(
      "div",
      { staticClass: "send-page-content" },
      [
        _c("Core-Title", { attrs: { loading: _vm.loading } }, [
          _c("h2", [_vm._v("Envío a CUR")])
        ]),
        _c("div", [_c("el-card", [_c("CUR-CurForm")], 1)], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }