var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "empty-text": _vm.emptyText,
                      data: _vm.historicalReportsList
                    },
                    on: {
                      "cell-mouse-leave": _vm.onCellMouseLeave,
                      "cell-mouse-enter": _vm.onCellMouseEnter
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "KEYNAME", prop: "keyname" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "TIPO", prop: "reportType" }
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "COMERCIALIZADORA",
                            prop: "marketerCode"
                          }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "USUARIO", prop: "username" }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "AÑO", prop: "year" }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "MES", prop: "month" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "ENLACE", prop: "url", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      href: scope.row.url,
                                      target: "_blank",
                                      title: "download",
                                      icon: "el-icon-download"
                                    }
                                  },
                                  [_vm._v(" Descargar ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm.isAdminPermission
                      ? _c("el-table-column", {
                          attrs: { label: "", width: "35" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "list-item-menu" },
                                      [
                                        _c("Reports-ListItemMenu", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.checkShowOptions(
                                                scope.row
                                              ),
                                              expression:
                                                "checkShowOptions(scope.row)"
                                            }
                                          ],
                                          attrs: {
                                            report: scope.row,
                                            "row-index": scope.$index
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }