import { Action, Module, VuexModule } from 'types-vue'
import AuthApiConnection from '../connections/api/Auth'
import ConfirmPasswordBody from '../models/connections/api/ConfirmPasswordBody'


@Module({ namespaced: true })
export default class extends VuexModule {


  @Action()
  protected async forgotPassword(email: string): Promise<any> {
    const result = await AuthApiConnection.forgotPassword({ email: email })
    return result.data
  }

  @Action()
  protected async confirmPassword(payload: ConfirmPasswordBody): Promise<any> {
    const result = await AuthApiConnection.confirmPassword(payload)
  }
}
