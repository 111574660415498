import { Store } from "@/Core/Models/Stores";
import { Component, MapAction, Vue } from "types-vue";


@Component
export default class ObtainVersionListMixin extends Vue {

  static requested: boolean = false

  @MapAction(Store.Versions) protected obtainVersionList: () => Promise<void>

  protected created() {
    if (ObtainVersionListMixin.requested) return

    try {
      ObtainVersionListMixin.requested = true
      this.obtainVersionList()
    } catch (error) { this.notifyObtainError(error) }
  }

  private notifyObtainError(error) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Error al obtener los datos de la version' })
  }
}