import { subOptions } from '@/Modules/Alerts/models'
import _ from 'lodash'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class MenuItem extends Vue {
  @Prop({ required: true })
  protected name: string

  @Prop({ required: true })
  protected options: {}

  @Prop({ required: true })
  protected form: {}

  @Prop({ required: true })
  protected group: string

  mainOptions = { ...subOptions }
}
