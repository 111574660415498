import { Store } from '@/Core/Models/Stores'
import { Bill } from '@/Modules/Bills/models'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class AmountSection extends Vue {
  @MapGetter(Store.BillDetails) protected bill: Bill


  protected get accepted(): boolean { return this.bill.result.code === '01'}
  protected get result(): string { return this.bill.result?.description }
  protected get reason(): string { return `: ${this.bill.resultReason}` }

  protected get amount(): string {
    return this.bill.amount.replace('.', ',')
  }
}