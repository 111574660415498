import { ElUploadInternalFileDetail } from 'element-ui/types/upload'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { UploadedFileFileResult, UploadedFile, UploadedFileState } from '@/Modules/Uploader/models';
import _ from 'lodash';


@Module({ namespaced: true })
export default class Uploader extends VuexModule {


  private _files: UploadedFile[] = []


  @Getter() public files(): UploadedFile[] { return this._files }
  @Getter() public showResults(): boolean { return this._files.length > 0 }

  
  @Mutation() protected setFiles(value: UploadedFile[]) { this._files = value }
  


  @Action({ useContext: true })
  protected newFile(ctx: ActionContext<any, any>, file: ElUploadInternalFileDetail): void {
    const originFiles = ctx.getters.files as UploadedFile[]
    const files = [...originFiles, { file, state: UploadedFileState.uploading }]
    ctx.commit('setFiles', files)
  }

  @Action({ useContext: true })
  protected finishUploadFile(ctx: ActionContext<any, any>, payload: { result?: UploadedFileFileResult,  error?: string, file: ElUploadInternalFileDetail }): void {
    const { file, result, error } = payload
    const isInvalid = result?.validationResponseVoList?.find(res => !res.body.result)
    const originFiles = ctx.getters.files as UploadedFile[]
    const haveError = !_.isNil(error)
    let state = UploadedFileState.done

    if (haveError) state = UploadedFileState.error
    else if (isInvalid) state = UploadedFileState.invalid

    const updatedFiles = originFiles.map(fileData => (fileData.file.uid === file.uid) ? { file, result, state, error } : fileData )
    ctx.commit('setFiles', updatedFiles)
  }

  
  @Action({ useContext: true })
  protected clean(ctx: ActionContext<any, any>): void {
    ctx.commit('setFiles', [])
  }
}
