import { FormConfig } from '@/Modules/Forms/models'
import _ from 'lodash'
import { Component, Prop, Vue } from 'types-vue'


const CORE_URL = process.env.VUE_APP_CORE_URL


@Component
export default class SystemSubjectForm extends Vue {

  @Prop({ default: 'Submit', type: String, }) protected submitLabel: string
  @Prop() protected defaultValues: any

  protected get formOptions(): FormConfig {
    const isEdit = !_.isNil(this.defaultValues)
    return {
      label: 'Formulario system subject',
      submitButton: this.submitLabel,
      size: 'medium',
      items: [
        { type: 'select', name: 'categories', label: 'Categoria',  options: `${CORE_URL}/system-subject-categories`, valueQuery:'code', default: this.defaultValues?.categories[0].code, disabled: isEdit },
        { type: 'text', name: 'code', label: 'Código', default: this.defaultValues?.code },
        { type: 'text', name: 'description', label: 'Descripción', default: this.defaultValues?.description },
        { type: 'text', name: 'businessGroup', label: 'Business group', default: this.defaultValues?.businessGroup },
        { type: 'switch', name: 'cur', label: 'CUR', default: this.defaultValues?.cur },
      ],
      rules: {
        marketer: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }],
        distributor: [{ required: true, trigger: 'blur',   message: 'Es obligatorio' }]
      }
    }
  }

  protected onSubmit(item) {
    const prepareItem = { ...item, categories: [item.categories] }
    this.$emit('submit', prepareItem)
  }

}