var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("Core-Title", { attrs: { loading: _vm.listLoading } }, [
            _c("h2", [_vm._v("CUPS disponibles")])
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "", size: "small" },
                  on: { click: _vm.onClickCreateA6161 }
                },
                [_vm._v("Crear A6161")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("NoContract-FiltersContainer"),
      _c("h3", [_vm._v("Búsquedas:")]),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              staticClass: "is-table"
            },
            [
              _c("NoContract-NoContractList", {
                attrs: {
                  noContract: _vm.noContractsList,
                  loading: _vm.listLoading
                }
              }),
              _c("Core-Pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  totalElements: _vm.totalElements
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("NoContract-PageSizeSelect", {
              on: { change: _vm.onChangePageSize }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }