var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.loading } }, [
        _c("h2", [_vm._v("Versiones")])
      ]),
      _c("div", { staticClass: "container" }, [_c("Versioning-Timeline")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }