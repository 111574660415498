var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "mfa-title" }, [
      _vm._v("Autenticación Multifactor (MFA)")
    ]),
    _c(
      "div",
      { staticClass: "form-container" },
      [
        _c("h4", { staticClass: "mfa-subtitle" }, [
          _vm._v("Introduce el código MFA")
        ]),
        _c("el-input", {
          attrs: { size: "small", placeholder: "Introduce el código MFA" },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.input,
            callback: function($$v) {
              _vm.input = $$v
            },
            expression: "input"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttons-container" },
      [
        _c(
          "el-button",
          { attrs: { loading: _vm.loading }, on: { click: _vm.onCancel } },
          [_vm._v("Cancelar")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary", loading: _vm.loading },
            on: { click: _vm.onSubmit }
          },
          [_vm._v("Acceder")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }