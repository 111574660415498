import { Vue } from 'types-vue';
import VueGtag from "vue-gtag";
import VueRouter from 'vue-router';



const gId = process.env.VUE_APP_G_CODE


export const initGtag = (router: VueRouter) => {
  if (!gId) return

  Vue.use(VueGtag, {
    config: { id: "G-RX7B66CK4L" }
  }, router);
}