import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { Bill } from '../models'
import { AxiosError } from 'axios'
import { BillsApi } from '../connections/api/Bills'


@Module({ namespaced: true })
export default class SelectedBills extends VuexModule {
  
  private _bills: Bill[] = []
  private _loading: boolean = false


  @Getter() public selectedBills(): Bill[] { return this._bills }
  @Getter() public loading(): boolean { return this._loading }


  @Mutation() protected setBills(value: Bill[]) { this._bills = [...value] }
  @Mutation() protected setLoading(value: boolean) { this._loading = value }




  @Action({ commit: 'setBills' })
  protected newSelectedBills(payload: {bills: Bill[]}) {
    return payload.bills
  }


  @Action({ useContext: true })
  protected async downloadFiles(ctx: ActionContext<any, any>, payload: { messagesTypesCodes: string[], downloadAll?: boolean }) {
    const billIds = ctx.getters.selectedBills.map((bill: Bill) => bill.id)
    const downloadAll = !!payload.downloadAll
    const filters = (downloadAll) ? ctx.rootGetters['Bills/billFilters'] : {}
    const processMessages = payload.messagesTypesCodes

    try {
      ctx.commit('setLoading', true)
      const response = await BillsApi.getUrlDownloadZip(billIds, processMessages, filters, downloadAll)
      window.open(response.data)
      ctx.commit('setBills', [])
    } catch (err) {
      const error = err as AxiosError
      
      if (error?.response?.status === 404) {
        return Notification.warning({ title: 'No se han encontrado ficheros', message: 'No se han encontrado ficheros con los datos introducidos.'})  
      }
      
      const messagaError = error?.response?.data?.message || 'Error al generar el zip'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
      throw messagaError
    } finally {
      ctx.commit('setLoading', false)
    }
  }


  @Action({ useContext: true }) protected clean(ctx: ActionContext<any, any>) {
    ctx.commit('setLoading', false)
    ctx.commit('setBills', [])
  }
}
