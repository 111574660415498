var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-menu" },
    [
      _c(
        "el-menu",
        {
          attrs: { "default-active": _vm.active },
          on: { select: _vm.onSelectElement }
        },
        [
          _vm._l(_vm.submenus, function(submenu, submenuName) {
            return [
              !!submenu.children && submenu.checkPermisions()
                ? _c(
                    "el-submenu",
                    { key: submenuName, attrs: { index: submenuName } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { class: submenu.icon }),
                        _c("span", [_vm._v(_vm._s(submenu.title))])
                      ]),
                      _vm._l(submenu.children, function(element, index) {
                        return [
                          element.checkPermisions()
                            ? _c(
                                "el-menu-item",
                                {
                                  key: index,
                                  attrs: {
                                    index: element.key,
                                    disabled: element.disabled
                                  }
                                },
                                [_vm._v(_vm._s(element.label))]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                : submenu.checkPermisions()
                ? _c(
                    "el-menu-item",
                    {
                      key: submenuName,
                      staticClass: "parent",
                      attrs: { index: submenuName }
                    },
                    [
                      _c("i", { class: submenu.icon }),
                      _c("span", [_vm._v(_vm._s(submenu.title))])
                    ]
                  )
                : _vm._e()
            ]
          }),
          _c(
            "div",
            { staticClass: "version-container" },
            [_c("Versioning-VersionButton")],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }