import _ from 'lodash';
import { Component, Vue } from 'types-vue';

@Component
export default class ColorsMixin extends Vue {
  
  private subtractLight (color, amount) {
    let cc = parseInt(color,16) - amount;
    let c: number | string = (cc < 0) ? 0 : (cc);
    c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  }

  protected darken(color, amount: number) {
    color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
    amount = Math.trunc((255* amount)/100)
    return color = `#${this.subtractLight(color.substring(0,2), amount)}${this.subtractLight(color.substring(2,4), amount)}${this.subtractLight(color.substring(4,6), amount)}`;
  }
}