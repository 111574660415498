import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'

@Component
export default class P48 extends Vue {
  protected currentStep: number = 0
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null
  protected claimTypes: string = null

  private updateClaimTypes(data) {
    if (data.claimtype && data.claimsubtype) {
      const typeAndSubtype = `t${data.claimtype}s${data.claimsubtype}`
      if (this.claimTypes !== typeAndSubtype) {
        this.claimTypes = typeAndSubtype
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '48',
        messagetype: 'A1'
      },
      a1: [
        {
          reqdate: currentDate,
          reqhour: currentHour,
          comreferencenum: this.formData?.comreferencenum,
          claimertype: this.formData?.claimertype,
          claimer: {
            claimerid: {
              claimerdocumenttype: this.formData?.claimerdocumenttype,
              claimerdocumentnum: this.formData?.claimerdocumentnum
            },
            claimername: {
              claimerfirstname: this.formData?.claimerfirstname,
              claimerlastname: this.formData?.claimerlastname,
              claimersecondname: this.formData?.claimersecondname,
              claimerbusinessname: this.formData?.claimerbusinessname
            },
            claimertelephone: {
              claimerprefixtel1: this.formData?.claimerprefixtel1,
              claimertelephone1: this.formData?.claimertelephone1
            },
            claimeremail: this.formData?.claimeremail
          },
          claimtype: this.formData?.claimtype,
          claimsubtype: this.formData?.claimsubtype,
          claimreferencelist: [
            {
              claimreference: {
                wrongattentiontype: this.formData?.wrongattentiontype,
                comreferencenum: this.formData?.comreferencenumCM,
                targetclaimcomreferencenum: this.formData?.targetclaimcomreferencenum,
                conceptcontract: this.formData?.conceptcontract,
                conceptfacturation: this.formData?.conceptfacturation,
                contact: {
                  contactname: this.formData?.contactname,
                  contacttelephone: {
                    telephoneprefix: this.formData?.telephoneprefix,
                    telephonenumber: this.formData?.telephonenumber
                  },
                  contactemail: this.formData?.contactemail
                },
                nnssexpedient: this.formData?.nnssexpedient,
                fraudrecordnum: this.formData?.fraudrecordnum,
                incidentperiod: {
                  datefrom: this.formData?.datefrom,
                  dateto: this.formData?.dateto
                },
                invoicenumber: this.formData?.invoicenumber,
                incidentlocation: {
                  incidentlocationdesc: this.formData?.incidentlocationdesc,
                  incidentlocationprovince: this.formData?.incidentlocationprovince,
                  incidentlocationcity: this.formData?.incidentlocationcity,
                  incidentlocationcitysubdivision: this.formData?.incidentlocationcitysubdivision,
                  incidentlocationzipcode: this.formData?.incidentlocationzipcode
                },
                reading: {
                  readingdate: this.formData?.readingdate,
                  readingvalue: this.formData?.readingvalue
                },
                incident: {
                  incidentdate: this.formData?.incidentdate
                },
                client: {
                  document: {
                    documenttype: this.formData?.documenttype,
                    documentnum: this.formData?.documentnum
                  },
                  titulartype: this.formData?.titulartype,
                  name: {
                    firstname: this.formData?.firstname,
                    familyname1: this.formData?.familyname1,
                    familyname2: this.formData?.familyname2,
                    businessName: this.formData?.businessName
                  },
                  telephone: {
                    telephoneprefix: this.formData?.telephoneprefixC,
                    telephonenumber: this.formData?.telephonenumberC
                  },
                  email: this.formData?.email,
                  clientAddress: {
                    province: this.formData?.province,
                    city: this.formData?.city,
                    zipcode: this.formData?.zipcode,
                    streettype: this.formData?.streettype,
                    street: this.formData?.street,
                    streetnumber: this.formData?.streetnumber,
                    portal: this.formData?.portal,
                    staircase: this.formData?.staircase,
                    floor: this.formData?.floor,
                    door: this.formData?.door
                  }
                },
                claimedcompensation: this.formData?.claimedcompensation,
                iban: this.formData?.iban
              }
            }
          ],
          cups: this.formData?.cups,
          legallimitdate: this.formData?.legallimitdate,
          priority: this.formData?.priority,
          extrainfo: this.formData?.extrainfo,
          registerdoclist: [
            {
              registerdoc: {
                date: this.formData?.date,
                doctype: this.formData?.doctype,
                url: this.formData?.url,
                extrainfo: this.formData?.extrainfodoc
              }
            }
          ]
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */
  protected onSubmitForm(newData: any) {
    this.currentStep++
    this.updateFormData(newData)
  }

  protected async onFinalSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()
    this.currentStep++

    try {
      this.loading = true

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  /**
   * VUE EVENTS
   */
  protected created() {
    if (this.$route.query?.step) {
      this.currentStep = parseInt(this.$route.query?.step as string)
    }
  }
}
