import { Component, MapGetter, Vue } from 'types-vue'

import { AxiosError } from 'axios'
import CoreReports from '@/Modules/Reports/connections/api/CoreReports'
import { FormConfig } from '@/Modules/Forms/models'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
import moment from 'moment'

@Component
export default class GlobalReport extends Vue {


  protected showing: boolean = false
  protected loading: boolean = false


  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean


  protected get formOptions(): FormConfig {
    return {
      label: 'form',
      submitButton: 'Generar informe',
      loading: this.loading,
      items: [
        { name: 'date', type: 'month', label: 'Selecciona el mes' },
        { name: 'extension', type: 'select', label: 'Selecciona el tipo de reporte', default: 'csv', options: [{ label: 'CSV', value: 'csv' }, { label: 'Excel', value: 'xlsx' }] }
      ],
      rules: {
        date: [{ required: true, trigger: 'blur', message: 'El mes es obligatorio' }],
        extension: [{ required: true, trigger: 'blur', message: 'El tipo es obligatorio' }],
      }
    }
  }

  public show() {
    this.showing = true
  }

  private getSystemSubjectCode() {
    return this.systemSubjectIsMarketer ? this.systemSubject.code : null
  }

  protected async onSubmit(form) {
    const { date } = form
    const month = (moment(date).get('month') + 1).toString();
    const year = moment(date).get('year').toString();

    try {
      this.loading = true
      const response = await CoreReports.getGlobalReport({ month, year })
      window.open(response.data, '_blank');
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al generar el reporte.'

      console.error(err)
      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
      this.showing = false
    }

  }
}