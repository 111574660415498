var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", [
        _c("div", { staticClass: "process-details-header-content" }, [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "state-container" }, [
              _c(
                "div",
                [
                  _c("Processes-StateColumn", {
                    attrs: { state: _vm.process.status }
                  })
                ],
                1
              ),
              _vm.resultReason
                ? _c("div", { staticClass: "cnmc" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.resultReason) +
                        ": " +
                        _vm._s(_vm.process.resultreasonDescription) +
                        " "
                    )
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "date" }, [
              _c(
                "span",
                [
                  _c("Core-TimeColumn", {
                    attrs: { time: _vm.process.createdAt }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "list-props" }, [
            _c("div", { staticClass: "process-info" }, [
              _c("div", { staticClass: "code" }, [
                _c("span", [
                  _c("b", [
                    _vm._v(
                      "Proceso " +
                        _vm._s(_vm._f("processCodeFilter")(_vm.processCode))
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "desciption" }, [
                _c("span", [_vm._v(_vm._s(_vm.description))])
              ]),
              _vm.process.processReason
                ? _c("div", { staticClass: "description" }, [
                    _c("span", [
                      _vm._v("Motivo: " + _vm._s(_vm.process.processReason))
                    ])
                  ])
                : _vm._e()
            ]),
            _vm.showMarketerInfo
              ? _c("div", [
                  _c("div", { staticClass: "item" }, [
                    _c("div", [
                      _vm._v("COM. ENTRANTE: "),
                      _c("b", [_vm._v(_vm._s(_vm.process.marketerCode))])
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("div", [
                      _vm._v("COM. SALIENTE: "),
                      _c("b", [_vm._v(_vm._s(_vm.process.outgoingMarketer))])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("REF.: ")])
                ]),
                _c("span", [_c("b", [_vm._v(_vm._s(_vm.numreference))])])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("CUPS: ")])
                ]),
                _c("span", [_c("b", [_vm._v(_vm._s(_vm.cups))])])
              ])
            ]),
            _c("div", { staticClass: "right" }, [_c("Uploader-Button")], 1)
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }