import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class InitialForm_48 extends Vue {
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa emisora',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona la empresa emisora',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa receptora',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona la empresa receptora',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          maxCharacters: 12
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
