import moment from 'moment'
import { Component, Prop, Vue } from 'types-vue'
import { Version } from '../../models'

@Component
export default class TimelineItem extends Vue {

  @Prop({ required: true }) protected version: Version


  get timestamp(): string { return moment(this.version.releaseDate).format('LL') }
  get versionNumber(): string { return this.version.versionNumber }
  get items(): string[] { return this.version.summary.split('|') }
  get title(): string { return this.version.shortSummary }
}