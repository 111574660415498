var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Selecciona los CUPS",
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "30rem"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("span", [_vm._v("Selecciona los CUPS que quieres añadir.")]),
      _c("el-input", {
        staticClass: "search-input",
        attrs: { size: "mini", placeholder: "Buscar cups" },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = $$v
          },
          expression: "search"
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "cupsTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataTable,
            height: "40vh",
            "empty-text": "No hay CUPS disponibles"
          },
          on: { "selection-change": _vm.onSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", { attrs: { property: "cups", label: "CUPS" } })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Cancelar")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.loading || _vm.selectedCupsCount === 0
              },
              on: { click: _vm.onClickConfirm }
            },
            [_vm._v("Eviar CUR")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }