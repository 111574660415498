var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasBeenDownloaded
    ? _c(
        "div",
        [
          _c("el-alert", {
            attrs: {
              title: "Sí",
              type: "success",
              center: "",
              closable: false,
              "show-icon": ""
            }
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("el-alert", {
            attrs: { title: "No", type: "warning", center: "", closable: false }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }