import { FormConfig } from '@/Modules/Forms/models'
import { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { FilesApi } from '../../connections/api/Files'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class P49 extends Vue {
  protected showForm: boolean = true
  protected formData: any = {}
  protected loading: boolean = false
  protected errors: string[] = []
  protected keyname: string = null
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa Emisora del Paso',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa Receptora del Paso',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS Solicitud',
          placeholder: 'Introduce el CUPS',
          valueQuery: 'code',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'comreferencenumdes',
          label: 'Nº Referencia a desistir',
          placeholder: 'Introduce el numero de referencia a desistir',
          minCharacters: 1,
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'tipodesistimiento',
          label: 'Tipo de desistimiento',
          placeholder: 'Selecciona el tipo de desistimiento',
          options: `${CORE_URL}/withdrawal-types`,
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'documenttype',
          label: 'Tipo Documento de Identificación',
          placeholder: 'Selecciona el tipo de documento',
          options: `${CORE_URL}/client-document-types`,
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'documentnum',
          label: 'Nº Documento',
          placeholder: 'Escribe el número de documento',
          maxCharacters: 20
        },
        {
          type: 'select',
          name: 'titulartype',
          label: 'Tipo de persona',
          placeholder: 'Selecciona el tipo de persona',
          options: `${CORE_URL}/client-person-types`,
          valueQuery: 'code'
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Observaciones',
          placeholder: 'Escribe las observaciones',
          maxCharacters: 400
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqhour: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        tipodesistimiento: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documenttype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        documentnum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        cups: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        titulartype: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenumdes: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }]
      }
    }
  }

  private updateFormData(newData: any) {
    this.formData = { ...this.formData, ...newData }
  }

  private getProcessData() {
    const currentDate = moment().format('YYYY-MM-DD')
    const currentHour = moment().format('hh:mm:ss')

    return {
      heading: {
        dispatchingcode: 'GML',
        dispatchingcompany: this.formData?.dispatchingcompany,
        destinycompany: this.formData?.destinycompany,
        communicationsdate: currentDate,
        communicationshour: currentHour,
        processcode: '49',
        messagetype: 'A1'
      },
      a149: [
        {
          comreferencenum: this.formData?.comreferencenum,
          reqdate: currentDate,
          reqhour: currentHour,
          comreferencenumdes: this.formData?.comreferencenumdes,
          tipodesistimiento: this.formData?.tipodesistimiento,
          documenttype: this.formData?.documenttype,
          documentnum: this.formData?.documentnum,
          titulartype: this.formData?.titulartype,
          cups: this.formData?.cups,
          extrainfo: this.formData?.extrainfo
        }
      ]
    }
  }

  /**
   * FORMS CALLBACKS
   */

  protected async onSubmit(newData: any) {
    this.updateFormData(newData)
    const process = this.getProcessData()

    try {
      this.loading = true
      this.showForm = false

      const response = await FilesApi.generateProcess(process)

      this.errors = response.data.body.error
      this.keyname = response.data.keyname
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar las facturas.'

      this.$notify.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    } finally {
      this.loading = false
    }
  }

  protected handleReturn() {
    this.showForm = true
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
