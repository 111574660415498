import AbstractApi, { Method } from "@/Core/api/AbstractApi"
import { AxiosPromise } from "axios"
import { S3Connector } from "../../models"

const CORE_URL = process.env.VUE_APP_CORE_URL

const Path = {
  Main: `${CORE_URL}/sap-connector-s3`,
}

class s3ConnectorSingleton extends AbstractApi{
  private static _instance: s3ConnectorSingleton
  private constructor() { super() }

  public static get instance(): s3ConnectorSingleton {
    if (!s3ConnectorSingleton._instance) s3ConnectorSingleton._instance = new s3ConnectorSingleton();
    return s3ConnectorSingleton._instance;
  }


  public getAll(): AxiosPromise<S3Connector[]> {
    return this.request({
      method: Method.GET,
      url: Path.Main,
    })
  }

  public create(data: S3Connector): AxiosPromise<S3Connector> {
    return this.request({
      method: Method.POST,
      url: Path.Main,
      data
    })
  }

  public update(data: S3Connector): AxiosPromise<S3Connector> {
    return this.request({
      method: Method.PATCH,
      url: `${Path.Main}/${data.id}`,
      data
    })
  }

  public delete(id: number): AxiosPromise<S3Connector> {
    return this.request({
      method: Method.DELETE,
      url: `${Path.Main}/${id}`
    })
  }
}

export const s3ConnectorApi = s3ConnectorSingleton.instance