import Page from '@/Core/views/Page/Page.vue'
import { RouteConfig } from 'vue-router'
import { Roles } from '../UsersManager/models'
import Changelog from './pages/Changelog/Changelog.vue'

export namespace VersionPages {
  export const Changelog = { name: 'VersionsChangelog' }
}
const routes: Array<RouteConfig> = [
  {
    path: '/version',
    component: Page,
    children: [
      {
        path: 'changelog',
        name: VersionPages.Changelog.name,
        component: Changelog
      }
    ]
  }
]

export default routes