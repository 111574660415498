import _ from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'types-vue'
import { DateFormItemConfig } from '../../models'

const validator = (prop: any) => true

@Component
export default class Date extends Vue {
  @Prop({ required: true, validator: validator }) protected config: DateFormItemConfig

  protected model: any = null
  protected startDate: Date = null
  protected endDate: Date = null

  protected get type(): string {
    return this.config.type
  }
  protected get size(): string {
    return this.config.size
  }
  protected get monthLimit(): boolean {
    return this.config.dateLimit === '1m'
  }
  protected get format(): string {
    return this.config.format
  }
  protected get defaultValue(): string {
    return this.config?.default
  }
  protected get disabled(): boolean {
    return this.config?.disabled
  }

  protected get pickerOptions(): object {
    return {
      onPick: this.onPick,
      disabledDate: this.disabledDate
    }
  }

  @Watch('model', { deep: true }) protected onChangeModel() {
    let value = ''
    if (!!this.model) {
      value = !!this.format ? moment(this.model).format(this.format) : this.model
    }
    this.$emit('change', { item: this.config, value })
  }

  protected onPick({ maxDate, minDate }) {
    this.startDate = minDate
    this.endDate = maxDate
  }

  protected disabledDate(date) {
    if (!!this.startDate && this.endDate) return false
    if (!this.monthLimit) return false
    if (_.isNil(this.startDate)) return false

    const diff = moment(date).diff(moment(this.startDate.toString()), 'months', true)
    const moreThanMonth = -1 >= diff || diff >= 1

    return moreThanMonth
  }

  private setDefaultValue() {
    this.model = this.defaultValue
  }

  created() {
    if (!!this.defaultValue) this.setDefaultValue()
  }

  @Watch('config.default', { deep: true }) protected onChangeDefault() {
    if (!!this.defaultValue) this.setDefaultValue()
  }
}
