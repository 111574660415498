import _ from "lodash"
import { CupsMasterApi } from "../connections/api/Masters"
import { Cups, ReadingReason } from "../models"




export const mapCups = async (cupsRaw: Cups[]): Promise<Cups[]> => {
  const readingReasonsResponse = await CupsMasterApi.getAllReadingReasons()
  const readingReasons = readingReasonsResponse.data
  
  return cupsRaw.map(cups => {
    return {
      ...cups,
      consumptions: cups.consumptions.map(consumition => {
        const readingReason = _.find(readingReasons, { 'id': parseInt(consumition.tipoLectura) }) as ReadingReason;
        return { ...consumition, tipoLectura: readingReason?.description || ''  }
      })
    }
  })
}