import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Vue } from 'types-vue'
import { SystemSubject } from '@/Modules/Auth/models'
import { ReportsApi } from '@/Modules/Reports/connections/api/Reports'
import { ProcessFilters } from '@/Modules/Processes/models'



@Component
export default class ExportButton extends Vue {

  protected loading: boolean = false

  @MapGetter(Store.ProcessesInprocess) protected allProcessFilters: ProcessFilters
  @MapGetter(Store.ProcessesInprocess) protected orderProp: string
  @MapGetter(Store.ProcessesInprocess) protected orderDirection: string
  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean  

  
  onClickCommand(command: string) {
    switch (command) {
      case 'pdf':
        this.download('pdf')
        break;
      case 'xlsx':
        this.download('xlsx');
        break;
      default:
        break;
    }
  }

  onClosed() {}


  private getSystemSubjectCode() {
    return this.systemSubjectIsMarketer ? this.systemSubject.code : undefined
  }

  private async download(typeFile: 'xlsx'|'pdf') {
    try {
      this.loading = true
      const response = await ReportsApi.getProcessesReport(typeFile, {
        ...this.allProcessFilters,
        cupsList: this.allProcessFilters?.cupsList?.split(','),
        processCodes: this.allProcessFilters?.processCodes?.toString()?.split(','),
        statusList: this.allProcessFilters?.statusList?.toString()?.split(','),
        fieldOrder: this.orderProp,
        orderASCorDESC: this.orderDirection,
        marketer_code: (this.allProcessFilters?.marketerCode) ? this.allProcessFilters?.marketerCode : this.getSystemSubjectCode()
      })
      window.open(response.data, '_blank')
    } catch (error) {
      console.error(error)
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Error al generar el reporte.'})
    } finally {
      this.loading = false
    }
  }
}