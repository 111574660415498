import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import MarketerBilling from '../connections/api/MarketerBilling'
import BillingModel from '../models/MarketerBilling'
import { ActionContext } from 'vuex'
import { BillingPeriod } from '../definitions'
import { AxiosError } from 'axios'
import { Notification } from 'element-ui'


@Module({ namespaced: true })
export default class BillingWitget extends VuexModule {
  
  private _billing: BillingModel[] = []
  private _billingPeriod: BillingPeriod = 'M'
  private _billingLoading: boolean = false




  @Getter() protected billing(): BillingModel[] { return this._billing }
  @Getter() protected billingPeriod(): string { return this._billingPeriod }
  @Getter() protected billingLoading(): boolean { return this._billingLoading }


  

  @Mutation() protected setBilling(billing: BillingModel[]) { this._billing = billing }
  @Mutation() protected setBillingPeriod(value: 'M' | 'Y') { this._billingPeriod = value }
  @Mutation() protected setBillingLoading(value: boolean) { this._billingLoading = value }
  



  @Action({ useContext: true })
  protected async obtainBilling(ctx: ActionContext<any, any>) {
    const period = ctx.getters.billingPeriod as BillingPeriod
    
    try {
      
      const response = (period === 'Y') 
        ? await MarketerBilling.getYearlyBilling()
        : await MarketerBilling.getMonthlyBilling()
      ctx.commit('setBilling', response.data)
    
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los datos.'
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    
    } finally {
      ctx.commit('setBillingLoading', false)
    }
    
  }
  
  @Action({ commit: 'setBillingPeriod' })
  protected newBillingPeriod(billingPeriod: 'M' | 'Y'): string {
    return billingPeriod
  }
}
