import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Send from './pages/Send/Send.vue'
import { Roles } from '../UsersManager/models'

export enum CurPages {
  Send = 'sendCur',
}

const routes: Array<RouteConfig> = [
  {
    path: '/cur',
    component: Page,
    children: [
      {
        path: 'send',
        name: CurPages.Send,
        meta: { menuActive: 'cur', denyRolesAcess: [Roles.Marketer.code]},
        component: Send
      }
    ]
  }
]

export default routes