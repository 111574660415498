var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            model: _vm.form,
            rules: _vm.rules,
            "hide-required-asterisk": ""
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Correo electrónico", prop: "email" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading || _vm.isEditor },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Nombre", prop: "givenName" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                model: {
                  value: _vm.form.givenName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "givenName", $$v)
                  },
                  expression: "form.givenName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Apellidos", prop: "familyName" } },
            [
              _c("el-input", {
                attrs: { size: "small", disabled: _vm.loading },
                model: {
                  value: _vm.form.familyName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "familyName", $$v)
                  },
                  expression: "form.familyName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Rol", prop: "userRol" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "small", placeholder: "Selecciona un rol" },
                  model: {
                    value: _vm.form.userRol,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "userRol", $$v)
                    },
                    expression: "form.userRol"
                  }
                },
                _vm._l(_vm.roles, function(rol, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: rol.name, value: rol.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Permisos", prop: "userRol" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "small",
                    placeholder: "Selecciona un permiso",
                    disabled: _vm.isSystemRole
                  },
                  model: {
                    value: _vm.form.userPermissions,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "userPermissions", $$v)
                    },
                    expression: "form.userPermissions"
                  }
                },
                _vm._l(_vm.permissions, function(per, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: per.name, value: per.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Sujeto", prop: "groupName" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    size: "small",
                    placeholder: "Selecciona un grupo",
                    filterable: "",
                    clearable: "",
                    "no-match-text": "No hay ningún grupo con tu búsqueda",
                    "filter-method": _vm.updateFilter,
                    disabled: _vm.isSystemRole,
                    multiple: ""
                  },
                  on: { focus: _vm.onFocus },
                  model: {
                    value: _vm.form.groupName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "groupName", $$v)
                    },
                    expression: "form.groupName"
                  }
                },
                _vm._l(_vm.options, function(group) {
                  return _c(
                    "el-option",
                    {
                      key: group.name,
                      attrs: { label: group.description, value: group.name }
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _c("span", { staticClass: "code" }, [
                          _vm._v(_vm._s(group.name))
                        ]),
                        _c("span", { staticClass: "description" }, [
                          _vm._v(_vm._s(group.description))
                        ])
                      ])
                    ]
                  )
                }),
                1
              ),
              _vm.isSystemRole
                ? _c("el-alert", {
                    attrs: {
                      title:
                        "Únicamente se puede elegir los permisos y el sujeto si tiene el rol de comercializadora o de distribuidora.",
                      type: "warning",
                      "show-icon": "",
                      closable: false
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "enabled" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { size: "small", disabled: _vm.loading },
                  model: {
                    value: _vm.form.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "enabled", $$v)
                    },
                    expression: "form.enabled"
                  }
                },
                [_vm._v("Usuario activo")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              [
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          size: "small",
                          disabled: _vm.disableSubmitButton
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v(_vm._s(_vm.submitButtonText))]
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }