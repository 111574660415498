import { Store } from '@/Core/Models/Stores'
import { Bill } from '@/Modules/Bills/models'
import _ from 'lodash'
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class BillInfo extends Vue {

  @MapGetter(Store.BillDetails) protected bill: Bill

  protected get elements(): any[] {

    const billTypeValue = (!_.isNil(this.bill.billType.id)) ? `${this.bill.billType.code} - ${this.bill.billType.description}` : '-'

    return [
      { name: 'Codigo factura', value: this.bill?.billNum || '-' },
      { name: 'Tipo de factura', value: billTypeValue },
      { name: 'Clase de factura', value: this.bill?.billClass?.description || '-' },
      { name: 'Fecha creación', value: this.bill?.billDate || '-' },
      { name: 'Tipo de peaje', value: this.bill?.tollType?.code || '-' },
      { name: 'CUPS', value: this.bill?.cups || '-' },
      { name: 'Codigo factura rectificadora', value: this.bill?.rectifiedBill?.billNum || '-'},
      { name: 'Cuota GTS (2012)', value: this.bill?.rectifiedBill?.concept2012 || '-'},
      { name: 'Tasa CNMC (2013)', value: this.bill?.rectifiedBill?.concept2013 || '-'}
    ]
      
  }
}