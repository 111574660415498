import { checkTokenExpired } from '@/Core/Utils'
import _ from 'lodash'
import { Notification } from 'element-ui'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { AxiosError } from 'axios'
import { NoContractModel } from '../models'
import { QueryParams } from '../definitions/QueryParams'
import NoContractApi from '../connections/api/NoContractApi'
import { NoContractListResponse } from '../models/api'


@Module({ namespaced: true })
export default class extends VuexModule {

  private _noContracts: NoContractModel[] = []
  private _processTypeFilter: any[] = []
  private _cupsFilter: string = ''
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _orderProp: string = null
  private _orderDirection: string = null
  private _listLoading: boolean = true
  private _isCupsClickable: boolean = true



  @Getter() public orderProp(): string { return this._orderProp }
  @Getter() public orderDirection(): string { return this._orderDirection }
  @Getter() public noContractsList(): NoContractModel[] { return this._noContracts }
  @Getter() public processTypeFilter(): any[] { return this._processTypeFilter }
  @Getter() public cupsFilter(): any { return this._cupsFilter }
  @Getter() public pageSize(): number { return this._pageSize }
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }
  @Getter() public isCupsClickable(): boolean { return this._isCupsClickable}


  @Mutation() protected setOrderProp(value: string) { this._orderProp = value }
  @Mutation() protected setOrderDirection(value: string) { this._orderDirection = value }
  @Mutation() protected setPageSize(pageSize: number) { this._pageSize = pageSize }
  @Mutation() protected setListLoading(value: boolean) { this._listLoading = value }

  @Mutation() protected setProcessTypeFilter(value) {
    const isNotEmpty = !_.isEmpty(value)

    this._processTypeFilter = value
    if (isNotEmpty) this._currentPage = 1
  }

  @Mutation() protected setCupsFilter(value) {
    const isNotEmpty = !_.isEmpty(value)

    this._cupsFilter = value
    if (isNotEmpty) this._currentPage = 1
  }

  @Mutation()
  protected setCurrentPage(value: string) {
    const isEmpty = _.isEmpty(value)
    this._currentPage = (isEmpty) ? 1 : parseInt(value)
  }

  @Mutation()
  protected setNoContracts(data: NoContractListResponse) {
    this._noContracts = [ ...data.flowAvailablePSVos ] 
    this._totalElements = data.totalElements
    this._listLoading = false
  }


  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setCupsFilter', queryParams[QueryParams.Cups])
    ctx.commit('setProcessTypeFilter', queryParams[QueryParams.ProcessType])
    ctx.commit('setOrderProp', queryParams[QueryParams.OrderProp])
    ctx.commit('setOrderDirection', queryParams[QueryParams.OrderDirection])

    ctx.dispatch('obtainNoContracts')
  }

  @Action({ useContext: true })
  protected async obtainNoContracts(ctx: ActionContext<any, any>) {
    const pageNumber = ctx.getters.currentPage - 1
    const pageSize = ctx.getters.pageSize
    const fieldOrder = ctx.getters.orderProp
    const orderASCorDESC = ctx.getters.orderDirection
    const processCodes = ctx.getters.processTypeFilter
    const cups = ctx.getters.cupsFilter

    if (!orderASCorDESC || !fieldOrder || _.isNil(pageNumber) || _.isNil(pageSize) || pageSize < 1) return

    try {
      ctx.commit('setListLoading', true)
      const response = await NoContractApi.getNoContractList({ pageNumber, pageSize, fieldOrder, orderASCorDESC, processCodes, cups })
      ctx.commit('setNoContracts', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los PS sin contratos.'

      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })
    }

  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ useContext: true })
  protected async removeNoContract(ctx: ActionContext<any, any>, file: NoContractModel): Promise<any> {
    const response = await NoContractApi.removeNoContractById(file.id)

    if (response.status === 200)
      ctx.dispatch('obtainNoContracts')
  }

}
