import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ObservationsForm_48 extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  protected get formOptions(): FormConfig {
    return {
      label: 'Observaciones',
      submitButton: 'Crear proceso',
      items: [
        {
          type: 'text',
          name: 'claimedcompensation',
          label: 'Indemnización solicitada',
          placeholder: 'Introduce la indemnización solicitada'
        },
        {
          type: 'text',
          name: 'iban',
          label: 'Cuenta IBAN',
          placeholder: 'Introduce la cuenta IBAN',
          maxCharacters: 34
        },
        {
          type: 'text',
          name: 'cups',
          label: 'CUPS solicitud',
          placeholder: 'Introduce el CUPS',
          maxCharacters: 20
        },
        {
          type: 'date',
          name: 'legallimitdate',
          label: 'Fecha límite legal',
          placeholder: 'Selecciona la fecha límite legal',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'priority',
          options: [
            { label: 'Prioridad normal', value: '0' },
            { label: 'Prioridad alta', value: '1' }
          ],
          label: 'Prioridad de la reclamación',
          placeholder: 'Selecciona la prioridad de la reclamación'
        },
        {
          type: 'textarea',
          name: 'extrainfo',
          label: 'Comentario de la solicitud',
          placeholder: 'Introduce los comentarios de la solicitud',
          maxCharacters: 400
        },
        {
          type: 'date',
          name: 'date',
          label: 'Fecha del documento',
          placeholder: 'Selecciona fecha del documento'
        },
        {
          type: 'select',
          name: 'doctype',
          label: 'Tipo de documento',
          options: `${CORE_URL}/attached-document-type`,
          placeholder: 'Selecciona tipo de documento',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'url',
          label: 'URL de descarga del documento',
          placeholder: 'Introduce la URL de descarga del documento',
          maxCharacters: 255
        },
        {
          type: 'textarea',
          name: 'extrainfodoc',
          label: 'Información adicional',
          placeholder: 'Introduce la información adicional',
          maxCharacters: 255
        }
      ],
      rules: {
        cups: [
          {
            required: p48required[this.claimTypes]?.cups,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        extrainfo: [
          {
            required: p48required[this.claimTypes]?.extrainfo,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        iban: [
          {
            required: p48required[this.claimTypes]?.iban,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        claimedcompensation: [
          {
            required: p48required[this.claimTypes]?.claimedcompensation,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
