import { AuthPages } from '@/Modules/Auth/router'
import { Component, Vue } from 'types-vue'
import { SessionVariables } from '../enum/SessionVariables'
import { checkTokenExpired } from '../Utils'

@Component
export default class TokenExpiredManagerMixin extends Vue {
  private timeout: number = 10000

  private checkToken() {
    const token = sessionStorage.getItem(SessionVariables.Auth)

    if (!token || !checkTokenExpired(token)) return

    this.$notify.warning({
      title: 'Sesión expirada',
      message: 'La sesión ha expirado, por favor vuelve a iniciar sesión.'
    })
    sessionStorage.setItem(SessionVariables.LoginCallbackURL, this.$route.fullPath)
    this.$router.push({ name: AuthPages.Signin })
  }

  created() {
    setInterval(this.checkToken, this.timeout)
  }
}
