var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "text",
            icon: "el-icon-download",
            loading: _vm.loading
          },
          on: {
            click: function($event) {
              return _vm.onClickDownloadFile(_vm.document)
            }
          }
        },
        [_vm._v("Descargar")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }