var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Core-Title", { attrs: { loading: _vm.loading } }, [
        _c("h2", [_vm._v(_vm._s(_vm._f("startCase")(_vm.masterCode)))])
      ]),
      _c("transition", { attrs: { name: "fade-to-top" } }, [
        _vm.isAdminPermission
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                staticClass: "buttons"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.onClickShowCreateForm }
                  },
                  [
                    _vm._v(
                      'Crear nuevo "' +
                        _vm._s(_vm._f("startCase")(_vm.masterCode)) +
                        '"'
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "transition",
        { attrs: { name: "fade-to-top" } },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "is-table"
            },
            [
              _c("Masters-List", {
                attrs: { list: _vm.list },
                on: { edit: _vm.onClickEditButton }
              }),
              _c("Core-Pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  totalElements: _vm.totalElements
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("Masters-CreateDialog", {
        attrs: { visible: _vm.dialogCreateVisible },
        on: {
          "update:visible": function($event) {
            _vm.dialogCreateVisible = $event
          }
        }
      }),
      _c("Masters-EditDialog", {
        attrs: { visible: _vm.dialogEditVisible },
        on: {
          "update:visible": function($event) {
            _vm.dialogEditVisible = $event
          }
        }
      }),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            staticClass: "footer"
          },
          [
            _c("Masters-PageSizeSelect", {
              on: { change: _vm.onChangePageSize }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }