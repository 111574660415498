var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters" },
    [
      _c(
        "Core-FiltersSections",
        { attrs: { options: _vm.formOptions }, on: { change: _vm.onChange } },
        [
          _c(
            "div",
            { staticClass: "sort" },
            [_c("UnprocessedMarketerFiles-SortSelect")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }