import FiltersMixin from '@/Core/mixins/FiltersMixin';
import { Store } from '@/Core/Models/Stores';
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin';
import _ from 'lodash'
import { Component, Filter, MapGetter, mixins, Prop } from 'types-vue'

@Component
export default class List extends mixins(FiltersMixin, PermissionMixin) {
  
  @Prop({ required: true})
  protected list: any[]



  protected showingMenuItem: any = null



  @MapGetter(Store.Master)
  protected properties: string[]



  get columnsWidth(): any {
    const maxPercent = 95

    const allWidth = this.properties.map(prop => this.getWidthColumn(prop))
    const sumAll = _.sum(allWidth)
    return this.properties.map((_col, index) => (allWidth[index] / sumAll) * maxPercent)
  }



  protected checkDate(key: string) {
    return key.toLowerCase().endsWith('date')
  }

  protected checkArray(item: any): boolean {
    return _.isArray(item)
  }

  protected checkBoolean(item: any): boolean {
    return _.isBoolean(item)
  }



  protected getWidthColumn(column: string) {
    const minLength = 6
    const maxLength = 30

    let maxValueLength = _.max(this.list.map(el => el[column]?.length || 0))
    maxValueLength = (maxValueLength > maxLength) ? maxLength : maxValueLength
    
    return _.max([maxValueLength, column.length, minLength])
  }



  protected onCellMouseEnter(item: any): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: any): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected checkShowOptions(item: any): boolean {
    return !!this.showingMenuItem && this.showingMenuItem.id === item.id
  }


  protected onClickEdit(item) {
    this.$emit('edit', item)
  }


  @Filter()
  protected cut(value: string) {
    const limit = 50

    if (_.isEmpty(value)) return '-'

    if (value.length >= limit) {
      return `${value.substring(0, limit)}...`
    } else {
      return value
    }
  }

  @Filter()
  protected filterArray(value: any[]): string {
    return value
      .map(item => {
        if (_.isObject(item) && _.has(item, 'code')) return _.get(item, 'code')
        return item
      })
      .toString()
  }

}