import { SelectOption } from '@/Modules/Forms/models'

export interface Rol {
  code: string
  name: string
}

export interface Permission {
  code: string
  name: string
}

export interface UserFormModel {
  email: string
  enabled: boolean
  givenName: string
  familyName: string
  groupName?: string[]
  userRol: string
  userPermissions: string
  marketer?: string
}

export interface Group {
  name: string
  description?: string
  UserPoolId?: string
  CreationDate?: string
  LastModifiedDate?: string
}

export interface User {
  enabled: boolean
  email: string
  givenName: string
  familyName: string
  username?: string
  lastModifiedDate?: string
  status?: string
  clientMetadata: {
    userRol: string
    userPermissions: string
    userGroup?: string // esta obsoleto
    userGroups: string[]
    marketer: string
  }
}

export interface UsersFilters {
  emails?: string[]
  groups?: string[]
  rol?: string
  permission?: string
}

export interface GetAllGroupsResponse {
  groups: Group[]
}

export interface GetAllUsersRequest {
  userGroup?: string
  paginationToken?: string
  limit?: number
  emails?: string[]
  groups?: string[]
  rol?: string
  permission?: string
}

export interface GetAllUsersResponse {
  users: User[]
  paginationToken: string
  totalUsers: number
}

export interface signupRequest {
  email: string
  userAttributes: {
    givenName: string
    familyName: string
    activeMarketer: string
  }
  clientMetadata: {
    userRol: string
    userPermissions: string
    marketer: string
    userGroups: string[]
  }
}

export interface createUserRequest {
  email: string
  userAttributes: {
    givenName: string
    familyName: string
    activeMarketer: string
  }
  clientMetadata: {
    userRol: string
    userPermissions: string
    userGroups: string[]
    marketer: string
  }
}

export namespace Roles {
  const createRole = (code, name): Rol => ({ code, name })

  export const Marketer = createRole('COMER', 'Comercializador')
  export const Distributor = createRole('DIST', 'Distribuidor')
  export const System = createRole('SYS', 'Sistema')
  export const list: Rol[] = Object.keys(Roles).map(key => Roles[key])
  export const options: SelectOption[] = [
    { value: 'COMER', label: 'Comercializador' },
    { value: 'DIST', label: 'Distribuidor' },
    { value: 'SYS', label: 'Sistema' }
  ]
}

export namespace Permissions {
  const create = (code, name): Permission => ({ code, name })

  export const Read = create('READ', 'Consulta')
  export const Admin = create('ADMIN', 'Gestión')
  export const list: Permission[] = Object.keys(Permissions).map(key => Permissions[key])
  export const options: SelectOption[] = [
    { value: 'READ', label: 'Consulta' },
    { value: 'ADMIN', label: 'Gestión' }
  ]
}

export namespace QueryParams {
  export const Emails = 'emails'
  export const Groups = 'groups'
  export const Permission = 'permission'
  export const Rol = 'rol'
  export const SortBy = 'sortBy'
  export const SortDir = 'sortDir'
}
