var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors.length > 0
        ? _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.errors } },
            [
              _c("el-table-column", {
                attrs: { label: "ERRORES" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1525313800
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.result ? _c("div", [_c("LOPD-ResultTree")], 1) : _vm._e(),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.url
            ? _c(
                "el-link",
                {
                  attrs: {
                    href: _vm.url,
                    target: "_blank",
                    type: "primary",
                    icon: "el-icon-download"
                  }
                },
                [_vm._v(" Descargar fichero")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.onClickReturn } },
            [_vm._v("Volver")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }