var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "mfa-title" }, [
      _vm._v("Configurar Autenticación Multifactor (MFA)")
    ]),
    _c("div", { staticClass: "step-container" }, [
      _vm._m(0),
      _c(
        "p",
        { staticClass: "recommendation" },
        [
          _vm._v("Recomendamos utilizar "),
          _c(
            "el-link",
            {
              attrs: {
                type: "primary",
                target: "_blank",
                href: "https://authy.com/download/"
              }
            },
            [_vm._v("Authy")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "step-container" }, [
      _vm._m(1),
      _c("div", { staticClass: "qr-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "secret-code-qr"
          },
          [
            _vm.qrErrorMessage
              ? _c(
                  "div",
                  { staticClass: "qr-error-message" },
                  [
                    _vm._v(" " + _vm._s(_vm.qrErrorMessage) + " "),
                    _c("el-button", {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-refresh-left",
                        circle: ""
                      },
                      on: { click: _vm.handleRetry }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.username && _vm.secretCode
              ? _c("qrcode-vue", {
                  staticClass: "qr-code",
                  attrs: {
                    value:
                      "otpauth://totp/" +
                      _vm.username +
                      "?secret=" +
                      _vm.secretCode,
                    size: 160
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.secretCode
        ? _c("span", [
            _c("p", [
              _vm._v("También puedes introducir el código manualmente")
            ]),
            _c(
              "p",
              {
                staticClass: "show-code-button",
                attrs: { type: "primary" },
                on: { click: _vm.handleShowSecretCode }
              },
              [_vm._v("Mostrar código")]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "secret-code-container" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSecretCode,
                expression: "showSecretCode"
              }
            ]
          },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.tooltipMessage,
                  placement: "bottom"
                }
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "secret-code",
                    on: {
                      click: _vm.copyToClipboard,
                      mouseenter: _vm.updateTooltipMessage
                    }
                  },
                  [_vm._v(_vm._s(_vm.secretCode))]
                )
              ]
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "step3-container" },
      [
        _vm._m(2),
        _c("el-input", {
          attrs: { size: "small" },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.input,
            callback: function($$v) {
              _vm.input = $$v
            },
            expression: "input"
          }
        }),
        _c(
          "div",
          { staticClass: "buttons-container" },
          [
            _c("el-button", { on: { click: _vm.handleCancel } }, [
              _vm._v("Cancelar")
            ]),
            !_vm.disabled
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.onSubmit }
                  },
                  [_vm._v("Asignar MFA")]
                )
              : _vm._e(),
            _vm.disabled
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: "",
                      type: "primary",
                      loading: _vm.loading
                    }
                  },
                  [_vm._v("Asignar MFA")]
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "step-title" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("1.")]),
      _vm._v(" Instala una aplicación compatible en tu dispositivo")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "step-title" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("2.")]),
      _vm._v(" Escanea el código QR")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "step-title" }, [
      _c("span", { staticClass: "step-number" }, [_vm._v("3.")]),
      _vm._v(" Escribe el código MFA")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }