import { Component, MapGetter, mixins } from 'types-vue'

import { FormConfig } from '@/Modules/Forms/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { QueryParams } from '@/Modules/History/definitions/QueryParams'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class BillFilters extends mixins(PermissionMixin) {
  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected get formOptions(): FormConfig {
    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'tag',
          name: QueryParams.Cups,
          label: 'Cups',
          default: (this.$route.query[QueryParams.Cups] as string)?.split(',') || []
        },

        {
          type: 'date',
          name: QueryParams.FromDate,
          label: 'Fecha inicio proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.FromDate]
        },
        {
          type: 'date',
          name: QueryParams.ToDate,
          label: 'Fecha fin proceso',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.ToDate]
        },
        {
          type: 'date',
          name: QueryParams.FromBillDate,
          label: 'Fecha inicio facturación',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.FromBillDate]
        },
        {
          type: 'date',
          name: QueryParams.ToBillDate,
          label: 'Fecha fin facturación',
          format: 'YYYY-MM-DD',
          default: this.$route.query[QueryParams.ToBillDate]
        },
        {
          type: 'select',
          name: QueryParams.BillType,
          label: 'Tipo de factura',
          default: this.$route.query[QueryParams.BillType],
          options: `${CORE_URL}/bill-types`,
          valueQuery: 'code',
          clearable: true
        },
        {
          type: 'select',
          name: QueryParams.ProcessType,
          label: 'Tipo de proceso',
          default: this.$route.query[QueryParams.ProcessType],
          options: [
            { value: '31', label: '31 - Facturación individual por CUPS' },
            { value: '32', label: '32 - Detalle por CUPS de la facturación ATR agregada' },
            { value: '33', label: '33 - Facturación agregada (datos por factura)' }
          ],
          clearable: true
        },
        {
          type: 'text',
          name: QueryParams.BillNumber,
          label: 'Número de factura',
          default: this.$route.query[QueryParams.BillNumber]
        },
        {
          type: 'text',
          name: QueryParams.PseudoBillNumber,
          label: 'Pseudo factura',
          default: this.$route.query[QueryParams.PseudoBillNumber]
        },
        {
          type: 'select',
          name: QueryParams.MarketerCode,
          label: 'Comercializadora',
          default: this.$route.query[QueryParams.MarketerCode] as string,
          clearable: true,
          valueQuery: 'code',
          options: `${CORE_URL}/system-subjects`,
          visible: this.showMarketerInfo
        }
      ]
    }
  }
}
