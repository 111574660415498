var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          return [
            _c(
              "div",
              { staticClass: "process-list" },
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "empty-text": _vm.emptyText,
                      data: _vm.unprocessedList
                    },
                    on: {
                      "cell-mouse-leave": _vm.onCellMouseLeave,
                      "cell-mouse-enter": _vm.onCellMouseEnter
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "FICHERO/ERROR", prop: "keyname" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("div", { staticClass: "info-column" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.keyname))
                                  ]),
                                  scope.row.error
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-warning-outline"
                                        }),
                                        _vm._v(" " + _vm._s(scope.row.error))
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "SUBIDO",
                            prop: "createdAt",
                            width: "150"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-TimeColumn", {
                                      attrs: {
                                        time: scope.row.createdAt,
                                        format: "DD/MM/YYYY HH:MM"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: { label: "", width: "150" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDownload(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Descargar fichero "),
                                            _c("i", {
                                              staticClass: "el-icon-download"
                                            })
                                          ]
                                        ),
                                        _c("span")
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "", width: "50", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("UnprocessedFiles-ListItemMenu", {
                                      attrs: {
                                        unprocessedFile: scope.row,
                                        "row-index": scope.$index
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }