import { FormConfig } from '@/Modules/Forms/models'
import { Component, Vue, Prop, Watch } from 'types-vue'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class AddressForm2 extends Vue {
  @Prop()
  formData: any

  // Personalized boolean variables.
  protected isProvinceRequired: boolean = false
  protected isCityRequired: boolean = false
  protected isZipCodeRequired: boolean = false
  protected isStreetRequired: boolean = false
  protected isStreetTypeRequired: boolean = false
  protected isStreetNumerRequired: boolean = false

  protected get formOptions(): FormConfig {
    return {
      label: 'Dirección del punto de suminstro',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'province',
          label: 'Provincia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la provincia',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'city',
          label: 'Municipio',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el municipio',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription'
        },
        {
          type: 'text',
          name: 'zipcode',
          label: 'Código postal',
          placeholder: 'Introduce el código postal',
          maxCharacters: 5
        },
        {
          type: 'select',
          name: 'streettype',
          label: 'Tipo de vía',
          options: `${CORE_URL}/location-street-types`,
          placeholder: 'Selecciona el tipo de vía',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'street',
          label: 'Nombre de calle',
          placeholder: 'Introduce la calle',
          maxCharacters: 60
        },
        {
          type: 'text',
          name: 'streetnumber',
          label: 'Número de finca',
          placeholder: 'Introduce el número de finca',
          maxCharacters: 4
        },
        {
          type: 'text',
          name: 'portal',
          label: 'Portal',
          placeholder: 'Introduce el portal',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'staircase',
          label: 'Escalera',
          placeholder: 'Introduce la escalera',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'floor',
          label: 'Piso',
          placeholder: 'Introduce el piso',
          maxCharacters: 5
        },
        {
          type: 'text',
          name: 'door',
          label: 'Puerta',
          placeholder: 'Introduce la puerta',
          maxCharacters: 5
        }
      ],
      rules: {
        province: [
          { required: this.isProvinceRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        city: [{ required: this.isCityRequired, trigger: 'blur', message: 'Es obligatorio' }],
        zipcode: [{ required: this.isZipCodeRequired, trigger: 'blur', message: 'Es obligatorio' }],
        street: [{ required: this.isStreetRequired, trigger: 'blur', message: 'Es obligatorio' }],
        streettype: [
          { required: this.isStreetTypeRequired, trigger: 'blur', message: 'Es obligatorio' }
        ],
        streetnumber: [
          { required: this.isStreetNumerRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  @Watch('formData')
  protected onChangeFormData() {
    if (this.formData?.updatereason === '01') {
      this.isProvinceRequired = true
      this.isCityRequired = true
      this.isZipCodeRequired = true
      this.isStreetRequired = true
      this.isStreetTypeRequired = true
      this.isStreetNumerRequired = true
    } else {
      this.isProvinceRequired = false
      this.isCityRequired = false
      this.isZipCodeRequired = false
      this.isStreetRequired = false
      this.isStreetTypeRequired = false
      this.isStreetNumerRequired = false
    }
  }
}
