import { Cups } from '@/Modules/Cups/models'
import moment from 'moment'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class MeterSection extends Vue {

  @Prop({ required: true }) cups: Cups

  protected get elements(): any[] {
    const tarMomentDate = moment(this.cups?.generalData?.fechaUltimoCambioTarifa)
    const comMomentDate = moment(this.cups?.generalData?.fechaUltimoCambioComercializador)
    const tarDate = tarMomentDate.isValid() ? tarMomentDate.format('LL') : '-'
    const comDate = comMomentDate.isValid() ? comMomentDate.format('LL') : '-'
    const lastMeter = this.cups?.apparatusData?.length - 1


    return [
      { name: 'Numero de serie', value: this.cups?.apparatusData[lastMeter]?.numeroSerie || '-' },
      { name: 'Tipo', value: this.cups?.apparatusData[lastMeter]?.tipo || '-' },
      { name: 'Estado PS', value: this.cups?.statusPS === '1' ? 'Activo': 'Inactivo' },
      { name: 'Calibre', value: this.cups?.apparatusData[lastMeter]?.calibre || '-' },
      { name: 'Codigo accesibilidad', value: this.cups?.apparatusData[lastMeter]?.codigoAccesibilidad || '-' },
      { name: 'Con telemedida', value: this.cups?.apparatusData[lastMeter]?.conTelemedida || '-' },
      { name: 'Lectura', value: this.cups?.apparatusData[lastMeter]?.lectura || '-' },
      { name: 'Marca / Modelo contador', value: this.cups?.apparatusData[lastMeter]?.marca || '-' },
      { name: 'Presion', value: this.cups?.apparatusData[lastMeter]?.presion || '-' },
      { name: 'Propiedad', value: this.cups?.apparatusData[lastMeter]?.propiedad || '-' },
      { name: 'Fecha último cambio tarifa', value: tarDate },
      { name: '', value: '' },
    ]
  }
}