import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Dashboard from './pages/Dashboard/Dashboard.vue'


export enum DashboardPages {
  Dashboard = 'dashboard'
}


const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: Page,
    children: [
      {
        path: '',
        name: DashboardPages.Dashboard,
        component: Dashboard
      }
    ]
  }
]

export default routes