import { Cups } from '@/Modules/Cups/models'
import moment from 'moment'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class GeneralTab extends Vue {

  @Prop({ required: true }) cups: Cups

  protected get generalElements(): any[] {
    const altaMomentDate = moment(this.cups.generalData.fechaAlta)
    const altaDate = altaMomentDate.isValid() ? altaMomentDate.format('LL') : '-'

    return [
      { name: 'Fecha alta', value: altaDate },
      { name: 'Rango de presión', value: this.cups?.generalData.presion || '-' },
      { name: 'QH', value: this.cups?.generalData.qh || '-' },
      { name: 'QD', value: this.cups?.generalData.qd || '-' },
      { name: 'QA', value: this.cups?.generalData.qa || '-' },
      { name: 'Derecho tur', value: this.cups?.generalData.derechoTur || '-' },
      { name: 'Tarifa de peaje', value: this.cups?.generalData.peaje || '-' },
      { name: '', value: '' },
      { name: '', value: '' },
    ]
  }
}