const requireModule = require.context('../../', true,  /literals/);

enum fileTypes {
  Core = 'Core', 
  Module  = 'Modules'
}

let allsLiterals = {}

const formatLiterals = (literals: object, prefix: string) => {
  return Object.keys(literals)
    .map(key => ({ [`${prefix} ${key}`]: literals[key] }))
    .reduce((prev, current) => ({ ...prev, ...current }))
}

requireModule
  .keys()
  .filter(filepath => filepath.endsWith('.ts') && !filepath.endsWith('index.ts'))
  .forEach((filepath: string) => {
    const literals = requireModule(filepath).default || requireModule(filepath)
    const filepathSplit = filepath.split('/')
    const type = filepathSplit[1]

    if (type === fileTypes.Core) {
      const lang = filepathSplit[3]
      const prefix = fileTypes.Core.toLowerCase()
      
      allsLiterals[lang] = {
        ...allsLiterals[lang],
        ...formatLiterals(literals, prefix)
      }

    } else if (type === fileTypes.Module) {
      const moduleName = filepathSplit[2]
      const lang = filepathSplit[4]
      const prefix = `${moduleName}`.toLowerCase()

      allsLiterals[lang] = {
        ...allsLiterals[lang],
        ...formatLiterals(literals, prefix)
      }

    }

  });

export default allsLiterals;