import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import { S3Connector } from '../../models'
import Form from '../Form/Form'

@Component
export default class CreateDialog extends Vue {
  protected showing: boolean = false
  protected loading: boolean = false

  @MapAction(Store.S3Connectors) protected createS3Connector: (s3Connector: S3Connector) => Promise<void>


  protected async onSubmit(s3Connector: S3Connector) {
    const form = this.$refs.form as Form

    try {
    
      this.loading = true
      await this.createS3Connector(s3Connector)
    
      this.showing = false
      form.clean()
      this.$notify({ type: 'success', title: 'Se ha añadido correctamente', message: `Se ha creado el conector correctamente` })
    
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al intentar crear el conector'})
    } finally {
      this.loading = false
    }
  }
  
  
  public show() {
    this.showing = true
  }
}