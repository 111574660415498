import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { Notification } from 'element-ui'
import { ActionContext } from 'vuex'
import AlertsApi from '../connections/api/Alerts'
import { AlertCongifuration, defaultConfiguration } from '../models'

@Module({ namespaced: true })
export default class Alerts extends VuexModule {
  private _alerts: {} = {}
  private _alertsLoading: boolean = false
  private _subject: string = ''
  private _configurationLoading: boolean = false
  private _configuration: AlertCongifuration = { ...defaultConfiguration }
  private _validSubjects: any = null
  private _firstTimeConfiguration = false

  @Getter() protected alerts(): {} {
    return this._alerts
  }

  @Getter() protected configuration(): {} {
    return this._configuration
  }
  @Getter() protected configurationLoading(): boolean {
    return this._configurationLoading
  }

  @Getter() protected alertsLoading(): boolean {
    return this._alertsLoading
  }

  @Getter() protected validSubjects(): boolean {
    return this._validSubjects
  }

  @Getter() protected firstTimeConfiguration(): {} {
    return this._firstTimeConfiguration
  }

  @Getter() protected subject(): {} {
    return this._subject
  }

  @Mutation() protected setAlerts(value: any) {
    this._alerts = value
  }

  @Mutation() protected setConfiguration(value: any) {
    this._configuration = value
  }

  @Mutation() protected setConfigurationLoading(value: boolean) {
    this._configurationLoading = value
  }

  @Mutation() protected setAlertsLoading(value: boolean) {
    this._alertsLoading = value
  }

  @Mutation() protected setValidSubjects(value: any) {
    this._validSubjects = value.map((el) => ({ name: el.description, code: el.code, id: el.id }))
  }

  @Mutation() protected setFirstTimeConfiguration(value: boolean) {
    this._firstTimeConfiguration = value
  }

  @Mutation() protected setSubject(value: any) {
    this._subject = value
  }

  @Action({ useContext: true })
  protected async obtainValidSubjects(ctx: ActionContext<any, any>): Promise<void> {
    try {
      ctx.commit('setAlertsLoading', true)
      const response = await AlertsApi.getValidSubjects()
      ctx.commit('setValidSubjects', response.data)
    } catch (error) {
      Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
    } finally {
      ctx.commit('setAlertsLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlerts(ctx: ActionContext<any, any>): Promise<void> {
    try {
      ctx.commit('setAlertsLoading', true)
      const response = await AlertsApi.getAlerts()
      ctx.commit('setAlerts', response.data)
    } catch (error) {
      if (!error.toString().includes('404')) {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      } else {
        ctx.commit('setAlerts', {})
      }
    } finally {
      ctx.commit('setAlertsLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlertsAdmin(ctx: ActionContext<any, any>): Promise<void> {
    try {
      ctx.commit('setAlertsLoading', true)
      const { email, code } = ctx.state._subject
      const response = await AlertsApi.getAlertsAdmin({
        userEmail: email,
        marketer: code
      })
      ctx.commit('setAlerts', response.data.information)
    } catch (error) {
      if (!error.toString().includes('404')) {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      } else {
        ctx.commit('setAlerts', {})
      }
    } finally {
      ctx.commit('setAlertsLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlertsAdminFiltered(ctx: ActionContext<any, any>): Promise<void> {
    try {
      ctx.commit('setAlertsLoading', true)
      const { email } = ctx.state._subject
      const response = await AlertsApi.getAlertsAdminFiltered({
        userEmail: email
      })
      ctx.commit('setAlerts', response.data)
    } catch (error) {
      if (!error.toString().includes('404')) {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      } else {
        ctx.commit('setAlerts', {})
      }
    } finally {
      ctx.commit('setAlertsLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlertsSummary(ctx: ActionContext<any, any>): Promise<void> {
    // AlertsApi.getAlertsSummary()
    // try {
    //   ctx.commit('setProcessLoading', true)
    //   const response = await Alerts.getAlerts({})
    //   ctx.commit('setMetrics', response.data)
    // } catch (error) {
    // } finally {
    //   ctx.commit('setProcessLoading', false)
    // }
  }

  @Action({ useContext: true })
  protected async obtainAlertsConfiguration(ctx: ActionContext<any, any>): Promise<void> {
    ctx.commit('setConfiguration', { ...defaultConfiguration })
    try {
      ctx.commit('setConfigurationLoading', true)
      const response = await AlertsApi.getAlertsConfiguration()
      ctx.commit('setConfiguration', response.data?.alertConfiguration)
    } catch (error) {
      if (error.toString().includes('404')) {
        ctx.commit('setFirstTimeConfiguration', true)
      } else {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      }
    } finally {
      ctx.commit('setConfigurationLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlertsConfigurationAdmin(ctx: ActionContext<any, any>): Promise<void> {
    ctx.commit('setConfiguration', { ...defaultConfiguration })
    try {
      ctx.commit('setConfigurationLoading', true)
      const { email, code } = ctx.state._subject
      const response = await AlertsApi.getAlertsConfigurationAdmin({
        userEmail: email,
        marketer: code
      })
      ctx.commit('setConfiguration', response.data?.alertConfiguration)
    } catch (error) {
      if (error.toString().includes('404')) {
        ctx.commit('setFirstTimeConfiguration', true)
      } else {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      }
    } finally {
      ctx.commit('setConfigurationLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async obtainAlertsConfigurationAdminEmail(ctx: ActionContext<any, any>): Promise<void> {
    ctx.commit('setConfiguration', { ...defaultConfiguration })
    try {
      ctx.commit('setConfigurationLoading', true)
      const { email } = ctx.state._subject
      const response = await AlertsApi.getAlertsConfigurationAdminEmail({
        userEmail: email
      })
      ctx.commit('setConfiguration', response.data)
    } catch (error) {
      if (error.toString().includes('404')) {
        ctx.commit('setFirstTimeConfiguration', true)
      } else {
        Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
      }
    } finally {
      ctx.commit('setConfigurationLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async updateAlertsConfiguration(
    ctx: ActionContext<any, any>,
    config: AlertCongifuration
  ): Promise<void> {
    try {
      ctx.commit('setConfigurationLoading', true)
      const response = await AlertsApi.patchAlertsConfiguration(config)
      ctx.commit('setConfiguration', response.data?.alertConfiguration)
    } catch (error) {
      Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
    } finally {
      ctx.commit('setConfigurationLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async updateAlertsConfigurationAdmin(
    ctx: ActionContext<any, any>,
    config: AlertCongifuration
  ): Promise<void> {
    try {
      const response = await AlertsApi.patchAlertsConfigurationAdmin(config)
      ctx.commit('setConfiguration', response.data?.alertConfiguration)
    } catch (error) {
      Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
    } finally {
      ctx.commit('setConfigurationLoading', false)
    }
  }

  @Action({ useContext: true })
  protected async activateAlertsConfiguration(
    ctx: ActionContext<any, any>,
    config: any
  ): Promise<void> {
    try {
      ctx.commit('setConfigurationLoading', true)

      await AlertsApi.postAlertsConfiguration(config)
      await ctx.dispatch('obtainAlertsConfiguration')
    } catch (error) {
      Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
    } finally {
      ctx.commit('setConfigurationLoading', false)
      ctx.commit('setFirstTimeConfiguration', false)
    }
  }

  @Action({ useContext: true })
  protected async activateAlertsConfigurationAdmin(
    ctx: ActionContext<any, any>,
    config: any
  ): Promise<void> {
    try {
      ctx.commit('setConfigurationLoading', true)
      await AlertsApi.postAlertsConfigurationAdmin(config)
      await ctx.dispatch('obtainAlertsConfigurationAdmin')
    } catch (error) {
      Notification.error({ title: `Error ${error?.response?.status}`, message: '' })
    } finally {
      ctx.commit('setConfigurationLoading', false)
      ctx.commit('setFirstTimeConfiguration', false)
    }
  }

  @Action({ useContext: true })
  protected updateSubject(ctx: ActionContext<any, any>, subject: any): void {
    ctx.commit('setSubject', subject)
  }
}
