var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("Core-Title", { attrs: { loading: _vm.listLoading } }, [
            _c("h2", [_vm._v("Ficheros no procesados por error")])
          ])
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-to-top", appear: "" } },
        [
          _c(
            "el-card",
            { staticClass: "is-table" },
            [
              _c("UnprocessedFiles-FilesList"),
              _c("Core-Pagination", {
                attrs: {
                  pageSize: _vm.pageSize,
                  totalElements: _vm.totalElements
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-to-top", appear: "" } }, [
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("UnprocessedFiles-PageSizeSelect", {
              on: { change: _vm.onChangePageSize }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }