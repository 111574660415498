var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "auth confirmPassword.title",
            expression: '"auth confirmPassword.title"'
          }
        ]
      }),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("auth confirmPassword.description", [_vm.email])
          )
        }
      }),
      _c("Auth-ConfirmPasswordForm", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.onSubmit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }