var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "page-header" },
      [_c("Core-Title", [_c("h2", [_vm._v("Generar informes")])])],
      1
    ),
    _c(
      "div",
      { staticClass: "section" },
      [
        _c("h3", [
          _vm._v(
            "Informes descargables (" +
              _vm._s(_vm.downloadableReports.length) +
              ")"
          )
        ]),
        _vm._l(_vm.downloadableReports, function(item, i) {
          return _c(
            "div",
            { key: i, staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "item-content",
                  on: {
                    click: function($event) {
                      return _vm.onClickElement(item)
                    }
                  }
                },
                [
                  _vm._m(0, true),
                  _c("div", [_c("span", [_vm._v(_vm._s(item.label))])])
                ]
              ),
              _c(item.component, {
                ref: item.ref,
                refInFor: true,
                tag: "component"
              })
            ],
            1
          )
        })
      ],
      2
    ),
    _vm.isDistributorOrSystemRole
      ? _c(
          "div",
          { staticClass: "section" },
          [
            _c("h3", [
              _vm._v("Informes SIPS (" + _vm._s(_vm.sipsReports.length) + ")")
            ]),
            _vm._l(_vm.sipsReports, function(item, i) {
              return _c(
                "div",
                { key: i, staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "item-content",
                      on: {
                        click: function($event) {
                          return _vm.onClickElement(item)
                        }
                      }
                    },
                    [
                      _vm._m(1, true),
                      _c("div", [_c("span", [_vm._v(_vm._s(item.label))])])
                    ]
                  ),
                  _c(item.component, {
                    ref: item.ref,
                    refInFor: true,
                    tag: "component"
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "el-icon-document" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "el-icon-document" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }