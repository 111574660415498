import { decode } from "jsonwebtoken"
import _ from "lodash"
import { Vue } from 'types-vue'
import { CookieNames } from "./enum/CookieNames"

export function checkTokenExpired(token: string): boolean {
  const decodedToken = decode(token) as any
  const tokenHasExpired = decodedToken.exp < new Date().getTime() / 1000

  return tokenHasExpired
}

export function checkDenyRoleAccess(denyRolesAcess: string[] = [], token: string): boolean {
  const decodedToken = decode(token) as any
  const { userRol } = decodedToken

  return !!denyRolesAcess.find(rol => rol === userRol)
}

export function decodeToken(token: string): object {
  return decode(token) as any
}

export function multipleIncludes(str: string, targets: string[]): boolean {
  return new RegExp(targets.join('|')).test(str);
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function cleanStringNumber(number: string): string {
  const num = parseInt(number)
  
  if (_.isNaN(num)) return number
  return num.toString()
}


export function getApiUrlWithCookieVersion(baseUrl: string) {
  const apiVersion = Vue.$cookies.get(CookieNames.ApiVersion) || 'v2'

  return baseUrl.replace('{{version}}', apiVersion)
}