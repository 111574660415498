import { render, staticRenderFns } from "./StateColumn.vue?vue&type=template&id=df387642&scoped=true&"
import script from "./StateColumn.ts?vue&type=script&lang=ts&"
export * from "./StateColumn.ts?vue&type=script&lang=ts&"
import style0 from "./StateColumn.vue?vue&type=style&index=0&id=df387642&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df387642",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src627230493/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df387642')) {
      api.createRecord('df387642', component.options)
    } else {
      api.reload('df387642', component.options)
    }
    module.hot.accept("./StateColumn.vue?vue&type=template&id=df387642&scoped=true&", function () {
      api.rerender('df387642', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/NoContract/components/columns/StateColumn/StateColumn.vue"
export default component.exports