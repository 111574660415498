import { Component, MapGetter, mixins, Prop } from 'types-vue'

import { FormConfig } from '@/Modules/Forms/models'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { QueryParams } from '@/Modules/History/definitions/QueryParams'
import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ProcessFilters extends mixins(PermissionMixin) {
  @Prop({ required: true })
  protected page: string

  @MapGetter(Store.UserGroups) protected systemSubjectIsMarketer: boolean

  protected get showMarketerInfo(): boolean {
    return (this.isDistributorRol && !this.systemSubjectIsMarketer) || this.isSystemRol
  }

  protected get formOptions(): FormConfig {
    const selectOptions = {
      contracts: [
        { value: '02', label: '02 - Solicitud de Cambio' },
        { value: '03', label: '03 - Solicitud de Anulación' },
        { value: '04', label: '04 - Solicitud de Baja' },
        { value: '05', label: '05 - Solicitud de Modificación' },
        { value: '19', label: '19 - Anulación de Distribuidor' },
        { value: '24', label: '24 - Comunicación de Baja' },
        { value: '25', label: '25 - Comunicación Cambio de comercializador' },
        { value: '26', label: '26 - Comunicación de Modificación de Datps' },
        { value: '27', label: '27 - Comunicación de Contratos ATR' },
        { value: '38', label: '38 - Puesta en Servicio Nuevos Suministros' },
        { value: '39', label: '39 - Comunicación NNSS' },
        { value: '41', label: '41 - Cambio de Comercializador con Modificación de Datos' },
        { value: '42', label: '42 - Cambio de Comercializador con Nuevos Productos Adicionales' },
        { value: '43', label: '43 - Solicitud de Nuevo Producto/Contrato' },
        { value: '49', label: '49 - Desistimiento' },
        { value: '50', label: '50 - Traspaso al CUR' }
      ],

      atcom: [
        { value: '44', label: '44 - Actuaciones domiciliarias' },
        { value: '45', label: '45 - Comunicación operaciones iniciativa distribuidor' },
        { value: '46', label: '46 - Anulación de Actuaciones domiciliarias' },
        { value: '47', label: '47 - Anulación de Actuaciones iniciativa distribuidor' },
        { value: '48', label: '48 - Reclamaciones' }
      ]
    }

    return {
      label: 'Filtros',
      submitButton: 'Filtrar',
      size: 'small',
      withoutSubmit: true,
      items: [
        {
          type: 'tag',
          name: QueryParams.Cups,
          label: 'Cups',
          default: (this.$route.query[QueryParams.Cups] as string)?.split(',') || []
        },
        {
          type: 'select',
          name: QueryParams.MarketerCode,
          label: 'Comercializadora',
          default: this.$route.query[QueryParams.MarketerCode] as string,
          clearable: true,
          valueQuery: 'code',
          options: `${CORE_URL}/system-subjects`,
          visible: this.showMarketerInfo
        },
        {
          type: 'text',
          name: QueryParams.ReferenceNumber,
          label: 'Núm. Referencia',
          default: this.$route.query[QueryParams.ReferenceNumber] as string,
          clearable: true
        },
        {
          type: 'select',
          name: QueryParams.MessageType,
          label: 'Tipo de mensaje',
          default: this.$route.query[QueryParams.MessageType] as string,
          clearable: true,
          valueQuery: 'code',
          options: `${CORE_URL}/process-message-types`
        },
        {
          type: 'select',
          name: QueryParams.ProcessType,
          label: 'Tipo de proceso',
          clearable: true,
          default: (this.$route.query[QueryParams.ProcessType] as string)?.split(','),
          options: selectOptions[this.page],
          valueQuery: 'code',
          multiple: true
        }
      ]
    }
  }
}
