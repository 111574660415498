import { Store } from '@/Core/Models/Stores'
import { ProcessDocumentsApi } from '@/Modules/Processes/connections/api/ProcessDocuments'
import { ProcessDocument, ProcessModel } from '@/Modules/Processes/models'
import { Component, MapAction, MapGetter, Prop, Vue } from 'types-vue'

@Component
export default class DeleteButton extends Vue {

  @Prop({ required: true, type: Object }) protected document: ProcessDocument

  @MapAction(Store.Details) protected getDocuments: () => Promise<void>
  @MapGetter(Store.Details) protected process: ProcessModel


  protected loading: boolean = false
  

  private confirmDelete() {
    return this.$confirm(`¿Estas seguro de eliminar el fichero "${this.document.filename}"?`, 'Eliminar fichero', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar' })
  }


  protected async onClickButton() {

    if (!await this.confirmDelete()) return

    try {
      this.loading = true
      await ProcessDocumentsApi.deleteDocument(this.process.id, this.document.filename)
      await this.getDocuments()
    } catch (error) {
      console.error(error)
      this.$notify.error('Error al eliminar el fichero.')
    } finally {
      this.loading = false
    }
  }
}