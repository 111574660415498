var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Cups-DetailsSection", { attrs: { title: "Ubicación" } }, [
    _c("div", { staticClass: "location-tab" }, [
      _vm.coordinates
        ? _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "l-map",
                {
                  staticStyle: { height: "35rem" },
                  attrs: { zoom: 16, center: _vm.mapCoordinates }
                },
                [
                  _c("l-tile-layer", {
                    attrs: {
                      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    }
                  }),
                  _c(
                    "l-marker",
                    { attrs: { "lat-lng": _vm.mapCoordinates } },
                    [
                      _c("l-icon", {
                        attrs: {
                          "icon-size": [32, 37],
                          "icon-anchor": [16, 37],
                          "icon-url": "/place.svg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { class: { right: _vm.coordinates } },
        [_c("Core-DataSection", { attrs: { elements: _vm.elements } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }