import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'
import _ from 'lodash'
import { Store } from '@/Core/Models/Stores'
import { HolidayFilter } from '../../models'
import moment from 'moment'

@Component
export default class DatePicker extends Vue {

  protected model: Date[] = []
  protected pickerOptions = {
    shortcuts: [
      { text: 'Mes actual', onClick: this.onClickCurrentMonth },
      { text: 'Año actual', onClick: this.onClickCurrentYear },
      { text: 'Prox. 6 meses', onClick: this.onClickNextSixMonths }
    ]
  }


  @MapGetter(Store.Holidays) protected filter: HolidayFilter
  @MapAction(Store.Holidays) protected updateFilter: (payload: { startDate: Date, endDate: Date }) => void


  @Watch('filter', { immediate: true })
  protected onChangeFilter() {
    const startDate = moment(this.filter.startDate).toDate()
    const endDate = moment(this.filter.endDate).toDate()

    this.model = [startDate, endDate]
  }

  private onClickCurrentMonth(picker) {
    picker.$emit('pick', [new Date(), new Date()]);
  }

  private onClickCurrentYear(picker) {
    const end = new Date();
    const start = new Date(new Date().getFullYear(), 0);
    end.setMonth(11)
    picker.$emit('pick', [start, end]);
  }

  private onClickNextSixMonths(picker) {
    const end = new Date();
    const start = new Date();
    end.setMonth(start.getMonth() + 6);
    picker.$emit('pick', [start, end]);
  }


  protected onChange() {
    if (_.isNil(this.model)) {
      this.model = [new Date(), new Date()]
    }

    this.updateFilter({ startDate: this.model[0], endDate: this.model[1] })
  }
}