import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, Vue } from 'types-vue'
import { Marketer } from '../../models'
import Form from '../Form/Form'

@Component
export default class EditorMarketerDialog extends Vue {

  protected showing: boolean = false
  protected loading: boolean = false
  protected marketer: Marketer = null


  @MapAction(Store.Marketers) private editMarketer:(marketer: Marketer) => Promise <void>

  protected async onSubmit(marketer: Marketer) {
    const form = this.$refs.form as Form

    try {
      this.loading = true
      await this.editMarketer(marketer)
      this.showing = false
      form.clean()
      this.$notify({ type: 'success', title: 'Se ha modificado correctamente', message: `Se ha modificado la comercializadora ${marketer.name} correctamente` })
    } catch (error) {
      this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: `Ha ocurrido un error al intentar modificar ${marketer.name}`})
    } finally {
      this.loading = false
    }
  }

  public show(marketer: Marketer) {
    this.showing = true
    this.marketer = marketer
  }
}