var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var desktop = ref.desktop
          var mobile = ref.mobile
          return [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "empty-text": _vm.emptyText,
                      data: _vm.atrContracts
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "active", width: "40" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: scope.row.active
                                        ? "Activo"
                                        : "Inactivo",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "circle",
                                      class: { enabled: scope.row.active }
                                    })
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    !mobile
                      ? _c("el-table-column", {
                          attrs: { label: "CODIGO", prop: "code" }
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "CUPS", prop: "cups" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("Core-CupsColumn", {
                                  attrs: {
                                    cups: scope.row.cups,
                                    index: scope.$index
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "NUM. DOCUMENTO TITULAR",
                        prop: "titularDocumentnum",
                        width: "100"
                      }
                    }),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "COMERCIALIZADORA",
                            prop: "marketerCode",
                            width: "190px"
                          }
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "FECHA INICIO",
                            prop: "effectiveStartDate",
                            width: "190px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-TimeColumn", {
                                      attrs: {
                                        time: scope.row.effectiveStartDate
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    desktop
                      ? _c("el-table-column", {
                          attrs: {
                            label: "FECHA FINALIZACIÓN",
                            prop: "effectiveEndDate",
                            width: "190px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("Core-TimeColumn", {
                                      attrs: {
                                        time: scope.row.effectiveEndDate
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    !_vm.tablet
                      ? _c("el-table-column", {
                          attrs: {
                            label: "",
                            width: "120",
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onClickDetails(
                                                  scope.row.cups
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v("Detalles "),
                                            _c("i", {
                                              staticClass: "el-icon-top-right"
                                            })
                                          ]
                                        ),
                                        _c("span")
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }