import { Store } from '@/Core/Models/Stores'
import { Component, MapAction, MapGetter, Prop, Vue, Watch } from 'types-vue'
import { MasterConfig } from '../../models/Masters'

@Component
export default class CreateDialog extends Vue {


  /**
   * PROPS
   */

  @Prop({ type: Boolean })
  protected visible: boolean



  /**
   * STATE
   */

  protected formLoading: boolean = false



  /**
   * STORE
   */

  @MapAction(Store.Master)
  protected createMasterItem: (masterItem) => Promise<any>

  @MapGetter(Store.Master) protected masterName: string
  @MapGetter(Store.Master) protected masterConfig: MasterConfig



  /**
   * GETTERS
   */

  protected get title(): string { return `Crear nuevo "${this.masterName}"` }
  protected get isSystemSubject(): boolean { return this.masterConfig.code === 'system-subjects' }



  /**
   * EVENTS
   */
  
  protected hideDialog() {
    this.$emit('update:visible', false)
  }


  protected onBeforeClose() {
    this.hideDialog()
  }


  protected onCancel() {
    this.hideDialog()
  }


  protected async onSubmit(form) {
    try {
      this.formLoading = true
      await this.createMasterItem(form)
      this.hideDialog()
      this.$notify({ type: 'success', title: 'Creado', message: `Elemento creado en el master "${this.masterName}"`})
    } catch (error) {
      this.$notify({ type: 'error', title: 'Error', message: `Error al crear un elemento en "${this.masterName}"`})
    } finally {
      this.formLoading = false
    }
  }

}