import { Component, Prop, Vue } from 'types-vue'

@Component
export default class PasswordChecker extends Vue {

  @Prop({ required: true }) protected password: string

  protected get checks() {
    return {
      length: { result: this.lengthValidator, label: 'Mínimo 8 caracteres' },
      lowercase: { result: this.lowercaseValidator, label: 'Contiene minusculas' },
      uppercase: { result: this.uppercaseValidator, label: 'Contiene mayusculas' },
      symbols: { result: this.symbolsValidator, label: 'Contiene simbolos' },
      digits: { result: this.digitsValidator, label: 'Contiene numeros' },
    }
  }

  protected get lengthValidator():    boolean { return this.password?.length > 8 }
  protected get lowercaseValidator(): boolean { return (/[a-z]/).test(this.password) }
  protected get uppercaseValidator(): boolean { return (/[A-Z]/).test(this.password) }
  protected get symbolsValidator():   boolean { return (/.[,!,@,#,$,%,^,&,*,+,?,_,~,-,(,)]/).test(this.password) }
  protected get digitsValidator():    boolean { return (/[0-9]/).test(this.password) }
  

  public checkPassword(): boolean {
    return this.lengthValidator
        && this.lowercaseValidator
        && this.uppercaseValidator
        && this.symbolsValidator
        && this.digitsValidator
  }
}