import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import { Address } from '../models'
import AddressesApi from '../connections/api/Addresses' 
import { AddressesResponseData } from '../models/api'
import { QueryParams } from '../definitions/QueryParams'
import { AxiosError } from 'axios'
import { Notification } from 'element-ui'



@Module({ namespaced: true })
export default class Addresses extends VuexModule {
  
  private _addressesList: Address[] = []
  private _pageSize: number = 0
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _listLoading: boolean = true
  private _nombreViaFilter: string = null
  private _municipalityFilter: number = null
  private _provinceFilter: number = null
  private _codViaFilter: number = null


  @Getter() public addressesList(): Address[] { return this._addressesList}
  @Getter() public pageSize(): number { return this._pageSize } 
  @Getter() public currentPage(): number { return this._currentPage }
  @Getter() public totalElements(): number { return this._totalElements }
  @Getter() public listLoading(): boolean { return this._listLoading }
  @Getter() public nombreViaFilter(): any { return this._nombreViaFilter }
  @Getter() public municipalityFilter(): any { return this._municipalityFilter }
  @Getter() public provinceFilter(): any { return this._provinceFilter }
  @Getter() public codViaFilter(): any { return this._codViaFilter }

  @Mutation()
  protected setAddresses(data: AddressesResponseData) {
    this._addressesList = data.content
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setCurrentPage(currentPage: number) {
    this._currentPage = currentPage
  }

  @Mutation()
  protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  @Mutation()
  protected setListLoading(value: boolean) {
    this._listLoading = value
  }

  @Mutation()
  protected setNombreViaFilter(newValue: string) {
    this._nombreViaFilter = newValue
  }

  @Mutation()
  protected setMunicipalityFilter(newValue: number) {
    this._municipalityFilter = newValue
  }

  @Mutation()
  protected setProvinceFilter(newValue: number) {
    this._provinceFilter = newValue
  }

  @Mutation()
  protected setCodViaFilter(newValue: number) {
    this._codViaFilter = newValue
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setCurrentPage', queryParams[QueryParams.CurrentPage])
    ctx.commit('setCodViaFilter', queryParams[QueryParams.CodVia])
    ctx.commit('setProvinceFilter', queryParams[QueryParams.Provincia])
    ctx.commit('setMunicipalityFilter', queryParams[QueryParams.Municipio])
    ctx.commit('setNombreViaFilter', queryParams[QueryParams.NombreVia])

    ctx.dispatch('obtainAddresses')
  }

  @Action({ commit: 'setCurrentPage' }) 
  protected updateCurrentPage(currentPage) { 
    return currentPage 
  }


  @Action({ useContext: true })
  protected async obtainAddresses(ctx: ActionContext<any, any>): Promise<any> {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const provincia = ctx.getters.provinceFilter
    const municipio = ctx.getters.municipalityFilter
    const codigovia = ctx.getters.codViaFilter
    const nombrevia = ctx.getters.nombreViaFilter

    if (_.isNil(page) || _.isNil(size) || size < 1) return

    try {
      ctx.commit('setListLoading', true)
      const response = await AddressesApi.getAddresses({ page, size, provincia, municipio, codigovia, nombrevia })
      ctx.commit('setAddresses', response.data)
    } catch (err) {
      const error = err as AxiosError
      const messagaError = error?.response?.data?.message || 'Error al cargar los callejeros.'
      
      Notification.error({ title: `Error ${error?.response?.status}`, message: messagaError })

    }
 
  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

}
