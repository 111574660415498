import { Component, Prop, Vue } from 'types-vue'
import { HistoryBillsApi } from '../../connections/api/HistoryBills'

@Component
export default class BillingTable extends Vue {
  @Prop({ default: [] }) billsList: any[]

  protected async onClickDownload(id: number): Promise<void> {
    const url = await HistoryBillsApi.downloadHistoryBill(id)
    window.open(url, '_blank')
  }
}
