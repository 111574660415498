import { Store } from '@/Core/Models/Stores'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import { User } from '@/Modules/UsersManager/models'
import { Component, MapAction, MapGetter, mixins, Vue, Watch } from 'types-vue'
import UserCreationDialog from '../../dialogs/UserCreationDialog/UserCreationDialog'
import UserEditionDialog from '../../dialogs/UserEditionDialog/UserEditionDialog'

@Component
export default class UsersTab extends mixins(PermissionMixin) {
  @MapGetter(Store.UserManagerUsers) protected totalActiveFilter: number

  protected onClickCreateButton() {
    const createDialog = this.$refs.createDialog as UserCreationDialog
    createDialog.show()
  }

  protected onEditUser(user: User) {
    const editDialog = this.$refs.editDialog as UserEditionDialog
    editDialog.show(user)
  }
}
