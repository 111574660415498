var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAdminPermission
    ? _c(
        "div",
        { staticClass: "uploader-header-menu" },
        [
          _c(
            "el-button",
            {
              attrs: { id: "open-uploader-button", type: "primary" },
              on: { click: _vm.onClickUploadButton }
            },
            [_vm._v("Subir fichero")]
          ),
          _c("Uploader-Drawer", {
            attrs: { visible: _vm.visible },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }