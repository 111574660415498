var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            loading: _vm.loading,
            placeholder: _vm.placeholder,
            "no-match-text": "No se ha encontrado resultado",
            size: _vm.size,
            disabled: _vm.disabled,
            multiple: _vm.multiple,
            clearable: _vm.clearable,
            filterable: ""
          },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        _vm._l(_vm.options, function(item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }