<template>
  <div id="app">
    <MediaQueryProvider :queries="queries">
      <router-view/>
    </MediaQueryProvider>
  </div>
</template>

<style lang="scss">
html, body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: .9rem;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
import { MediaQueryProvider } from "vue-component-media-queries";

export default {
  data() {
    return { 
      queries: {
        mobile: '(max-width: 760px)',
        tablet: '(max-width: 1024px)',
        desktop: '(min-width: 1024px)',
      }
    }
  },
  components: { MediaQueryProvider }
}
</script>