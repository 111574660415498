import { Store } from '@/Core/Models/Stores'
import { ElTable } from 'element-ui/types/table'
import { Component, Filter, MapAction, MapGetter, Prop, Vue, Watch } from 'types-vue'
import { Bill } from '../../../models'
import { BillsPages } from '../../../router'

@Component
export default class BillsList extends Vue {

  @Prop({ required: true, type: Array }) protected bills: Bill[]
  @Prop({ required: true, type: Boolean }) protected loading: boolean
  @Prop({ required: false, type: Boolean, default: false }) protected withSelect: boolean


  
  
  /**
   * STATE
   */
  protected defaultOrder = { prop: null, order: null }
  protected showingMenuItem: Bill = null
  
  

  /**
   * STORE
   */
  
  @MapAction(Store.SelectedBills) protected newSelectedBills: (payload: { bills: Bill[] }) => void
  @MapGetter(Store.SelectedBills) protected selectedBills: Bill[]




  /**
   * EVENTS
   */

  protected onClickDetails(bill: Bill) {
    this.$router.push({ name: BillsPages.Bill, params: { id: bill.id.toString() }})
  }

  protected onCellMouseEnter(item: Bill): void {
    this.showingMenuItem = item
  }

  protected onCellMouseLeave(item: Bill): void {
    if (this.showingMenuItem.id === item.id) this.showingMenuItem = null
  }

  protected onSelectionChange(bills: Bill[]): void {
    this.newSelectedBills({ bills })
  }




  /**
   * GETTERS
   */

  protected get emptyText(): string {
    return (this.loading)
      ? 'CARGANDO FACTURAS...'
      : 'NO HAY FACTURAS'
  }


  
  /**
   * METHODS
   */

  // protected checkShowOptions(process: Bill): boolean {
  //   return !!this.showingMenuItem && this.showingMenuItem.id === process.id
  // }



  /**
   * FILTERS
   */
  @Filter()
  protected currency(value: number) {
    if (!value && value !== 0) return ''
      
    return `${value} €`
  }

  @Watch('selectedBills', { deep: true }) protected onChangeSelectedProcesses() {
    if (this.selectedBills.length === 0) {
      const table = this.$refs.table as ElTable
      table.clearSelection()
    }
  }
}