var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, "row-class-name": "cursor-pointer" },
          on: {
            "row-click": _vm.onRowClick,
            "cell-mouse-leave": _vm.onCellMouseLeave,
            "cell-mouse-enter": _vm.onCellMouseEnter
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "isActive", width: "30" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.isActive ? "Activo" : "Inactivo",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "circle",
                          class: { enabled: scope.row.isActive }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "inputFromSapBucket", label: "BUCKET DE ENTRADA" }
          }),
          _c("el-table-column", {
            attrs: { prop: "outputToSapBucket", label: "BUCKET DE SALIDA" }
          }),
          _vm.isAdminPermission
            ? _c("el-table-column", {
                attrs: { width: "150", prop: "options" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.removeLoadingId === scope.row.id
                            ? _c("i", { staticClass: "el-icon-loading" })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.checkShowOptions(scope.row),
                                  expression: "checkShowOptions(scope.row)"
                                }
                              ],
                              staticClass: "button-menu"
                            },
                            [
                              _c("el-button", {
                                attrs: { type: "text", size: "mini" }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickEdit(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("Editar")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickDelete(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("Eliminar")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  56401795
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }