import Page from '@/Core/views/Page/Page.vue'
import { RouteConfig } from 'vue-router'
import { Roles } from './models'
import Main from './pages/Main/Main.vue'

export enum UsersManagerPages {
  Main = 'UsersMain'
}

const routes: Array<RouteConfig> = [
  {
    path: '/users',
    component: Page,
    children: [
      {
        path: '',
        name: UsersManagerPages.Main,
        meta: { menuActive: 'usersManager', denyRolesAcess: [Roles.Marketer.code] },
        component: Main
      }
    ]
  }
]

export default routes