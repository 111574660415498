import { Store } from '@/Core/Models/Stores'
import { Component, MapGetter, Vue } from 'types-vue'
import { NoContractPages } from '../../router'

@Component
export default class UploadResult extends Vue {
  @MapGetter(Store.CreateA6161) protected validationErrors: string[]
  @MapGetter(Store.CreateA6161) protected validationResult: boolean
  @MapGetter(Store.CreateA6161) protected presignedUrl: string
  @MapGetter(Store.CreateA6161) protected loading: boolean



  protected onClickDownload() {
    window.open(this.presignedUrl, '_blank')
  }

  protected onClickReturn() {
    location.reload();
  }
}