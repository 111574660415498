var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MatchMedia",
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: { "empty-text": _vm.emptyText, data: _vm.elements },
          on: {
            "cell-mouse-leave": _vm.onCellMouseLeave,
            "cell-mouse-enter": _vm.onCellMouseEnter
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "FECHA CREACIÓN",
              prop: "createdAt",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.createdAt.replace("T", " ")))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "COMERCIALIZADORA",
              prop: "marketer",
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.marketer))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "CUPS", prop: "cups", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.cups))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { "min-width": "175" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onClickDownload(scope.row.id)
                              }
                            }
                          },
                          [
                            _vm._v("Descargar resultado"),
                            _c("i", { staticClass: "el-icon-download" })
                          ]
                        ),
                        _c("span")
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }