import { RouteConfig } from 'vue-router'
import Page from '@/Core/views/Page/Page.vue'
import Main from './pages/Main/Main.vue'
import Details from './pages/Details/Details.vue'

export enum AtrContractsPages {
  Main = 'atr-contracts-main',
  Details = 'atr-contracts-details'
}

const routes: Array<RouteConfig> = [
  {
    path: '/atr-contracts',
    component: Page,
    children: [
      {
        path: '',
        name: AtrContractsPages.Main,
        meta: { menuActive: 'AtrContracts' },
        component: Main
      },
      {
        path: 'details/:cups',
        name: AtrContractsPages.Details,
        component: Details
      }
    ]
  }
]

export default routes
