import { Store } from '@/Core/Models/Stores'
import _ from 'lodash';
import { Component, MapGetter, Vue } from 'types-vue'

@Component
export default class ResultTree extends Vue {

  @MapGetter(Store.FormCreateCupsWithContract) protected result: any
  @MapGetter(Store.FormCreateCupsWithContract) protected totalPages: string

  private getItem(data: any): any {
    const result = []

    _.forIn(data, (value, key) => {
      const isObject = _.isObject(value)
      result.push({
        label: key,
        value: isObject ? null : value,
        children: isObject ? this.getItem(value) : null
      })
    });

    return result
  }


  protected get information(): any {
    return this.getItem(this.result)
  }

}