import { FormConfig } from '@/Modules/Forms/models'
import { Component, MapGetter, Vue, Watch } from 'types-vue'
import { Store } from '@/Core/Models/Stores'
import { SystemSubject } from '@/Modules/Auth/models'
const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class InitialForm extends Vue {
  // Personalized boolean variables.
  protected isModelEffectDateRequired: boolean = true
  protected isReqTransferDateDisabled: boolean = true
  protected isModelEffectDateDisabled: boolean = true
  protected defaultDispatchingCompany: string = null
  protected disabledDispatchingCompany: boolean = false

  @MapGetter(Store.UserGroups) protected systemSubject: SystemSubject

  protected get formOptions(): FormConfig {
    return {
      label: 'Inicio',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'select',
          name: 'dispatchingcompany',
          label: 'Empresa emisora',
          options: `${CORE_URL}/system-subjects?categories=COM,CLI`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code',
          default: this.defaultDispatchingCompany,
          disabled: this.disabledDispatchingCompany
        },
        {
          type: 'select',
          name: 'destinycompany',
          label: 'Empresa receptora',
          options: `${CORE_URL}/system-subjects?categories=GRE,DIS`,
          placeholder: 'Selecciona empresa',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'comreferencenum',
          label: 'Referencia',
          placeholder: 'Introduce el numero de referencia',
          maxCharacters: 12
        },
        {
          type: 'select',
          name: 'modeffectdate',
          label: 'Modelo fecha efecto',
          options: `${CORE_URL}/contract-effect-date-models`,
          placeholder: 'Selecciona modelo fecha efecto',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'reqactivationdate',
          label: 'Fecha efecto solicitada',
          placeholder: 'Introduce la fecha efecto',
          disabled: this.isReqTransferDateDisabled
        }
      ],
      rules: {
        dispatchingcompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        destinycompany: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        comreferencenum: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        modeffectdate: [{ required: true, trigger: 'blur', message: 'Es obligatorio' }],
        reqactivationdate: [
          { required: this.isModelEffectDateRequired, trigger: 'blur', message: 'Es obligatorio' }
        ]
      }
    }
  }

  protected async change(process: any) {
    // Form constants.
    const modeffectdate = process.modeffectdate
    localStorage.removeItem('p38_supplypointform_json')

    // REQ. TRANSFER DATE LOGIC.
    if (modeffectdate !== '') {
      if (modeffectdate === '04') {
        this.isModelEffectDateRequired = true
        this.isReqTransferDateDisabled = false
      } else if (modeffectdate === '01' || modeffectdate === '02' || modeffectdate === '05') {
        this.isModelEffectDateRequired = false
        this.isReqTransferDateDisabled = true
      } else {
        this.isModelEffectDateRequired = false
        this.isReqTransferDateDisabled = false
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }

  @Watch('systemSubject', { deep: true, immediate: true })
  protected onChangeSystemSubject() {
    const specialSubjects = ['0000', '0234']

    if (this.systemSubject.code && !specialSubjects.includes(this.systemSubject.code)) {
      this.defaultDispatchingCompany = this.systemSubject.code
      this.disabledDispatchingCompany = true
    }
  }
}
