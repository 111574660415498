import { Cups } from '@/Modules/Cups/models'
import moment from 'moment'
import { Component, Prop, Vue } from 'types-vue'

@Component
export default class InspectionsSection extends Vue {
  @Prop({ required: true }) cups: Cups

  protected get elements(): any[] {
    const inspectionMomentDate = moment(this.cups.generalData.fechaUltimaInspeccion)
    
    const inspectionDate = inspectionMomentDate.isValid() ? inspectionMomentDate.format('LL') : '-'

    return [
      { name: 'Última inspección', value: inspectionDate },
      { name: 'Observaciones', value: this.cups?.generalData.codigoResultadoInspeccion || '-' },
      { name: '', value: '' },
    ]
  }
}