var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.noop.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("auth signin.email"),
                  disabled: _vm.loading
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onClickLogin.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.username,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "show-password": "",
                  placeholder: _vm.$t("auth signin.password"),
                  disabled: _vm.loading
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onClickLogin.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "rememberme-resetart" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      id: "forgotPasswordButton",
                      type: "text",
                      disabled: _vm.loading
                    },
                    on: { click: _vm.onClickForgotPassword }
                  },
                  [_vm._v(_vm._s(_vm.$t("auth signin.forgotPassword")))]
                )
              ],
              1
            )
          ]),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loading,
                      id: "signinBtn",
                      type: "primary"
                    },
                    on: { click: _vm.onClickLogin }
                  },
                  [_vm._v(_vm._s(_vm.$t("auth signin.submit")))]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }