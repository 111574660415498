import { Store } from '@/Core/Models/Stores'
import { Group } from '@/Modules/UsersManager/models'
import { Component, MapAction, Vue } from 'types-vue'

@Component
export default class GroupCreationDialog extends Vue {

  @MapAction(Store.UserManagerGroups) protected createGroup: (group: Group) => Promise<any>
  

  protected showing: boolean = false
  protected loading: boolean = false

  private successNotify(group: Group) {
    this.$notify({ type: 'success', title: 'Se ha creado correctamente', message: `El grupo "${group.name}" se ha creado correctamente`})
  }

  private errorNotify(error) {
    this.$notify({ type: 'error', title: `Error ${error?.response?.status}`, message: 'Ha ocurrido un error al crear el grupo'})
  }

  protected async onSubmitCreate(group: Group) {
    try {
      this.loading = true
      await this.createGroup(group)
      this.successNotify(group)
      this.showing = false
    } catch (error) {
      this.errorNotify(error)
    } finally {
      this.loading = false
    }
  }

  public show() {
    this.showing = true
  }
}