export default {
  signin: {
    "title": "Iniciar sesión",
    "email": "Correo electrónico",
    "password": "Contraseña",
    "rememberMe": "Recuérdame",
    "forgotPassword": "¿Has olvidado tu contraseña?",
    "submit": 'Acceder',
    "errorMessage": "Error al acceder, comprueba tus credenciales e intentalo de nuevo"
  }
}