import { Store } from '@/Core/Models/Stores'
import { BillsApi } from '@/Modules/Bills/connections/api/Bills'
import { Bill } from '@/Modules/Bills/models'
import { BillDownloadResponse } from '@/Modules/Bills/models/api'
import { FileEditorPages } from '@/Modules/FilesEditor/router'
import PermissionMixin from '@/Modules/User/mixins/PermissionMixin'
import moment from 'moment'
import { Component, MapGetter, mixins, Vue } from 'types-vue'

@Component
export default class DetailsRight extends mixins(PermissionMixin) {

  @MapGetter(Store.BillDetails) protected bill: Bill

  protected goToEdit() {
    this.$router.push({ name: FileEditorPages.Init, query: { fileKey: this.bill.keyname }})
  }

  get date(): string {
    return (!this.bill)
      ? ''
      : moment(this.bill.billDate).format('LL')
  }

  protected async downloadBill() {
    const downloadBillResponse = await BillsApi.downloadById(this.bill.id) as BillDownloadResponse
    window.open(downloadBillResponse.data, '_blank').focus();
  }
}