import _ from 'lodash'
import { Action, Getter, Module, Mutation, VuexModule } from 'types-vue'
import { ActionContext } from 'vuex'
import UnprocessedFilesApi from '../../UnprocessedFiles/connections/api/UnprocessedFiles'
import UnprocessedMarketerFilesApi from '../../UnprocessedFiles/connections/api/UnprocessedMarketerFiles'
import { UnprocessedFile } from '../../UnprocessedFiles/models'
import { UnprocessedFilesResponse } from '../../UnprocessedFiles/models/api'
import { FiltersName } from '../enums/Filters'

@Module({ namespaced: true })
export default class UnprocessedMarketerFiles extends VuexModule {
  private _unprocessedFilesList: UnprocessedFile[] = []
  private _pageSize: number = 10
  private _currentPage: number = 1
  private _totalElements: number = 0
  private _listLoading: boolean = true
  private _startDateFilter: string = null
  private _endDateFilter: string = null
  private _originSubjectFilter: string = null
  private _keynameFilter: string = null
  private _orderDirectionFilter: string = 'DESC'
  private _orderPropFilter: string = 'createdAt'

  @Getter() public unprocessedList(): UnprocessedFile[] {
    return this._unprocessedFilesList
  }
  @Getter() public pageSize(): number {
    return this._pageSize
  }
  @Getter() public currentPage(): number {
    return this._currentPage
  }
  @Getter() public totalElements(): number {
    return this._totalElements
  }
  @Getter() public listLoading(): boolean {
    return this._listLoading
  }

  @Getter() public allProcessFilters(): any {
    const filters = {
      startDate: this._startDateFilter,
      endDate: this._endDateFilter,
      originSubject: this._originSubjectFilter,
      keyname: this._keynameFilter
    }

    return _.omitBy(filters, _.isNil)
  }

  @Getter() public allSortFilters(): any {
    const sort = {
      orderDirection: this._orderDirectionFilter,
      orderProp: this._orderPropFilter
    }
    return _.omitBy(sort, _.isNil)
  }

  @Getter() public totalActiveFilter(): number {
    let result: number = 0

    if (!_.isNil(this._startDateFilter)) result++
    if (!_.isNil(this._endDateFilter)) result++
    if (!_.isNil(this._originSubjectFilter)) result++
    if (!_.isNil(this._keynameFilter)) result++

    return result
  }

  @Mutation()
  protected setUnprocessedMarketerFiles(data: UnprocessedFilesResponse) {
    this._unprocessedFilesList = data.files
    this._totalElements = data.totalElements
    this._listLoading = false
  }

  @Mutation()
  protected setCurrentPage(currentPage: number) {
    this._currentPage = currentPage
  }

  @Mutation()
  protected setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  @Mutation()
  protected setListLoading(value: boolean) {
    this._listLoading = value
  }

  @Action({ commit: 'setCurrentPage' }) protected updateCurrentPage(currentPage) {
    return currentPage
  }

  @Mutation() protected setEndDateFilter(value: string) {
    const hasChanged = value !== this._endDateFilter

    this._endDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setStartDateFilter(value: string) {
    const hasChanged = value !== this._startDateFilter

    this._startDateFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setOriginSubjectFilter(value: string) {
    const hasChanged = value !== this._originSubjectFilter

    this._originSubjectFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setKeynameFilter(value: string) {
    const hasChanged = value !== this._keynameFilter

    this._keynameFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setOrderDirectionFilter(value: string) {
    const hasChanged = value !== this._orderDirectionFilter

    this._orderDirectionFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Mutation() protected setOrderPropFilter(value: string) {
    const hasChanged = value !== this._orderPropFilter

    this._orderPropFilter = value
    if (hasChanged) this._currentPage = 1
  }

  @Action({ useContext: true })
  protected processUrlQueryParams(ctx: ActionContext<any, any>, queryParams: any) {
    ctx.commit('setEndDateFilter', queryParams[FiltersName.EndDate])
    ctx.commit('setStartDateFilter', queryParams[FiltersName.StartDate])
    ctx.commit('setOriginSubjectFilter', queryParams[FiltersName.OriginSubject])
    ctx.commit('setKeynameFilter', queryParams[FiltersName.Keyname])
    ctx.commit('setOrderDirectionFilter', queryParams[FiltersName.OrderDirection])
    ctx.commit('setOrderPropFilter', queryParams[FiltersName.OrderProp])

    ctx.dispatch('obtainUnprocessedMarketerFiles')
  }

  @Action({ commit: 'setUnprocessedMarketerFiles', useContext: true })
  protected async obtainUnprocessedMarketerFiles(ctx: ActionContext<any, any>): Promise<any> {
    const page = ctx.getters.currentPage - 1
    const size = ctx.getters.pageSize
    const sort = `${ctx.getters.allSortFilters.orderProp || 'createdAt'},${ctx.getters
      .allSortFilters.orderDirection || 'DESC'}`
    const filter = ctx.getters.allProcessFilters

    if (!sort || _.isNil(page) || _.isNil(size)) return
    ctx.commit('setListLoading', true)
    const response = await UnprocessedMarketerFilesApi.getUnprocessedMarketerFiles({
      page,
      size,
      sort,
      ...filter
    })

    return response.data
  }

  @Action({ useContext: true })
  protected newPageSize(ctx: ActionContext<any, any>, pageSize: number): void {
    ctx.commit('setPageSize', pageSize)
  }

  @Action({ useContext: true })
  protected async removeUnprocessed(
    ctx: ActionContext<any, any>,
    file: UnprocessedFile
  ): Promise<any> {
    const response = await UnprocessedFilesApi.removeById(file.keyname)

    if (response.status === 200) ctx.dispatch('obtainBills')
  }
}
