var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchMedia", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var mobile = ref.mobile
          return [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    mobile
                      ? _c("el-button", {
                          attrs: { icon: "el-icon-more", type: "text" },
                          on: { click: _vm.onClickMenu }
                        })
                      : _c("img", {
                          attrs: {
                            src: "/logo.png",
                            alt: "Madrileña red de gas"
                          },
                          on: { click: _vm.onClickLogo }
                        })
                  ],
                  1
                ),
                _c("Searcher-SearchBar", { staticClass: "center" }),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    !mobile ? _c("Auth-SelectUserGroup") : _vm._e(),
                    _c("User-UserMenu")
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }