import { FormConfig } from '@/Modules/Forms/models'
import { Component, Prop, Vue } from 'types-vue'
import { p48required } from '@/Modules/ProcessesForms/models/p48required'

const CORE_URL = process.env.VUE_APP_CORE_URL

@Component
export default class ExpedientIncidenceForm extends Vue {
  @Prop({ required: true })
  protected claimTypes: string

  // Personalized boolean variables.

  protected get formOptions(): FormConfig {
    return {
      label: 'Expediente e incidencia',
      submitButton: 'Siguiente',
      items: [
        {
          type: 'text',
          name: 'nnssexpedient',
          label: 'Código de expediente de NNSS',
          placeholder: 'Introduce el código de expediente de NNSS',
          maxCharacters: 20
        },
        {
          type: 'text',
          name: 'fraudrecordnum',
          label: 'Código de expediente de fraude',
          placeholder: 'Introduce el código de expediente de fraude',
          maxCharacters: 20
        },
        {
          type: 'date',
          name: 'datefrom',
          label: 'Fecha desde',
          placeholder: 'Introduce la fecha desde',
          valueQuery: 'code'
        },
        {
          type: 'date',
          name: 'dateto',
          label: 'Fecha hasta',
          placeholder: 'Introduce la fecha hasta',
          valueQuery: 'code'
        },
        {
          type: 'text',
          name: 'invoicenumber',
          label: 'Número de factura',
          placeholder: 'Introduce el número de factura',
          maxCharacters: 17
        },
        {
          type: 'text',
          name: 'incidentlocationdesc',
          label: 'Descripción de la ubicación de la incidencia',
          placeholder: 'Introduce la descripción de la ubicación de la incidencia',
          maxCharacters: 45
        },
        {
          type: 'select',
          name: 'incidentlocationprovince',
          label: 'Provincia de la incidencia',
          options: `${CORE_URL}/location-province`,
          placeholder: 'Selecciona la provincia',
          valueQuery: 'code'
        },
        {
          type: 'select',
          name: 'incidentlocationcity',
          label: 'Municipio de la incidencia',
          options: `${CORE_URL}/ine-municipalities`,
          placeholder: 'Selecciona el municipio',
          valueQuery: 'netMunicipalityCode',
          labelQuery: 'municipalityIneDescription'
        },
        {
          type: 'text',
          name: 'incidentlocationcitysubdivision',
          label: 'Población de la incidencia',
          placeholder: 'Introduce la población de la incidencia',
          maxCharacters: 11
        },
        {
          type: 'text',
          name: 'incidentlocationzipcode',
          label: 'Código postal de la incidencia',
          placeholder: 'Introduce el código postal de la incidencia',
          maxCharacters: 5
        }
      ],
      rules: {
        datefrom: [
          {
            required: p48required[this.claimTypes]?.datefrom,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        dateto: [
          {
            required: p48required[this.claimTypes]?.dateto,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        incidentlocationdesc: [
          {
            required: p48required[this.claimTypes]?.incidentlocation,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        incidentlocationprovince: [
          {
            required: p48required[this.claimTypes]?.incidentlocation,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        incidentlocationcity: [
          {
            required: p48required[this.claimTypes]?.incidentlocation,
            trigger: 'blur',
            message: 'Es obligatorio'
          }
        ],
        fraudrecordnum: [
            {
              required: p48required[this.claimTypes]?.fraudrecordnum,
              trigger: 'blur',
              message: 'Es obligatorio'
            }
          ],
          nnssexpedient: [
            {
              required: p48required[this.claimTypes]?.nnssexpedient,
              trigger: 'blur',
              message: 'Es obligatorio'
            }
          ],
          invoicenumber: [
            {
              required: p48required[this.claimTypes]?.invoicenumber,
              trigger: 'blur',
              message: 'Es obligatorio'
            }
          ]
      }
    }
  }

  protected onSubmit(model) {
    this.$emit('submit', model)
  }
}
