var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Cups-GeneralSection", { attrs: { cups: _vm.cups } }),
      _c("Cups-ClientSection", { attrs: { cups: _vm.cups } }),
      _c("Cups-MeterSection", { attrs: { cups: _vm.cups } }),
      _c("Cups-InspectionsSection", { attrs: { cups: _vm.cups } }),
      _c("Cups-LocationSection", { attrs: { cups: _vm.cups } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }