export default {
  confirmPassword: {
    "title": "Cambiar contraseña",
    "description": "Si tu correo eletrónico es <b>{0}</b>, por favor introduce tu nueva contraseña.",
    "paramsError": "Ha occurrido un error al intentar cambiar la contraseña",
    "validatorInputPassword": "Introduce tu nueva contraseña",
    "repeatPasswordValidator": "Repite tu nueva contraseña",
    "noMatchValidator": "No coninciden las dos contraseñas",
    "succesTitle": "Contraseña cambiada",
    "successMessage": "Contraseña cambiada correctamente",
    "errorTitle": "Error",
    "errorMessage": "Error en la peticion para cambiar de contraseña",
  }
} 