import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue'

import { Store } from '@/Core/Models/Stores'
import _ from 'lodash'

@Component
export default class Contracts extends Vue {
  /**
   * STORE
   */

  // Getters
  @MapGetter(Store.HistoryContracts) protected historyContracts: any[]
  @MapGetter(Store.HistoryContracts) protected totalElements: number
  @MapGetter(Store.HistoryContracts) protected pageSize: number
  @MapGetter(Store.HistoryContracts) protected isLoading: boolean
  @MapGetter(Store.HistoryContracts) protected totalActiveFilter: number

  //Actions
  @MapAction(Store.HistoryContracts) protected obtainHistoryContracts: () => Promise<void>
  @MapAction(Store.HistoryContracts) protected newPageSize: (pageSize: number) => Promise<void>
  @MapAction(Store.HistoryContracts) protected processUrlQueryParams: (queryParams: any) => void

  /**
   * EVENTS
   */

  protected async onChangePageSize(pageSize: number) {
    this.newPageSize(pageSize)
  }

  /**
   * WATCHERS
   */

  @Watch('$route', { deep: true, immediate: true })
  protected async onChangeQueryParams() {
    this.processUrlQueryParams(this.$route.query)
  }
}
