var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "bills-details"
    },
    [
      _c("Bills-Header"),
      _vm.error
        ? _c(
            "div",
            [
              _c("el-alert", {
                attrs: {
                  title: "Error",
                  type: "error",
                  description: _vm.error,
                  "show-icon": "",
                  closable: false
                }
              })
            ],
            1
          )
        : !_vm.loading
        ? _c(
            "div",
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "space-between" },
                  [_c("Bills-AmountSection"), _c("Bills-DetailsRight")],
                  1
                )
              ]),
              _c("Bills-CompaniesSection"),
              _c("Bills-ProcessSection"),
              _c("Bills-BillInfo"),
              _c("Bills-BillConcepts")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }