import AbstractApi, { Method } from '@/Core/api/AbstractApi'
import { AxiosPromise } from 'axios'
import _ from 'lodash'

interface GetHistoryAtcomListParams {
  processCodes: string[]
  page: number
  size: number
}

const CORE_SERVICE_URL_BASE = process.env.VUE_APP_CORE_URL
const Path = {
  HistoryAtcom: `${CORE_SERVICE_URL_BASE}/hist-flow-process/paged`,
  Download: `${CORE_SERVICE_URL_BASE}/hist-flow-process/download`
}

class HistoryAtcomApiSingleton extends AbstractApi {
  private static _instance: HistoryAtcomApiSingleton
  private constructor() {
    super()
  }

  public static get instance(): HistoryAtcomApiSingleton {
    if (!HistoryAtcomApiSingleton._instance)
      HistoryAtcomApiSingleton._instance = new HistoryAtcomApiSingleton()
    return HistoryAtcomApiSingleton._instance
  }

  public getHistoryAtcomList({ ...rawParams }: GetHistoryAtcomListParams): AxiosPromise {
    const method = Method.GET
    const url = Path.HistoryAtcom
    const params = {
      ...rawParams,
      processCodes: rawParams.processCodes || '44, 45, 46, 47, 48'
    }

    return this.request({ method, url, params })
  }

  public async downloadHistoryAtcom(id: number): Promise<string> {
    const method = Method.GET
    const url = Path.Download
    const params = {
      id
    }

    const { data } = await this.request({ method, url, params })

    return data
  }
}

export const HistoryAtcomApi = HistoryAtcomApiSingleton.instance
