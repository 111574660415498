import FormValidateMixin from '@/Core/mixins/FormValidate'
import { Store } from '@/Core/Models/Stores'
import { DashboardPages } from '@/Modules/Dashboard/router'
import { Component, MapAction, MapGetter, mixins, Vue } from 'types-vue'
import { SystemSubject } from '../../models'
import CupsModal from '../CupsModal/CupsModal'

@Component
export default class CurForm extends mixins(FormValidateMixin) {

  protected loading: boolean = false
  protected cup: string = ''

  @MapGetter(Store.SendCur) protected systemSubjectsOrigin: SystemSubject
  @MapGetter(Store.SendCur) protected systemSubjectsDestination: SystemSubject
  @MapAction(Store.SendCur) protected sendCur: (payload: { origin: string, destination: string, cups: string[], reqTransferDate: string }) => Promise<void>

  protected form = {
    origin: '',
    destination: '',
    reqTransferDate: '',
    cups: []
  }

  protected rules = {
    origin: [{ required: true, message: 'El origen es obligatorio.', trigger: 'change' }],
    destination: [{ required: true, message: 'El destino es obligatorio', trigger: 'change' }],
    reqTransferDate: [{ required: true, message: 'La fecha es obligatoria', trigger: 'change' }],
  }


  protected async send() {
    this.loading = true
    await this.sendCur({
      origin: this.form.origin,
      destination: this.form.destination,
      cups: this.form.cups,
      reqTransferDate: this.form.reqTransferDate
    })
    this.$router.push({ name: DashboardPages.Dashboard })
    this.loading = false
  }


  protected onInputConfirm() {
    let cup = this.cup;
    
    if (cup) {
      const list = cup.split(' ')
      list.forEach(el => {
          if(el && !this.form.cups.includes(el)) {
            this.form.cups.push(el);
          }
        
      });
    }

    this.cup = '';
  }

  protected onClose(cup) {
    this.form.cups.splice(this.form.cups.indexOf(cup), 1);
  }

  protected async onSubmit() {
    if (!await this.validateForm()) return
    if (this.form.cups.length > 0) await this.send()
    const cupsModal = this.$refs.cupsModal as CupsModal
    cupsModal.show(this.form.origin, '2021-01-01')
  }

  protected async onSelectCups(cups: string[]) {
    this.form.cups = cups
    await this.send()
  }
}