import { Component, Prop, Vue } from 'types-vue'

@Component
export default class ExtensionIcon extends Vue {
  @Prop({ required: true, type: String }) protected extension: string


  protected get fileIcon(): string {
    const extension = (this.extension[0] === '.') ? this.extension.substring(1) : this.extension
    return `fiv-icon-${extension}`
  }
}